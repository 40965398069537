import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../Icon/index.js'
import ClickOutside from '../ClickOutside/index.js'

const Modal = ({ show, className, children, onClose, allowClickOutside, hasCloseButton }) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <div className={classNames('modal-backdrop', { show })}>
      { allowClickOutside && (
        <ClickOutside onClick={handleClose}>
          <div className={classNames('modal', className)}>
            {hasCloseButton
              ? <Icon
            className='modal-close fas fa-times'
            onClick={handleClose}
                />
              : ''}
            {children}
          </div>
        </ClickOutside>
      )}

      { !allowClickOutside && (
        <div className={classNames('modal', className)}>
          {hasCloseButton
            ? <Icon
            className='modal-close fas fa-times'
            onClick={handleClose}
              />
            : ''}
          {children}
        </div>
      )}
    </div>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  allowClickOutside: PropTypes.bool,
  hasCloseButton: PropTypes.bool
}

Modal.defaultProps = {
  show: false,
  onClose: () => {}, // This is intentional
  allowClickOutside: true,
  hasCloseButton: true
}

export default Modal
