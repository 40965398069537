import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import sessionWatcher from '@graphql/mutators/session-watcher'
import destroySession from '@graphql/mutators/destroy-session'
import { cookie, faviconSwitcher } from '@utils'
import { Buttons } from '@shared/Action'
import { Button } from '@lib'
import Modal from '@lib/Modal'
import { useIdleTimer } from 'react-idle-timer'

const popUpExceptions = ['login', 'authenticate', 'create-password', 'forgot-password', 'change-password', 'tos', 'update-password']

const SessionPopUp = () => {
  const location = useLocation()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [showModal, setShowModal] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [userState, setUserState] = useState('Active')
  const [remaining, setRemaining] = useState(0)
  const [elapsed, setElapsed] = useState(0)
  const [count, setCount] = useState(0)

  const settingsCookie = JSON.parse(cookie.get('sessionSettings') || null)
  const [sessionSettings, setSessionSettings] = useState(settingsCookie)
  let promptBeforeIdle = sessionSettings?.promptBeforeIdle || settingsCookie?.promptBeforeIdle
  let timeout = sessionSettings?.timeout || settingsCookie?.timeout
  const noIdler = popUpExceptions.find(e => location.pathname.indexOf(e) >= 0)

  useEffect(() => {
    if (!noIdler) {
      if (!sessionSettings && cookie.get('sessionSettings')) {
        setSessionSettings(JSON.parse(cookie.get('sessionSettings')))
      } else if (!sessionSettings || cookie.get('sessionSettings')) {
        sessionWatcher().then(({ data }) => {
          if (data.sessionWatcher) {
            cookie.set('sessionSettings', 0, -1)
            const session = data.sessionWatcher
            if (session.isTableauAccessUpdated) {
              cookie.set('sessionStatus', 'false', 15)
              localStorage.clear()
              try {
                destroySession().then(({ data, errors, extensions }) => {
                  cookie.set('isTableauAccessUpdated', true, 14)
                  window.location.href = '/login'
                })
              } catch (err) {
                window.location.href = '/login'
              }
            } else {
              timeout = Math.ceil(Math.abs(new Date().getTime() - parseInt(session.expiresAt)) / 1000) * 1000
              promptBeforeIdle = session.sessionWarningTime * 1000
              setSessionSettings({
                timeout,
                promptBeforeIdle
              })
            }
          }
        })
      }
    }
  }, [sessionSettings, noIdler])

  const onIdle = () => {
    logoutSession(true)
    setShowWarningModal(false)
    setShowModal(true)
  }

  const onActive = () => {
    setUserState('Active')
    setShowWarningModal(false)
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const onPrompt = () => {
    setUserState('Prompted')
    setShowWarningModal(true)
  }

  const { getRemainingTime, getElapsedTime, activate } = useIdleTimer({
    disabled: noIdler,
    crossTab: true,
    syncTimers: 200,
    onIdle,
    onActive,
    onAction,
    onPrompt,
    promptBeforeIdle,
    timeout,
    throttle: 500
  })

  useEffect(() => {
    if (!noIdler) {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
        setElapsed(Math.ceil(getElapsedTime() / 1000))
      }, 500)

      const interval2 = setInterval(() => {
        if (localStorage.getItem('lastDashInteraction')) {
          localStorage.removeItem('lastDashInteraction')
          activate()
        }
        if (cookie.get('sessionStatus') === 'false') {
          onIdle()
        }
      }, 2000)

      return () => {
        clearInterval(interval)
        clearInterval(interval2)
      }
    }
  }, [noIdler])

  useEffect(() => {
    const isTableauAccessFlag = cookie.get('isTableauAccessUpdated')
    if ((query.get('session_timeout') || isTableauAccessFlag === 'true') && cookie.get('sessionStatus') === 'false') {
      faviconSwitcher.update(true)
      setShowModal(true)
      setShowWarningModal(false)
      window.blur()
      setTimeout(window.focus, 0)
    }
  }, [])

  const logoutSession = (timedOut) => {
    localStorage.clear()
    try {
      destroySession().then(({ data, errors, extensions }) => {
        cookie.set('csrfCookie', 0, -1)
        cookie.set('sessionStatus', 'false', 14)
        window.location.href = '/login' + (timedOut ? '?session_timeout=true' : '')
      }).catch(err => {
        console.log(err)
        cookie.set('csrfCookie', 0, -1)
        cookie.set('sessionStatus', 'false', 14)
        window.location.href = '/login' + (timedOut ? '?session_timeout=true' : '')
      })
    } catch (err) {
      cookie.set('csrfCookie', 0, -1)
      cookie.set('sessionStatus', 'false', 14)
      window.location.href = '/login' + (timedOut ? '?session_timeout=true' : '')
    }
  }

  const timerDisplay = (timeSeconds) => {
    const date = new Date(0)
    date.setSeconds(timeSeconds) // specify value for SECONDS here
    const timeString = String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0')
    return timeString
  }

  const continueSession = () => {
    activate()
  }

  if (noIdler && !showModal && !showWarningModal) {
    return null
  }

  return (
    <Fragment>
      <Modal
        show={showModal}
        onClose={() => {
          cookie.set('isTableauAccessUpdated', false, -15)
          if (showModal) {
            window.location.href = '/login'
          }
          setShowModal(false)
        }}
      >
        <div className='header'>
          Session Message
        </div>
        <div className='content'>
          <div className='content-message centered'>
            {
              query.get('session_timeout') || !cookie.get('isTableauAccessUpdated')
                ? 'Your session has expired due to inactivity. Please log in again.'
                : 'Your access has been updated. Please log in again.'
            }
          </div>
        </div>
      </Modal>

      {window.location.pathname !== '/login' &&
        <Modal
          show={showWarningModal}
          allowClickOutside={false}
          onClose={() => continueSession()}
          hasCloseButton={false}
        >
          <div className='header'>
            Your session is about to expire
          </div>
          <div className='content'>
            <div className='content-message'>
              Do you want to stay signed in? You will be logged out in <span className='timer'>{timerDisplay(remaining)}s</span>
            </div>
            <Buttons className='flex right'>
              <Button
                className='small primary blue'
                text='Keep me signed in'
                onClick={() => continueSession()}
              />

              <Button
                className='small secondary'
                text='Sign me out'
                onClick={() => logoutSession()}
              />
            </Buttons>
          </div>
        </Modal>
      }

    </Fragment>
  )
}

export default SessionPopUp
