import client from '../client.js'
import templateFieldMappingGql from '../queries/template-field-mapping.gql'
import updateTemplateFieldMappingGql from '../mutations/update-template-field-mapping.gql'

const updateTemplateFieldMapping = (input) => {
  return client.mutate({
    mutation: updateTemplateFieldMappingGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateTemplateFieldMapping) {
        const variables = {
          id: input.id
        }
        try {
          store.writeQuery({
            query: templateFieldMappingGql,
            variables,
            data: {
              templateFieldMappings: data.updateTemplateFieldMapping
            }
          })
        } catch (error) {
          client.query({
            query: templateFieldMappingGql,
            variables
          })
        }
      }
    }
  })
}

export default updateTemplateFieldMapping
