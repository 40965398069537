import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Can } from '@caslConfig/can.js'
import { Datatable, SelectColumnFilter, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import masterReferencesGql from '@graphql/queries/master-references'
import destroyMasterReference from '@graphql/mutators/destroy-master-reference'

const MasterReferenceList = ({
  links,
  onDelete
}) => {
  // values query
  const masterReferencesQuery = useQuery(masterReferencesGql, {
    fetchPolicy: 'network-only'
  })

  const handleDelete = (masterReferenceID) => {
    Swal.fire({
      text: 'Are you sure you want to delete this record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        destroyMasterReference(masterReferenceID).then(() => {
          onDelete(masterReferenceID)
          Swal.fire({
            text: 'Master reference deleted!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Reference Group',
        accessor: 'group.value',
        Filter: SelectColumnFilter,
        filter: 'includes'
      },
      {
        Header: 'Value',
        accessor: 'value'
      },
      {
        id: 'actionBtnsCol',
        accessor: 'id',
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='MasterReference'>
                <Link
                  className='action-button edit-button'
                  to={`${links.edit}/${value}`}
                  icon='fas fa-pencil-alt'
                  id={`${value}-edit`}
                />
                <Tooltip
                  anchorId={`${value}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='MasterReference'>
                <div
                  onClick={() => handleDelete(value)}
                  className='action-button delete-button'
                  id={`${value}-delete`}
                >
                  <Icon
                    className='fas fa-trash'
                  />
                </div>
                <Tooltip
                  anchorId={`${value}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (masterReferencesQuery.loading || masterReferencesQuery.error) {
    return null
  }

  const { masterReferences } = masterReferencesQuery.data

  if (!masterReferences) {
    return null
  }

  return (
    <ListView>
      <Can I='create' a='MasterReference'>
        <Datatable
          columns={columns}
          data={masterReferences}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Master Reference',
            to: '/settings/master-reference/new'
          }}
        />
      </Can>
      <Can not I='create' a='MasterReference'>
        <Datatable
          columns={columns}
          data={masterReferences}
        />
      </Can>
    </ListView>
  )
}

MasterReferenceList.propTypes = {
  clientId: PropTypes.string,
  templateGroup: PropTypes.string,
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string
}

MasterReferenceList.defaultProps = {
  clientId: null,
  links: {
    view: '',
    edit: ''
  },
  fields: {
    client: true,
    template: true,
    templateField: true,
    name: true,
    isRequired: false,
    action: true
  },
  onDelete: () => {}, // This is intentional
  filters: {},
  value: null
}

export default MasterReferenceList
