import React from 'react'
import PropTypes from 'prop-types'

const LoadOverlay = ({ loaderType, progress }) => {
  if (loaderType === 'progressBar') {
    if (progress > 0 && document.querySelector('.progressBarInner')) {
      document.querySelector('.progressBarInner').style.width = progress + '%'
    }
    return (
      <div className='loadOverlayWrap'>
        <div className='loaderWrap'>
          <div className='progressBarLoader'>
            <div className='progressBarInner'></div>
          </div>
        </div>
      </div>
    )
  }

  if (loaderType === 'preloader') {
    return (
      <div className='preloadOverlayWrap'>
        <div className='preloaderWrap'>
          <div className='preloaderCircle'>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    )
  }

  if (loaderType === 'componentLoader') {
    return (
      <div className='componentOverlayWrap'>
        <div className='componentWrap'>
          <div className='componentCircle'>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='loadOverlayWrap' >

    </div>
  )
}
LoadOverlay.propTypes = {
  loaderType: PropTypes.string,
  progress: PropTypes.number
}
LoadOverlay.defaultProps = {
  loaderType: ''
}

export default LoadOverlay
