import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import xevantLogo from '@images/xevant-logo-2021.png'

const Unauthorized = ({ className }) => (
  <div className={classNames('error-message', className)}>
    <img src={xevantLogo} className='unauthorized'/>
    <h1>We&apos;re Sorry...</h1>
    <h4>The link you followed is invalid or has expired. If you require assistance,
      please contact your Xevant client success representative or send an
      email to clientsuccess@xevant.com</h4>
  </div>
)

Unauthorized.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

Unauthorized.defaultProps = {
  children: null
}

export default Unauthorized
