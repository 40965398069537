import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import client from '@graphql/client'

import tableauNavigationsGql from '@graphql/queries/tableau-navigations'
import menuOrderSettingGql from '@graphql/queries/menu-order-setting'

const customSortingList = [
  { name: 'Executive Dashboard', order: ['Executive Dashboard', 'Specialty Trend Drivers', 'Non-Specialty Trend Drivers', 'Home Scorecard', 'Scorecard'] },
  { name: 'FinanceLogic', order: ['Scorecard', 'Contract Performance', 'Inflation', 'PMPM', 'Pricing Performance', 'NDC Price Lookup', 'Rebate'] },
  { name: 'ScripLogic', order: ['Drug Cost Report', 'Utilization', 'Channel', 'Top Drugs and Therapy Classes', 'GLP-1 Insights'] },
  { name: 'Services', order: ['Claims Data', 'Power Data'] }
]

const crumdaleSortingList = [
  { name: 'Executive Dashboard', order: ['Executive Dashboard', 'Specialty Trend Drivers', 'Non-Specialty Trend Drivers', 'Home Scorecard', 'Scorecard'] },
  { name: 'FinanceLogic', order: ['Scorecard', 'Contract Performance', 'Inflation', 'PMPM', 'Pricing Performance', 'NDC Price Lookup', 'Rebate'] },
  { name: 'ScripLogic', order: ['Scorecard', 'Drug Analysis', 'Drug Cost Reports', 'Member', 'Utilization', 'Channel', 'Member Cost', 'GLP-1 Insights'] },
  { name: 'Services', order: ['Claims Data', 'Power Data'] }
]

const DynamicNavigation = ({ user }) => {
  const menuStorage = JSON.parse(localStorage.getItem('menu-storage'))
  const [menuDrops, setMenuDrops] = useState({ ...menuStorage })
  localStorage.setItem('menu-storage', JSON.stringify(menuDrops))

  const systemMenuOrderStorage = localStorage.getItem('system-menu-order-storage')
  const menuOrderLastFetchedAt = (localStorage.getItem('system-menu-order-storage-last-fetch-at'))
  const [systemMenuOrder, setSystemMenuOrder] = useState(systemMenuOrderStorage)
  localStorage.setItem('system-menu-order-storage', systemMenuOrder)
  const currentClientLastUpdateAt = (new Date(user.userClients?.filter(u => u.clientId === user.clientId)[0].updatedAt).getTime())
  useEffect(() => {
    if (!systemMenuOrder || !menuOrderLastFetchedAt || menuOrderLastFetchedAt < currentClientLastUpdateAt) {
      client.query({
        query: menuOrderSettingGql
      }).then(({ data }) => {
        if (data.menuOrderSetting) {
          setSystemMenuOrder(data.menuOrderSetting?.settingValue?.toLowerCase())
          localStorage.setItem('system-menu-order-storage-last-fetch-at', Date.now())
        } else {
          setSystemMenuOrder('.')
        }
      })
    }
  }, [systemMenuOrder])

  let expandNavs = []
  const expandNavStore = JSON.parse(localStorage.getItem('tabNavs'))
  let navTypeFilt = 'appExpandNav'
  if (window.location.pathname.split('/')[1] === 'datalogic') {
    navTypeFilt = 'datalogicExpandNav'
  }

  const [loadNav, setLoadNav] = useState(true)
  useEffect(() => {
    // ONLY QUERY IF NOT UPDATED IN LAST 3 MINUTES
    if (!user.userClients?.filter(u => u.clientId === user.clientId)[0].navigation || Date.now() - new Date(user.userClients?.filter(u => u.clientId === user.clientId)[0].updatedAt) > 180000) {
      if (loadNav) {
        client.query({
          query: tableauNavigationsGql,
          variables: {
            navigationType: navTypeFilt,
            client: user?.client?.name
          }
        }).then(({ data }) => {
          if (data.tableauNavigations !== JSON.stringify(JSON.parse(localStorage.getItem('tabNavs')))) {
            localStorage.setItem('tabNavs', data.tableauNavigations)
          }
          setLoadNav(false)
        })
      }
    }
  }, [loadNav])

  if (expandNavStore?.length > 0) {
    if (Object.hasOwn(expandNavStore[0], 'contentUrl') && user.client.contentUrl !== expandNavStore[0].contentUrl) {
      localStorage.removeItem('tabNavs')
      setLoadNav(true)
    } else {
      expandNavs = expandNavStore
    }
  } else {
    // getting cached navigation from userClient
    const activeUserClient = user.userClients?.filter(u => u.clientId === user.clientId)[0]
    if (activeUserClient.navigation !== null) {
      localStorage.setItem('tabNavs', activeUserClient.navigation)
      expandNavs = JSON.parse(activeUserClient.navigation)
    }
  }

  if (!expandNavs || expandNavs.length < 1) {
    return <p className='popout-menu-loading'>Fetching Menu</p>
  }
  const NavLink = ({ item }) => {
    let isActive
    if (item.name === 'DataLogic') {
      isActive = (window.location.pathname === item.contentUrl)
    } else {
      isActive = window.location.pathname.includes(`/dashboard/${item.contentUrl}/${item.defaultView.viewUrlName}`)
    }
    if (item.name === 'DataLogic') {
      return (
        <Link to={item.contentUrl}>
          <div className={'link xev-dashboard-item ' + (isActive ? 'active' : '')}>
              <p>{item.name}</p>
          </div>
        </Link>
      )
    }

    return (
      <>
        <Link to={`/dashboard/${item.contentUrl}/${item.defaultView.viewUrlName}`}>
          <div className={'link xev-dashboard-item ' + (isActive ? 'active' : '')}>
            <p>{item.name}</p>
          </div>
        </Link>
      </>
    )
  }
  NavLink.propTypes = {
    item: PropTypes.object
  }

  const NavDrop = ({ item }) => {
    const handlePlaceholderClick = () => {
      setMenuDrops(prevState => ({
        ...prevState,
        [item.id]: !menuDrops[item.id]
      }))
    }

    const sampleData = [
      { name: 'Executive Dashboard', icon: 'far fa-house' },
      { name: 'ScripLogic', icon: 'far fa-mortar-pestle' },
      { name: 'ClinicalLogic', icon: 'far fa-prescription-bottle-medical' },
      { name: 'FinanceLogic', icon: 'far fa-dollar-sign' },
      { name: 'Plan Review', icon: 'far fa-memo' },
      { name: 'AlertLogic', icon: 'far fa-bell' },
      { name: 'BidLogic', icon: 'far fa-square-dollar' },
      { name: 'RebateLogic', icon: 'far fa-tags' },
      { name: 'TrendLogic', icon: 'far fa-arrow-trend-up' },
      { name: 'Services', icon: 'far fa-bell-concierge' },
      { name: 'Custom Reports', icon: 'far fa-square-poll-vertical' },
      { name: 'Legacy', icon: 'far fa-table-layout' }
    ]

    const icon = sampleData.find(x => x.name === item.name)?.icon

    const urlSplit = window.location.hostname.split('.')
    const hasCustomSort = urlSplit[0] === 'crumdalepartners' ? crumdaleSortingList.find(x => x.name === item.name) : customSortingList.find(x => x.name === item.name)

    if (hasCustomSort) {
      if (item.name === 'Executive Dashboard') {
        // fake views into workbooks
        const edWb = item.workbooks.find(w => w.name === 'Executive Dashboard')
        const fakeWbs = edWb.views.map(v => {
          return {
            name: v.name,
            contentUrl: edWb.contentUrl,
            defaultView: { viewUrlName: v.viewUrlName }
          }
        })
        item.workbooks = [...fakeWbs, ...item.workbooks.filter(w => w.name !== 'Executive Dashboard')]
      }

      return (
        <div className='placeholder'>
        <div onClick={handlePlaceholderClick} className='placeholder-name-container'>
          <div className='icon-container'>
            <i className={icon + ' fa-fw'}></i>
          </div>
          <p className='placeholder-name'>{item.name}</p>
          <div className='placeholder-arrow'>
            <i className={`far fa-chevron-down ${menuDrops[item.id] && 'rotate'}`}></i>
          </div>
        </div>
        <div className={`placeholder-children ${menuDrops[item.id] && 'expand'}`}>
          {hasCustomSort.order.map((x, key) => {
            const a = item.workbooks.find(w => w.name === x)
            const b = item.children.find(c => c.name === x)
            if (a) {
              return (
                <NavLink item={a} key={key} />
              )
            } else if (b) {
              return (
                <NavDrop item={b} key={key} />
              )
            }
          })}

          {item?.views?.filter(x => !hasCustomSort.order.includes(x.name)).map((i, key) => {
            return <NavLink item={i} key={key} />
          })}

          {item?.workbooks?.filter(x => !hasCustomSort.order.includes(x.name)).map((i, key) => {
            return <NavLink item={i} key={key} />
          })}

          {item?.children?.filter(x => !hasCustomSort.order.includes(x.name)).map((i, key) => {
            return <NavDrop item={i} key={key} />
          })}
        </div>
      </div>
      )
    } else {
      return (
        <div className='placeholder'>
          <div onClick={handlePlaceholderClick} className='placeholder-name-container'>
            <div className='icon-container'>
              <i className={icon + ' fa-fw'}></i>
            </div>
            <p className='placeholder-name'>{item.name}</p>
            <div className='placeholder-arrow'>
              <i className={`far fa-chevron-down ${menuDrops[item.id] && 'rotate'}`}></i>
            </div>
          </div>
          <div className={`placeholder-children ${menuDrops[item.id] && 'expand'}`}>
          {item.views
            ? <>
              {item.views.map((x, key) => {
                const viewItem = {
                  contentUrl: item.contentUrl,
                  defaultView: { viewUrlName: x.viewUrlName },
                  name: x.name
                }
                return <NavLink item={viewItem} key={key} />
              })}
            </>
            : <>
            {item?.workbooks?.sort((a, b) => {
              const findCustomSorting = urlSplit[0] === 'crumdalepartners' ? crumdaleSortingList.find(x => x.name === item.name) : customSortingList.find(x => x.name === item.name)
              if (findCustomSorting) {
                const aIndex = findCustomSorting.order.indexOf(a.name)
                const bIndex = findCustomSorting.order.indexOf(b.name)
                if (aIndex >= 0 && bIndex >= 0) {
                  return aIndex - bIndex
                } else if (aIndex < 0 && bIndex < 0) {
                  return 0
                } else if (aIndex < 0) {
                  return 1
                } else {
                  return -1
                }
              } else if (item.name === 'Plan Review') {
                if (a.name.toLowerCase().includes('updated')) {
                  return -1
                } else if (b.name.toLowerCase().includes('updated')) {
                  return 1
                } else if (a.name.toLowerCase().includes('xevant')) {
                  return 1
                } else if (b.name.toLowerCase().includes('xevant')) {
                  return -1
                } else {
                  return a.name.localeCompare(b.name)
                }
              } else {
                if (a.name.includes('Scorecard')) {
                  return -1
                } else if (b.name.includes('Scorecard')) {
                  return 1
                } else {
                  return a.name.localeCompare(b.name)
                }
              }
            }).map((x, key) => {
              if (item.name === 'Executive Dashboard' || item.name === 'Claims Data') {
                return x.views.map((v, key2) => {
                  const viewItem = {
                    contentUrl: x.contentUrl,
                    defaultView: { viewUrlName: v.viewUrlName },
                    name: v.name
                  }
                  return <NavLink item={viewItem} key={key2} />
                })
              } else if (x.name === 'BL 3.0') {
                return x.views.map((v, key2) => {
                  const blDashList = ['Rate Summary']
                  if (blDashList.includes(v.name)) {
                    const viewItem = {
                      contentUrl: x.contentUrl,
                      defaultView: { viewUrlName: v.viewUrlName },
                      name: v.name
                    }
                    return <NavLink item={viewItem} key={key2} />
                  }
                  return null
                })
              } else if ((item.name === 'RebateLogic' && x.name === 'RebateLogic Proposal')) {
                return <NavDrop item={x} key={key} />
              } else if (item.name === 'RebateLogic Proposal' && key === 0) {
                return (
                  <Fragment key={key}>
                    <Link to={'/datalogic/file-uploads/claims/new-upload'}>
                      <div className={`link xev-dashboard-item ${window.location.pathname.includes('/datalogic/file-uploads/claims/new-upload') ? 'active' : ''}`}>
                          <p>Claims File Upload</p>
                      </div>
                    </Link>

                    <NavLink item={x} />
                  </Fragment>
                )
              }
              return <NavLink item={x} key={key} />
            })}
            {item.children.map((x, key) =>
              <NavDrop item={x} key={key} />
            )}
            </>
            }
          </div>
        </div>
      )
    }
  }
  NavDrop.propTypes = {
    item: PropTypes.object
  }

  if (expandNavs.length === 1) {
    if (!systemMenuOrder) {
      return null
    }
    const item = expandNavs[0]
    if (user?.client?.clientModules?.find(cm => cm.module.name === 'BidLogic')) {
      const bidlogicFolder = item.children.find(x => x.name === 'BidLogic')
      if (bidlogicFolder) {
        bidlogicFolder.workbooks.push({
          name: 'DataLogic',
          contentUrl: '/datalogic'
        })
      }
    }
    const sortOrder = (a, b) => {
      const aIndex = systemMenuOrder.indexOf(a.name.toLowerCase())
      const bIndex = systemMenuOrder.indexOf(b.name.toLowerCase())

      if (aIndex >= 0 && bIndex >= 0) {
        return aIndex - bIndex
      } else if (aIndex < 0 && bIndex < 0) {
        return 0
      } else if (aIndex < 0) {
        return 1
      } else {
        return -1
      }
    }
    item.children.sort(sortOrder)
    return (
      <div className='popout-menu-nav'>
        <div className='placeholder-children expand'>
          {(() => {
            const execDashboard = item.children.find(c => c.name === 'Executive Dashboard')

            if (execDashboard) {
              item.children.splice(item.children.indexOf(execDashboard), 1)
              return <NavDrop item={execDashboard} />
            }
          })()}
          {(() => {
            const hsWb = user.homepage ? item.workbooks.find(w => w.contentUrl === user.homepage) : item.workbooks.find(w => w.name === 'Home Scorecard' || w.name === 'Scorecard')

            if (hsWb) {
              item.workbooks.splice(item.workbooks.indexOf(hsWb), 1)
              return <NavLink item={hsWb} />
            }
          })()}
          {item.children.map((x, key) =>
            <NavDrop item={x} key={key} />
          )}
          {item.workbooks.filter(x => (x.name !== 'Usage Analytics' && x.name !== 'Data Dictionary')).map((x, key) =>
            <NavLink item={x} key={key} />
          )}
        </div>
      </div>
    )
  }
  return (
    <div>
      {expandNavs?.map((navI, key) =>
        navI.showTabs
          ? (
            <NavLink item={navI} key={key} />
            )
          : (
            <NavDrop item={navI} key={key} />
            )
      )}
    </div>
  )
}
DynamicNavigation.propTypes = {
  user: PropTypes.object,
  item: PropTypes.object
}

export default DynamicNavigation
