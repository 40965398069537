import client from '../client.js'
import destroyUserFavoriteGql from '../mutations/destroy-user-favorite.gql'

const destroyUserFavorite = (input) => {
  return client.mutate({
    mutation: destroyUserFavoriteGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyUserFavorite
