import React, { Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import TemplateFieldMappingForm from '@shared/TemplateFieldMappingForm'

const Content = ({ user }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleSave = () => {
    Swal.fire({
      text: 'Field mapping updated!',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })

    navigate('/settings/template-settings#template-field-mappings')
  }

  return (
    <Fragment>

      <Title text='Edit Template Field Mapping' user={user} />

      <TemplateFieldMappingForm
        id={id}
        onSave={handleSave}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const EditTemplateFieldMapping = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default EditTemplateFieldMapping
