import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import PropTypes from 'prop-types'
import { Title } from '@lib'
import Swal from 'sweetalert2'
import UserForm from '@shared/UserForm'

const Content = ({ user }) => {
  const navigate = useNavigate()
  // console.log(user)
  const handleSave = () => {
    Swal.fire({
      text: 'User created',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })

    navigate('/settings/users')
  }

  return (
    <Fragment>

      <Title text='New User' user={user}/>

      <UserForm
        // id={id}
        onSave={handleSave}
        user={user}
      />
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const NewUser = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewUser
