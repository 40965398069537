import client from '../client.js'
import noticesGql from '../queries/notices.gql'
import createNoticeGql from '../mutations/create-notice.gql'

const createNotice = (input) => {
  return client.mutate({
    mutation: createNoticeGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createNotice) {
        try {
          // get notices cache
          const cache = store.readQuery({
            query: noticesGql
          })

          // add to notices cache
          store.writeQuery({
            query: noticesGql,
            data: {
              notices: [...cache.notices, data.createNotice]
            }
          })
        } catch (error) {
          // re-query notices
          client.query({
            query: noticesGql
          })
        }
      }
    }
  })
}

export default createNotice
