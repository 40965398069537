import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Icon, Tooltip } from '@lib'
import { Link, ListView } from '@shared'

import { Can } from '@caslConfig/can.js'
import resourcesGql from '@graphql/queries/resources'
import destroyResource from '@graphql/mutators/destroy-resource'

import Swal from 'sweetalert2'

const Content = ({ user }) => {
  const resourcesQuery = useQuery(resourcesGql, {
    fetchPolicy: 'network-only'
  })

  const handleDelete = (v) => {
    Swal.fire({
      text: 'Are you sure you want to delete this resource?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        destroyResource({ id: v }).then(() => {
          Swal.fire({
            text: 'Resource deleted',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          resourcesQuery.refetch()
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Summary',
        accessor: 'summary'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        id: 'actionBtnsCol',
        accessor: (d) => d,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='Resource'>
                <Link
                  className='action-button edit-button'
                  to={`/settings/trainings-and-use-cases/edit/${value.id}`}
                  icon='fas fa-pencil-alt'
                  id={`${value.id}-edit`}
                />
                <Tooltip
                  anchorId={`${value.id}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='Resource'>
                <div
                  onClick={() => handleDelete(value.id)}
                  className='action-button delete-button'
                  id={`${value.id}-delete`}
                >
                  <Icon className='fas fa-trash'/>
                </div>
                <Tooltip
                  anchorId={`${value.id}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (resourcesQuery.loading || resourcesQuery.error) {
    return null
  }
  const { resources } = resourcesQuery.data

  return (
    <Fragment>
      <Title text='Trainings & Use Cases' user={user} />

      <ListView>
        <Can I='create' a='Resource'>
          <Datatable
            columns={columns}
            data={resources}
            newRowBtn={{
              icon: 'fas fa-plus',
              text: 'New Resource',
              to: '/settings/trainings-and-use-cases/new'
            }}
          />
        </Can>
        <Can not I='create' a='Resource'>
          <Datatable
            columns={columns}
            data={resources}
          />
        </Can>
      </ListView>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const ResourcesList = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default ResourcesList
