import React, { useState, useEffect } from 'react'
import { Button } from '@lib'
import { Input } from '@shared/Form'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import updateClientSummaryLog from '@graphql/mutators/update-finance-summary-log'
import { getCurrentMonthName, time } from '@utils'

const FinanceApprovalForm = ({ data, user }) => {
  const step = data?.step
  const [initialApproval, setInitialApproval] = useState(false)
  const [initialTimeStamp, setInitialTimeStamp] = useState(null)
  const [paymentDate, setPaymentDate] = useState(data?.paymentDate)
  const [paymentTimeStamp, setPaymentTimeStamp] = useState(null)
  const [finalApproval, setFinalApproval] = useState(false)
  const [finalApprovalTimeStamp, setFinalApprovalTimeStamp] = useState(null)

  useEffect(() => {
    if (step === 2) {
      setInitialTimeStamp(time.utc(data.initialApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
    } else if (step === 3) {
      setInitialTimeStamp(time.utc(data.initialApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
      setPaymentTimeStamp(time.utc(data.pdCreatedAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
    } else if (step === 4) {
      setInitialTimeStamp(time.utc(data.initialApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
      setPaymentTimeStamp(time.utc(data.pdCreatedAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
      setFinalApprovalTimeStamp(time.utc(data.finalApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm a'))
    }
  }, [])
  const handleInitialApproval = () => {
    Swal.fire({
      text: 'Are you sure you want to approve this payment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve'
    }).then((result) => {
      if (result.isConfirmed) {
        // call graphql mutation to
        updateClientSummaryLog({
          date: String(new Date().toLocaleString()),
          period: getCurrentMonthName()
        }).then(({ data }) => {
          Swal.fire({
            text: 'Payment approved',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          setInitialApproval(true)
          setInitialTimeStamp(time(data.updateClientSummaryLog.initialApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm:a'))
        }).catch((err) => {
          Swal.fire({
            text: 'Error approving payment, please try again later.',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
          console.error('updating finance summary log error: ', err)
        })
      }
    })
  }

  const setSchedule = () => {
    Swal.fire({
      text: 'Are you sure? This will send a notification for Client Success for final approval.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Set date'
    }).then((result) => {
      if (result.isConfirmed) {
        // call graphql mutation to
        updateClientSummaryLog({
          date: String(paymentDate),
          period: getCurrentMonthName()
        }).then(({ data }) => {
          Swal.fire({
            text: 'Payment date has been set',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          setPaymentTimeStamp(time(data.updateClientSummaryLog.pdCreatedAt).tz(user.timezone).format('YYYY-MM-DD h:mm:a'))
        }).catch((err) => {
          Swal.fire({
            text: 'Error setting payment date, please try again later.',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
          console.error('setting payment date error: ', err)
        })
      }
    })
  }

  const handleFinalApproval = () => {
    Swal.fire({
      text: 'Are you sure you want to approve this payment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Approve'
    }).then((result) => {
      if (result.isConfirmed) {
        updateClientSummaryLog({
          date: String(new Date().toLocaleString()),
          period: getCurrentMonthName()
        }).then(({ data }) => {
          Swal.fire({
            text: 'Payment approved, moving data to Production',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          setFinalApproval(true)
          setFinalApprovalTimeStamp(time(data.updateClientSummaryLog.finalApprovalAt).tz(user.timezone).format('YYYY-MM-DD h:mm:a'))
        })
      }
    })
  }

  useEffect(() => {
    const wrap = document.querySelector('#tableauViz').getBoundingClientRect()
    document.querySelector('#finance-summary-approval').style.top = `${wrap.top}px`
    document.querySelector('#finance-summary-approval').style.left = `${wrap.left}px`
  }, [])
  return (
    <div id='finance-summary-approval'>
      { step >= 1 && (
      <div className='approval-step'>
        <div className='input-label'>
          Approval from Finance
        </div>
        <Button
        className='standard primary'
        text={initialApproval ? 'Approved' : 'Approve'}
        onClick={() => handleInitialApproval()}
        disabled={initialApproval || initialTimeStamp}
        />

        {initialTimeStamp && <div className='timestamp'>
          Timestamp: {initialTimeStamp}
        </div>}
      </div>)}

      { step >= 2 && (<div className='approval-step'>
        <hr/>
          <div className='schedule-input'>
            <Input
              placeholder='Set a date'
              type='datepicker'
              icon='fas fa-calendar'
              label='Payment Date'
              className='icon-right'
              settings={{
                minDate: new Date()
              }}
              value={paymentDate}
              onChange={(value) => setPaymentDate(value)}
              disabled={paymentTimeStamp}
            />
            {paymentDate &&
              <div className='button-spacer'>
                <Button
                  className='standard primary'
                  icon='fas fa-check'
                  onClick={() => setSchedule()}
                  disabled={paymentTimeStamp}
                />
              </div>
            }
          </div>

          {paymentTimeStamp && <div className='timestamp'>
            Timestamp: {paymentTimeStamp}
          </div>}
      </div>)}

      { step >= 3 && (<div className='approval-step'>
        <hr/>
        <div className='input-label'>
          Client Success
        </div>
        <Button
          className='standard primary'
          text={finalApproval ? 'Approved' : 'Approve'}
          onClick={() => handleFinalApproval()}
          disabled={finalApproval || finalApprovalTimeStamp}
        />
        {finalApprovalTimeStamp && <div className='timestamp'>
          Timestamp: {finalApprovalTimeStamp}
        </div>}
      </div>)}

    </div>
  )
}

FinanceApprovalForm.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object
}

export default FinanceApprovalForm
