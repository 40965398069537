import client from '../client.js'
import destroyNoticeGql from '../mutations/destroy-notice.gql'

const destroyNotice = (id) => {
  return client.mutate({
    mutation: destroyNoticeGql,
    variables: {
      id
    },
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyNotice
