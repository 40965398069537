import client from '../client.js'

import applyBidlogicFiltersGql from '../mutations/apply-bidlogic-filters.gql'

const applyBidlogicFilters = (input) => {
  return client.mutate({
    mutation: applyBidlogicFiltersGql,
    variables: input,
    update: (store, { data }) => {
      // console.log('Logger::sendForgotPasswordMutator', data)
    }
  })
}

export default applyBidlogicFilters
