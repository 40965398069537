import client from '../client.js'
import validateLoginGql from '../mutations/validate-login.gql'

const validateLogin = (input) => {
  return client.mutate({
    mutation: validateLoginGql,
    variables: input,
    update: (store, { data }) => {
      localStorage.setItem('MfaToken', data.validateLogin?.mfaToken)
    }
  })
}

export default validateLogin
