import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import { Loaders } from '@lib/index.js'
import { Title, Button, Icon, Modal } from '@lib'
import client from '@graphql/client'
import { Form, Group, Input, FormError } from '@shared/Form'
import { object, array, time } from '@utils'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import { Can } from '@caslConfig/can.js'
import TableauViz from '@shared/TableauViz'
import { FilterUpdateType } from '@utils/tableau-embed-api.js'

import bidlogicReferencesGql from '@graphql/queries/bidlogic-references'
import bidlogicPbmFiltersGql from '@graphql/queries/bidlogic-pbm-filters'
import bidlogicX4FiltersGql from '@graphql/queries/bidlogic-x4-filters'
import fileRunRequestsGql from '@graphql/queries/file-run-request'
import fileUploadsGql from '@graphql/queries/file-uploads'
import clientCustomersGql from '@graphql/queries/client-customers'
import createFileRunRequest from '@graphql/mutators/create-file-run-request'
import destroyFileRunRequest from '@graphql/mutators/destroy-file-run-request'
import bidlogicRateOptionsGql from '@graphql/queries/bidlogic-rate-options'
import applyBidlogicFilters from '@graphql/mutators/apply-bidlogic-filters'
import watchRequestStatus from '@graphql/mutators/watch-request-status'

const Content = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const [pbmList, setPbmList] = useState()
  const [rateCodeDescList, setRateCodeDescList] = useState()
  const [specialtyList, setSpecialtyList] = useState()
  const [networkList, setNetworkList] = useState()
  const [formularyList, setFormularyList] = useState()
  const [blImportClaims, setBlImportClaims] = useState()
  const [blRepriceDate, setBlRepriceDate] = useState()
  const [blGenericIndicator, setBlGenericIndicator] = useState()
  const [blRateSummaryType, setBlRateSummaryType] = useState()

  const [x4DateType, setX4DateType] = useState()
  const [x4FinalClaimStatus, setX4FinalClaimStatus] = useState()

  const [fileUploads, setFileUploads] = useState()
  const [currentReprice, setCurrentReprice] = useState()

  const [disabled, setDisabled] = useState(false)
  const [clearAllFilters, setClearAllFilters] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const ccagDefaultInputState = {
    ccagClient: [],
    ccagCarrier: [],
    ccagAccount: [],
    ccagGroup: []
  }
  const [ccagInput, setCcagInput] = useState(ccagDefaultInputState)
  const defaultInputState = {
    inputGroups: [
      {
        groupIndex: 0,
        id: null,
        network: null,
        formulary: null,
        specialtyList: null,
        rateCodeDesc: null
      }
    ]
  }
  const [input, setInput] = useState(defaultInputState)
  const [reprices, setReprices] = useState([])
  const [doneQueries, setDoneQueries] = useState({})
  const [clientCustomers, setClientCustomers] = useState()
  const [inputErrors, setInputErrors] = useState({})
  const [bidlogicFilters, setBidlogicFilters] = useState({})
  const [isBidlogic, setIsBidlogic] = useState(false)
  const [additionalInput, setAdditionalInput] = useState({})
  const [title, setTitle] = useState('')
  const [loaderState, setLoaderState] = useState({
    ccagField: null,
    ccagProcessing: true,
    submit: false,
    dynamicRatesApply: false
  })
  const [isDashboardExecutionParent, setIsDashboardExecutionParent] = useState(false);

  const alertFilters = null
  const importClaims = []
  const repriceDate = []
  const genericIndicator = []
  const rateSummaryType = []
  const dateType = []
  const finalClaimStatus = []

  let viz

  const defaultRatesInputState = {
    1: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Brand',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 30
    },
    2: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Brand',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 90
    },
    3: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Brand',
      specialty_indicator: 'Specialty',
      days_supply_group: 30
    },
    4: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Brand',
      specialty_indicator: 'Specialty',
      days_supply_group: 90
    },
    5: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Generic',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 30
    },
    6: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Generic',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 90
    },
    7: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Generic',
      specialty_indicator: 'Specialty',
      days_supply_group: 30
    },
    8: {
      mail_order_indicator: 'Mail Order',
      generic_indicator: 'Generic',
      specialty_indicator: 'Specialty',
      days_supply_group: 90
    },
    9: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Brand',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 30
    },
    10: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Brand',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 90
    },
    11: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Brand',
      specialty_indicator: 'Specialty',
      days_supply_group: 30
    },
    12: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Brand',
      specialty_indicator: 'Specialty',
      days_supply_group: 90
    },
    13: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Generic',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 30
    },
    14: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Generic',
      specialty_indicator: 'Non-Specialty',
      days_supply_group: 90
    },
    15: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Generic',
      specialty_indicator: 'Specialty',
      days_supply_group: 30
    },
    16: {
      mail_order_indicator: 'Retail',
      generic_indicator: 'Generic',
      specialty_indicator: 'Specialty',
      days_supply_group: 90
    }
  }
  const [state, setState] = useState({
    input: {
      rates: {
        ...defaultRatesInputState
      }
    },
    errors: {},
    rateOptions: []
  })
  const [ccagFilters, setCcagFilters] = useState({
    combos: []
  })
  const [resetViz, setResetViz] = useState(false)

  const watchAnalysisStatus = async (analysisIds) => {
    watchRequestStatus({
      ids: analysisIds
    }).then(resp => {
      if (resp.data?.watchRequestStatus?.length) {
        setReprices(resp.data.watchRequestStatus)
      } else if (resp.extensions) {
        Swal.fire({
          title: 'Error',
          text: 'There was an error processing your reprice request. Please try again later.',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }

  useEffect(() => {
    if (loading) {
      client.query({
        query: bidlogicPbmFiltersGql,
        fetchPolicy: 'no-cache'
      }).then(async ({ data }) => {
        if (data.bidlogicPbmFilters) {
          const response = JSON.parse(data.bidlogicPbmFilters)

          const clientPbmList = []
          const rateCodeDesc = []
          const specialtyList = []
          const networkList = []
          const formularyList = []
          const newFileUploads = {}

          await client.query({
            query: fileUploadsGql,
            fetchPolicy: 'no-cache'
          }).then(({ data }) => {
            data.fileUploads?.forEach(f => {
              if (f.fileStatus.value === 'Done') {
                newFileUploads[f.fileType] = [...newFileUploads[f.fileType] || []]
                newFileUploads[f.fileType].push({ value: f.id, text: f.fileName, clientCustomerId: f.clientCustomerId, pbmId: f.pbmId, status: f.fileStatus.value })
              }
            })

            response.data?.forEach(row => {
              clientPbmList.push({ value: row.pbm, text: row.pbm })

              row.rate_descriptions.forEach(rd => {
                if (rd) {
                  rateCodeDesc.push({ pbm: row.pbm, value: rd, text: rd })
                }
              })

              if (row.file_ids) {
                if (row.file_ids.specialty_ids) {
                  row.file_ids.specialty_ids.forEach(s => {
                    if (newFileUploads?.specialty?.find(file => file.value === s.toString())) {
                      specialtyList.push({ pbm: row.pbm, value: s, text: newFileUploads?.specialty?.find(file => file.value === s.toString())?.text })
                    }
                  })
                }

                if (row.file_ids.network_ids) {
                  row.file_ids.network_ids.forEach(n => {
                    if (newFileUploads?.network?.find(file => file.value === n.toString())) {
                      networkList.push({ pbm: row.pbm, value: n, text: newFileUploads?.network?.find(file => file.value === n.toString())?.text })
                    }
                  })
                }

                if (row.file_ids.formulary_ids) {
                  row.file_ids.formulary_ids.forEach(f => {
                    if (newFileUploads?.formulary?.find(file => file.value === f.toString())) {
                      formularyList.push({ pbm: row.pbm, value: f, text: newFileUploads?.formulary?.find(file => file.value === f.toString())?.text })
                    }
                  })
                }
              }
            })
          })

          setPbmList(clientPbmList)
          setRateCodeDescList(rateCodeDesc)
          setSpecialtyList(specialtyList)
          setNetworkList(networkList)
          setFormularyList(formularyList)
          setFileUploads(newFileUploads)
          setDoneQueries(prevState => ({
            ...prevState,
            fileUploads: true
          }))
        }
      })

      client.query({
        query: bidlogicReferencesGql,
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        const bidlogicRefs = data.bidlogicReferences
        bidlogicRefs.forEach(ref => {
          switch (ref.group.value) {
            case 'BidLogic Import Claims':
              if (ref.value.toLowerCase().includes('x4') && !user.client.clientModules.find(cm => cm.module.name === 'Home Scorecard')) {
                break
              }
              importClaims.push(ref)
              break
            case 'BidLogic Reprice Date':
              repriceDate.push(ref)
              break
            case 'BidLogic Generic Indicator':
              genericIndicator.push(ref)
              break
            case 'BidLogic Rate Summary Type':
              rateSummaryType.push(ref)
              break
            case 'X4 Date Type':
              dateType.push(ref)
              break
            case 'X4 Final Claim Status':
              finalClaimStatus.push(ref)
              break
          }
        })

        setInput(prevState => ({
          ...prevState,
          blImportClaims: importClaims[0]?.id
        }))
        setBlImportClaims(importClaims)
        setBlRepriceDate(repriceDate)
        setBlGenericIndicator(genericIndicator)
        setBlRateSummaryType(rateSummaryType)
        setX4DateType(dateType)
        setX4FinalClaimStatus(finalClaimStatus)

        setDoneQueries(prevState => ({
          ...prevState,
          bidlogicReferences: true
        }))
      })

      client.query({
        query: bidlogicX4FiltersGql,
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        if (data.bidlogicX4Filters) {
          const response = JSON.parse(data.bidlogicX4Filters)
          const cagOptions = {
            clients: [],
            carriers: [],
            accounts: [],
            groups: [],
            combos: response.data
          }
          response.data.forEach(c => {
            if (!cagOptions.clients.find(co => co.value === c.client)) {
              cagOptions.clients.push({ value: c.client, text: c.client })
            }
            if (!cagOptions.carriers.find(co => co.value === c.carrier)) {
              cagOptions.carriers.push({ value: c.carrier, text: c.carrier })
            }
            Object.keys(c.account_groups).forEach(ag => {
              if (!cagOptions.accounts.find(co => co.value === ag)) {
                cagOptions.accounts.push({ value: ag, text: ag })
              }

              c.account_groups[ag].forEach(g => {
                if (!cagOptions.groups.find(co => co.value === g)) {
                  cagOptions.groups.push({ value: g, text: g })
                }
              })
            })
          })

          setCcagFilters({
            ...cagOptions
          })

          setLoaderState(prevState => ({ ...prevState, ccagField: null, ccagProcessing: false, currentLevel: null }))
        }
      })

      client.query({
        query: fileRunRequestsGql,
        variables: {
          userId: user?.id
        },
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        if (data.fileRunRequests) {
          const notProcessedReqIds = data.fileRunRequests.filter(frr => !frr.isProcessed).map(frr => frr.id)
          if (notProcessedReqIds.length) {
            notProcessedReqIds.forEach(id => {
              watchAnalysisStatus([id])
            })
          }
          setReprices(data.fileRunRequests)
        }
        setDoneQueries(prevState => ({
          ...prevState,
          reprices: true
        }))
      })

      client.query({
        query: clientCustomersGql
      }).then(({ data }) => {
        if (data.clientCustomers) {
          setClientCustomers(data.clientCustomers)
        }
      })
    }
  }, [loading])

  useEffect(() => {
    if (doneQueries.bidlogicReferences && doneQueries.reprices && doneQueries.fileUploads) {
      setLoading(false)
    }
  }, [doneQueries])

  useEffect(() => {
    if (isBidlogic && !state.rateOptions?.length) {
      client.query({
        query: bidlogicRateOptionsGql,
        variables: {
          executionId: state.frrid
        }
      }).then(response => {
        if (JSON.parse(response.data.bidlogicRateOptions).errors) {
          Swal.fire({
            title: 'Error',
            text: JSON.parse(response.data.bidlogicRateOptions)?.errors[0]?.message || 'There was an error processing your request. Please try again later.',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
        } else if (response.data?.bidlogicRateOptions) {
          const rateFile = JSON.parse(response.data.bidlogicRateOptions)
          const rateOptions = []

          const totalRateValues = {}
          rateFile.data.forEach(option => {
            const rateCodes = []
            const fileData = [...option.file_data]
            fileData.forEach(rateCode => {
              if (!rateCodes.includes(rateCode.pbm_rate_code)) {
                rateCodes.push(rateCode.pbm_rate_code)
                totalRateValues[rateCode.pbm_rate_code] = {}
              }

              const currentRateValues = totalRateValues[rateCode.pbm_rate_code]

              if (rateCode.mail_order_indicator === 'Mail Order') {
                if (rateCode.generic_indicator === 'Brand') {
                  if (rateCode.specialty_indicator === 'Non-Specialty') {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[1] = {
                        ...rateCode,
                        id: 1
                      }
                    } else {
                      currentRateValues[2] = {
                        ...rateCode,
                        id: 2
                      }
                    }
                  } else {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[3] = {
                        ...rateCode,
                        id: 3
                      }
                    } else {
                      currentRateValues[4] = {
                        ...rateCode,
                        id: 4
                      }
                    }
                  }
                } else {
                  if (rateCode.specialty_indicator === 'Non-Specialty') {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[5] = {
                        ...rateCode,
                        id: 5
                      }
                    } else {
                      currentRateValues[6] = {
                        ...rateCode,
                        id: 6
                      }
                    }
                  } else {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[7] = {
                        ...rateCode,
                        id: 7
                      }
                    } else {
                      currentRateValues[8] = {
                        ...rateCode,
                        id: 8
                      }
                    }
                  }
                }
              } else {
                if (rateCode.generic_indicator === 'Brand') {
                  if (rateCode.specialty_indicator === 'Non-Specialty') {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[9] = {
                        ...rateCode,
                        id: 9
                      }
                    } else {
                      currentRateValues[10] = {
                        ...rateCode,
                        id: 10
                      }
                    }
                  } else {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[11] = {
                        ...rateCode,
                        id: 11
                      }
                    } else {
                      currentRateValues[12] = {
                        ...rateCode,
                        id: 12
                      }
                    }
                  }
                } else {
                  if (rateCode.specialty_indicator === 'Non-Specialty') {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[13] = {
                        ...rateCode,
                        id: 13
                      }
                    } else {
                      currentRateValues[14] = {
                        ...rateCode,
                        id: 14
                      }
                    }
                  } else {
                    if (rateCode.days_supply_group === 30) {
                      currentRateValues[15] = {
                        ...rateCode,
                        id: 15
                      }
                    } else {
                      currentRateValues[16] = {
                        ...rateCode,
                        id: 16
                      }
                    }
                  }
                }
              }
            })

            rateCodes.forEach(code => {
              const rateKeys = Object.keys(totalRateValues[code])
              if (rateKeys.length < 16) {
                const missingRates = []
                for (let i = 1; i < 16; i++) {
                  if (rateKeys.indexOf(JSON.stringify(i)) === -1) {
                    missingRates.push(i)
                  }
                }

                if (missingRates.length > 0) {
                  missingRates.forEach(mr => {
                    totalRateValues[code][mr] = {}
                  })
                }
              }

              const thisOption = fileData.find(fd => fd.pbm_rate_code === code)
              rateOptions.push({
                text: `Option ${code}:
                  ${thisOption?.pbm} -
                  ${thisOption?.full_description} -
                  ${option.frri?.formulary?.fileName ? 'Formulary: \'' + option.frri.formulary.fileName + '\' - ' : ''}
                  ${option.frri?.network?.fileName ? 'Network: \'' + option.frri.network.fileName + '\' - ' : ''}
                  ${option.frri?.specialtyList?.fileName ? 'Specialty: \'' + option.frri.specialtyList.fileName + '\'' : ''}`,
                value: code,
                frri: option.frri,
                currentRateValues: totalRateValues[code]
              })
            })
          })

          setState(prevState => ({
            ...prevState,
            rateOptions
          }))
        }
      })
    }
  }, [isBidlogic, state.rateOptions])

  useEffect(() => {
    if (resetViz) {
      setIsBidlogic(true)
      setResetViz(false)
    }
  }, [resetViz])

  useEffect(() => {
    const applyFilters = async () => {
      if (isBidlogic) {
        if (!document.getElementById('tableauViz')) {
          let newFilters = '?filters'
          for (const key in bidlogicFilters) {
            if (key.includes('(p)')) {
              newFilters += '&p|' + encodeURIComponent(key.replace('(p)', '')) + '=' + encodeURIComponent(bidlogicFilters[key])
            } else {
              newFilters += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(bidlogicFilters[key])
            }
          }
          localStorage.setItem('temp-filter-storage', newFilters)
        } else {
          viz = document.getElementById('tableauViz')
          for (const key in bidlogicFilters) {
            if (key.includes('(p)')) {
              const parameters = await viz.workbook.getParametersAsync()
              const thisParam = parameters.find(p => p.name === key.replace('(p)', ''))
              await thisParam.changeValueAsync(bidlogicFilters[key])
            } else {
              const newVal = [bidlogicFilters[key]]
              await viz.workbook.activeSheet.applyFilterAsync(key, newVal, FilterUpdateType.Replace)
            }
          }
        }
      }
    }
    applyFilters()
  }, [bidlogicFilters])

  useEffect(() => {
    if (currentReprice) {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [currentReprice])

  const optimizeCCAGCombos = (ccagFilters) => {
    if (!ccagFilters.combos) return null

    const clientIndex = new Map()
    const carrierIndex = new Map()
    const accountIndex = new Map()
    const groupIndex = new Map()

    ccagFilters.combos.forEach((c) => {
      if (!clientIndex.has(c.client)) clientIndex.set(c.client, new Set())
      clientIndex.get(c.client).add(c)

      if (!carrierIndex.has(c.carrier)) carrierIndex.set(c.carrier, new Set())
      carrierIndex.get(c.carrier).add(c)

      Object.keys(c.account_groups).forEach((account) => {
        if (!accountIndex.has(account)) accountIndex.set(account, new Set())
        accountIndex.get(account).add(c)

        c.account_groups[account].forEach((group) => {
          if (!groupIndex.has(group)) groupIndex.set(group, new Set())
          groupIndex.get(group).add(c)
        })
      })
    })

    return {
      clientIndex,
      carrierIndex,
      accountIndex,
      groupIndex
    }
  }

  const ccagMap = useMemo(() => optimizeCCAGCombos(ccagFilters), [ccagFilters.combos])

  const getFilteredItems = useCallback(
    (items, filterFn) => {
      const filteredItems = items?.filter(filterFn)
      return filteredItems?.sort((a, b) => a.value.localeCompare(b.value))
    },
    []
  )

  const filteredClients = useMemo(
    () =>
      getFilteredItems(
        ccagFilters.clients,
        (c) => ccagFilters.combos.some(co => co.client === c.value)
      ),
    [ccagFilters.clients, ccagFilters.combos, getFilteredItems]
  )

  const filteredCarriers = useMemo(
    () =>
      ccagInput.ccagClient?.length > 0
        ? getFilteredItems(
          ccagFilters.carriers,
          (c) => ccagFilters.combos.some(
            (co) => co.carrier === c.value &&
                    (!ccagInput.ccagClient?.length || ccagInput.ccagClient?.includes(co.client))
          )
        )
        : [],
    [ccagFilters.carriers, ccagFilters.combos, ccagInput.ccagClient, getFilteredItems]
  )

  const filteredAccounts = useMemo(
    () =>
      ccagInput.ccagClient?.length > 0
        ? getFilteredItems(
          ccagFilters.accounts,
          (c) => ccagFilters.combos.some(
            (co) =>
              Object.keys(co.account_groups).some(
                (ag) =>
                  ag === c.value &&
                  (!ccagInput.ccagClient?.length || ccagInput.ccagClient?.includes(co.client)) &&
                  (!ccagInput.ccagCarrier?.length || ccagInput.ccagCarrier?.includes(co.carrier))
              )
          )
        )
        : [],
    [ccagFilters.accounts, ccagFilters.combos, ccagInput.ccagClient, ccagInput.ccagCarrier, getFilteredItems]
  )

  const filteredGroups = useMemo(
    () =>
      ccagInput.ccagClient?.length > 0
        ? getFilteredItems(
          ccagFilters.groups,
          (c) => ccagFilters.combos.some(
            (co) =>
              Object.values(co.account_groups).flat().some(
                (ag) =>
                  ag === c.value &&
                  (!ccagInput.ccagClient?.length || ccagInput.ccagClient?.includes(co.client)) &&
                  (!ccagInput.ccagCarrier?.length || ccagInput.ccagCarrier?.includes(co.carrier)) &&
                  (!ccagInput.ccagAccount?.length || Object.keys(co.account_groups).some((r) => ccagInput.ccagAccount?.includes(r)))
              )
          )
        )
        : [],
    [ccagFilters.groups, ccagFilters.combos, ccagInput.ccagClient, ccagInput.ccagCarrier, ccagInput.ccagAccount, getFilteredItems]
  )

  const handleCCAGChange = useCallback(
    (name, value) => {
      const valueSet = new Set(value)
      const result = {
        ccagClient: [],
        ccagCarrier: [],
        ccagAccount: [],
        ccagGroup: []
      }

      if (name === 'ccagClient') {
        if (!value.length) {
          return result
        }
        valueSet.forEach((client) => {
          const clientCombos = ccagMap.clientIndex.get(client)
          if (clientCombos) {
            clientCombos.forEach((c) => {
              result.ccagCarrier.push(c.carrier)
              Object.keys(c.account_groups).forEach((account) => {
                result.ccagAccount.push(account)
                c.account_groups[account].forEach((group) => result.ccagGroup.push(group))
              })
            })
          }
        })
        result.ccagClient = [...valueSet]
      } else if (name === 'ccagCarrier') {
        if (!value.length) {
          return {
            ccagClient: ccagInput.ccagClient,
            ccagCarrier: [],
            ccagAccount: [],
            ccagGroup: []
          }
        }
        valueSet.forEach((carrier) => {
          ccagMap.carrierIndex.get(carrier)?.forEach((c) => {
            Object.keys(c.account_groups).forEach((account) => {
              result.ccagAccount.push(account)
              c.account_groups[account].forEach((group) => result.ccagGroup.push(group))
            })
          })
        })
        result.ccagClient = ccagInput.ccagClient
        result.ccagCarrier = [...valueSet]
      } else if (name === 'ccagAccount') {
        if (!value.length) {
          return {
            ccagClient: ccagInput.ccagClient,
            ccagCarrier: ccagInput.ccagCarrier,
            ccagAccount: [],
            ccagGroup: []
          }
        }
        valueSet.forEach((account) => {
          ccagMap.accountIndex.get(account)?.forEach((c) => {
            c.account_groups[account].forEach((group) => result.ccagGroup.push(group))
          })
        })
        result.ccagClient = ccagInput.ccagClient
        result.ccagCarrier = ccagInput.ccagCarrier
        result.ccagAccount = [...valueSet]
      } else if (name === 'ccagGroup') {
        if (!value.length) {
          return {
            ccagClient: ccagInput.ccagClient,
            ccagCarrier: ccagInput.ccagCarrier,
            ccagAccount: ccagInput.ccagAccount,
            ccagGroup: []
          }
        }
        result.ccagClient = ccagInput.ccagClient
        result.ccagCarrier = ccagInput.ccagCarrier
        result.ccagAccount = ccagInput.ccagAccount
        result.ccagGroup = [...valueSet]
      }

      return result
    },
    [ccagMap, ccagInput]
  )

  const handleMonthsOfDataChange = useCallback(
    (name, value) => {
      if (value > 12) {
        setInputErrors((prevState) => ({ ...prevState, [name]: [{ message: 'Months of data cannot exceed 12' }] }))
      } else if (inputErrors[name]) {
        const oldErrObj = { ...inputErrors }
        delete oldErrObj[name]
        setInputErrors(oldErrObj)
      }
      setInput((prevState) => ({ ...prevState, [name]: value }))
    },
    [inputErrors]
  )

  const handleGroupInputChange = useCallback(
    (name, value, groupIndex) => {
      if (groupIndex || groupIndex === 0) {
        const newGroups = [...input.inputGroups]
        const thisGroup = newGroups.find(ng => ng.groupIndex === groupIndex)
        thisGroup[name] = value

        if (name === 'id') {
          thisGroup.formulary = null
          thisGroup.network = null
          thisGroup.rateCodeDesc = null
          thisGroup.specialtyList = null
        }

        setInput(prevState => ({
          ...prevState,
          inputGroups: newGroups
        }))
      }
    },
    [input.inputGroups]
  )

  const debouncedHandleInputChange = useCallback(
    debounce((name, value, groupIndex) => {
      if (groupIndex === undefined && !name.includes('ccag')) {
        if (name === 'monthsOfData') {
          handleMonthsOfDataChange(name, value)
        }
        setInput((prevState) => ({ ...prevState, [name]: value }))
      } else if (groupIndex !== undefined) {
        handleGroupInputChange(name, value, groupIndex)
      } else if (name.includes('ccag')) {
        const newInputObj = handleCCAGChange(name, value)
        setCcagInput((prevState) => ({
          ...prevState,
          ccagClient: name === 'ccagClient' ? newInputObj.ccagClient : prevState.ccagClient.filter(client => newInputObj.ccagClient.includes(client)),
          ccagCarrier: (name === 'ccagClient' || name === 'ccagCarrier') ? newInputObj.ccagCarrier : prevState.ccagCarrier.filter(carrier => newInputObj.ccagCarrier.includes(carrier)),
          ccagAccount: (name === 'ccagClient' || name === 'ccagCarrier' || name === 'ccagAccount') ? newInputObj.ccagAccount : prevState.ccagAccount.filter(account => newInputObj.ccagAccount.includes(account)),
          ccagGroup: newInputObj.ccagGroup
        }))
        setLoaderState(prevState => ({ ...prevState, ccagField: null, ccagProcessing: false, currentLevel: null }))
      }
    }, 200),
    [handleCCAGChange, handleMonthsOfDataChange, handleGroupInputChange]
  )

  const handleInputChange = (name, value, groupIndex) => {
    if (name.includes('ccag')) {
      const currentLevel = filterLevels[name]
      setLoaderState(prevState => ({
        ...prevState,
        ccagProcessing: true,
        ccagField: name,
        currentLevel
      }))
    }

    debouncedHandleInputChange(name, value, groupIndex)
  }

  if (loading) {
    return null
  }

  const PbmGroup = ({ group }) => {
    const toggleCollapse = (groupIndex) => {
      const newGroups = [...input.inputGroups]
      newGroups.find(ng => ng.groupIndex === groupIndex).collapsed = !group?.collapsed
      setInput(prevState => ({
        ...prevState,
        inputGroups: newGroups
      }))
    }

    const deleteGroup = (groupIndex) => {
      const newGroups = [...input.inputGroups].filter(ng => ng.groupIndex !== groupIndex)
      setInput(prevState => ({
        ...prevState,
        inputGroups: newGroups
      }))
    }

    return (
      <div className={`group-wrap group-content collapsible ${group.collapsed && 'collapsed'}`}>
        <div className='button-wrap'>
          <i className={`fal ${group.collapsed ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up'} fa-2x`} onClick={(e) => toggleCollapse(group.groupIndex)}></i>
          {input.inputGroups.length > 1 &&
            <i className='fal fa-minus-circle fa-2x red' onClick={(e) => deleteGroup(group.groupIndex)}></i>
          }
        </div>
        <div className='group-right'>
          {group.collapsed
            ? <h2>{pbmList.find(p => p.value === group.id)?.text || `PBM ${input.inputGroups.indexOf(group) + 1}`}</h2>
            : <>
              <Group>
                <Input
                  label='Pharmacy Benefit Manager'
                  type='select'
                  placeholder={pbmList?.find(p => p.value === group.id)?.text || 'Select PBM'}
                  options={pbmList?.map(option => ({ value: option.value, text: option.text }))}
                  value={group.id}
                  onChange={(v) => handleInputChange('id', v, group.groupIndex)}
                  errors={inputErrors[`inputGroup${group.groupIndex}pbm`]}
                />
                <Input
                  label='Rate Code Desc'
                  type='select'
                  placeholder={rateCodeDescList?.find(f => f.value === group.rateCodeDesc)?.text || 'Select Rate Code Desc'}
                  disabled={!group.id}
                  options={rateCodeDescList?.filter(r => r.pbm === group.id)}
                  value={group.rateCodeDesc}
                  onChange={(v) => handleInputChange('rateCodeDesc', v, group.groupIndex)}
                  errors={inputErrors[`inputGroup${group.groupIndex}rateCodeDesc`]}
                />
              </Group>
              <Group>
                <Input
                  label='Specialty List'
                  type='select'
                  placeholder={specialtyList?.find(f => f.value === group.specialtyList)?.text || 'Select Specialty'}
                  disabled={!group.id}
                  options={specialtyList?.filter(r => r.pbm === group.id)}
                  value={group.specialtyList}
                  onChange={(v) => handleInputChange('specialtyList', v, group.groupIndex)}
                  errors={inputErrors[`inputGroup${group.groupIndex}specialty`]}
                />
                <Input
                  label='Formulary'
                  type='select'
                  placeholder={formularyList?.find(f => f.value === group.formulary)?.text || 'Select Formulary'}
                  disabled={!group.id}
                  options={formularyList?.filter(r => r.pbm === group.id)}
                  value={group.formulary}
                  onChange={(v) => handleInputChange('formulary', v, group.groupIndex)}
                  errors={inputErrors[`inputGroup${group.groupIndex}formulary`]}
                />
                <Input
                  label='Network'
                  type='select'
                  placeholder={networkList?.find(f => f.value === group.network)?.text || 'Select Network'}
                  disabled={!group.id}
                  options={networkList?.filter(r => r.pbm === group.id)}
                  value={group.network}
                  onChange={(v) => handleInputChange('network', v, group.groupIndex)}
                  errors={inputErrors[`inputGroup${group.groupIndex}network`]}
                />
              </Group>
            </>
          }
        </div>
      </div>
    )
  }
  PbmGroup.propTypes = {
    group: PropTypes.object
  }

  const AddGroupButton = () => {
    const addPbm = () => {
      const newGroups = [...input.inputGroups]
      newGroups.push({ groupIndex: Math.max(...input.inputGroups.map(ig => ig.groupIndex)) + 1 })

      setInput(prevState => ({
        ...prevState,
        inputGroups: newGroups
      }))
    }

    return (
      <div className='group-wrap'>
        <Button
          className='add-pbm-button'
          text='Add Another PBM'
          icon='fas fa-plus-circle fa-lg'
          onClick={() => addPbm()}
          disabled={input.inputGroups?.length > 5}
        />
      </div>
    )
  }

  const handleRun = (evt) => {
    setDisabled(true)
    setLoaderState(prevState => ({ ...prevState, submit: true }))
    evt.preventDefault()
    const feErrors = {}

    if (!input.blRepriceDate) {
      feErrors.blRepriceDate = [{ message: 'Reprice date is required' }]
    }
    if (!input.blGenericIndicator) {
      feErrors.blGenericIndicator = [{ message: 'Generic indicator is required' }]
    }
    if (!input.monthsOfData) {
      feErrors.monthsOfData = [{ message: 'Months of data is required' }]
    } else if (input.monthsOfData > 12) {
      feErrors.monthsOfData = [{ message: 'Months of data cannot exceed 12' }]
    }
    if (!input.blRateSummaryType) {
      feErrors.blRateSummaryType = [{ message: 'Rate summary type is required' }]
    }

    let inputsForImports = {}
    if (blImportClaims.find(bic => bic.id === input.blImportClaims)?.value?.toLowerCase().includes('x4')) {
      if (!ccagInput.ccagClient) {
        feErrors.ccagClient = [{ message: 'Client is required' }]
      }
      if (!ccagInput.ccagCarrier) {
        feErrors.ccagCarrier = [{ message: 'Carrier is required' }]
      }
      if (!ccagInput.ccagAccount) {
        feErrors.ccagAccount = [{ message: 'Account is required' }]
      }
      if (!ccagInput.ccagGroup) {
        feErrors.ccagGroup = [{ message: 'Group is required' }]
      }
      if (!input.finalClaimStatus) {
        feErrors.finalClaimStatus = [{ message: 'Final claim status is required' }]
      }
      if (!input.repriceDateType) {
        feErrors.repriceDateType = [{ message: 'Reprice date type is required' }]
      }
      if (!input.startDate) {
        feErrors.startDate = [{ message: 'Start date is required' }]
      }
      if (!input.endDate) {
        feErrors.endDate = [{ message: 'End date is required' }]
      }
      if (!input.coveredMembers) {
        feErrors.coveredMembers = [{ message: 'Covered members is required' }]
      }
      if (!input.coveredEmployees) {
        feErrors.coveredEmployees = [{ message: 'Covered employees is required' }]
      }

      inputsForImports = {
        x4ClientName: ccagInput.ccagClient?.join('|||'),
        x4CarrierName: ccagInput.ccagCarrier?.join('|||'),
        x4AccountName: ccagInput.ccagAccount?.join('|||'),
        x4GroupName: ccagInput.ccagGroup?.join('|||'),
        x4FinalClaimStatusId: input.finalClaimStatus,
        x4DateTypeId: input.repriceDateType,
        x4StartDate: input.startDate,
        x4EndDate: input.endDate,
        x4CoveredMembers: input.coveredMembers,
        x4CoveredEmployees: input.coveredEmployees
      }
    } else {
      if (!input.clientCustomer) {
        feErrors.clientCustomer = [{ message: 'Client is required' }]
      }
      if (!input.claimsFile) {
        feErrors.claimsFile = [{ message: 'Claim File is required' }]
      }
    }

    if (!input.repriceName) {
      feErrors.repriceName = [{ message: 'Reprice Name is required' }]
    }
    input.inputGroups.forEach(ig => {
      if (!ig.id) {
        feErrors[`inputGroup${ig.groupIndex}pbm`] = [{ message: 'PBM is required' }]
      }
      if (!ig.rateCodeDesc) {
        feErrors[`inputGroup${ig.groupIndex}rateCodeDesc`] = [{ message: 'Rate Code Desc is required' }]
      }
    })

    if (!object.keys(feErrors).length) {
      const newInput = {
        additionalInputs: JSON.stringify({
          importClaimsId: input.blImportClaims,
          blRepriceDate: input.blRepriceDate,
          blGenericIndicator: input.blGenericIndicator,
          blRateSummaryType: input.blRateSummaryType,
          blMonthsOfData: input.monthsOfData,
          ...inputsForImports
        }),
        clientCustomerId: input.clientCustomer,
        claimUploadId: input.claimsFile,
        repriceName: input.repriceName,
        inputGroups: JSON.stringify(input.inputGroups)
      }

      createFileRunRequest(newInput).then(({ data, extensions }) => {
        Swal.fire({
          text: 'File run request created',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })
        if (data.createFileRunRequest) {
          setInput(defaultInputState)
          setCcagInput(ccagDefaultInputState)
          setReprices([...reprices, data.createFileRunRequest])
          setInputErrors({})

          setLoading(true)
          setDisabled(false)
        } else {
          setInputErrors(array.groupBy(extensions.errors, 'path'))
          setDisabled(false)
        }
        setLoaderState(prevState => ({ ...prevState, submit: false }))
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setDisabled(false)
      setInputErrors(feErrors)
    }
  }

  const handleViewRepriceDetails = (reprice) => {
    setCurrentReprice(reprice)
  }

  const handleViewReprice = (repriceId) => {
    const thisReprice = reprices.find(r => r.id === repriceId)
    if (thisReprice.isProcessed && !thisReprice.isEmptyRequest) {
      const newInputObj = {
        'Execution Id': repriceId,
        'Months of Data(p)': thisReprice.monthsOfData,
        'Rate Summary Type(p)': blRateSummaryType.find(blrst => blrst.id === thisReprice.rateSummaryTypeId)?.value
      }
      let newFilters = '?filters'
      for (const key in newInputObj) {
        if (key.includes('(p)')) {
          newFilters += '&p|' + encodeURIComponent(key.replace('(p)', '')) + '=' + encodeURIComponent(newInputObj[key])
        } else {
          newFilters += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(newInputObj[key])
        }
      }
      localStorage.setItem('temp-filter-storage', newFilters)
      setBidlogicFilters(newInputObj)

      setState(prevState => ({
        ...prevState,
        input: {
          ...prevState.input,
          rates: defaultRatesInputState
        },
        rateOptions: [],
        frrid: repriceId
      }))

      setAdditionalInput({})

      if (isBidlogic) {
        document.querySelector('#tableauViz').remove()
        setIsBidlogic(false)
        setResetViz(true)
      } else {
        setIsBidlogic(true)
      }
    } else if (thisReprice.isProcessed && thisReprice.isEmptyRequest) {
      Swal.fire({
        text: 'The options selected do not return any data. Please try again or reach out to your Account team and they will be happy to assist.',
        icon: 'warning',
        showConfirmButton: true
      })
    }
  }

  const handleDeleteReprice = (repriceId) => {
    Swal.fire({
      text: 'Are you sure you want to delete this Reprice?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        destroyFileRunRequest({
          id: repriceId
        }).then(({ data, extensions }) => {
          if (data.destroyFileRunRequest) {
            Swal.fire({
              text: 'Reprice deleted',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
            if (state.frrid === repriceId) {
              setIsBidlogic(false)
              setBidlogicFilters({})
              setAdditionalInput({})
              setState(prevState => ({
                ...prevState,
                input: {
                  rates: defaultRatesInputState
                },
                rateOptions: [],
                frrid: null
              }))
            }
            setLoading(true)
          } else if (extensions.errors) {
            Swal.fire({
              text: 'Error deleting reprice',
              icon: 'error',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
      }
    })
  }

  const handleAdditionalInputChange = async (name, value) => {
    setAdditionalInput(prevState => ({
      ...prevState,
      [name]: value
    }))

    if (name === 'rateOption') {
      viz = document.getElementById('tableauViz')
      const parameters = await viz.workbook.getParametersAsync()
      const thisParam = parameters.find(p => p.name === 'Selected Option')
      await thisParam.changeValueAsync(value)
    }
  }

  const applyAdditionalInputs = (name, value) => {
    const additionalInputsObj = {
      executionId: state.frrid || 22,
      inputName: name,
      frriId: state.rateOptions.find(ro => ro.value === additionalInput?.rateOption)?.frri?.id,
      additionalInputs: JSON.stringify(value)
    }

    setLoaderState(prevState => ({ ...prevState, dynamicRatesApply: true }))

    applyBidlogicFilters(additionalInputsObj).then(async resp => {
      if (resp.data.applyBidlogicFilters) {
        viz = document.getElementById('tableauViz')
        await viz.refreshDataAsync()
        setLoaderState(prevState => ({ ...prevState, dynamicRatesApply: false }))
      } else if (resp.extensions?.errors || resp.errors) {
        Swal.fire({
          text: 'Error applying additional inputs',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000
        })
        setLoaderState(prevState => ({ ...prevState, dynamicRatesApply: false }))
      }
    })
  }

  const DynamicRatesTable = () => {
    const handleRateInputChange = (id, name, value) => {
      const newVal = name === 'discount' ? (value * 0.01) : value
      setState(prevState => ({
        ...prevState,
        input: {
          ...prevState.input,
          rates: {
            ...prevState.input.rates,
            [id]: {
              ...prevState.input.rates[id],
              [name]: parseFloat(newVal)
            }
          }
        }
      }))
    }

    return (
      <div className='table-wrap'>
        <table className='response-rates-table'>
          <thead className='table-header'>
            <tr>
              <th>Dynamic Discount</th>
              <th>Dynamic Dispensing Fee</th>
              <th>Dynamic Admin Fee</th>
              <th>Dynamic Rebate</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(state.rateOptions[state.rateOptions.findIndex(sro => sro.value === additionalInput?.rateOption)]?.currentRateValues || {})?.map((keyName, i) => {
              const item = state.rateOptions[state.rateOptions.findIndex(sro => sro.value === additionalInput?.rateOption)]?.currentRateValues[keyName]

              if (Object.keys(item).length < 1) {
                return <tr key={i}><td colSpan={4}></td></tr>
              }

              return (
                <tr key={i}>
                  <td className='rate-input discount'>
                    <Input
                      type='number'
                      settings={{ float: true }}
                      value={state.input?.rates[item.id]?.discount?.toString()}
                      onChange={(v) => handleRateInputChange(item.id, 'discount', v)}
                      errors={state.errors?.['rate' + item.id + 'discount']}
                      icon='fal fa-percentage'
                      iconRight={true}
                    />
                  </td>
                  <td className='rate-input'>
                    <Input
                      type='number'
                      settings={{ float: true, doubleDecimal: true }}
                      value={state.input?.rates[item.id]?.dispensingFee?.toString()}
                      onChange={(v) => handleRateInputChange(item.id, 'dispensingFee', v)}
                      errors={state.errors?.['rate' + item.id + 'dispensingFee']}
                      icon='fal fa-dollar-sign'
                    />
                  </td>
                  <td className='rate-input'>
                    <Input
                      type='number'
                      settings={{ float: true, doubleDecimal: true }}
                      value={state.input?.rates[item.id]?.adminFee?.toString()}
                      onChange={(v) => handleRateInputChange(item.id, 'adminFee', v)}
                      errors={state.errors?.['rate' + item.id + 'adminFee']}
                      icon='fal fa-dollar-sign'
                    />
                  </td>
                  <td className='rate-input'>
                    {item.generic_indicator === 'Brand' &&
                      <Input
                        type='number'
                        settings={{ float: true, doubleDecimal: true }}
                        value={state.input?.rates[item.id]?.rebate?.toString()}
                        onChange={(v) => handleRateInputChange(item.id, 'rebate', v)}
                        errors={state.errors?.['rate' + item.id + 'rebate']}
                        icon='fal fa-dollar-sign'
                      />
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const filterLevels = {
    ccagClient: 1,
    ccagCarrier: 2,
    ccagAccount: 3,
    ccagGroup: 4
  }

  const isEmpty = (variable) => !variable || (Array.isArray(variable) && variable.length === 0)

  const isCcagLoading = (field) => {
    const fieldLevel = filterLevels[field]

    if (loaderState.currentLevel) {
      return loaderState.ccagProcessing && loaderState.currentLevel && fieldLevel > loaderState.currentLevel
    } else {
      return loaderState.ccagProcessing
    }
  }

  return (
    <Fragment>
      <Title text='BidLogic' user={user} isDashboardExecution={isDashboardExecutionParent}/>

      <Form className='bl-form' onSubmit={handleRun}>
        <FormError errors={inputErrors} />
        <div className='bidlogic-content'>
          {input?.inputGroups?.map((group, key) => {
            return (
              <PbmGroup key={key} group={group} />
            )
          })}

          <AddGroupButton />

          <div className='group-wrap group-content'>
            <div className='group-right'>
              <Group>
                <Input
                  label='Import Claims'
                  type='select'
                  options={blImportClaims?.map(option => ({ value: option.id, text: option.value }))}
                  value={input.blImportClaims}
                  onChange={(v) => handleInputChange('blImportClaims', v)}
                  errors={inputErrors.blImportClaims}
                />
                <Input
                  label='Reprice Date'
                  type='select'
                  options={blRepriceDate?.map(option => ({ value: option.id, text: option.value }))}
                  value={input.blRepriceDate}
                  onChange={(v) => handleInputChange('blRepriceDate', v)}
                  errors={inputErrors.blRepriceDate}
                />
                <Input
                  label='Generic Indicator'
                  type='select'
                  options={blGenericIndicator?.map(option => ({ value: option.id, text: option.value }))}
                  value={input.blGenericIndicator}
                  onChange={(v) => handleInputChange('blGenericIndicator', v)}
                  errors={inputErrors.blGenericIndicator}
                />
              </Group>

              <Group>
                <Input
                  label='Months of Data'
                  type='number'
                  value={input.monthsOfData}
                  onChange={(v) => handleInputChange('monthsOfData', v)}
                  errors={inputErrors.monthsOfData}
                />
                <Input
                  label='Rate Summary Type'
                  type='select'
                  options={blRateSummaryType?.map(option => ({ value: option.id, text: option.value }))}
                  value={input.blRateSummaryType}
                  onChange={(v) => handleInputChange('blRateSummaryType', v)}
                  errors={inputErrors.blRateSummaryType}
                />
                <Input
                  label='Reprice Name'
                  type='text'
                  value={input.repriceName}
                  onChange={(v) => handleInputChange('repriceName', v)}
                  errors={inputErrors.repriceName}
                />
              </Group>
            </div>

            <hr className='horizontal-rule' />

            <div className='group-right'>
              {(input?.blImportClaims && blImportClaims?.find(bic => bic.id === input.blImportClaims)?.value?.toLowerCase().includes('x4')) &&
                <>
                  <Group>
                    <Input
                      label='Client Name'
                      type='multiselect'
                      options={filteredClients}
                      value={ccagInput.ccagClient}
                      onChange={(v) => handleInputChange('ccagClient', v)}
                      loading={isCcagLoading('ccagClient')}
                      errors={inputErrors.ccagClient}
                    />
                    <Input
                      label='Carrier Name'
                      type='multiselect'
                      disabled={isEmpty(ccagInput.ccagClient)}
                      options={filteredCarriers}
                      value={ccagInput.ccagCarrier}
                      hasSelectAll={true}
                      onChange={(v) => handleInputChange('ccagCarrier', v)}
                      loading={isCcagLoading('ccagCarrier')}
                      errors={inputErrors.ccagCarrier}
                    />
                    <Input
                      label='Account Name'
                      type='multiselect'
                      disabled={isEmpty(ccagInput.ccagCarrier)}
                      options={filteredAccounts}
                      value={ccagInput.ccagAccount}
                      hasSelectAll={true}
                      onChange={(v) => handleInputChange('ccagAccount', v)}
                      loading={isCcagLoading('ccagAccount')}
                      errors={inputErrors.ccagAccount}
                    />
                  </Group>
                  <Group>
                    <Input
                      label='Group Name'
                      type='multiselect'
                      disabled={isEmpty(ccagInput.ccagAccount)}
                      options={filteredGroups}
                      value={ccagInput.ccagGroup}
                      hasSelectAll={true}
                      onChange={(v) => handleInputChange('ccagGroup', v)}
                      loading={isCcagLoading('ccagGroup')}
                      errors={inputErrors.ccagGroup}
                    />
                    <Input
                      label='Final Claim Status'
                      type='select'
                      options={x4FinalClaimStatus?.map(option => ({ value: option.id, text: option.value }))}
                      value={input.finalClaimStatus}
                      onChange={(v) => handleInputChange('finalClaimStatus', v)}
                      errors={inputErrors.finalClaimStatus}
                    />
                    <Input
                      label='Date Type'
                      type='select'
                      options={x4DateType?.map(option => ({ value: option.id, text: option.value }))}
                      value={input.repriceDateType}
                      onChange={(v) => handleInputChange('repriceDateType', v)}
                      errors={inputErrors.repriceDateType}
                    />
                  </Group>
                  <Group>
                    <Input
                      label='Start Date'
                      type='datepicker'
                      settings={{
                        maxDate: input.endDate ? input.endDate : null
                      }}
                      value={input.startDate}
                      onChange={(v) => handleInputChange('startDate', v)}
                      errors={inputErrors.startDate}
                    />
                    <Input
                      label='End Date'
                      type='datepicker'
                      settings={{
                        minDate: input.startDate ? input.startDate : null
                      }}
                      value={input.endDate}
                      onChange={(v) => handleInputChange('endDate', v)}
                      errors={inputErrors.endDate}
                    />
                    <Input
                      label='Total Covered Members'
                      type='number'
                      value={input.coveredMembers}
                      onChange={(v) => handleInputChange('coveredMembers', v)}
                      errors={inputErrors.coveredMembers}
                    />
                  </Group>
                  <Group>
                    <Input
                      label='Total Covered Employees'
                      type='number'
                      value={input.coveredEmployees}
                      onChange={(v) => handleInputChange('coveredEmployees', v)}
                      errors={inputErrors.coveredEmployees}
                    />
                    <Input
                      type='hidden'
                      className='invisible-input'
                    />
                    <div className='run-button-wrap'>
                      <Button
                        text='Run Analysis'
                        className='run-button'
                        type='submit'
                        disabled={disabled}
                        loading={loaderState.submit}
                      />
                    </div>
                  </Group>
                </>
              }

              {(input?.blImportClaims && blImportClaims?.find(bic => bic.id === input.blImportClaims)?.value?.toLowerCase().includes('datalogic')) &&
                <Group>
                  <Input
                    label='Client Name'
                    type='select'
                    options={clientCustomers?.map(cc => ({ value: cc.id, text: cc.name }))}
                    value={input.clientCustomer}
                    onChange={(v) => handleInputChange('clientCustomer', v)}
                    errors={inputErrors.clientCustomer}
                  />
                  <Input
                    label='Claim File'
                    type='select'
                    disabled={!input.clientCustomer}
                    options={fileUploads?.claims?.filter(c => c.clientCustomerId === input.clientCustomer)}
                    value={input.claimsFile}
                    onChange={(v) => handleInputChange('claimsFile', v)}
                    errors={inputErrors.claimsFile}
                  />
                  <div className='run-button-wrap'>
                    <Button
                      text='Run Analysis'
                      className='run-button'
                      type='submit'
                      disabled={disabled}
                      loading={loaderState.submit}
                    />
                  </div>
                </Group>
              }

            </div>
          </div>
          <Can I='create' a='FileRunRequest'>
            <div className='group-wrap'>
              <div className='group-header'>
                <div className='header-col'>Reprice Name</div>
                <div className='header-col'>Reprice Date</div>
                <div className='header-col'>Actions</div>
              </div>
              <div className='group-content-table'>
                {reprices?.length < 1
                  ? <div className='row'>
                    <div className='col'>No data available</div>
                  </div>
                  : <>
                    {reprices?.map((reprice, key) => {
                      return (
                        <div key={key} className='row'>
                          <div className='col'>{reprice?.repriceName}</div>
                          <div className='col'>{time.utc(reprice?.createdAt).tz(user?.timezone).format('MM/DD/YYYY hh:mm A')}</div>
                          <div className='col action-buttons'>
                            <Can I='create' a='FileRunRequest'>
                              <div className='action-button' onClick={() => handleViewRepriceDetails(reprice)}>
                                <Icon
                                  className='fal fa-info-circle'
                                />
                              </div>
                            </Can>
                            <div className='action-button' onClick={() => handleViewReprice(reprice.id)}>
                              {reprice.isProcessed && !reprice.isEmptyRequest
                                ? <Icon className='fas fa-eye' />
                                : reprice.isProcessed && reprice.isEmptyRequest
                                  ? <Icon className='fas fa-exclamation-triangle warning' />
                                  : <Loaders.LoaderCircleLines dark={true} />
                              }
                            </div>
                            <Can I='delete' a='FileRunRequest'>
                              <div className='action-button delete-button' onClick={() => handleDeleteReprice(reprice.id)}>
                                <Icon
                                  className='fas fa-trash'
                                />
                              </div>
                            </Can>
                          </div>
                        </div>
                      )
                    })}
                  </>
                }
              </div>
            </div>
          </Can>
        </div>
      </Form>

      <Modal
        className='reprice-details-modal'
        show={showModal}
        onClose={() => {
          setCurrentReprice(null)
        }}
      >
        <div className='reprice-details'>
          <h2>{currentReprice?.repriceName}</h2>

          {currentReprice?.fileRunRequestItem?.length > 0 && (
            <>
              {currentReprice?.fileRunRequestItem?.map((item, key) => (
                <>
                  <React.Fragment key={key}>
                    <div className='reprice-details--group'>
                      <div className='reprice-details--item'>
                        <p className='reprice-details--label'>PBM</p>
                        <p className='reprice-details--body'>{item.pbmName}</p>
                      </div>

                      <div className='reprice-details--item'>
                        <p className='reprice-details--label'>Rate Code Desc</p>
                        <p className='reprice-details--body'>{item.rateCodeDesc}</p>
                      </div>
                    </div>

                    {item.specialtyList && (
                      <div className='reprice-details--group'>
                        <div className='reprice-details--item'>
                          <p className='reprice-details--label'>Specialty List</p>
                          <p className='reprice-details--body'>{item.specialtyList.fileName}</p>
                        </div>
                      </div>
                    )}

                    {item.formulary && (
                      <div className='reprice-details--group'>
                        <div className='reprice-details--item'>
                          <p className='reprice-details--label'>Formulary</p>
                          <p className='reprice-details--body'>{item.formulary.fileName}</p>
                        </div>
                      </div>
                    )}

                    {item.network && (
                      <div className='reprice-details--group'>
                        <div className='reprice-details--item'>
                          <p className='reprice-details--label'>Network</p>
                          <p className='reprice-details--body'>{item.network.fileName}</p>
                        </div>
                      </div>
                    )}

                    <hr />
                  </React.Fragment>
                </>
              ))}
            </>
          )}

          <div className='reprice-details--group'>
            <div className='reprice-details--item'>
              <p className='reprice-details--label'>Import Claims</p>
              <p className='reprice-details--body'>{currentReprice?.importClaims?.value}</p>
            </div>

            <div className='reprice-details--item'>
              <p className='reprice-details--label'>Reprice Date</p>
              <p className='reprice-details--body'>{currentReprice?.repriceDate?.value}</p>
            </div>
          </div>

          <div className='reprice-details--group'>
            <div className='reprice-details--item'>
              <p className='reprice-details--label'>Generic Indicator</p>
              <p className='reprice-details--body'>{currentReprice?.genericIndicator?.value}</p>
            </div>

            <div className='reprice-details--item'>
              <p className='reprice-details--label'>Months of Data</p>
              <p className='reprice-details--body'>{currentReprice?.monthsOfData}</p>
            </div>
          </div>

          <div className='reprice-details--group'>
            <div className='reprice-details--item'>
              <p className='reprice-details--label'>Rate Summary Type</p>
              <p className='reprice-details--body'>{currentReprice?.rateSummaryType?.value}</p>
            </div>
          </div>

          <hr />

          { currentReprice?.x4ClientName && (
            <>
              <div className='reprice-details--group'>
                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Client Name</p>
                  <p className='reprice-details--body'>{currentReprice?.x4ClientName.split('|||').join(', ')}</p>
                </div>

                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Carrier Name</p>
                  <p className='reprice-details--body'>{currentReprice?.x4CarrierName.split('|||').join(', ')}</p>
                </div>
              </div>

              <div className='reprice-details--group'>
                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Account Name</p>
                  <p className='reprice-details--body'>{currentReprice?.x4AccountName.split('|||').join(', ')}</p>
                </div>

                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Group Name</p>
                  <p className='reprice-details--body'>{currentReprice?.x4GroupName.split('|||').join(', ')}</p>
                </div>
              </div>

              <div className='reprice-details--group'>
                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Final Claim Status</p>
                  <p className='reprice-details--body'>{currentReprice?.x4FinalClaimStatus?.value}</p>
                </div>

                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Date Type</p>
                  <p className='reprice-details--body'>{currentReprice?.x4DateType?.value}</p>
                </div>
              </div>

              <div className='reprice-details--group'>
                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Start Date</p>
                  <p className='reprice-details--body'>{time.utc(currentReprice?.x4StartDate).tz(user.timezone).format('MM/DD/YYYY')}</p>
                </div>

                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>End Date</p>
                  <p className='reprice-details--body'>{time.utc(currentReprice?.x4EndDate).tz(user.timezone).format('MM/DD/YYYY')}</p>
                </div>
              </div>
            </>
          )}

          { currentReprice?.clientCustomer && (
            <>
              <div className='reprice-details--group last'>
                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Client Name</p>
                  <p className='reprice-details--body'>{currentReprice?.clientCustomer?.name}</p>
                </div>

                <div className='reprice-details--item'>
                  <p className='reprice-details--label'>Claim File</p>
                  <p className='reprice-details--body'>{currentReprice?.claim?.fileName}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      {isBidlogic &&
        <TableauViz
          user={user}
          isBidlogic={isBidlogic}
          alertFilters={alertFilters}
          setTitle={(v) => setTitle(v)}
          clearAllFilters={clearAllFilters}
          setClearAllFilters={setClearAllFilters}
          onDashboardExecutionChange={(isExecuting) => setIsDashboardExecutionParent(isExecuting)}
        >
          <>
            {title === 'Claims With High Ratio of Cost to AWP' &&
              <div className='additional-input-group'>
                <div className='additional-input bl-input ratio-cost-awp'>
                  <h2 className='blue-bg'>Ratio of Cost to AWP Threshold</h2>
                  <Group>
                    <Input
                      type='number'
                      settings={{ float: true, doubleDecimal: true }}
                      className='ratio-input'
                      placeholder='50%'
                      value={additionalInput?.ratioCostAwp}
                      onChange={(v) => handleAdditionalInputChange('ratioCostAwp', parseFloat(v))}
                    />
                    <Button
                      text='Apply'
                      className='apply-button'
                      onClick={() => applyAdditionalInputs('ratioCostAwp', additionalInput?.ratioCostAwp)}
                      disabled={!additionalInput?.ratioCostAwp}
                    />
                  </Group>
                </div>
              </div>
            }

            {title === 'Rate Tuning' &&
              <div className='additional-input-group'>
                <div className='additional-input bl-input rate-option-select'>
                  <h2 className='blue-bg'>Select an Option to Fine Tune</h2>
                  <div className='rate-options-wrap'>
                    {state.rateOptions.length > 0 && state.rateOptions.map((option, key) => {
                      return (
                        <div className='option-wrap' key={key}>
                          <input
                            className='option-input'
                            type='radio'
                            name='rateOption'
                            id={key}
                            value={option.value}
                            onChange={(v) => handleAdditionalInputChange('rateOption', option.value)}
                          />
                          <label className='option-label' htmlFor={key}>{option.text}</label>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='additional-input bl-input current-rates-table'>
                  <h2 className='green-bg'>Current Rates</h2>
                  <div className='table-wrap'>
                    <div className='table-left'>
                      <table>
                        <thead className='table-header'><tr><th colSpan={4}></th></tr></thead>
                        <tbody className='current-rates-table-rows'>
                          <tr>
                            <td rowSpan={8}>Mail Order</td>
                            <td rowSpan={4}>Brand</td>
                            <td rowSpan={2}>Non-Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={4}>Generic</td>
                            <td rowSpan={2}>Non-Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>

                          <tr>
                            <td rowSpan={8}>Retail</td>
                            <td rowSpan={4}>Brand</td>
                            <td rowSpan={2}>Non-Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={4}>Generic</td>
                            <td rowSpan={2}>Non-Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                          <tr>
                            <td rowSpan={2}>Specialty</td>
                            <td>30</td>
                          </tr>
                          <tr>
                            <td>90</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='table-right'>
                      <table>
                        <thead className='table-header'>
                          <tr>
                            <th>Rate Discount</th>
                            <th>Rate Dispensing Fee</th>
                            <th>Rate Admin Fee</th>
                            <th>Rate Rebate</th>
                          </tr>
                        </thead>
                        <tbody className='current-rates-table-rows display-current'>
                          {Object.keys(state.rateOptions?.[state.rateOptions.findIndex(sro => sro.value === additionalInput.rateOption)]?.currentRateValues || {})?.map((key, i) => {
                            const option = state.rateOptions.find(ro => ro.value === additionalInput.rateOption)?.currentRateValues[key]

                            if (Object.keys(option).length === 0) {
                              return <tr key={i}><td colSpan={4}></td></tr>
                            }

                            const curFormatter = new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            })

                            const perFormatter = new Intl.NumberFormat('en-Us', {
                              style: 'percent',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })

                            return (
                              <tr key={i}>
                                <td>{perFormatter.format(option.discount)}</td>
                                <td>{curFormatter.format(option.dispensing_fee)}</td>
                                <td>{curFormatter.format(option.admin_fee)}</td>
                                <td>{curFormatter.format(option.rebate)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div className='additional-input bl-input dynamic-rates-table'>
                  <h2 className='green-bg'>Dynamic Rates</h2>
                  {DynamicRatesTable()}

                  <Button
                    text='Apply'
                    className='apply-button'
                    onClick={() => applyAdditionalInputs('dynamicRates', state.input?.rates)}
                    loading={loaderState.dynamicRatesApply}
                  />
                </div>
              </div>
            }
          </>
        </TableauViz>
      }

    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const BidLogicInputForm = ({ user }) => {
  return <Content user={user} />
}

BidLogicInputForm.propTypes = {
  user: PropTypes.object,
  isBidlogic: PropTypes.bool,
  alertFilters: PropTypes.object
}

export default BidLogicInputForm
