import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Can } from '@caslConfig/can.js'
import { Icon, Tooltip, Loaders } from '@lib'
import { DynamicNavigation } from '@shared'
import { axiosGet } from '@utils'
import sideBarLogo from '@images/logo-white-icon-text.png'
import popoutMenuLogo from '@images/xevant-logo-2021.png'

import navigationsGql from '@graphql/queries/navigations'

import authGql from '@graphql/queries/auth'
import client from '@graphql/client'

const ShortcutIcons = ({ user }) => {
  const pathIndex = 2
  const navTypeFilt = 'appIconNav'
  const activeModule = window.location.pathname.split('/')[pathIndex]
  const iconLinks = {}

  const ShortcutIcon = ({ item }) => {
    const [imageSrc, setImagesrc] = useState(null)
    if (!iconLinks[item.name]) {
      return null
    }
    const iconLink = iconLinks[item.name] || '#'
    const shortcutModule = iconLink.split('/')[2]

    useEffect(() => {
      if (item.iconPath.includes('.')) {
        async function getImage () {
          const image = await import(`@images/icons/${item.iconPath}`)
          setImagesrc(image.default)
        }
        getImage()
      }
    }, [])

    return (
      <li>
        <Link to={iconLink} className={activeModule && activeModule === shortcutModule ? 'active' : ''}>
          {!item.iconPath.includes('.')
            ? (
              <Icon className={item.iconPath} />
              )
            : (
              <img src={imageSrc} />
              )
          }
          <span>{item.name}</span>
        </Link>
      </li>
    )
  }
  ShortcutIcon.propTypes = {
    item: PropTypes.object
  }

  const navigationsQuery = useQuery(navigationsGql, {
    variables: {
      navigationType: navTypeFilt,
      getParent: false,
      getChildren: false
    }
  })
  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      navigationsQuery.refetch()
    }

    return () => { isMounted = false }
  }, [])

  const [loadingTabNavs, setLoadingTabNavs] = useState(true)
  useEffect(() => {
    if (loadingTabNavs) {
      const waitForTabNav = () => {
        const tabNav = JSON.parse(localStorage.getItem('tabNavs'))?.filter(t => t.name === 'Xevant')[0]
        if (!tabNav) {
          setTimeout(() => {
            waitForTabNav()
          }, 5000)
        } else {
          setLoadingTabNavs(false)
        }
      }
      waitForTabNav()
    }
  }, [loadingTabNavs])
  if (loadingTabNavs) {
    return (
      <div className='iconLoaderWrap'>
        <Loaders.LoaderCircle />
      </div>
    )
  }

  if (navigationsQuery.loading || navigationsQuery.error) {
    return null
  }
  const { navigations } = navigationsQuery.data
  const iconNavs = [...navigations]

  iconNavs.sort((a, b) => {
    if (a.order && b.order) {
      if (a.order - b.order === 0) {
        return a.name.localeCompare(b.name)
      } else {
        return a.order - b.order
      }
    } else if (a.order) {
      return -1
    } else if (b.order) {
      return 1
    } else {
      return a.name.localeCompare(b.name)
    }
  })

  const tabNav = JSON.parse(localStorage.getItem('tabNavs'))?.filter(t => t.name === 'Xevant')[0]

  if (tabNav) {
    const userHomepage = user.userClients.find(uc => uc.clientId === user.clientId).homepage

    if (userHomepage) {
      let homepageWb = tabNav.workbooks.find(w => w.contentUrl.toLowerCase() === userHomepage.toLowerCase())

      if (homepageWb) {
        iconLinks.Home = '/dashboard/' + homepageWb.contentUrl + '/' + homepageWb.defaultView.viewUrlName
      } else {
        for (const tn of tabNav.children) {
          homepageWb = tn.workbooks.find(w => w.name.toLowerCase() === userHomepage.toLowerCase())
          if (homepageWb) {
            iconLinks.Home = '/dashboard/' + homepageWb.contentUrl + '/' + homepageWb.defaultView.viewUrlName
          }
        }
      }
    } else {
      let execDashboardWb

      for (const tn of tabNav.children) {
        execDashboardWb = tn.workbooks.find(w => w.name.toLowerCase().includes('executive dashboard'))
        if (execDashboardWb) {
          iconLinks.Home = '/dashboard/' + execDashboardWb.contentUrl + '/' + execDashboardWb.defaultView.viewUrlName
        }
      }

      if (!iconLinks.Home) {
        for (const tn of tabNav.workbooks) {
          if (tn.name.toLowerCase().includes('scorecard')) {
            iconLinks.Home = '/dashboard/' + tn.contentUrl + '/' + tn.defaultView.viewUrlName
          }
        }
      }
    }

    for (const tn of tabNav.children) {
      if (iconNavs.map(n => n.name).includes(tn.name)) {
        for (const w of tn.workbooks) {
          if (w.name.toLowerCase().includes('scorecard') || w.name.includes('RebateLogic Submission') || (w.name.includes('RebateLogic Proposal') && !iconLinks[tn.name])) {
            iconLinks[tn.name] = '/dashboard/' + w.contentUrl + '/' + w.defaultView.viewUrlName
          } else if (w.name.includes('BL 3.0')) {
            const blDefaultView = w.views.find(v => v.name.includes('Rate Summary'))
            iconLinks[tn.name] = '/dashboard/' + w.contentUrl + '/' + blDefaultView.viewUrlName
          } else if (w.name.toLowerCase().includes('summary')) {
            iconLinks[tn.name] = '/dashboard/' + w.contentUrl + '/' + w.defaultView.viewUrlName
          }
        }
      } else if (tn.name.toLowerCase().includes('alert')) {
        const defWb = tn.workbooks.find(w => w.name.toLowerCase().includes('summary'))

        if (defWb) {
          iconLinks.AlertLogic = '/dashboard/' + defWb.contentUrl + '/' + defWb.defaultView.viewUrlName
        }
      } else if (tn.name.toLowerCase() === 'resources') {
        const marketplaceWb = tn.workbooks.find(w => w.name.toLowerCase().includes('marketplace'))

        if (marketplaceWb) {
          iconLinks.Marketplace = '/dashboard/' + marketplaceWb.contentUrl + '/' + marketplaceWb.defaultView.viewUrlName
        }
      }
    }
    if (user.client.clientModules?.find(cm => cm.module.name === 'DataLogic') && user.userClients?.find(uc => uc.clientId === user.clientId).isDatalogicUser) {
      iconLinks.DataLogic = '/datalogic'
    }
  }

  return (
    <div className='menu'>
      <ul>
        {iconNavs.map((iconNav, key) => {
          return (
            <ShortcutIcon key={key} item={iconNav} />
          )
        })}
        <Can I='read' a='DataLogicSettings'>
          <ShortcutIcon item={{ name: 'DataLogic', iconPath: 'fas fa-folders' }} />
        </Can>
      </ul>
    </div>
  )
}
ShortcutIcons.propTypes = {
  iconNavs: PropTypes.array,
  user: PropTypes.object
}

const RadialMenuItems = ({ user }) => {
  const radialMenuItems = [
    {
      title: 'Settings',
      permission: 'Setting',
      icon: 'fas fa-cogs fa-lg',
      link: '/settings'
    },
    {
      title: 'Resources',
      permission: 'Resource',
      icon: 'fas fa-books fa-lg',
      link: '/resources'
    }
  ]

  /* WD-461 - SHORT-TERM SOLUTION: ADDED TO SUPPORT MULTIPLE CLIENTS ACCESSING DEMO SITES */
  // if (user.client?.name?.includes('Demo') && !(user.systemRole?.name === 'Superuser' || user.systemRole?.name === 'Xevant Admin')) {
  //   for (const [ctr, menuItem] of radialMenuItems.entries()) {
  //     if (menuItem.title === 'Settings') {
  //       radialMenuItems.splice(ctr, 1)
  //     }
  //   }
  // }
  /* END WD-461 - SHORT-TERM SOLUTION: ADDED TO SUPPORT MULTIPLE CLIENTS ACCESSING DEMO SITES */

  return (
    <ul className='radial-menu-items'>
      {radialMenuItems.map((rItem, key) => (
        <Can I='read' a={rItem.permission} key={key}>
          <li key={key} className='radial-menu-item'>
            <a href={rItem.link}>
              <i className={rItem.icon}></i>
              <p>{rItem.title}</p>
            </a>
          </li>
        </Can>
      ))}
    </ul>
  )
}

const Sidebar = ({ user }) => {
  const sidebarExpand = localStorage.getItem('sidebar-expand')
  const [isExpand, setIsExpand] = useState(sidebarExpand)
  const [showTooltip, setShowTooltip] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user.userClients.find(uc => uc.clientId === user.clientId && uc.userGroupForLogoId)?.userGroup?.logoPath === undefined) {
      client.query({
        query: authGql,
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        if (data.auth) {
          handleLogo(data.auth)
        }
      })
    } else {
      handleLogo(user)
    }
  }, [])

  const handleLogo = async (user) => {
    const logo = user.userClients.find(uc => uc.clientId === user.clientId && uc.userGroupForLogoId)?.userGroup?.logoPath || user.client?.logoPath
    if (user.userClients.find(uc => uc.clientId === user.clientId && uc.userGroupForLogoId)?.userGroup?.logoPath) {
      const response = await axiosGet(`s3/group-logo/${logo}`)
      setLogoUrl(response.data.url)
    } else if (logo) {
      const response = await axiosGet(`s3/client-logo/${logo}`)
      setLogoUrl(response.data.url)
    } else {
      setLogoUrl(popoutMenuLogo)
    }
    setLoading(false)
  }

  const handleExpandClick = (e) => {
    e.preventDefault()
    if (isExpand) {
      setIsExpand(false)
      localStorage.removeItem('sidebar-expand')
    } else {
      setIsExpand(true)
      localStorage.setItem('sidebar-expand', true)
    }
  }

  const [isRadialExpand, setIsRadialExpand] = useState(false)
  const handleExpandRadialMenu = (e) => {
    e.preventDefault()
    if (isRadialExpand) {
      setIsRadialExpand(false)
    } else {
      setIsRadialExpand(true)
    }
  }

  return (
    <div className={`sidebar-container ${isExpand && 'expand'}`}>

      <div className={`popout-menu ${isExpand && 'expand'}`}>
        <div className='popout-menu-logo'>
          {/* <img src={popoutMenuLogo} /> */}
          <img src={loading ? '' : logoUrl} />
        </div>
        <DynamicNavigation user={user} />
      </div>

      <div className='sidebar'>
        <div className='sidebar-menu'>
          <Can I='read' a='Sidebar'>
            <ShortcutIcons user={user} />
            {/* <ShortcutIcons iconNavs={iconNavs} /> */}
           </Can>
        </div>

        <div className={`sidebar-radial-menu ${isRadialExpand && 'expand'}`}><RadialMenuItems user={user} /></div>
        {/* <div onMouseLeave={handleExpandRadialMenu} className={`sidebar-radial-menu ${isRadialExpand && 'expand'}`}><RadialMenuItems /></div> */}
        <div onClick={handleExpandRadialMenu} className={`sidebar-grid-menu ${isRadialExpand && 'expand'}`}>
        {/* <div onMouseEnter={handleExpandRadialMenu} className={`sidebar-grid-menu ${isRadialExpand && 'expand'}`}> */}
          <a
            href='#'
            onMouseLeave={() => setShowTooltip(false)}
            onMouseEnter={() => setShowTooltip(true)}
            id='grid-menu-button'
          >
            <i className='fas fa-th'></i>
          </a>
        </div>
        <Tooltip
          anchorId='grid-menu-button'
          content='Waffle Menu'
          isOpen={showTooltip && !isRadialExpand}
        />
        <div className='sidebar-logo'>
          <div className='logo'>
            <img src={sideBarLogo} />
          </div>
        </div>

        {/* <div onClick={handleExpandClick} className='menu-slide-arrow'><i className='fas fa-chevron-right'></i></div> */}
        <Can I='read' a='Sidebar'>
          <div onClick={handleExpandClick} className='menu-slide-arrow'><i className={`fas fa-chevron-right ${isExpand && 'expand'}`}></i></div>
        </Can>
      </div>
    </div>
  )
}

RadialMenuItems.propTypes = {
  user: PropTypes.object
}

Sidebar.propTypes = {
  user: PropTypes.object
}

export default Sidebar
