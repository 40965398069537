import client from '../client.js'

import updatePasswordGql from '../mutations/update-password.gql'

const updatePassword = (input) => {
  return client.mutate({
    mutation: updatePasswordGql,
    variables: input,
    update: (store, { data }) => {
      // console.log('Logger::updateForgottenPassword', data)
    }
  })
}

export default updatePassword
