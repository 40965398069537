import React, { useState, useEffect } from 'react'
import { Page, Button } from '@lib'
import { Link } from '@shared'
import { Group, Submit, Input, FormMessage, FormError } from '@shared/Form'
import { object, array, axiosGet } from '@utils'
import client from '@graphql/client'

import logoImage from '@images/xevant-logo-horizontal.png'
import bgImage from '@images/networkingBG.png'

import triggerPasswordReset from '@graphql/mutators/trigger-password-reset'
import clientLogoGql from '@graphql/queries/client-logo'

const ForgotPassword = () => {
  const [state, setState] = useState({})
  const [errors, setErrors] = useState({})
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  const [clientLogo, setClientLogo] = useState('')
  useEffect(() => {
    if (!clientLogo) {
      client.query({
        query: clientLogoGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientLogo) {
          axiosGet(`s3/client-logo/${data.clientLogo.logoPath}`).then(({ data }) => {
            setClientLogo(data.url)
          })
        } else if (window.location.hostname.includes('localhost') || window.location.hostname === 'app.xevant.com') {
          setClientLogo(logoImage)
        }
      })
    }
  }, [clientLogo])

  const handleInputChange = (name, value) => {
    const newInputError = { ...errors }
    newInputError[name] = []
    setErrors(newInputError)

    const newInput = { ...state }
    newInput[name] = value
    setState(newInput)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const feErrors = {}

    if (!state.email) {
      feErrors.email = [{
        path: 'email',
        message: 'email is required'
      }]
    }

    if (!state.email || !state.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      feErrors.email = [{
        path: 'email',
        message: 'email is not valid'
      }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      triggerPasswordReset({
        email: state.email
      }).then(({ data, extensions }) => {
        if (data.triggerPasswordReset) {
          setState((prevState) => ({
            ...prevState,
            emailCheck: null,
            successMessage: 'An email has been sent to the email address. Follow the instructions to reset your password.'
          }))
          setErrors({})
        }

        if (extensions?.errors) {
          const beErrors = array.groupBy(extensions.errors, 'path')
          if (beErrors.emailCheck) {
            setState((prevState) => ({
              ...prevState,
              successMessage: null,
              emailCheck: 'An email has been sent to the email address. Follow the instructions to reset your password.'
            }))
          }

          delete beErrors.emailCheck
          setErrors(beErrors)
        }
        setLoaderState(prevState => ({ ...prevState, submit: false }))
      })
    } else {
      setErrors(feErrors)
    }
  }

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          {/* <div className='form'> */}
          {/* <div className='forgot-pass-wrap'> */}
            <div className='left-info forgot-password'>
              <div className='login-logo-left'>
                <img src={clientLogo} />
              </div>
              <div className='left-content'>
                <h2>Forgot your password?</h2>
                <h3>
                  Don&apos;t worry, we&apos;ll help you set up a new one.
                </h3>
              </div>
              <div className='copyright'>
                Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
              </div>
            </div>
            <div className='right-form'>

              <br/><br/><br/><br/>
              <h1>Reset your password</h1>
              <p className='big-text'>Enter the email associated with your account and we’ll send an email with instructions on how to reset your password.</p>

              <form
                onSubmit={handleSubmit}
                noValidate
              >
                <FormMessage
                  message={state.successMessage}
                  className='success spaced-bottom'
                />

                <FormError errors={errors} />

                {state.emailCheck && (
                  <FormMessage
                    message={state.emailCheck}
                    className='success spaced-bottom'
                  />
                )}

                <Group className='email-reset-group'>
                  <Input
                    className='login-input'
                    placeholder='Enter your email address'
                    label='We&apos;ll send the recovery link to'
                    type='text'
                    onChange={(value) => handleInputChange('email', value)}
                    errors={errors.email}
                    dataAttrId={'forgot_password_email_field'}
                  />
                </Group>

                <Submit className='right'>
                  <Button
                    className='login-button full-width primary'
                    text='Send Link'
                    type='submit'
                    loading={loaderState.submit}
                    data-attribute-id='forgot_password_btn'
                  />
                </Submit>

                <br/>
                <p className='form-bottom'>
                  <Link to='/login'>Back to login</Link>
                </p>
              </form>
            </div>
          {/* </div> */}
        </div>
      </div>
    </Page>
  )
}

export default ForgotPassword
