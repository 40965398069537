import React, { Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import UserForm from '@shared/UserForm'

const Content = ({ user }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  // console.log(user)
  const handleSave = () => {
    Swal.fire({
      text: 'User updated',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })

    navigate('/settings/users')
  }

  return (
    <Fragment>
      <Title text='Edit User' user={user}/>

      <UserForm
        id={id}
        onSave={handleSave}
        user={user}
      />
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const EditUser = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default EditUser
