import client from '../client.js'
import createUserFavoriteGql from '../mutations/create-user-favorite.gql'

const createUserFavorite = (input) => {
  return client.mutate({
    mutation: createUserFavoriteGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default createUserFavorite
