import client from '../client.js'
import updateResetMfaGql from '../mutations/update-reset-mfa.gql'

const updateResetMfa = (input) => {
  return client.mutate({
    mutation: updateResetMfaGql,
    variables: input
  })
}

export default updateResetMfa
