import client from '../client.js'

import createTemplateFieldAllowedValueGql from '../mutations/create-template-field-allowed-value.gql'

const createTemplateFieldAllowedValue = (input) => {
  return client.mutate({
    mutation: createTemplateFieldAllowedValueGql,
    variables: input
  })
}

export default createTemplateFieldAllowedValue
