import client from '../client.js'
import authGql from '@graphql/queries/auth'
import updatePasswordChangeGql from '../mutations/update-password-change.gql'

const updatePasswordChange = (input) => {
  return client.mutate({
    mutation: updatePasswordChangeGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updatePasswordChange) {
        // update auth cache
        store.writeQuery({
          query: authGql,
          data: {
            auth: data.updatePasswordChange
          }
        })
      }
    }
  })
}

export default updatePasswordChange
