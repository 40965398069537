import React, { cloneElement, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Page } from '@lib'
import { Board, Loader, SideMenu, NoticePopUp, UserBox } from '@shared'

import defineAbility from '@caslConfig/system-role-permissions.js'
import { AbilityContext } from '@caslConfig/can.js'

import authGql from '@graphql/queries/auth'
import clientTitleBarGql from '@graphql/queries/client-title-bar'
import client from '@graphql/client'
import updateUser from '@graphql/mutators/update-user'
import Swal from 'sweetalert2'

const Dashboard = ({ children }) => {
  const authQuery = useQuery(authGql)

  if (authQuery.loading) {
    return (
      <Loader />
    )
  }

  const user = authQuery?.data?.auth

  if (!user) {
    return (
      <Navigate replace to='/login' />
    )
  } else {
    window.pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        role: user.systemRole?.name // Optional
        // full_name:    // Recommended if using Pendo Feedback

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: user.client.id, // Highly recommended, required if using Pendo Feedback
        name: user.client.name // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    })
  }

  const [clientTitleBar, setClientTitleBar] = useState()
  useEffect(() => {
    if (!clientTitleBar) {
      client.query({
        query: clientTitleBarGql,
        variables: {
          url: window.location.hostname
        }
      }).then(({ data }) => {
        if (data.clientTitleBar?.name) {
          document.getElementById('title').innerHTML = ''
          setClientTitleBar(data.clientTitleBar.name)
          const name = data.clientTitleBar.name
          if (name.includes('_RLS')) {
            const newTitle = name.replace('_RLS', '')
            document.getElementById('title').innerHTML = `${newTitle}`
          } else {
            document.getElementById('title').innerHTML = `${name}`
          }
        } else {
          document.getElementById('title').innerHTML = `${window.location.hostname}`
        }
      })
    }
  }, [clientTitleBar])

  if (user.isForcePasswordUpdate) {
    return <Navigate replace to='/update-password' />
  }

  if (!user.isTosAccepted) {
    return <Navigate replace state={{ user }} to='/tos' />
  }

  if (!user.clientId && user.userClients.length > 0) {
    // need to site switch
    const clientIdToSwitchTo = (user.userClients.find(uc => window.location.href.toLowerCase().includes(uc.client.clientUrl.toLowerCase()))?.clientId || user.userClients[0]?.clientId)

    updateUser({
      id: user.id,
      clientId: clientIdToSwitchTo
    }).then(({ data }) => {
      if (data.updateUser) {
        localStorage.removeItem('tabNavs')
        Swal.fire({
          text: 'Switching to ' + data.updateUser.client.name,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          location.reload()
        })
      }
    })
  }

  // COMMENTED FOR TASK EFA-267~
  if (!window.location.href.includes('localhost') || localStorage.getItem('devUrl')) {
    if (!localStorage.getItem('siteSwitching') && !window.location.href.includes('?alert=')) {
      const currentUrl = (!window.location.href.includes('localhost') ? window.location.href : localStorage.getItem('devUrl') + (window.location.href.match(/(\?.*)/)?.[0] || ''))
      const currentHost = currentUrl.match(/[A-Za-z0-9-.]{2,}.xevant.com/)?.[0]?.toLowerCase() || currentUrl.match(/[A-Za-z0-9-.]{2,}.xevant.io/)?.[0]?.toLowerCase()

      if (currentHost && currentHost !== user.client.clientUrl.toLowerCase()) {
        // console.log('WRONG SITE, URL AND USER.CLIENT DONT MATCH')
        const clientForUrl = user.userClients.find(uc => uc.client.clientUrl.toLowerCase() === currentHost)

        if (clientForUrl) {
          // console.log('SWITCH USER TO CLIENT: ' + clientForUrl.clientId, clientForUrl)
          updateUser({
            id: user.id,
            clientId: clientForUrl.clientId
          }).then(({ data, extensions }) => {
            localStorage.removeItem('tabNavs')
            localStorage.removeItem('filter-storage')
            window.location.href = (!window.location.href.includes('localhost') ? currentUrl : window.location.href + (currentUrl.match(/(\?.*)/)?.[0] || ''))
          })
        } else {
          // console.log('SWITCH URL TO USER CLIENTID: ' + user.client.clientUrl)
          if (!window.location.href.includes('localhost')) {
            window.location.href = 'https://' + user.client.clientUrl
          } else {
            window.location.href = '/'
          }
        }
      }
    } else {
      // console.log('CURRENTLY SWITCHING TO: ' + user.client.clientUrl)
      localStorage.removeItem('siteSwitching')
    }
  }

  const ability = defineAbility(user)

  return (
    <AbilityContext.Provider value={ability}>
      <Page
        name='dashboard'
        className='dashboard'
      >
        {/* <Sidebar /> */}
        <SideMenu user={user} />
        <UserBox user={user} />
        <Board>
          {cloneElement(children, { user })}
        </Board>
        <NoticePopUp/>
      </Page>
    </AbilityContext.Provider>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node
}

export default Dashboard
