import React, { Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import ReleaseNoteForm from '@shared/ReleaseNoteForm'

const Content = ({ user }) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const handleSave = () => {
    Swal.fire({
      text: 'Release note updated!',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })

    navigate('/settings/notices-and-release-notes#release-notes')
  }

  return (
    <Fragment>

      <Title text='Edit Release Note' user={user}/>

      <ReleaseNoteForm
        id={id}
        onSave={handleSave}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const EditReleaseNote = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default EditReleaseNote
