import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link, Icon } from '@lib'
import { Can } from '@caslConfig/can.js'
// import { Icon as Iconify } from '@iconify/react'

const Content = ({ user }) => {
  // console.log(user)

  if (user?.client?.clientModules.find(cm => cm.module.name === 'RebateLogic') && !user?.client?.clientModules.find(cm => cm.module.name === 'BidLogic')) {
    window.location.href = '/datalogic/file-uploads/claims'
  }

  let accessDataMods = []
  if (user.systemRole.name === 'Superuser') {
    accessDataMods = ['Claims', 'Rates', 'Specialty', 'Network', 'Formulary']
  } else {
    user?.client?.clientModules.forEach(cm => {
      // WD-458: Commented block below to hide BL components for DL Phase 1
      if (cm.module.name === 'RebateLogic') {
        if (!accessDataMods.includes('Claims')) {
          accessDataMods.push('Claims')
        }
      }

      if (cm.module.name === 'BidLogic') {
        for (const m of ['Claims', 'Rates', 'Specialty', 'Network', 'Formulary']) {
          if (!accessDataMods.includes(m)) {
            accessDataMods.push(m)
          }
        }
      }
    })
  }

  const getDLModuleIcon = (accessDataMods) => {
    let icon = ''
    switch (accessDataMods) {
      case 'Claims':
        icon = 'fas fa-file-alt'
        break
      case 'Rates':
        icon = 'fas fa-file-invoice-dollar'
        break
      case 'Specialty':
        icon = 'fas fa-file-prescription'
        break
      case 'Network':
        icon = 'fas fa-file-prescription'
        break
      case 'Formulary':
        icon = 'fas fa-file-prescription'
        break
      default:
        icon = 'fas fa-file'
    }
    return icon
  }

  return (
    <Fragment>
      <Title text='DataLogic' user={user}/>

      <ul className='directories'>
        {accessDataMods.map((am, i) => (
          <li key={i}>
            <Can I='read' a='FileUpload'>
              <Link to={'/datalogic/file-uploads/' + am.toLowerCase()}>
                <Icon
                className={getDLModuleIcon(am)}
                />
                <span>{am}</span>
              </Link>
            </Can>
          </li>
        ))}
      </ul>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const DataLogicHome = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default DataLogicHome
