import client from '../client.js'
import destroyTemplateFieldAllowedValueGql from '../mutations/destroy-template-field-allowed-value.gql'

const destroyTemplateFieldAllowedValue = (input) => {
  return client.mutate({
    mutation: destroyTemplateFieldAllowedValueGql,
    variables: { id: input },
    update: (store, { data }) => {
    }
  })
}

export default destroyTemplateFieldAllowedValue
