import client from '../client.js'
import destroyFileRunRequestGql from '../mutations/destroy-file-run-request.gql'

const destroyFileRunRequest = (input) => {
  return client.mutate({
    mutation: destroyFileRunRequestGql,
    variables: input,
    update: (store, { data }) => {
      // Intentional
    }
  })
}

export default destroyFileRunRequest
