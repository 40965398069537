import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Dashboard from '@wrappers/Dashboard'
import { Title, Button } from '@lib'
import { Form, Group, Input, Submit, FormError } from '@shared/Form'
import client from '@graphql/client'
import Swal from 'sweetalert2'
import { axiosGet } from '@utils'
import axios from 'axios'
import config from '@config'

import resourceGql from '@graphql/queries/resources'
import createResource from '@graphql/mutators/create-resource'

const Content = ({ user }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [input, setInput] = useState({})
  const [inputErrors, setInputErrors] = useState({})
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  useEffect(() => {
    if (id && loading) {
      const variables = { id }
      client.query({
        query: resourceGql,
        variables
      }).then(({ data }) => {
        if (data.resources) {
          // create placeholder for file name
          let filePlaceHolder = data.resources[0].url.replace('/resourceUploads/trainings/', '')
          filePlaceHolder = filePlaceHolder.replace('/content', '.zip')
          setInput({
            ...data.resources[0],
            logoPath: data.resources[0].image,
            filePlaceHolder
          })
          setLoading(false)
        } else {
          // console.log(extensions)
        }
      })
    }
  }, [loading])

  if (loading) {
    return null
  }

  const handleInputChange = (name, v) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    if (name === 'image' && (v instanceof File || !v)) {
      setInput(prevState => ({
        ...prevState,
        [name]: v,
        logoPath: null
      }))
    }
    if (name === 'file' && (v instanceof File || !v)) {
      setInput(prevState => ({
        ...prevState,
        [name]: v,
        filePlaceHolder: null
      }))
    }
    setInput(prevState => ({
      ...prevState,
      [name]: v
    }))
  }

  // console.log(input)
  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}

    if (!input.name) {
      feErrors.name = [{
        message: 'Resource name is required'
      }]
    } else if (!input.type) {
      feErrors.name = [{
        message: 'Resource type is required'
      }]
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      const newInput = { ...input }
      if (input.file instanceof File) {
        newInput.file = JSON.stringify({
          originalname: input.file.name,
          mimetype: input.file.type,
          size: input.file.size
        })
      }
      if (input.image instanceof File) {
        newInput.image = JSON.stringify({
          originalname: input.image.name,
          mimetype: input.image.type,
          size: input.image.size
        })
      }

      if (id) {
        newInput.id = id
      }

      createResource(newInput).then(async response => {
        if (response.data.createResource) {
          Swal.fire({
            text: 'Resource updated',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })

          if (input.file instanceof File || input.image instanceof File) {
            let bucketEnv = 'dev'
            if (config.APP_ENV === 'prod' || config.APP_ENV === 'production') {
              bucketEnv = 'prod'
            } else if (config.APP_ENV === 'staging' || config.APP_ENV === 'stage') {
              bucketEnv = 'stage'
            }
            const bucket = `xevant-${bucketEnv}-alcatraz-be-uploads-us-west-2`
            const key = encodeURIComponent('zipFiles/' + response.data.createResource.fileName.replace(/^\//, ''))
            const keyImage = encodeURIComponent('resourceUploads/images/' + response.data.createResource.image.replace(/^\//, ''))
            await axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
              const uploadUrl = e.data.url
              axios.put(uploadUrl, input.file)

              await axiosGet(`s3/signed-url/${bucket}/${keyImage}`).then(async e2 => {
                const uploadUrlImage = e2.data.url
                axios.put(uploadUrlImage, input.image)
                navigate('/settings/trainings-and-use-cases')
              })
            })
          } else {
            navigate('/settings/trainings-and-use-cases')
          }
        }
      })
    }
    setInputErrors(feErrors)
  }

  return (
    <Fragment>
      <Title text='Edit Resource' user={user}/>

      <Form className='course-upload-form' onSubmit={handleSave}>
      <FormError errors={inputErrors} />
        <Group>
          <Input
            label='Resource Name'
            type='text'
            value={input.name}
            onChange={(v) => handleInputChange('name', v)}
            errors={inputErrors.name}
          />
        </Group>
        <Group>
          <Input
            type='select'
            label='Resource Type'
            placeholder='Select resource type'
            options={[{ text: 'Training Course', value: 'Training' }, { text: 'Use Case', value: 'UseCase' }]}
            value={input.type}
            onChange={(v) => handleInputChange('type', v)}
            errors={inputErrors.type}
          />
        </Group>
        <Group>
          <Input
            label='Upload Resource'
            type='draganddrop'
            placeholder='upload course as .zip file'
            value={input.file}
            onChange={(v) => handleInputChange('file', v.target.files[0])}
            accept='.zip'
            filePlaceHolder={input.filePlaceHolder}
            className='resource-upload'
          />
        </Group>
        <Group>
          <Input
            label='Upload Thumbnail Image'
            type='draganddrop'
            value={input.image}
            onChange={(v) => handleInputChange('image', v.target.files[0])}
            accept='.png, .jpg, .jpeg'
            logoDirectory='resource-image'
            logoPath={input.logoPath}
            className='thumbnail-upload'
          />
        </Group>
        <Group>
          <Input
            label={<>Resource Summary <span className='optional'>(Optional)</span></>}
            type='textarea'
            value={input.summary}
            onChange={(v) => handleInputChange('summary', v)}
          />
        </Group>

        <Submit>
          <Button
            type='submit'
            text='Save Resource'
            icon='fas fa-arrow-alt-to-top'
            iconAlign='left'
            className='icon-left primary full-width'
            loading={loaderState.submit}
          >
          </Button>
        </Submit>

      </Form>

    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const EditResource = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default EditResource
