import client from '../client.js'
import createUserGroupGql from '../mutations/create-user-group.gql'

const createUserGroup = (input) => {
  return client.mutate({
    mutation: createUserGroupGql,
    variables: input
  })
}

export default createUserGroup
