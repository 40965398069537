import client from '../client.js'
import destroyReleaseNoteGql from '../mutations/destroy-release-note.gql'

const destroyReleaseNote = (id) => {
  return client.mutate({
    mutation: destroyReleaseNoteGql,
    variables: {
      id
    },
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyReleaseNote
