import React, { useEffect, useState } from 'react'
import config from '@config'
import PropTypes from 'prop-types'
import { triggerInputChange, axiosGet } from '@utils'

const Preview = ({ logoDirectory, logoPath, file, filePlaceHolder }) => {
  let element = null

  if (filePlaceHolder) {
    element = <i className='fas fa-file-archive fa-2x'></i>
    return element
  }

  if (!file) {
    element = <i className='fad fa-cloud-upload-alt fa-2x'></i>
    return element
  }

  if (!file?.name) {
    if (logoPath) {
      axiosGet(`s3/${logoDirectory}/${logoPath}`).then(({ data }) => {
        element = <img src={ data.url } />
      })
    } else {
      element = <i className='fad fa-cloud-upload-alt fa-2x'></i>
    }
    return element
  }

  // no exeptions filtered
  const fileType = file?.name.split('.').pop()
  const imageFileType = ['jpg', 'jpeg', 'png']
  const documentFileType = ['xls', 'csv']
  const zipFileType = ['zip']

  if (imageFileType.includes(fileType)) {
    element = logoPath
      ? (<img src={ config.API_URL + logoDirectory + logoPath } />)
      : (<img src={ URL.createObjectURL(file) } />)
  } else if (documentFileType.includes(fileType)) {
    element = <i className='fas fa-file fa-2x'></i>
  } else if (zipFileType.includes(fileType)) {
    element = <i className='fas fa-file-archive fa-2x'></i>
  }

  return element
}

const ImagePreview = ({ logoPath, logoDirectory, file, filePlaceHolder, accept }) => {
  const [element, setElement] = useState(<i className='fad fa-cloud-upload-alt fa-2x'></i>)

  useEffect(() => {
    if (logoPath) {
      axiosGet(`s3/${logoDirectory}/${logoPath}`).then(({ data }) => {
        setElement(<img src={data.url}/>)
      })
    } else if (filePlaceHolder) {
      setElement(<i className='fas fa-file-archive fa-2x'></i>)
    } else if (file) {
      if (accept.includes('zip')) {
        setElement(<i className='fas fa-file-archive fa-2x'></i>)
      } else if (accept.includes('xlsx') || accept.includes('txt')) {
        setElement(<i className='far fa-file-alt fa-2x'></i>)
      } else {
        setElement(<img src={ URL.createObjectURL(file) }/>)
      }
    } else {
      setElement(<i className='fad fa-cloud-upload-alt fa-2x'></i>)
    }
  }, [logoPath, file, filePlaceHolder])

  return (element)
}

const DragAndDropInput = ({
  accept,
  value,
  logoDirectory = null,
  logoPath = null,
  filePlaceHolder,
  onChange,
  className
}) => {
  const textPlaceHolder = `Supports ${accept} file${accept.split(',').length > 1 ? 's' : ''} `
  return (
    <>
      <ImagePreview
        file={value}
        logoPath={logoPath}
        logoDirectory={logoDirectory}
        filePlaceHolder={filePlaceHolder}
        accept={accept}
      />
      <div className='drag-drop-file-input-right'>
        Drag and drop, or <b>browse</b> to your file
        <div className='filename-wrap'>
          {(value?.name || logoPath || filePlaceHolder) &&
            <span className='red-x' onClick={() => triggerInputChange(className, '') }>
              <i className='fas fa-times-circle'></i>
            </span>
          }
          <p>{ value?.name || logoPath || filePlaceHolder || textPlaceHolder }</p>
        </div>
      </div>
      <input type='file' accept={accept} onChange={onChange} />
    </>
  )
}
DragAndDropInput.propTypes = {
  accept: PropTypes.string,
  value: PropTypes.any,
  logoDirectory: PropTypes.string,
  logoPath: PropTypes.string,
  filePlaceHolder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}

export default DragAndDropInput
