import client from '../client.js'
import createClientGql from '../mutations/create-client.gql'

const createClient = (input) => {
  return client.mutate({
    mutation: createClientGql,
    variables: input
  })
}

export default createClient
