import client from '../client.js'
import updateUserClientHomepageGql from '../mutations/update-user-client-homepage.gql'

const updateUserClientHomepage = (input) => {
  return client.mutate({
    mutation: updateUserClientHomepageGql,
    variables: input
  })
}

export default updateUserClientHomepage
