import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'
import client from '@graphql/client'

import { Card, Button } from '@lib'
import { Form, Input, FormError, Submit } from '@shared/Form'
import { array, object } from '@utils'

import templatesGql from '@graphql/queries/templates'
import templateFieldMappingGql from '@graphql/queries/template-field-mapping'
import createTemplateFieldMapping from '@graphql/mutators/create-template-field-mapping'
import updateTemplateFieldMapping from '@graphql/mutators/update-template-field-mapping'

const TemplateFieldMappingForm = ({ id, onSave }) => {
  const [input, setInput] = useState({})
  const [formOptions, setFormOptions] = useState({})
  const [loading, setLoading] = useState(true)
  const [inputErrors, setInputErrors] = useState({})

  useQuery(templatesGql, {
    fetchPolicy: 'network-only',
    onCompleted: ({ templates }) => {
      const options = templates.map(template => ({ value: template.id, text: template.name, fields: template.templateFields }))
      setFormOptions((prevState) => ({
        ...prevState,
        templateOptions: options
      })
      )
    }
  })

  useEffect(() => {
    if (id && loading) {
      client.query({
        query: templateFieldMappingGql,
        variables: { id }
      }).then(({ data }) => {
        if (data.templateFieldMappings) {
          let { templateFieldMappings } = data
          if (templateFieldMappings.constructor === Array) {
            templateFieldMappings = templateFieldMappings[0]
          }
          const templateId = templateFieldMappings.templateField.template.id
          const templateFieldId = templateFieldMappings.templateField.id
          const alternateColumnName = templateFieldMappings.alternateColumnName

          setInput({
            templateId,
            templateFieldId,
            alternateColumnName
          })

          setLoading(false)
        }
      })
    }
  }, [loading])

  if (id && loading) {
    return null
  }

  const handleInputChange = (name, value) => {
    const newInput = { ...input }

    if (name === 'templateId') {
      delete newInput.templateFieldId
    }

    newInput[name] = value
    setInput(newInput)

    const updatedError = inputErrors
    delete updatedError[name]
    setInputErrors(updatedError)
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const updatedInput = { ...input }

    const feErrors = {}

    if (!input.templateId) {
      feErrors.templateId = [{
        message: 'Template is required'
      }]
    }
    if (!input.templateFieldId) {
      feErrors.templateFieldId = [{
        message: 'Template Field is required'
      }]
    }
    if (!input.alternateColumnName) {
      feErrors.alternateColumnName = [{
        message: 'Alternate Column Name is required'
      }]
    }

    if (!object.keys(feErrors).length) {
      if (id) {
        // Function updateTemplateFieldMapping
        updatedInput.id = id
        updateTemplateFieldMapping(updatedInput).then(({ data, extensions }) => {
          if (data.updateTemplateFieldMapping) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          } else {
            Swal.fire({
              text: 'Record already exists!',
              icon: 'error',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
      } else {
        // Function createTemplateFieldMapping
        createTemplateFieldMapping(input).then(({ data, extensions }) => {
          if (data.createTemplateFieldMapping) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          } else {
            Swal.fire({
              text: 'Record already exists!',
              icon: 'error',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
      }
    } else {
      setInputErrors(feErrors)
    }
  }

  return (
    <Card>
      <Form className='field-mapping-form' onSubmit={handleSave}>
        <FormError errors={inputErrors} />
        <Input
          placeholder='Select Template'
          type='select'
          label='Template'
          options={formOptions.templateOptions}
          value={input.templateId}
          onChange={(value) => handleInputChange('templateId', value)}
          errors={inputErrors.templateId}
        />
        <Input
          placeholder='Select Template Field'
          type='select'
          label='Template Field'
          options={formOptions.templateOptions?.find(tOpt => tOpt.value === input.templateId)?.fields.map(({ id, fieldName }) => ({ value: id, text: fieldName }))}
          value={input.templateFieldId}
          onChange={(value) => handleInputChange('templateFieldId', value)}
          errors={inputErrors.templateFieldId}
          disabled={!input.templateId}
        />
        <Input
          type='text'
          label='Alternate Column Name'
          value={input.alternateColumnName}
          onChange={(value) => handleInputChange('alternateColumnName', value)}
          errors={inputErrors.alternateColumnName}
        />

        <Submit className='right'>
          <Button
            type='submit'
            className='form-button icon-left'
            icon={`${id ? '' : 'fas fa-plus'}`}
            text={`${id ? 'Edit' : 'Add'} Field Mapping`}
          />
        </Submit>
      </Form>
    </Card>
  )
}

TemplateFieldMappingForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func
}

TemplateFieldMappingForm.defaultProps = {
  id: '',
  onSave: () => {}
}

export default TemplateFieldMappingForm
