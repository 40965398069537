import React, { Fragment, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link } from '@lib'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { NoticeList, ReleaseNoteList } from '@shared'
import 'react-tabs/style/react-tabs.css'

const Content = ({ user }) => {
  const tabs = {
    '#notices': 0,
    '#release-notes': 1
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const { hash } = useLocation()
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)

  return (
    <Fragment>
      <Title text='Notices & Release Notes' user={user}/>

      <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className='tab-navs'>
          <Tab>
            <Link
              to='#notices'
              onClick={() => { setTabIndex(identifyActiveTab('#notices')) }}
            >
              Notices
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#release-notes'
              onClick={() => { setTabIndex(identifyActiveTab('#release-notes')) }}
            >
              Release Notes
            </Link>
          </Tab>
        </TabList>

        <TabPanel>
          <NoticeList
            itemClass='background-white'
            links={{
              edit: '/settings/notices-and-release-notes/edit-notice'
            }}
            user={user}
          />
        </TabPanel>
        <TabPanel>
          <ReleaseNoteList
            itemClass='background-white'
            links={{
              edit: '/settings/notices-and-release-notes/edit-release-note'
            }}
          />
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const NoticesAndReleaseNotes = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default NoticesAndReleaseNotes
