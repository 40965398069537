const isArray = (array) => {
  if (array && array.constructor === Array) {
    return true
  }

  return false
}

const groupBy = (array, key) => {
  const result = {}

  if (isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i]

      if (!(obj[key] in result)) {
        result[obj[key]] = []
      }

      result[obj[key]].push(obj)
    }
  }

  return result
}

const unique = (array) => {
  return [...new Set(array)]
}

const uniqueByProperty = (array, property, keepOg) => {
  if (keepOg) {
    const newMap = new Map()
    array.forEach(groups => {
      if (!newMap.has(groups[property])) {
        newMap.set(groups[property], groups)
      }
    })
    return [...newMap.values()]
  }

  return [...new Map(array.map((groups) => [groups[property], groups])).values()]
}

const countElement = (array, element) => {
  let count = 0
  for (let i = 0; i < array.length; i++) {
    if (array[i] === element) {
      count++
    }
  }
  return count
}

export default {
  isArray,
  groupBy,
  unique,
  uniqueByProperty,
  countElement
}
