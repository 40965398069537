import React from 'react'
import { ApolloProvider } from '@apollo/client'

import Routes from './routes.js'
import graphQLClient from './graphql/client.js'

const App = () => (
  <ApolloProvider client={graphQLClient}>
      <Routes />
  </ApolloProvider>
)

export default App
