import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import { Title, Button } from '@lib'
import client from '@graphql/client'
import { Form, Input, Submit, FormError } from '@shared/Form'
import { ListView, Item, Field } from '@shared/ListView'
import { object, axiosGet, validateEmail } from '@utils'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import axios from 'axios'
import config from '@config'

import clientGql from '@graphql/queries/client'
import modulesGql from '@graphql/queries/modules'
import systemRolesGql from '@graphql/queries/system-roles'
import createClient from '@graphql/mutators/create-client'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    name: null,
    clientStatus: 'Standard',
    clientUrl: null,
    moduleIds: [],
    users: [],
    isEmailMfaEnabled: false
  })
  const [modules, setModules] = useState()
  const [roles, setRoles] = useState()
  const [roleOption, setRoleOption] = useState('1')
  const [isDatalogicDisabled, setIsDatalogicDisabled] = useState(false)
  const datalogicModules = ['RebateLogic', 'BidLogic']

  useEffect(() => {
    if (loading) {
      client.query({
        query: modulesGql
      }).then(({ data }) => {
        setModules(data.modules)

        if (id) {
          client.query({
            query: clientGql,
            variables: { id }
          }).then(({ data }) => {
            const moduleIds = []
            data.client.clientModules.forEach(cm => {
              moduleIds.push(cm.module.id)
            })
            setInput(prevState => ({
              ...prevState,
              name: data.client.name,
              clientStatus: data.client.clientStatus,
              clientUrl: data.client.clientUrl,
              image: (data.client.logoPath && { name: data.client.logoPath }),
              logoPath: data.client.logoPath,
              moduleIds,
              isEmailMfaEnabled: data.client.isEmailMfaEnabled
            }))
            setLoading(false)
          })
        } else {
          // setLoading(false)
          client.query({
            query: systemRolesGql
          }).then(({ data }) => {
            setRoles(data.systemRoles)
            setLoading(false)
          })
          setInput(prevState => ({
            ...prevState,
            users: [{ username: user.username, role: user.systemRoleId }]
          }))
        }
      })
    }
  }, [loading])

  useEffect(() => {
    if (!loading) {
      handleDatalogicDisabled(input.moduleIds)
    }
  }, [input.moduleIds])

  if (loading) {
    return null
  }

  function handleDatalogicDisabled (moduleIds) {
    /* WD-458 : ADD BL TO ARRAY WHEN SUPPORTED BY THE NEW DL VERSION  */
    const datalogicModuleIds = []

    modules.forEach(m => {
      if (datalogicModules.includes(m.name)) {
        datalogicModuleIds.push(m.id)
      }
    })

    if (datalogicModuleIds.some(dlModules => ((moduleIds).includes(dlModules)))) {
      setIsDatalogicDisabled(false)
    } else {
      setIsDatalogicDisabled(true)
    }
  }

  const handleToggleModule = (module, v) => {
    const newInputError = { ...inputErrors }
    newInputError.moduleIds = []
    setInputErrors(newInputError)

    document.querySelector('.module-section').classList.remove('has-errors')

    const newInput = { ...input }
    newInput.moduleIds = [...input.moduleIds]
    if (v) {
      newInput.moduleIds.push(module.id)
    } else {
      newInput.moduleIds = newInput.moduleIds.filter(id => id !== module.id)
      if (!newInput.moduleIds.some(newModId => datalogicModules.includes(modules.find(m => m.id === newModId).name))) {
        newInput.moduleIds = newInput.moduleIds.filter(id => id !== modules.find(m => m.name === 'DataLogic').id)
        document.querySelector('.toggle.datalogic > .toggle-input').classList.remove('active')
        document.querySelector('.toggle.datalogic > .toggle-input').setAttribute('value', false)
      }
    }

    setInput(newInput)
  }

  const handleInputChange = (name, v) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const updateObj = { [name]: v }

    if (name === 'image') {
      updateObj.logoPath = undefined
    } else if (name === 'clientStatus') {
      updateObj[name] = (v ? 'Demo' : 'Standard')
    }

    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleInputToggleEmail = (name, v) => {
    const updateObj = { [name]: v }

    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleAddUser = () => {
    const newUsername = document.querySelector('.add-user-text-input').value
    const newUserRole = roleOption
    const isValidEmail = validateEmail(newUsername)

    if (isValidEmail.ok) {
      const newInput = { ...input }
      newInput.users = [...input.users]
      newInput.users.push({ username: newUsername.toLowerCase(), role: newUserRole })
      setInput(newInput)
      document.querySelector('.add-user-text-input').value = ''
    } else {
      Swal.fire({
        // text: 'Invalid Username (Email Address)',
        text: isValidEmail.message,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      })
    }
  }

  const handleRemoveUser = (remUser) => {
    const newInput = { ...input }
    newInput.users = [...input.users]
    newInput.users = newInput.users.filter(u => u.username !== remUser)
    setInput(newInput)
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    // console.log(input)

    const feErrors = {}
    if (!input.name) {
      feErrors.name = [{ message: 'Client name is required' }]
    }
    if (!input.clientUrl) {
      feErrors.clientUrl = [{ message: 'URL is required' }]
    } else {
      const isValidUrl = String(input.clientUrl).toLowerCase().match(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/)
      if (!isValidUrl) {
        feErrors.clientUrl = [{ message: 'URL is invalid' }]
      }
    }
    if (!input.image) {
      feErrors.image = [{ message: 'Client logo is required' }]
    }
    if (input.moduleIds.length < 1) {
      feErrors.moduleIds = [{ message: 'At least one module must be selected' }]
      document.querySelector('.module-section').classList.add('has-errors')
    }

    const imagetype = input.image?.type
    if (imagetype && !['image/jpeg', 'image/png'].includes(imagetype)) {
      feErrors.imagetype = [{ message: 'Thumbnail must be of type .png, .jpg, .jpeg' }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      const newInput = { ...input }
      if (id) {
        newInput.id = id
      }
      if (input.users) {
        newInput.users = JSON.stringify(input.users)
      }
      if (input.image) {
        newInput.image = JSON.stringify({
          originalname: input.image.name,
          mimetype: input.image.type,
          size: input.image.size
        })
      }
      createClient(newInput).then(async response => {
        if (response.data.createClient) {
          Swal.fire({
            text: id ? 'Client updated' : 'Client successfully created',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })

          if (input.image instanceof File) {
            let bucketEnv = 'dev'
            if (config.APP_ENV === 'prod' || config.APP_ENV === 'production') {
              bucketEnv = 'prod'
            } else if (config.APP_ENV === 'staging' || config.APP_ENV === 'stage') {
              bucketEnv = 'stage'
            }
            const bucket = `xevant-${bucketEnv}-alcatraz-be-uploads-us-west-2`
            const key = encodeURIComponent('clientLogos/' + response.data.createClient.logoPath.replace(/^\//, ''))
            await axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
              const uploadUrl = e.data.url
              axios.put(uploadUrl, input.image)
              navigate('/settings/clients')
            })
          } else {
            navigate('/settings/clients')
          }
        } else {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setInputErrors(response?.extensions?.errors || response?.errors)
        }
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  return (
    <Fragment>
      <Title text={(id ? 'Edit Client' : 'New Client')} user={user}/>

      <Form className='new-client-form' onSubmit={handleSave}>
        <FormError errors={inputErrors} />

        <div className='form-split-wrap'>
          <div className='form-left'>
            <Input
              label='Client Name'
              type='text'
              value={input.name}
              onChange={(v) => handleInputChange('name', v)}
              disabled={!!id}
              errors={inputErrors?.name}
            />
            {/* <div className='demo-toggle'>
              <Input
                label='Demo'
                type='toggle'
                // value={input.clientStatus}
                value={(input.clientStatus === 'Demo')}
                onChange={(v) => handleInputChange('clientStatus', v)}
              />
            </div> */}
            <div className='enable-email'>
              <Input
                type='toggle'
                value={input.isEmailMfaEnabled}
                onChange={(v) => handleInputToggleEmail('isEmailMfaEnabled', v)}
              />
              <p>Enable Email 2FA</p>
            </div>
            <Input
              label='URL'
              type='text'
              value={input.clientUrl}
              onChange={(v) => handleInputChange('clientUrl', v)}
              errors={inputErrors.clientUrl}
            />

            <Input
              label='Upload Client Logo'
              type='draganddrop'
              value={input.image}
              onChange={(v) => handleInputChange('image', v.target.files[0])}
              accept='.png, .jpg, .jpeg'
              logoDirectory='client-logo'
              logoPath={input.logoPath}
              className='logo-upload'
              errors={inputErrors.image}
            />

            {!id &&
            <div className='form-input'>
              <div className='input-label'>Add Users</div>
              <div className='add-user-input'>
                <div className='text-and-dropdown'>
                  <input
                    type='text'
                    className='add-user-text-input'
                  />
                  <div className='mid-border'></div>
                  <Input
                    type='select'
                    options={[...roles.filter(x => x.name !== 'Superuser').map(r => ({ value: r.id, text: r.name }))]}
                    value={roleOption}
                    onChange={(value) => setRoleOption(value)}
                    placeholder={roles[1].name}
                  />
                </div>
                <Button
                  className='add-user-button'
                  text='Add User'
                  onClick={() => handleAddUser()}
                />
              </div>
              <div className='added-users'>
                {input.users.map((inputUser, key) =>
                  <div className='added-user-box' key={key}>
                    <span>{inputUser.username}</span> | <span>{roles.find(r => r.id === inputUser.role).name}</span><span onClick={inputUser.username !== user.username ? () => handleRemoveUser(inputUser.username) : () => {}} className='remove-user-x'>x</span>
                  </div>
                )}
              </div>
            </div>
            }
            <Submit>
              <Button
                className='primary'
                type='submit'
                text={id ? 'Save Client' : 'Create Client'}
                style={{ width: '100%' }}
                loading={loaderState.submit}
              />
            </Submit>
          </div>
          <div className='form-right'>
            <div className='module-section border-wrap'>
              <h2>Modules</h2>
              <ListView>
                {modules.map(module => (
                  <Item key={module.id}>
                    <Input
                      className={(module.name).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr)}
                      type='toggle'
                      value={input.moduleIds.includes(module.id)}
                      onChange={(v) => handleToggleModule(module, v)}
                      disabled={module.name === 'DataLogic' && isDatalogicDisabled !== false}
                    />
                    <Field
                      // label='Name'
                      value={module.name}
                    />
                  </Item>
                ))}
              </ListView>
            </div>
            {/* <Submit>
              <Button
                type='submit'
                text={id ? 'Save Client' : 'Create Client'}
                style={{ width: '210px' }}
              />
            </Submit> */}
          </div>
        </div>
      </Form>

    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const NewClient = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewClient
