import client from '../client.js'

import resendUserInviteGql from '../mutations/resend-user-invite.gql'

const resendUserInvite = (input) => {
  return client.mutate({
    mutation: resendUserInviteGql,
    variables: input,
    update: (store, { data }) => {
      // console.log('Logger::sendForgotPasswordMutator', data)
    }
  })
}

export default resendUserInvite
