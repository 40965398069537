import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import BidLogicInputForm from '@screens/BidLogic'

import Dashboard from '@wrappers/Dashboard'
import { Title, Tooltip } from '@lib'
import { Loader } from '@shared'
import Swal2 from 'sweetalert2'
import SwalWithReact from 'sweetalert2-react-content'

import TableauViz from '@shared/TableauViz'
import authGql from '@graphql/queries/auth'
import alertGql from '@graphql/queries/alert'

const Swal = SwalWithReact(Swal2)

const Content = ({ user }) => {
  const { workbook, view } = useParams()
  const [title, setTitle] = useState()
  const [clearAllFilters, setClearAllFilters] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showAlertsModal, setShowAlertsModal] = useState(false)
  const [isDashboardExecutionParent, setIsDashboardExecutionParent] = useState(false);
  const navigate = useNavigate()
  let alertFilters = null
  const skipFetchAlert = (!window.location.search.includes('?alert=') || !(window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts')))
  const { loading, data } = useQuery(alertGql, {
    variables: {
      alertId: parseInt(window.location.search.replace('?alert=', ''))
    },
    skip: (!window.location.search.includes('?alert=') || !(window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts')))
  })
  if (loading) {
    return <Loader/>
  }

  if (data?.alert) {
    const members = data.alert.members.map(m => m.member)
    if (user.clientId === data.alert.clientId && members.includes(user.email)) {
      alertFilters = data
    } else {
      navigate('/invalid-link')
    }
  } else if (!skipFetchAlert) {
    navigate('/invalid-link')
  }

  if (workbook.includes('BL3_0')) {
    return <BidLogicInputForm user={user} />
  }

  const revertView = async () => {
    setClearAllFilters(true)
    const viz = document.getElementById('tableauViz')
    // eslint-disable-next-line no-unused-vars
    let blockNewStickyFilts = true
    viz.revertAllAsync().then(() => {
      setTimeout(() => {
        localStorage.removeItem('temp-filter-storage')
        localStorage.removeItem('filter-storage')
        // setStickyFilters('')
        blockNewStickyFilts = false
      }, 5000)
    })
  }

  const getShareLink = () => {
    const filterStorage = localStorage.getItem('filter-storage')
    navigator.clipboard.writeText(window.location.href + (filterStorage !== null ? filterStorage : ''))

    const xevShareBtn = document.querySelector('.xevShareBtn')
    xevShareBtn.querySelector('i').classList.remove('fa-copy')
    xevShareBtn.querySelector('i').classList.add('fa-check')
    setTimeout(() => {
      xevShareBtn.querySelector('i').classList.add('fa-copy')
      xevShareBtn.querySelector('i').classList.remove('fa-check')
    }, 1000)
  }

  const makeJumpToClaimsMenu = async () => {
    const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))
    const customFolderIds = []
    let claimsWorkbooks = []
    let customClaimsWorkbooks = []

    Swal.fire({
      position: 'top',
      backdrop: 'rgba(0,0,0,0)',
      showConfirmButton: false,
      customClass: {
        popup: 'jumpToClaimsPopup',
        htmlContainer: 'jumpToClaimsContent'
      }
    })
    Swal.showLoading()

    const buildMenu = () => {
      Swal.update({
        html: <div className='allClaimsWrapper'>
          <div className='claimsHeader'><p>STANDARD</p><i id ='claims-question' className='fas fa-question-circle claimsTooltip'></i></div>
          <Tooltip
            anchorId='claims-question'
            className='claims-question-tool-tip'
            content={<>Navigating to Claims Detail dashboards<br/>
            from here will carry over currently selected<br/>
            filters and parameters.<br/><br/>
            Claims Summary dashboards provide <br/>
            grouped data based on the category<br/>
            defined.
            </>}
            place='right'
          />
          <div className='claimsWarning'><p>Warning: Downloading claims detail without <br></br>filters may significantly increase load times.</p></div>
          <div className='claimLinksContain standardClaims'>
          </div>
        </div>
      })

      setTimeout(() => {
        claimsWorkbooks.forEach(claimsWorkbook => {
          claimsWorkbook.views.forEach(claimsView => {
            const claimsLink = document.createElement('div')
            claimsLink.classList.add('claimsLink')
            claimsLink.innerHTML = `
              <a href='/dashboard/${claimsWorkbook.contentUrl}/${claimsView.viewUrlName}'>
                <div class='pWrap'><p>${claimsView.name}</p></div>
              </a>
              `
            const claimLinksContainer = document.querySelector('.standardClaims')
            claimLinksContainer.appendChild(claimsLink)
          })
        })

        if (customClaimsWorkbooks.length > 0) {
          const allClaimsWrapper = document.querySelector('.allClaimsWrapper')

          const customHeader = document.createElement('div')
          customHeader.classList.add('claimsHeader')
          customHeader.innerHTML = '<p>CUSTOM</p>'
          allClaimsWrapper.appendChild(customHeader)

          const customLinksContainer = document.createElement('div')
          customLinksContainer.classList.add('claimLinksContain', 'customClaims')
          let customHtml = ''
          customClaimsWorkbooks.forEach(customClaimsWorkbook => {
            customClaimsWorkbook.views.forEach(claimsView => {
              customHtml += `
              <div class='claimsLink'>
                <a href='/dashboard/${customClaimsWorkbook.contentUrl}/${claimsView.viewUrlName}'>
                <div class='pWrap'><p>${claimsView.name}</p></div>
                </a>
              </div>
              `
            })
          })
          customLinksContainer.innerHTML = customHtml
          allClaimsWrapper.appendChild(customLinksContainer)
        }
      }, 50)
    }

    const findClaimsData = async (navToSearch) => {
      for (const projectFolder of navToSearch) {
        if (projectFolder.name.includes('Custom')) {
          customFolderIds.push(projectFolder.id)
        }

        if (customFolderIds.includes(projectFolder.parentProjectId) && projectFolder.name.includes('Claims Data')) {
          customClaimsWorkbooks = customClaimsWorkbooks.concat(projectFolder.workbooks)
        } else if (projectFolder.name === 'Claims Data') {
          claimsWorkbooks = claimsWorkbooks.concat(projectFolder.workbooks)
        }

        if (projectFolder?.children.length > 0) {
          findClaimsData(projectFolder?.children)
        }
      }
    }
    await findClaimsData(tabNavs.filter(t => t.name === 'Xevant'))

    if (claimsWorkbooks.length > 0 || customClaimsWorkbooks.length > 0) {
      buildMenu()
    }
  }

  return (
    <Fragment>
      <Title
        text={title}
        isDashboard={true}
        isDashboardExecution={isDashboardExecutionParent}
        user={user}
        setShowAlertsModal={setShowAlertsModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
        getShareLink={getShareLink}
        makeJumpToClaimsMenu={makeJumpToClaimsMenu}
        revertView={revertView}
      />

      <TableauViz
        setTitle={setTitle}
        user={user}
        clearAllFilters={clearAllFilters}
        setClearAllFilters={setClearAllFilters}
        alertFilters={alertFilters}
        showSubscriptionModal={showSubscriptionModal}
        showAlertsModal={showAlertsModal}
        setShowAlertsModal={setShowAlertsModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
        onDashboardExecutionChange={(isExecuting) => setIsDashboardExecutionParent(isExecuting)}
      />
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const TableauDashboard = () => {
  const authQuery = useQuery(authGql)

  if (authQuery.loading) {
    return (
      <Loader />
    )
  }

  const user = authQuery?.data?.auth

  if (!user) {
    window.location.href = '/login'
  }

  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default TableauDashboard
