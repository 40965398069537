import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { Card, Button, Tinymce } from '@lib'
import { Form, Group, Input, FormError, Submit } from '@shared/Form'
import { array, object } from '@utils'

import client from '@graphql/client'
import releaseNoteGql from '@graphql/queries/release-note'
import createReleaseNote from '@graphql/mutators/create-release-note'
import updateReleaseNote from '@graphql/mutators/update-release-note'
import { set } from 'immutable'

const ReleaseNoteForm = ({ id, onSave }) => {
  const [input, setInput] = useState({
    name: null,
    version: '',
    bodyText: '',
    isEnabled: true
  })

  const [loading, setLoading] = useState(true)
  const [restricted, setRestricted] = useState({})
  const [inputErrors, setInputErrors] = useState({})
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (id && loading) {
      const variables = { id }

      client.query({
        query: releaseNoteGql,
        variables
      }).then(({ data }) => {
        if (data.releaseNote) {
          setInput({
            id: data.releaseNote.id,
            name: data.releaseNote.name,
            version: data.releaseNote.version,
            bodyText: data.releaseNote.bodyText,
            isEnabled: data.releaseNote.isEnabled
          })
          setLoading(false)
        } else {
          setRestricted(true)
        }
      })
    }
  }, [loading])

  if (id && loading) {
    if (restricted === true && !input.id) {
      // return (
      //   <Unauthorized className='board-message' />
      // )
    } else {
      return null
    }
  }

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleEditorChange = (name, value) => {
    setInput(input => ({
      ...input,
      [name]: value
    }))

    setInputErrors(input => ({
      ...input,
      [name]: []
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    setDisabled(true)

    const feErrors = {}

    if (!input.name) {
      feErrors.name = [{
        message: 'Name is required'
      }]
    }
    if (!input.version) {
      feErrors.version = [{
        message: 'Version is required'
      }]
    }

    if (!input.bodyText || input.bodyText === '<p></p>') {
      feErrors.bodyText = [{
        message: 'Body text is required'
      }]
    }

    if (!object.keys(feErrors).length) {
      if (id) {
        updateReleaseNote(input).then(({ data, extensions }) => {
          if (data.updateReleaseNote) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        createReleaseNote(input).then(({ data, extensions }) => {
          if (data.createReleaseNote) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            const sqlError = extensions.errors.filter(e => e.path === 'sqlError')
            extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')

            if (sqlError[0].message === 'unique violation') {
              Swal.fire({
                text: 'Record already exists!',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setDisabled(false)
      setInputErrors(feErrors)
    }
  }
  return (
    <Card>
      <Form onSubmit={handleSave}>
        <FormError errors={inputErrors} />

        <Group>
          <Input
            label='Name'
            type='text'
            value={input.name}
            onChange={(value) => handleInputChange('name', value)}
            errors={inputErrors.name}
          />
          <Input
            label='Version'
            type='text'
            value={input.version}
            onChange={(value) => handleInputChange('version', value)}
            errors={inputErrors.version}
          />
        </Group>

        <Group>
          <Tinymce
            value={input.bodyText}
            onChange={(value) => handleEditorChange('bodyText', value)}
          />
        </Group>

        <Submit className='right'>
          <Button
            type='submit'
            className='form-button primary icon-left'
            icon={`${id ? '' : 'fas fa-plus'}`}
            text={`${id ? 'Update' : 'Add'} Release Note`}
            disabled={disabled}
          />
        </Submit>
      </Form>
    </Card>
  )
}

ReleaseNoteForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func
}

ReleaseNoteForm.defaultProps = {
  id: '',
  onSave: () => {} // This is intentional
}

export default ReleaseNoteForm
