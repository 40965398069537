
import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { useLocation } from 'react-router-dom'
import { Title, Link } from '@lib'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { AlertList } from '@shared'
import 'react-tabs/style/react-tabs.css'
import { useQuery } from '@apollo/client'

import alertsGql from '@graphql/queries/alerts'

const Content = ({ user }) => {
  const { hash } = useLocation()
  const tabs = {
    '#my-alerts': 0,
    '#alerts-for-others': 1,
    '#set-alerts': 2
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)
  const [refetchFlag, setRefetchFlag] = useState(null)

  const myAlertsQuery = useQuery(alertsGql, {
    variables: {
      fetchPolicy: 'network-only',
      alertTab: '#my-alerts',
      ownerId: user.id
    }
  })

  const alertsForOthersQuery = useQuery(alertsGql, {
    variables: {
      fetchPolicy: 'network-only',
      alertTab: '#alerts-for-others',
      ownerId: user.id
    }
  })

  const setAlertsQuery = useQuery(alertsGql, {
    variables: {
      fetchPolicy: 'network-only',
      alertTab: '#set-alerts',
      memberEmail: user.email
    }
  })

  useEffect(() => {
    myAlertsQuery.refetch()
    alertsForOthersQuery.refetch()
    setAlertsQuery.refetch()
  }, [refetchFlag])

  if (myAlertsQuery.loading || myAlertsQuery.error || alertsForOthersQuery.loading || alertsForOthersQuery.error || setAlertsQuery.loading || setAlertsQuery.error) {
    return null
  }

  const { alerts: myAlerts } = myAlertsQuery.data
  const { alerts: alertsForOthers } = alertsForOthersQuery.data
  const { alerts: setAlerts } = setAlertsQuery.data

  if (!myAlerts || !alertsForOthers || !setAlerts) {
    return null
  }
  return (
    <Fragment>
      <Title text='Manage Alerts' user={user} />
      <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className='tab-navs'>
          <Tab>
            <Link
              to='#my-alerts'
              onClick={() => { setTabIndex(identifyActiveTab('#my-alerts')) }}
            >
              {`My Alerts (${myAlerts.length})`}
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#alerts-for-others'
              onClick={() => { setTabIndex(identifyActiveTab('#alerts-for-others')) }}
            >
              {`Alerts Set For Others (${alertsForOthers.length})`}
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#set-alerts'
              onClick={() => { setTabIndex(identifyActiveTab('#set-alerts')) }}
            >
              {`Alerts Set For Me (${setAlerts.length})`}
            </Link>
          </Tab>
        </TabList>
        <TabPanel>
          <AlertList user={user} setRefetchFlag={setRefetchFlag}/>
        </TabPanel>
        <TabPanel>
          <AlertList user={user} setRefetchFlag={setRefetchFlag}/>
        </TabPanel>
        <TabPanel>
          <AlertList user={user} memberOf={true} setRefetchFlag={setRefetchFlag}/>
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}
const ManageAlerts = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}
export default ManageAlerts
