import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@lib'
import { Form, Group, Input, Info, FormError, Submit } from '@shared/Form'
import { Buttons } from '@shared/Action'
import { useNavigate } from 'react-router-dom'
import Modal from '@lib/Modal'
import { object, array, triggerInputChange, validateEmail } from '@utils'

import updateSubscription from '@graphql/mutators/update-subscription'
import createSubscription from '@graphql/mutators/create-subscription'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

const SubscriptionForm = ({ showModal, closeModal, subscription }) => {
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(true)
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    id: null,
    subject: '',
    viewType: '',
    format: '',
    orientation: '',
    frequency: 'schedule',
    memberEmails: '',
    startDate: null,
    endDate: null,
    message: '',
    scheduleSchedule: null,
    scheduleDay: null,
    scheduleWeekday: null,
    scheduleTime: null,
    scheduleTimezone: null,
    isSkippedIfEmpty: false
  })
  const navigate = useNavigate()

  const selectOptions = {
    scheduleOptions: [{ text: 'Daily', value: 'daily' }, { text: 'Weekly', value: 'weekly' }, { text: 'Monthly', value: 'monthly' }],
    viewTypeOptions: [{ text: 'This view', value: 'view' }, { text: 'Entire workbook', value: 'workbook' }],
    formatOptions: [{ text: 'Image', value: 'image' }, { text: 'PDF', value: 'pdf' }, { text: 'Image & PDF', value: 'both' }],
    scheduleWeekdayOptions: [{ text: 'Monday', value: 'monday' }, { text: 'Tuesday', value: 'tuesday' }, { text: 'Wednesday', value: 'wednesday' }, { text: 'Thursday', value: 'thursday' }, { text: 'Friday', value: 'friday' }, { text: 'Saturday', value: 'saturday' }, { text: 'Sunday', value: 'sunday' }],
    frequencyOptions: [{ text: 'On schedule', value: 'schedule' }, { text: 'When data refreshes', value: 'dataRefresh' }],
    scheduleDayOptions: [
      { text: 'First Day', value: 'firstDay' },
      { text: 'Last Day', value: 'lastDay' },
      { text: 'First [Specific Day]', value: 'specific first' },
      { text: 'Second [Specific Day]', value: 'specific second' },
      { text: 'Third [Specific Day]', value: 'specific third' },
      { text: 'Fourth [Specific Day]', value: 'specific fourth' },
      { text: 'Last [Specific Day]', value: 'specific last' }
    ],
    timezoneOptions: [
      { text: 'Alaska', value: 'US/Alaska' },
      { text: 'Arizona', value: 'US/Arizona' },
      { text: 'Central', value: 'US/Central' },
      { text: 'East-Indiana', value: 'US/East-Indiana' },
      { text: 'Eastern', value: 'US/Eastern' },
      { text: 'Hawaii', value: 'US/Hawaii' },
      { text: 'Indiana-Starke', value: 'US/Indiana-Starke' },
      { text: 'Michigan', value: 'US/Michigan' },
      { text: 'Mountain', value: 'US/Mountain' },
      { text: 'Pacific', value: 'US/Pacific' },
      { text: 'Pacific-New', value: 'US/Pacific-New' },
      { text: 'Samoa', value: 'US/Samoa' },
      { text: 'UTC', value: 'UTC' }
    ]
  }

  const selectPlaceholders = {
    viewTypePlaceholder: input.viewType ? selectOptions.viewTypeOptions.find(option => option.value === input.viewType).text : 'Select view type',
    formatPlaceholder: input.format ? selectOptions.formatOptions.find(option => option.value === input.format).text : 'Select format',
    frequencyPlaceholder: input.frequency ? selectOptions.frequencyOptions.find(option => option.value === input.frequency).text : 'Select frequency',
    schedulePlaceholder: input.scheduleSchedule ? selectOptions.scheduleOptions.find(option => option.value === input.scheduleSchedule).text : ' ',
    scheduleWeekdayPlaceholder: input.scheduleWeekday ? selectOptions.scheduleWeekdayOptions.find(option => option.value === input.scheduleWeekday).text : ' ',
    scheduleDayPlaceholder: input.scheduleDay ? selectOptions.scheduleDayOptions.find(option => option.value === input.scheduleDay).text : ' ',
    timezonePlaceholder: input.scheduleTimezone ? selectOptions.timezoneOptions.find(option => option.value === input.scheduleTimezone).text : ' ',
    customMessagePlaceholder: 'Add a custom message...',
    memberEmailsPlaceholder: 'Enter email addresses separated by commas'
  }
  useEffect(() => {
    if (loading) {
      if (subscription) {
        let sched = {}
        if (subscription.schedule) {
          const splitSched = subscription.schedule.split('||')
          sched = {
            scheduleSchedule: splitSched[0],
            scheduleDay: splitSched[1],
            scheduleWeekday: splitSched[2],
            scheduleTime: splitSched[3],
            scheduleTimezone: splitSched[4]
          }
        }
        const memberEmailsArr = []
        subscription.members.forEach(m => {
          if (!memberEmailsArr.includes(m.member.trim())) {
            memberEmailsArr.push(m.member.trim())
          }
        })
        setInput({
          id: subscription.id,
          members: subscription.members,
          subject: subscription.subject,
          viewType: subscription.viewType,
          format: subscription.format,
          orientation: subscription.orientation,
          frequency: 'schedule',
          message: subscription.message,
          startDate: subscription.startDate,
          endDate: subscription.endDate,
          isSkippedIfEmpty: subscription.isSkippedIfEmpty,
          memberEmails: memberEmailsArr.join(', '),
          ...sched
        })
      }
      setLoading(false)
    } else {
      if (subscription) {
        handleFakeTextarea({ target: { value: input.memberEmails } })
      }

      const syncTextareas = []
      syncTextareas.push(document.querySelector('.member-fake-textarea'))
      syncTextareas.push(document.querySelector('.with-fake > textarea'))
      syncTextareas.forEach(st => st.addEventListener('scroll', () => {
        syncTextareas.forEach(t => {
          t.scrollTop = st.scrollTop
          t.scrollLeft = st.scrollLeft
        })
      }))
    }
  }, [loading])

  const handleInputChange = (name, v) => {
    if (input.frequency === 'schedule' && input.scheduleTime && input.scheduleTimezone && input.startDate) {
      delete inputErrors.schedule
    }
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    if (name === 'memberEmails') {
      triggerInputChange('member-fake-textarea', v)
    }

    if (name === 'startDate') {
      const start = new Date(v)
      const end = new Date(input.endDate)

      if (end <= start) {
        setInput(prevState => ({
          ...prevState,
          endDate: null
        }))
      }
    }

    if (name === 'noEndDate' && v) {
      setInput(prevState => ({
        ...prevState,
        endDate: null
      }))
    }

    if (name === 'viewType' && v === 'workbook') {
      setInput(prevState => ({
        ...prevState,
        [name]: v,
        format: 'pdf'
      }))
    } else {
      setInput(prevState => ({
        ...prevState,
        [name]: v
      }))
    }
  }

  const handleFakeTextarea = (e) => {
    const v = e.target.value.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    const emailsToValidate = v.split(/,\s*/)
    let emailErrs = false
    let newV = v
    emailsToValidate.forEach(email => {
      if (email) {
        if (validateEmail(email.trim()).ok) {
          newV = newV.replace(email.trim(), '<span class="valid-email">' + email.trim() + '</span>')
        } else {
          emailErrs = true
        }
      }
    })
    document.querySelector('.member-fake-textarea').innerHTML = newV.replace(/\s(?!class)/g, '&nbsp;<wbr>')

    if (/(.com|.net|.gov)$/g.test(v) || /,\s*$/.test(v)) {
      if (emailErrs) {
        setInputErrors({ memberEmails: [{ message: 'Invalid email format entered.' }] })
      } else {
        setInputErrors({})
      }
    }
  }

  const generateEmailSpan = () => {
    if (subscription) {
      const validEmails = subscription.members.map((member) => {
        return (<><span className='valid-email' key={member.id}>{member.member}, </span><wbr></wbr></>)
      })
      return <>{validEmails}</>
    } else {
      return ''
    }
  }

  const handleSave = async (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    setDisabled(true)
    const inputToSubmit = { ...input }

    const feErrors = {}

    if (!input.subject) {
      feErrors.subject = [{
        message: 'Subject field is required'
      }]
    }
    if (!input.viewType) {
      feErrors.viewType = [{
        message: 'View field is required'
      }]
    }
    if (!input.format) {
      feErrors.format = [{
        message: 'Format field is required'
      }]
    }
    if (['pdf', 'both'].includes(input.format) && !input.orientation) {
      feErrors.orientation = [{
        message: 'Orientation field required'
      }]
    }
    if (!input.frequency) {
      feErrors.frequency = [{
        message: 'Frequency field required'
      }]
    }
    if (input.frequency === 'schedule') {
      if (!input.scheduleTime || !input.scheduleTimezone || !input.startDate) {
        feErrors.schedule = [{
          message: 'Fill out appropriate scheduling fields'
        }]
      }

      inputToSubmit.schedule = [input.scheduleSchedule, input.scheduleDay, input.scheduleWeekday, input.scheduleTime, input.scheduleTimezone].join('||')
    }
    if (input.noEndDate) {
      inputToSubmit.endDate = null
    }

    const errMessage = 'Include a valid email'
    if (!input.memberEmails.trim().split(',').filter(email => email.length > 0).every(email => validateEmail(email.trim()).ok)) {
      feErrors.memberEmails = [{
        message: errMessage
      }]
    }

    const memberArr = inputToSubmit.memberEmails.split(',').filter(email => email.length > 0).map(email => email.toLowerCase().trim())

    if (memberArr.length !== array.unique(memberArr).length) {
      feErrors.memberEmails = [{
        message: 'Remove duplicate email'
      }]
    }

    if (memberArr.length === 0) {
      feErrors.memberEmails = [{
        message: 'Subscribers field is required'
      }]
    }

    if (!object.keys(feErrors).length) {
      delete inputToSubmit.noEndDate
      delete inputToSubmit.scheduleSchedule
      delete inputToSubmit.scheduleDay
      delete inputToSubmit.scheduleTime
      delete inputToSubmit.scheduleTimezone
      delete inputToSubmit.scheduleWeekday
      delete inputToSubmit.createdBy
      delete inputToSubmit.updatedAt
      delete inputToSubmit.lastRanAt
      delete inputToSubmit.members
      delete inputToSubmit.user
      delete inputToSubmit.id

      if (subscription) {
        // UPDATE
        inputToSubmit.ids = [input.id]
        inputToSubmit.adminStop = true

        const newMembers = array.unique(inputToSubmit.memberEmails.toLowerCase().split(',').map(email => email.trim()))
        const removedMembers = []
        const addedMembers = []
        input.members?.forEach(inputMember => {
          if (!newMembers.includes(inputMember.member)) {
            removedMembers.push(inputMember.member)
          } else if (!newMembers.includes(inputMember.member.toLowerCase().trim())) {
            removedMembers.push(inputMember.member.toLowerCase().trim())
          }
        })
        newMembers?.forEach(newMember => {
          if (newMember && !input.members?.find(m => m.member === newMember.toLowerCase().trim())) {
            addedMembers.push(newMember.toLowerCase().trim())
          }
        })
        inputToSubmit.removedMembers = removedMembers
        inputToSubmit.addedMembers = addedMembers

        updateSubscription(inputToSubmit).then(({ data }) => {
          if (data.updateSubscription) {
            setInputErrors({})
            Swal.fire({
              icon: 'success',
              text: 'Successfully updated',
              timer: 3000,
              showConfirmButton: false
            })
            closeModal()
          }
        })
      } else {
        // CREATE
        // GET WORKBOOK AND VIEW ID
        const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))
        const viz = document.getElementById('tableauViz')
        const wbName = viz.workbook.name
        const wbViewName = viz.workbook.activeSheet.name
        let foundWb = null
        let wbId
        let viewId
        const findWorkbook = async (navToSearch) => {
          for (const projectFolder of navToSearch) {
            for (const wb of projectFolder.workbooks) {
              if (wb.name === wbName && wb.views.find(wbv => wbv.name === wbViewName)) {
                foundWb = wb
                wbId = wb.id
                viewId = wb.views.find(wbv => wbv.name === wbViewName).id
              }
            }
            if (projectFolder?.children && !foundWb) {
              findWorkbook(projectFolder.children)
            }
          }
        }
        await findWorkbook(tabNavs.filter(t => t.name === 'Xevant'))

        inputToSubmit.workbookId = wbId
        inputToSubmit.viewId = viewId
        // END GET WORKBOOK AND VIEW ID STUFF

        if (viz.workbook.activeCustomView && inputToSubmit.viewType !== 'workbook') {
          inputToSubmit.customViewUrl = viz.workbook.activeCustomView.url
        }

        inputToSubmit.filterString = localStorage.getItem('filter-storage')

        createSubscription(inputToSubmit).then(({ data, extensions }) => {
          if (data.createSubscription) {
            setInputErrors({})
            Swal.fire({
              icon: 'success',
              text: 'Successfully created',
              timer: 3000,
              showConfirmButton: false
            })
            closeModal()
          } else {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setInputErrors(feErrors)
      setDisabled(false)
    }
  }

  if (loading) {
    return null
  }

  return (
    <Fragment>
      <Modal show={showModal}>
        <div className='subscription-form-modal'>
          <h2>{ subscription ? 'Edit Subscription' : 'Subscribe' }</h2>
          <Form onSubmit={handleSave}>
            <FormError errors={inputErrors} />

            {(input.viewType === 'view' && localStorage.getItem('filter-storage')?.split('&').length > 1) &&
              <Group>
                <div className='form-input'>
                  <div className='input-label'>Filters Applied</div>
                  <div className='applied-filter-contain'>
                  {localStorage.getItem('filter-storage').split('&').map((filter, key) =>
                    filter !== '?filters' &&
                    <div key={key} className='applied-filter'>{(filter.includes('p|') ? decodeURIComponent(filter.split('=')[0]).match(/(?![&p|])(.*)(?=\|)/)[1] : decodeURIComponent(filter.split('=')[0]))}</div>
                  )}
                  </div>
                </div>
              </Group>
            }
            <Group>
              <Input
                label='Subject/Name'
                type='text'
                value={input.subject}
                onChange={(v) => handleInputChange('subject', v)}
                errors={inputErrors.subject}
              />
            </Group>
            <Group>
              <Input
                label='Select View'
                type='select'
                options={selectOptions.viewTypeOptions}
                placeholder={selectPlaceholders.viewTypePlaceholder}
                value={input.viewType}
                onChange={(v) => handleInputChange('viewType', v)}
                errors={inputErrors.viewType}
              />
            </Group>
            <Group>
              {input.viewType === 'workbook'
                ? <Info
                    label='Select Format'
                    value='PDF'
                  />
                : <Input
                    label='Select Format'
                    type='select'
                    className={input.format}
                    options={selectOptions.formatOptions}
                    placeholder={selectPlaceholders.formatPlaceholder}
                    value={input.format}
                    disabled={input.viewType === 'workbook'}
                    onChange={(v) => handleInputChange('format', v)}
                    errors={inputErrors.format}
                  />
              }
            </Group>
            {['pdf', 'both'].includes(input.format) &&
              <Group className='radio-group'>
                <span>Orientation</span>
                <input
                  type='radio'
                  name='orientation'
                  checked={input.orientation === 'portrait'}
                  id='portrait'
                  value='portrait'
                  onChange={(v) => handleInputChange('orientation', v.target.value)}
                />
                <label htmlFor='portrait'>Portrait</label>
                <input
                  type='radio'
                  name='orientation'
                  checked={input.orientation === 'landscape'}
                  id='landscape'
                  value='landscape'
                  onChange={(v) => handleInputChange('orientation', v.target.value)}
                />
                <label htmlFor='landscape'>Landscape</label>
              </Group>
            }

            {/* <Group>
              <Input
                label='Select Frequency'
                type='select'
                options={selectOptions.frequencyOptions}
                placeholder={selectPlaceholders.frequencyPlaceholder}
                value={input.frequency}
                onChange={(v) => handleInputChange('frequency', v)}
                errors={inputErrors.frequency}
              />
            </Group> */}
            {input?.frequency === 'schedule' &&
            <>
              <Group className='schedule-group'>
                <Input
                  label='Schedule'
                  type='select'
                  placeholder={selectPlaceholders.schedulePlaceholder}
                  options={selectOptions.scheduleOptions}
                  value={input.scheduleSchedule}
                  onChange={(v) => handleInputChange('scheduleSchedule', v)}
                  errors={inputErrors?.scheduleSchedule}
                />
                {input?.scheduleSchedule === 'weekly'
                  ? <Input
                    label='Day'
                    type='select'
                    options={selectOptions.scheduleWeekdayOptions}
                    placeholder={selectPlaceholders.scheduleWeekdayPlaceholder}
                    value={input.scheduleWeekday}
                    onChange={(v) => handleInputChange('scheduleWeekday', v)}
                    />
                  : input?.scheduleSchedule === 'monthly'
                    ? <>
                      <Input
                        label='Day'
                        type='select'
                        options={selectOptions.scheduleDayOptions}
                        placeholder={selectPlaceholders.scheduleDayPlaceholder}
                        value={input.scheduleDay}
                        onChange={(v) => handleInputChange('scheduleDay', v)}
                      />
                      {input?.scheduleDay?.includes('specific') &&
                      <Input
                        label='Day of the Week'
                        type='select'
                        placeholder={selectPlaceholders.scheduleWeekdayPlaceholder}
                        options={selectOptions.scheduleWeekdayOptions}
                        value={input.scheduleWeekday}
                        onChange={(v) => handleInputChange('scheduleWeekday', v)}
                      />
                      }
                    </>
                    : ''}
                <Input
                  label='Time'
                  type='datetime'
                  settings={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:iK'
                  }}
                  value={input.scheduleTime}
                  onChange={(v) => handleInputChange('scheduleTime', dayjs(v).format('h:mma'))}
                />
                <Input
                  label='Time Zone'
                  type='select'
                  // options={[{ text: 'MST', value: 'mst' }]}
                  options={selectOptions.timezoneOptions}
                  value={input.scheduleTimezone}
                  placeholder={selectPlaceholders.timezonePlaceholder}
                  onChange={(v) => handleInputChange('scheduleTimezone', v)}
                />
                <Group>
                <Input
                  label='Start Date'
                  type='datepicker'
                  className='icon-right'
                  icon='fas fa-calendar'
                  value={input.startDate}
                  settings={{
                    minDate: input.startDate ? input.startDate : new Date()
                  }}
                  onChange={(v) => handleInputChange('startDate', v)}
                />
                <Input
                  label='End Date'
                  type='datepicker'
                  className='icon-right'
                  icon='fas fa-calendar'
                  value={input.noEndDate ? null : input.endDate}
                  disabled={input.noEndDate}
                  clear={input.noEndDate}
                  settings={{
                    minDate: new Date(new Date(input.startDate).getTime() + 86400000)
                  }}
                  onChange={(v) => handleInputChange('endDate', v)}
                />
                <div className='no-end-date-check-wrap'>
                  <div className='checkbox'>
                    <input
                      type='checkbox'
                      onChange={(v) => handleInputChange('noEndDate', v.target.checked)}
                    />
                  </div>
                  <span>No End Date</span>
                </div>
                </Group>
              </Group>

              <p className='dynamic-schedule-text'>
                {input.scheduleSchedule === 'daily'
                  ? `Occurs daily at ${input.scheduleTime} ${input.scheduleTimezone}`
                  : input.scheduleSchedule === 'weekly'
                    ? `Occurs every ${input.scheduleWeekday} at ${input.scheduleTime} ${input.scheduleTimezone} `
                    : input.scheduleSchedule === 'monthly'
                      ? `Occurs on the ${input.scheduleDay === 'firstDay'
                      ? 'first day'
                      : input.scheduleDay === 'lastDay'
                      ? 'last day'
                      : input.scheduleDay?.includes('specific')
                      ? input.scheduleDay?.replace('specific ', '') + ' ' + input.scheduleWeekday
                      : ''} of each month, at ${input.scheduleTime} ${input.scheduleTimezone}`
                      : ''}
              </p>
            </>
            }

            <div className='form-break-border'></div>

            <Group>
              <Input
                type='textarea'
                placeholder={selectPlaceholders.customMessagePlaceholder}
                value={input.message}
                onChange={(v) => handleInputChange('message', v)}
                errors={inputErrors.message}
              />
            </Group>

            <Group>
              <div className='fake-textarea-wrap form-input'>
                <div className='input-label'>.</div>
                <div className='input textarea'>
                  <div
                    className='member-fake-textarea'
                    onInput={(e) => handleFakeTextarea(e)}
                  >
                    {generateEmailSpan()}
                  </div>
                </div>
              </div>
              <Input
                type='textarea'
                label='Subscribers'
                placeholder={selectPlaceholders.memberEmailsPlaceholder}
                value={input.memberEmails}
                onChange={(v) => handleInputChange('memberEmails', v)}
                className={`member-textarea with-fake ${subscription ? 'edit' : ''}`}
                errors={inputErrors.memberEmails}
              />
            </Group>

            <Group>
              <div className='is-skipped-if-empty-check-wrap'>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    onChange={(v) => handleInputChange('isSkippedIfEmpty', v.target.checked)}
                    checked={input.isSkippedIfEmpty}
                  />
                </div>
                <span>Do not send if empty</span>
              </div>
            </Group>

            <Submit className='right'>
              {!subscription ? <Button className='tertiary form-foot-link' onClick={() => navigate('/help-center/manage-subscriptions')} text='Manage Subscriptions'/> : <div></div> }
              <Buttons>
                <Button
                  type='submit'
                  className='form-button primary'
                  text={subscription ? 'Edit' : 'Subscribe'}
                  disabled={disabled}
                />
                <Button
                  onClick={() => closeModal()}
                  className='form-button secondary'
                  text='Cancel'
                />
              </Buttons>
            </Submit>
          </Form>
        </div>
      </Modal>
    </Fragment>
  )
}
SubscriptionForm.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  subscription: PropTypes.object
}

export default SubscriptionForm
