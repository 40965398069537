import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, LoadOverlay } from '@lib'
import { Form, Group, Input, Submit, FormError } from '@shared/Form'
import { array, object, validateEmail } from '@utils'
import { Unauthorized } from '@screens/General/Pages'
import RoleSelectorModal from '@shared/RoleSelectorModal'

import client from '@graphql/client'
import userGql from '@graphql/queries/user'
import userClientsGql from '@graphql/queries/user-clients'
import systemRolesGql from '@graphql/queries/system-roles'
import tableauGroupsGql from '@graphql/queries/tableau-groups'
import createUser from '@graphql/mutators/create-user'
import updateUser from '@graphql/mutators/update-user'

const UserForm = ({ id, onSave, user }) => {
  const [input, setInput] = useState({
    systemRoleId: '',
    email: '',
    firstName: '',
    lastName: '',
    isDatalogicUser: false,
    isRlsUser: false,
    sites: [],
    groups: []
  })
  const [loading, setLoading] = useState(true)
  const [loadingGroups, setLoadingGroups] = useState(true)
  const [inputErrors, setInputErrors] = useState({})
  const [restricted, setRestricted] = useState({})
  const [roles, setRoles] = useState()
  const [userClients, setUserClients] = useState()
  const [clientGroups, setClientGroups] = useState([])
  const [loaderState, setLoaderState] = useState({
    overlay: false,
    // overlayLoaderType: 'progressBar',
    progress: 0,
    submit: false,
    systemRoleInput: true,
    siteInput: true,
    groupInput: true
  })
  const [groupsDisabled, setGroupsDisabled] = useState(true)
  const [siteRoles, setSiteRoles] = useState([])
  const [siteGroups, setSiteGroups] = useState([])
  const [clientDatalogicEnabled, setClientDatalogicEnabled] = useState(false)

  useEffect(() => {
    if (user) {
      if (user.client.clientModules.find(cm => cm.module.name === 'DataLogic')) {
        setClientDatalogicEnabled(true)
      }
    }
  }, [])

  useEffect(() => {
    if (input.sites.length === 0) {
      setGroupsDisabled(true)
      if (input.groups.length !== 0) {
        const newInput = { ...input }
        newInput.groups = []
        setInput(newInput)
      }
    }
  }, [input.sites])

  useEffect(() => {
    if (loading) {
      if (id) {
        client.query({
          query: userGql,
          variables: {
            id
          },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          if (data.user) {
            const inputObj = {
              systemRoleId: data.user.systemRoleId,
              systemRole: data.user.systemRole,
              email: data.user.email,
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              isDatalogicUser: false,
              isRlsUser: data.user.isRlsUser,
              sites: []
            }
            const initSiteRoles = []
            data.user.userClients.forEach(uc => {
              if (uc.clientId === user.clientId) {
                inputObj.isDatalogicUser = uc.isDatalogicUser
              }

              inputObj.sites.push(uc.clientId)
              initSiteRoles.push({
                value: uc.clientId,
                siteRoleId: uc.siteRoleId
              })
            })
            setSiteRoles(initSiteRoles)
            setInput(inputObj)
            setLoading(false)
          } else {
            setRestricted(true)
          }
        })

        client.query({
          query: tableauGroupsGql,
          variables: {
            forUserId: id
          },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          const belongsGroupIds = []
          JSON.parse(data.tableauGroups).forEach(tg => {
            belongsGroupIds.push(tg?.id)
          })
          setInput(prevState => ({
            ...prevState,
            groups: belongsGroupIds
          }))
          setSiteGroups(belongsGroupIds)
          setLoadingGroups(false)
        })
      } else {
        setLoaderState(prevState => ({ ...prevState, groupInput: false }))
        setLoading(false)
      }

      client.query({
        query: systemRolesGql
      }).then(({ data }) => {
        setRoles(data.systemRoles)
        setLoaderState(prevState => ({ ...prevState, systemRoleInput: false }))
      })

      client.query({
        query: userClientsGql,
        variables: {
          userId: user.id
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        setUserClients(data.userClients)
        setLoaderState(prevState => ({ ...prevState, siteInput: false }))
      })
    }
  }, [loading])

  useEffect(() => {
    if (!loadingGroups && id && clientGroups?.length > 0) {
      const newClientGroups = [...clientGroups]
      for (const ncg of newClientGroups) {
        if (input.groups.includes(String(ncg.id))) {
          ncg.oldBelongsTo = true
        }
      }
      setClientGroups(newClientGroups)
      setLoadingGroups(true)
    }
  }, [loadingGroups, clientGroups])

  useEffect(() => {
    if (!loading) {
      const newIds = input.sites?.filter(v => !clientGroups.find(cg => cg.clientId === v))
      if (input.sites.length === 0) {
        setGroupsDisabled(true)
      }
      if (newIds.length > 0) {
        setLoaderState(prevState => ({ ...prevState, groupInput: true }))

        client.query({
          query: tableauGroupsGql,
          variables: {
            clientId: newIds
          }
        }).then(({ data }) => {
          setLoaderState(prevState => ({ ...prevState, groupInput: true }))

          if (data.tableauGroups) {
            const newClientGroups = [...clientGroups, ...JSON.parse(data.tableauGroups)]
            const uniqueObjArray = array.uniqueByProperty(newClientGroups, 'id', true)
            setClientGroups(uniqueObjArray)
            setTimeout(() => {
              setLoaderState(prevState => ({ ...prevState, groupInput: false }))
              setGroupsDisabled(false)
            }, 1000)
          }
        })
      }
    }
  }, [input.sites])

  useEffect(() => {
    if (inputErrors.siteRoles?.length > 0) {
      let completeSiteRoles = true
      input.sites.forEach(s => {
        if (!siteRoles.find(sr => sr.value === s)?.siteRoleId) {
          completeSiteRoles = false
        }
      })

      if (completeSiteRoles) {
        const newInputError = { ...inputErrors }
        newInputError.siteRoles = []
        setInputErrors(newInputError)
      }
    }
  }, [siteRoles])

  if (id && loading) {
    if (restricted === true && !input.id) {
      return (
        <Unauthorized className='board-message' />
      )
    }
  }
  if (loading) {
    return null
  }

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleInputToggleDataLogic = (name, v) => {
    const updateObj = { [name]: v }

    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}
    if (!input.email) {
      feErrors.email = [{ message: 'Email is required' }]
    } else {
      const isEmailValid = validateEmail(input.email)

      if (!isEmailValid.ok) {
        feErrors.email = [{ message: isEmailValid.message }]
      }
    }

    if (!input.firstName) {
      feErrors.firstName = [{ message: 'First name is required' }]
    }

    if (!input.lastName) {
      feErrors.lastName = [{ message: 'Last name is required' }]
    }

    if (!input.systemRoleId) {
      if (siteRoles.length > 0) {
        input.systemRoleId = siteRoles[0]?.siteRoleId
      }
    }

    if (input.sites.length < 1) {
      feErrors.sites = [{ message: 'At least one client site must be selected' }]
    }

    input.groups = siteGroups
    const filteredGroups = input.groups?.filter(g => clientGroups.find(cg => cg.id === g && input.sites.includes(String(cg.clientId))))
    input.groups = filteredGroups

    if (!input.groups || input.groups.length < 1) {
      feErrors.groups = [{ message: 'At least one group must be selected for each client/site assigned' }]
    } else {
      input.sites.forEach(is => {
        const validGroup = clientGroups.find(cg => cg.name !== 'All Users' && String(cg.clientId) === is && input.groups.includes(cg.id))

        if (!validGroup) {
          feErrors.groups = [{ message: 'At least one group must be selected for each client/site assigned' }]
        }
      })
    }

    input.sites.forEach(s => {
      if (!siteRoles.find(sr => sr.value === s)?.siteRoleId) {
        feErrors.siteRoles = [{ message: 'Role must be selected for each site' }]
      }
    })

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      // HERE TO REFERENCE FOR OVERLAY LOADER TYPE
      // setLoaderState(prevState => ({ ...prevState, overlay: true }))
      // setTimeout(() => {
      //   setLoaderState(prevState => ({ ...prevState, progress: 100 }))
      // }, 6000)

      for (const cg of clientGroups) {
        if (input?.groups?.includes(cg.id)) {
          cg.newBelongsTo = true
        }
      }

      if (id) {
        const newInput = {
          id,
          firstName: input.firstName,
          lastName: input.lastName,
          sites: input.sites,
          groups: input.groups,
          clientGroups: JSON.stringify(clientGroups),
          siteRoles: JSON.stringify(siteRoles),
          isDatalogicUser: input.isDatalogicUser,
          isRlsUser: input.isRlsUser
        }

        updateUser(newInput).then(({ data, extensions }) => {
          if (data.updateUser) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        input.clientGroups = JSON.stringify(clientGroups.filter(cg => cg.newBelongsTo))
        input.siteRoles = JSON.stringify(siteRoles)
        createUser(input).then(({ data, extensions }) => {
          if (data.createUser) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  return (
    <Form className='user-form' onSubmit={handleSave}>
      <FormError errors={inputErrors} />

      <Group>
        <Input
          label='Email'
          type='text'
          value={input.email}
          disabled={!!id}
          onChange={(value) => handleInputChange('email', value)}
          errors={inputErrors.email}
        />
      </Group>

      <Group>
        <Input
          label='First Name'
          type='text'
          value={input.firstName}
          onChange={(value) => handleInputChange('firstName', value)}
          errors={inputErrors.firstName}
        />
      </Group>

      <Group>
        <Input
          label='Last Name'
          type='text'
          value={input.lastName}
          onChange={(value) => handleInputChange('lastName', value)}
          errors={inputErrors.lastName}
        />
      </Group>

      <Group>
        <Input
          label='Clients/Sites'
          type='multiselect'
          placeholder='Select clients/sites'
          options={userClients?.map(u => ({ value: u.clientId, text: u?.client?.name }))}
          value={input.sites}
          onChange={(v) => handleInputChange('sites', v)}
          errors={inputErrors.sites}
          loading={loaderState.siteInput}
        />
      </Group>

      <RoleSelectorModal
        getState={siteRoles}
        setState={(v) => setSiteRoles(v)}
        getStateGroups={siteGroups}
        setStateGroups={(v) => setSiteGroups(v)}
        disabled={!input.sites.length || !userClients}
        clientGroups={clientGroups?.filter(cg => input.sites.includes(String(cg.clientId)) && cg.name !== 'All Users')?.map(cg => ({ value: cg.id, text: cg.name + ' (' + userClients?.find(uc => uc.clientId === String(cg.clientId))?.client.name + ')' }))}
        roles={roles?.map(r => ({ value: r.id, text: r.name }))}
        sites={userClients?.map(u => ({ value: u.clientId, text: u?.client?.name })).filter(s => input.sites.includes(s.value)) || []}
      />

      <div className='enable-datalogic'>
        <Input
          type='toggle'
          value={input.isRlsUser}
          onChange={(v) => handleInputChange('isRlsUser', v)}
        />
        <p>RLS User</p>
      </div>

      { clientDatalogicEnabled && (
        <div className='enable-datalogic'>
          <Input
            type='toggle'
            value={input.isDatalogicUser}
            onChange={(v) => handleInputToggleDataLogic('isDatalogicUser', v)}
          />
          <p>DataLogic access</p>
        </div>
      )}

      <Submit className='right'>
        <Button
          type='submit'
          className='icon-left primary'
          icon={`${id ? '' : 'fad fa-user-plus'}`}
          text={`${id ? 'Update' : 'Add'} User`}
          loading={loaderState.submit}
        />
      </Submit>
      {loaderState.overlay && <LoadOverlay loaderType={loaderState.overlayLoaderType} progress={loaderState.progress} />}
    </Form>
  )
}
UserForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func,
  user: PropTypes.object
}
UserForm.defaultProps = {
  onSave: () => {} // This is intentional
}

export default UserForm
