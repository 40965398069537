import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Datatable, Button, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import { Buttons } from '@shared/Action'
import { Group } from '@shared/Form'
import Modal from '@lib/Modal'

import { Can } from '@caslConfig/can.js'
import noticesGql from '@graphql/queries/notices'
import destroyNotice from '@graphql/mutators/destroy-notice'
import { time } from '@utils'

const NoticeList = ({ links, user }) => {
  const [showNotice, setShowNotice] = useState(false)
  const [notice, setNotice] = useState({
    title: '',
    headText: '',
    bodyText: '',
    releaseNoteId: null
  })
  // values query
  const noticesQuery = useQuery(noticesGql, {
    fetchPolicy: 'network-only'
  })

  const handlePreview = (notice) => {
    const newNoticeInfo = {
      title: notice.name,
      headText: notice.headText,
      bodyText: notice.bodyText,
      releaseNoteId: notice.releaseNoteId
    }
    setNotice(newNoticeInfo)
    setShowNotice(true)

    window.blur()
  }

  const handleDelete = (noticeID) => {
    Swal.fire({
      text: 'Are you sure you want to delete this notice?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        destroyNotice(noticeID).then(({ data, extensions }) => {
          if (data) {
            if (data.destroyNotice) {
              Swal.fire({
                text: 'Notice deleted!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              })
              noticesQuery.refetch()
            } else {
              const { errors } = extensions

              Swal.fire({
                text: errors[0].message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
          }
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Start Date',
        accessor: (n) => {
          if (user.timezone) {
            const formattedTime = time.utc(n.startAt).tz(user.timezone).format('MM/DD/YYYY')
            if (formattedTime === 'Invalid Date' || formattedTime === null) {
              return '-'
            } else {
              return formattedTime
            }
          } else {
            if (n.startAt === 'Invalid Date' || n.startAt === null) {
              return '-'
            } else {
              return n.startAt
            }
          }
        }
      },
      {
        Header: 'End Date',
        accessor: (n) => {
          if (user.timezone) {
            const formattedTime = time.utc(n.endAt).tz(user.timezone).format('MM/DD/YYYY')
            if (formattedTime === 'Invalid Date' || formattedTime === null) {
              return '-'
            } else {
              return formattedTime
            }
          } else {
            if (n.endAt === 'Invalid Date' || n.endAt === null) {
              return '-'
            } else {
              return n.endAt
            }
          }
        }
      },
      {
        Header: 'Enabled',
        accessor: (n) => (n.isEnabled ? 'Yes' : 'No')
      },
      {
        Header: 'Author',
        accessor: 'user.fullName'
      },
      {
        id: 'actionBtnsCol',
        accessor: (n) => n,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='read' a='Notice'>
                <div
                  onClick={() => handlePreview(value)}
                  className='action-button'
                  id={`${value.id}-preview`}
                >
                  <i className='fas fa-eye'></i>
                </div>
                <Tooltip
                  anchorId={`${value.id}-preview`}
                  content='Preview'
                />
              </Can>
              <Can I='update' a='Notice'>
                <Link
                  className='action-button edit-button'
                  to={`${links.edit}/${value.id}`}
                  icon='fas fa-pencil-alt'
                  id={`${value.id}-edit`}
                />
                <Tooltip
                  anchorId={`${value.id}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='Notice'>
                <div
                  onClick={() => handleDelete(value.id)}
                  className='action-button delete-button'
                  id={`${value.id}-delete`}
                >
                  <Icon
                    className='fas fa-trash'
                  />
                </div>
                <Tooltip
                  anchorId={`${value.id}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (noticesQuery.loading || noticesQuery.error) {
    return null
  }

  const { notices } = noticesQuery.data

  if (!notices) {
    return null
  }

  return (
    <ListView>
      <Can I='create' a='Notice'>
        <Datatable
          columns={columns}
          data={notices}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Notice',
            to: '/settings/notices-and-release-notes/new-notice'
          }}
        />
      </Can>

      <Can not I='create' a='Notice'>
        <Datatable
          columns={columns}
          data={notices}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Notice',
            to: '/settings/notices-and-release-notes/new-notice'
          }}
        />
      </Can>

      <Modal
        show={showNotice}
        onClose={() => {
          setShowNotice(false)
        }}
      >
        <div className='header'>{notice.title}</div>
        <div className='content'>
          <div className='notice-header'>{notice.headText}</div>
          <div className='notice-body'>{notice.bodyText}</div>

          {notice.releaseNoteId && (
            <div className='notice-release-note'>
              <Link
                to={`/help-center/release-notes#${notice.releaseNoteId}`}
                onClick={() => {
                  setShowNotice(false)
                }}
              >
                <span>Click here to view the release note</span>
              </Link>
            </div>
          )}

          <Group className='notice'>
            <input value='yes' type='checkbox' onChange={() => {}} />
            <label>Dismiss until next notice</label>
          </Group>

          <Buttons className='notice'>
            <Button
              className='small white'
              text='OK'
              onClick={() => {
                setShowNotice(false)
              }}
            />
          </Buttons>
        </div>
      </Modal>
    </ListView>
  )
}

NoticeList.propTypes = {
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string,
  user: PropTypes.object
}

NoticeList.defaultProps = {
  links: {
    view: '',
    edit: ''
  },
  fields: {
    // client: true,
    // template: true,
    // templateField: true,
    // name: true,
    // isRequired: false,
    // action: true
  },
  onDelete: () => {}, // This is intentional
  filters: {},
  value: null
}

export default NoticeList
