import React from 'react'
import PropTypes from 'prop-types'

const LoaderA = () => {
  return (
    <div className='loader-a'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

const LoaderCircle = () => {
  return (
    <div className='loader-circle'>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

const LoaderCircleLines = ({ dark }) => {
  return (
  <div className={`loader-circle-lines ${dark ? 'dark' : ''}`}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  )
}
LoaderCircleLines.propTypes = {
  dark: PropTypes.bool
}

export default {
  LoaderA,
  LoaderCircle,
  LoaderCircleLines
}
