/**
 *
 * @param {string} password
 * @description global utility for validating password via regex
 * @returns boolean
 */
export const isValidPassword = (password) => {
  const passwordValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{12,}$/
  return passwordValidation.test(password)
}
