const validateNumber = (input) => {
  const result = {
    ok: false
  }

  const regex = /^-?\d*\.?\d*$/

  if (regex.test(input)) {
    result.ok = true
  }

  return result
}

export default validateNumber
