import client from '../client.js'

import fileUploadGql from '../queries/file-uploads.gql'
import createFileUploadGql from '../mutations/create-file-upload.gql'

const createFileUpload = (input) => {
  return client.mutate({
    mutation: createFileUploadGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createFileUpload) {
        try {
          const fileUploadsStore = store.readQuery({
            query: fileUploadGql
          })

          store.writeQuery({
            query: fileUploadGql,
            data: {
              fileUploads: [...fileUploadsStore.fileUploads, data.createFileUpload]
            }
          })
        } catch (error) {
          client.query({
            query: fileUploadGql
          })
        }
      }
    }
  })
}

export default createFileUpload
