import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Maintenance = ({ className }) => (
  <div className={classNames('server-message', className)}>
    <div className='top-bar'></div>
    <div className='message'>
      This page is under maintenance
    </div>
  </div>
)

Maintenance.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

Maintenance.defaultProps = {
  children: null
}

export default Maintenance
