import client from '../client.js'

import createClientCustomerGql from '../mutations/create-client-customer.gql'
import clientCustomersGql from '../queries/client-customers.gql'

const createClientCustomer = (input) => {
  return client.mutate({
    mutation: createClientCustomerGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createClientCustomer) {
        try {
          // read cache of customers
          const clientCustomersStore = store.readQuery({
            query: clientCustomersGql
          })

          // update cache
          store.writeQuery({
            query: clientCustomersGql,
            data: {
              clientCustomers: [...clientCustomersStore.clientCustomers, data.createClientCustomer]
            }
          })
        } catch (error) {
          client.query({
            query: clientCustomersGql
          })
        }
      }
    }
  })
}

export default createClientCustomer
