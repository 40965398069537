import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Page, Button } from '@lib'
import { Group, Submit } from '@shared/Form'

import bgImage from '@images/networkingBG.png'
import logoImage from '@images/xevant-logo-horizontal.png'
import { TosText, PrivacyPolicyText } from '../../Resources/Tos/tos.js'

import destroySession from '@graphql/mutators/destroy-session'
import updateUser from '@graphql/mutators/update-user'

const Tos = () => {
  const { state } = useLocation()
  const [agree, setAgree] = useState(false)
  const navigate = useNavigate()
  const [sections, setSections] = useState([])

  const handleSubmit = () => {
    updateUser({
      id: state.user.id,
      isTosAccepted: true
    }).then(({ data }) => {
      if (data.updateUser) {
        navigate('/', { replace: true })
      }
    })
  }

  const handleLogout = () => {
    destroySession().then(({ data, extensions }) => {
      if (data.destroySession) {
        localStorage.clear()
        navigate('/login', { replace: true })
      }
    })
  }

  const scrollToSection = (v) => {
    let sectionH
    if (v.target.localName === 'h5' || v.target.localName === 'span') {
      sectionH = Array.from(document.querySelectorAll('.doc-box h1')).filter(el => {
        return el.innerHTML === v.target.innerHTML
      })[0]
    } else {
      sectionH = Array.from(document.querySelectorAll('.doc-box h2')).filter(el => {
        return el.innerHTML === v.target.innerHTML
      })[0]
    }

    const topPos = sectionH.offsetTop
    const scrollDiv = document.querySelector('.doc-box')
    scrollDiv.scrollTop = topPos
  }

  const watchScroll = () => {
    document.querySelectorAll('.doc-box h2').forEach(h => {
      if (h.offsetTop - 20 < document.querySelector('.doc-box').scrollTop) {
        if (document.querySelector('.left-content a.active')) {
          document.querySelector('.left-content a.active').classList.remove('active')
        }
        const sLink = Array.from(document.querySelectorAll('.left-content a')).filter(el => {
          return el.innerHTML === h.innerHTML
        })[0]
        sLink.classList.add('active')
      }
    })
  }

  useEffect(() => {
    const sectionsH = document.querySelectorAll('.doc-box h2')
    if (sectionsH.length > 0) {
      const sectionsArr = []
      sectionsH.forEach(h => {
        sectionsArr.push(h.innerHTML)
      })
      setSections(sectionsArr)
    }
  }, [document.querySelectorAll('.doc-box h2').length])

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle tos-mid'>
          <div className='left-info'>
            <div className='login-logo-left'>
              <img src={logoImage} />
            </div>

            <div className='left-content'>
              <h5 onClick={(v) => scrollToSection(v)}>Terms of Service</h5>
              {sections.length > 0 &&
                sections.map((x, key) =>
                <div key={key}>
                  {x === 'Contents' &&
                    <h5 className='topMargin' onClick={(v) => scrollToSection(v)}>Privacy Policy</h5>
                  }
                  <a onClick={(v) => scrollToSection(v)}>{x}</a>
                </div>
                )
              }
            </div>
          </div>
          <div className='right-form'>
            <div id='right-form-header'>
              <span>Last Modified: 2023-01-25</span>
            </div>
            <div className='doc-box' onScroll={() => watchScroll()}>
              <h1>Terms of Service</h1>
              <TosText
                scrollToSection={scrollToSection}
              />
              <PrivacyPolicyText
                scrollToSection={scrollToSection}
              />
            </div>

            <Group>
              <div className='checkbox-agreement'>
                <div className='checkbox'>
                  <input
                    value='yes'
                    type='checkbox'
                    onChange={(v) => setAgree(v.target.checked)}
                    data-attribute-id='tos_agreement_checkbox_field'
                  />
                </div>
                <label>
                  I have read and agree to the terms of service and privacy policy of Xevant.
                </label>
              </div>
            </Group>

            <Submit className='center'>
            <Button
              type='submit'
              onClick={() => handleSubmit()}
              disabled={!agree}
              className='primary login-button'
              text='ACCEPT'
              data-attribute-id="tos_accept_btn"
            />
            <Button
              onClick={() => handleLogout()}
              className='secondary login-button'
              text='DECLINE'
              data-attribute-id='tos_decline_btn'
            />
            </Submit>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Tos
