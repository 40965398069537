import config from '../../config.js'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from '@apollo/client'
import { cookie } from '@utils'

const defaultLink = createUploadLink({ uri: `${config.API_URL}/graphql` })

const linkWithAuth = new ApolloLink((operation, forward) => {
  const headers = {}

  const csrfCookie = cookie.get('csrfCookie')
  if (csrfCookie) {
    headers['x-csrf-token'] = csrfCookie
  } else {
    return null
  }

  operation.setContext({
    fetchOptions: {
      credentials: 'include'
    },
    headers,
    includeExtensions: true
  })

  return forward(operation)
})

const link = linkWithAuth.concat(defaultLink)

export default link
