import client from '../client.js'

import releaseNoteGql from '../queries/release-note.gql'
import updateReleaseNoteGql from '../mutations/update-release-note.gql'

const updateReleaseNote = (input) => {
  return client.mutate({
    mutation: updateReleaseNoteGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateReleaseNote) {
        const variables = {
          id: input.id
        }

        try {
          // update releaseNote cache
          store.writeQuery({
            query: releaseNoteGql,
            variables,
            data: {
              releaseNote: data.updateReleaseNote
            }
          })
        } catch (error) {
          // re-query releaseNote
          client.query({
            query: releaseNoteGql,
            variables
          })
        }
      }
    }
  })
}

export default updateReleaseNote
