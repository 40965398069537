function getFaviconElement () {
  return document.querySelector('link[rel="icon"][href*="favicon"]')
}

const update = (isAlert) => {
  const favicon = getFaviconElement()

  if (isAlert) {
    favicon.href = '/favicon-alert.ico'
  } else {
    favicon.href = '/favicon.ico'
  }
}

export default {
  update
}
