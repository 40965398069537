import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import config from '@config'

import PropTypes from 'prop-types'

function Tinymce ({ onChange, value }) {
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = (content) => {
    setCurrentValue(content)
    onChange(content)
  }

  return (
    <Editor
      value={currentValue}
      onEditorChange={handleChange}
      apiKey={config.TINYMCE_API_KEY}
      init={{
        height: 500,
        width: '100%',
        menubar: false,
        branding: false,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')
          input.onchange = function () {
            var file = this.files[0]
            var reader = new FileReader()
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime()
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64)
              blobCache.add(blobInfo)
              cb(blobInfo.blobUri(), { title: file.name })
            }
            reader.readAsDataURL(file)
          }
          input.click()
        },
        plugins: 'mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough |  code link image table forecolor backcolor | align lineheight  | checklist numlist bullist indent outdent',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}'
      }}
    />
  )
}

Tinymce.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default Tinymce
