import React, { Fragment, useEffect } from 'react'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link, Icon } from '@lib'
import { Can } from '@caslConfig/can.js'
import PropTypes from 'prop-types'

const workbookIcon = {
  UsageAnalytics: 'fas fa-chart-line',
  DataDictionary: 'fas fa-magnifying-glass-chart'
}

const resourceItems = [
  { name: 'Trainings & Use Cases', icon: 'fas fa-book-reader', link: '/help-center/trainings-and-use-cases', permission: 'read', resource: 'Resource' },
  { name: 'Terms of Service', icon: 'fas fa-file-contract', link: '/help-center/terms-of-service', permission: 'read', resource: 'TermsOfService' },
  { name: 'Release Notes', icon: 'fas fa-sticky-note', link: '/help-center/release-notes', permission: 'read', resource: 'ReleaseNote' },
  { name: 'Manage Subscriptions', icon: 'fas fa-envelope', link: '/help-center/manage-subscriptions', permission: 'read', resource: 'Subscription' },
  { name: 'Manage Alerts', icon: 'fas fa-bell', link: '/help-center/manage-alerts', permission: 'read', resource: 'Alert' }
]

const Content = ({ user }) => {
  const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))

  const workbookResourceLink = () => {
    if (tabNavs) {
      const item = tabNavs[0]
      return (
        item?.workbooks.filter(x => (x.name === 'Usage Analytics' || x.name === 'Data Dictionary')).map((workbook, index) => {
          return (
            <div className='resource-item' key={index}>
              <Link to={`/dashboard/${workbook.contentUrl}/${workbook.defaultView.viewUrlName}`}>
                <Icon className={workbookIcon[workbook.contentUrl]} />
                <span>{workbook.name}</span>
              </Link>
            </div>
          )
        })
      )
    } else {
      return ''
    }
  }

  useEffect(() => {
    const container = document.querySelector('.resource-container')
    const items = container.querySelectorAll('.resource-item')
    if (items.length % 2) {
      const blankItem = document.createElement('div')
      blankItem.className = 'resource-item'
      blankItem.style.visibility = 'hidden'
      container.appendChild(blankItem)
    }
  }, [])

  return (
    <Fragment>
      <Title text='Help Center' user={user}/>

      <div className='resource-list'>
        <div className='resource-container'>
          {resourceItems.map((item, index) => {
            return (
              <div className='resource-item' key={index}>
                <Can I={item.permission} a={item.resource} key={index}>
                    <Link to={item.link}>
                      <Icon className={item.icon} />
                      <span>{item.name}</span>
                    </Link>
                </Can>
              </div>
            )
          })}
          {workbookResourceLink()}
        </div>
      </div>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const Trainings = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default Trainings
