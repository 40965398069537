import client from '../client.js'

import sendMfaEmailGql from '../mutations/send-mfa-email.gql'

const sendMfaEmail = (input) => {
  return client.mutate({
    mutation: sendMfaEmailGql,
    variables: input,
    update: (store, { data }) => {

    }
  })
}

export default sendMfaEmail
