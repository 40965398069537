import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Icon, Tooltip, Loaders } from '@lib'
import { Loader } from '@shared'

import createUserFavorite from '@graphql/mutators/create-user-favorite'
import destroyUserFavorite from '@graphql/mutators/destroy-user-favorite'
import userFavoritesGql from '@graphql/queries/user-favorites'

const Bookmark = () => {
  let [bookmarkArray] = useState([])
  let [bookmarkIconElement] = useState([<Icon key={0} icon='mdi:bookmark-plus' iconify={true} />])
  let userFavorites = null
  let showTooltip = true
  // get user favorites
  const userFavoritesQuery = useQuery(userFavoritesGql)
  const [loaderState, setLoaderState] = useState({
    bookmark: false
  })

  const loadUserFavorites = () => {
    if (userFavoritesQuery.loading) {
      return (
        <Loader />
      )
    }

    userFavorites = JSON.parse(userFavoritesQuery?.data?.userFavorites)

    if (userFavorites?.length > 0) {
      bookmarkArray = []

      userFavorites.forEach((bookmark, key) => {
        const bookmarkPath = '/dashboard/' + bookmark.view.contentUrl.replace('/sheets', '')
        let bookmarkName = bookmark.label

        if (bookmarkName === 'Home Scorecard' || bookmarkName === 'Scorecard') {
          bookmarkName = bookmark.view.contentUrl?.split('/')[0]?.replace('LogicHomeScorecard', 'LogicScorecard').replace('Scorecard', ' Scorecard') || bookmark.label
        } else {
          bookmarkName = bookmark.view.contentUrl?.split('/')[0]?.replace('HomeScorecard', '').replace('Scorecard', ' ') + ' ' + bookmark.view.name
        }

        bookmarkArray.push(
          <div key={key}>
            <li>
              <div className='bookmark-icon' onClick={() => handleDeleteBookmark(bookmark.view.id)} id={`${bookmark.view.id} remove-icon`}>
                <Icon icon='mdi:bookmark-minus' iconify={true} />
              </div>
              <a target='_blank' rel='noreferrer' href={bookmarkPath}>{bookmarkName}</a>
            </li>
            <Tooltip
              anchorId={`${bookmark.view.id} remove-icon`}
              content='Remove from Bookmarks'
              className='white'
            />
          </div>
        )

        if (window.location.pathname === bookmarkPath) {
          bookmarkIconElement = []
          bookmarkIconElement.push(<Icon key={0} icon='bi:bookmark-check-fill' iconify={true} />)
          showTooltip = false
          const bookmarkIcon = document.querySelector('.bookmark-icon')
          bookmarkIcon?.classList.add('bookmark-active')
        }
      })

      bookmarkArray.sort((a, b) => {
        const prevBookmark = a.props.children[0].props.children[1].props.children.toLowerCase()
        const currentBookmark = b.props.children[0].props.children[1].props.children.toLowerCase()

        return prevBookmark.localeCompare(currentBookmark)
      })
    }
  }

  if (window.location.pathname.startsWith('/dashboard')) {
    loadUserFavorites()

    useEffect(() => {
      const bookmarkIcon = document.querySelector('.bookmark-icon')
      bookmarkIcon.classList.remove('bookmark-active')

      loadUserFavorites()
    }, [window.location.pathname])

    if (document.getElementById('tableauViz')?.workbook?._workbookImpl?.name) {
      document.querySelector('.bookmark-icon')?.classList.remove('disabled')
    } else if (!document.querySelector('.bookmark-icon')?.classList.contains('disabled')) {
      document.querySelector('.bookmark-icon')?.classList.add('disabled')
    }
  } else {
    useEffect(() => {
      if (!window.location.pathname.startsWith('/dashboard')) {
        const bookmarkElement = document.getElementById('bookmark')
        if (bookmarkElement) {
          bookmarkElement.remove()
        }
      }
    }, [])
  }

  const handleBookmarkBoxClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const bookmarkPopup = document.querySelector('.bookmark-popup')
    bookmarkPopup.classList.toggle('expand')

    if (bookmarkPopup.classList.contains('expand')) {
      const watchForFocus = (ee) => {
        if (!ee.target.closest('.bookmark-popup')) {
          bookmarkPopup.classList.remove('expand')
          document.removeEventListener('click', watchForFocus)
        }
      }
      document.addEventListener('click', watchForFocus)
    }
  }

  const handleAddBookmark = async (e) => {
    e.preventDefault()
    const bookmarkIcon = document.querySelector('.bookmark-icon')

    if (!bookmarkIcon.classList.contains('bookmark-active') && !bookmarkIcon.classList.contains('disabled')) {
      setLoaderState(prevState => ({ ...prevState, bookmark: true }))

      const tabNavs = JSON.parse(localStorage.getItem('tabNavs'))
      const viz = document.getElementById('tableauViz')
      const workbookName = viz.workbook.name
      const activeViewName = viz.workbook.activeSheet.name
      // We only need this to be unique, but Tableau does not really appear to create favorites with label we define
      const bookmarkName = workbookName + ' ' + activeViewName

      let foundWorkbook = null
      let viewId

      const findWorkbook = async (navToSearch) => {
        for (const projectFolder of navToSearch) {
          for (const workbook of projectFolder.workbooks) {
            if (workbook.name === workbookName) {
              for (const view of workbook.views) {
                if (view.name === activeViewName) {
                  foundWorkbook = workbook
                  viewId = view.id
                }
              }
            }
          }
          if (projectFolder?.children && !foundWorkbook) {
            findWorkbook(projectFolder.children)
          }
        }
      }

      await findWorkbook(tabNavs.filter(t => t.name === 'Xevant'))

      createUserFavorite({ viewId, viewName: bookmarkName }).then(async ({ data }) => {
        if (data.createUserFavorite) {
          await userFavoritesQuery.refetch()
          loadUserFavorites()

          toast('Bookmark added', {
            icon: () => <Icon icon='bi:bookmark-check-fill' iconify={true} />,
            className: 'bookmark-toast add'
          })
          setLoaderState(prevState => ({ ...prevState, bookmark: false }))
        } else {
          Swal.fire({
            text: 'Error adding bookmark',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
          setLoaderState(prevState => ({ ...prevState, bookmark: false }))
        }
      })
    }
  }

  const handleDeleteBookmark = (viewId) => {
    setLoaderState(prevState => ({ ...prevState, bookmark: true }))

    destroyUserFavorite({ viewId }).then(async () => {
      await userFavoritesQuery.refetch()
      loadUserFavorites()

      bookmarkIconElement = []
      bookmarkIconElement.push(<Icon key={0} icon='mdi:bookmark-plus' iconify={true} />)
      const bookmarkIcon = document.querySelector('.bookmark-icon')
      bookmarkIcon.classList.remove('bookmark-active')

      toast('Bookmark removed', {
        icon: () => <Icon icon='bi:bookmark-dash-fill' iconify={true} />,
        className: 'bookmark-toast remove'
      })
      setLoaderState(prevState => ({ ...prevState, bookmark: false }))
    })
  }

  return (
    <div id='bookmark-container'>
      <div id='bookmark'>
        <div className='bookmark-icon disabled' onClick={handleAddBookmark} id='bookmark-icon-id'>
          {loaderState.bookmark
            ? <div className='bookmark-loader-wrap'><Loaders.LoaderCircleLines dark={true} /></div>
            : bookmarkIconElement
          }
        </div>
       {showTooltip
         ? <Tooltip
              anchorId='bookmark-icon-id'
              content={'Add to Bookmarks'}
              className='white'
           />
         : ''}

        <div className='bookmark-list' onClick={handleBookmarkBoxClick}>
          <span>Bookmarks</span>
          <Icon
            className='fas fa-caret-down'
          />
        </div>
        <ToastContainer
          autoClose={1500}
          closeButton={false}
          closeOnClick
          draggable={false}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnFocusLoss
          pauseOnHover
          position='top-center'
          rtl={false}
          theme='light'
        />
      </div>
      <div className='bookmark-popup'>
        <ul>
          {bookmarkArray.length > 0 ? bookmarkArray : <li>No bookmarks saved</li>}
        </ul>
      </div>
    </div>
  )
}

Bookmark.propTypes = {
  user: PropTypes.object
}

export default Bookmark
