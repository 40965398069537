import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon as Iconify } from '@iconify/react'

const Icon = ({ className, onClick, iconify, icon }) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <Fragment>
      { iconify
        ? (
          <div className='iconify-centered'>
            <Iconify icon={icon} className={className} />
          </div>
          )
        : (
          <i
            className={className}
            onClick={handleClick}
          />
          )
      }
    </Fragment>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconify: PropTypes.bool,
  tooltipBackground: PropTypes.string,
  icon: PropTypes.string
}

Icon.defaultProps = {
  onClick: () => {}, // This is intentional
  iconify: false,
  tooltipBackground: '#F3F3F9'
}

export default Icon
