import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { Datatable, Icon, Tooltip } from '@lib'
import { ListView } from '@shared/ListView'
import { time } from '@utils'

import userDashboardExecutionsGql from '@graphql/queries/user-dashboard-executions'

const UserDashboardExecutionList = ({ user, dashboardName, onClick }) => {
  const columns = useMemo(() =>
    [
      {
        Header: 'Report Level Name',
        accessor: u => {
          return u.filters.filter(f => f.clientDashboardFilter.filterName === 'Report Level Name')[0]?.filterValue
        }
      },
      {
        Header: 'Period 1',
        accessor: u => {
          const period1Start = u.filters.filter(f => f.clientDashboardFilter.filterName === 'Period 1 Start Date')[0]?.filterValue
          const period1End = u.filters.filter(f => f.clientDashboardFilter.filterName === 'Period 1 End Date')[0]?.filterValue

          return period1Start + ' - ' + period1End
        }
      },
      {
        Header: 'Period 2',
        accessor: u => {
          const period2Start = u.filters.filter(f => f.clientDashboardFilter.filterName === 'Period 2 Start Date')[0]?.filterValue
          const period2End = u.filters.filter(f => f.clientDashboardFilter.filterName === 'Period 2 End Date')[0]?.filterValue

          return period2Start + ' - ' + period2End
        }
      },
      {
        Header: 'Run Date',
        class: 'testing123',
        accessor: u => {
          if (user.timezone) {
            const formattedTime = time.utc(u.createdAt).tz(user.timezone).format('MM/DD/YYYY hh:mm A')
            if (formattedTime === 'Invalid Date' || formattedTime === null) {
              return '-'
            } else {
              return formattedTime
            }
          } else {
            if (u.createdAt === 'Invalid Date' || u.createdAt === null) {
              return '-'
            } else {
              return u.createdAt
            }
          }
        }
      },
      {
        id: 'actionBtnsCol',
        accessor: u => u,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              {user.id === value.userId && value.isProcessed && (
                <>
                  <div
                    onClick={() => onClick(value)}
                    className='action-button view-button'
                    id={`${value.id}-view`}
                  >
                    <Icon
                      className='fas fa-eye'
                    />
                  </div>
                  <Tooltip
                    anchorId={`${value.id}-view`}
                    content='View'
                  />
                </>
              )}
              {user.id === value.userId && !value.isProcessed && (
                <>
                  <div
                    onClick={() => {}}
                    className='action-button spin-button'
                    id={`${value.id}-spin`}
                  >
                    <Icon
                      className='fas fa-spinner fa-spin'
                    />
                  </div>
                  <Tooltip
                    anchorId={`${value.id}-spin`}
                    content='Processing'
                  />
                </>
              )}
            </div>
          )
        }
      }
    ], []
  )

  const userDashboardExecutionsQuery = useQuery(userDashboardExecutionsGql, {
    variables: {
      userId: user.id,
      dashboardName
    },
    pollInterval: 5000
  })

  if (userDashboardExecutionsQuery.loading) {
    return null
  }

  const { userDashboardExecutions } = userDashboardExecutionsQuery.data

  return (
    <ListView className='user-dashboard-execution-table'>
      <Datatable
        columns={columns}
        data={userDashboardExecutions}
        displayPagination={false}
        displayRecordCount={false}
        displaySearch={false}
      />
    </ListView>
  )
}

UserDashboardExecutionList.propTypes = {
  user: PropTypes.object,
  dashboardName: PropTypes.string,
  onClick: PropTypes.func
}

UserDashboardExecutionList.defaultProps = {
  user: {
    id: null
  },
  dashboardName: null,
  onClick: () => {}
}

export default UserDashboardExecutionList
