import client from '../client.js'
import templateFieldAllowedValueGql from '../queries/template-field-allowed-value.gql'
import updateTemplateFieldAllowedValueGql from '../mutations/update-template-field-allowed-value.gql'

const updateTemplateFieldAllowedValue = (input) => {
  return client.mutate({
    mutation: updateTemplateFieldAllowedValueGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateTemplateFieldAllowedValue) {
        const variables = {
          id: input.id
        }
        try {
          store.writeQuery({
            query: templateFieldAllowedValueGql,
            variables,
            data: {
              templateFieldAllowedValues: data.updateTemplateFieldAllowedValue
            }
          })
        } catch (error) {
          client.query({
            query: templateFieldAllowedValueGql,
            variables
          })
        }
      }
    }
  })
}

export default updateTemplateFieldAllowedValue
