import React, { Fragment, useState, useLayoutEffect } from 'react'
import updateUserNotice from '@graphql/mutators/update-user-notice'
import { cookie, faviconSwitcher } from '@utils'

import { Button, Link } from '@lib'
import Modal from '@lib/Modal'
import { Buttons } from '@shared/Action'
import { Group } from '@shared/Form'

const NoticePopUp = () => {
  const [showNotice, setShowNotice] = useState(false)
  const [checked, setChecked] = useState(false)
  const [notice, setNotice] = useState({
    userNoticeId: null,
    title: '',
    headText: '',
    bodyText: '',
    releaseNoteId: null
  })

  let userNotices = null

  const closeNotice = () => {
    if (cookie.get('sessionStatus') === 'true') {
      if (checked) {
        updateUserNotice({
          id: notice.userNoticeId,
          isNoticeDisplay: false
        })
      }

      faviconSwitcher.update(false)
      setShowNotice(false)

      userNotices = JSON.parse(localStorage.getItem('userNotices'))
      userNotices.shift()
      localStorage.setItem('userNotices', JSON.stringify(userNotices))
      setTimeout(() => {
        triggerNotice()
      }, 500)
    }
  }

  const triggerNotice = () => {
    if (cookie.get('sessionStatus') === 'true') {
      if (localStorage.getItem('userNotices') !== null && localStorage.getItem('userNotices') !== '[]') {
        userNotices = JSON.parse(localStorage.getItem('userNotices'))

        const { notice } = userNotices[0]

        const newNoticeInfo = {
          userNoticeId: userNotices[0].id,
          title: notice.name,
          headText: notice.headText,
          bodyText: notice.bodyText,
          releaseNoteId: notice.releaseNoteId
        }
        setNotice(newNoticeInfo)
        faviconSwitcher.update(true)
        setShowNotice(true)

        window.blur()
      }
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      triggerNotice()
    }, 1000)
  }, [])

  return (
    <Fragment>
      <Modal
        allowClickOutside={false}
        show={showNotice}
        onClose={closeNotice}
      >
        <div className='header'>
          {notice.title}
        </div>
        <div className='content'>
          <div className='notice-header'>{notice.headText}</div>
          <div className='notice-body'>{notice.bodyText}</div>

          {notice.releaseNoteId && (
            <div className='notice-release-note'>
              <Link
                to={`/help-center/release-notes#${notice.releaseNoteId}`}
                onClick={closeNotice}
              >
                <span>Click here to view the release note</span>
              </Link>
            </div>
          )}

          <Group className='notice'>
            <input
              value='yes'
              type='checkbox'
              onChange={() => {
                setChecked(!checked)
              }}
            />
            <label>Dismiss until next notice</label>
          </Group>

          <Buttons className='notice'>
            <Button
              className='small white'
              text='OK'
              onClick={closeNotice}
            />
          </Buttons>
        </div>
      </Modal>
    </Fragment>
  )
}

export default NoticePopUp
