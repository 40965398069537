import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { Datatable, Tooltip } from '@lib'
import { ListView } from '@shared/ListView'
import { AlertForm } from '@shared'
import { useQuery } from '@apollo/client'
import { time } from '@utils'

import alertsGql from '@graphql/queries/alerts'
import updateAlert from '@graphql/mutators/update-alert'
import destroyAlert from '@graphql/mutators/destroy-alert'

const AlertList = ({ user, memberOf, setRefetchFlag }) => {
  const [showModal, setShowModal] = useState(false)
  const [alert, setAlert] = useState(null)
  const [alertCheckboxes, setAlertCheckBoxes] = useState([])
  const [showMassPause, setShowMassPause] = useState(false)
  const [showMassResume, setShowMassResume] = useState(false)

  const alertsQuery = useQuery(alertsGql, {
    variables: {
      fetchPolicy: 'network-only',
      alertTab: window.location.hash === '' ? '#my-alerts' : window.location.hash,
      ...(memberOf ? { memberEmail: user.email } : { ownerId: user.id })
    }
  })

  const handleCheckbox = (v) => {
    const a = alertCheckboxes
    if (v.target.checked) {
      setAlertCheckBoxes(prevValues => [...prevValues, v.target.value])
    } else {
      const filteredCheckbox = a.filter(cb => cb !== v.target.value)
      setAlertCheckBoxes(prevValues => [...filteredCheckbox])
    }
  }

  const handleEdit = (value) => {
    setAlert(value)
    setShowModal(true)
  }

  const handleDelete = async (value) => {
    Swal.fire({
      text: 'Are you sure you want to delete this alert?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        destroyAlert({ ids: [value.id], adminDelete: window.location.hash !== '#set-alerts' }).then(({ data, extensions }) => {
          if (data.destroyAlert) {
            Swal.fire({
              text: 'Alert deleted',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            })
            alertsQuery.refetch()
            setRefetchFlag({ action: 'delete', id: value.id })
          }
        })
      }
    })
  }

  const handleMassCheckbox = async (e) => {
    if (e.target.checked) {
      const alertIds = []
      alerts.forEach(element => {
        alertIds.push(element.id)
      })
      setAlertCheckBoxes(alertIds)
    } else {
      setAlertCheckBoxes([])
    }
    const checkboxes = document.querySelectorAll('.data-table tbody input[type="checkbox"]')

    checkboxes.forEach(cb => { cb.checked = e.target.checked })
  }

  const handleMassDelete = async () => {
    Swal.fire({
      text: 'Are you sure you want to delete these alerts?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        destroyAlert({ ids: [...alertCheckboxes], adminDelete: window.location.hash !== '#set-alerts' }).then(({ data, extensions }) => {
          if (data.destroyAlert) {
            Swal.fire({
              text: 'Alerts deleted',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            })
            alertsQuery.refetch()
            setRefetchFlag({ action: 'delete', id: alertCheckboxes })
            setAlertCheckBoxes([])
            document.getElementById('mass-checkbox').checked = false
          }
        })
      }
    })
  }

  const handleMassPause = async (value) => {
    Swal.fire({
      text: 'Are you sure you want to pause these alerts?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Pause'
    }).then((result) => {
      if (result.isConfirmed) {
        updateAlert({ ids: alertCheckboxes, action: 'multiPause', isPaused: true }).then(({ data, extensions }) => {
          if (data.updateAlert) {
            Swal.fire({
              text: 'Alerts paused',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            })
            alertsQuery.refetch()
          }
        })
      }
    })
  }

  const handleMassResume = async (value) => {
    Swal.fire({
      text: 'Are you sure you want to resume these alerts?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Resume'
    }).then((result) => {
      if (result.isConfirmed) {
        updateAlert({ ids: alertCheckboxes, action: 'multiPause', isPaused: false }).then(({ data, extensions }) => {
          if (data.updateAlert) {
            Swal.fire({
              text: 'Alerts resumed',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            })
            alertsQuery.refetch()
          }
        })
      }
    })
  }

  const handlePause = async (value) => {
    Swal.fire({
      text: `Are you sure you want to ${value.isPaused ? 'resume' : 'pause'} this alert?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${value.isPaused ? 'Resume' : 'Pause'}`
    }).then((result) => {
      if (result.isConfirmed) {
        updateAlert({ ids: [value.id], isPaused: !value.isPaused, action: 'pause' }).then(({ data, extensions }) => {
          if (data.updateAlert) {
            Swal.fire({
              text: value.isPaused ? 'Alert resumed' : 'Alert paused',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            })
            alertsQuery.refetch()
          }
        })
      }
    })
  }

  const columns = useMemo(() => [
    {
      id: 'checkboxCol',
      disableSortBy: true,
      accessor: n => n,
      Header: '',
      Cell: function createCheckboxCol ({ value }) {
        return (
          <div className='checkbox-col'>
            <input
              onChange={(v) => handleCheckbox(v)}
              value={value.id}
              checked={alertCheckboxes.includes(value.id)}
              type='checkbox'
            />
          </div>
        )
      }
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Last Ran At',
      accessor: n => {
        if (user.timezone) {
          const formattedTime = time.utc(n.lastRanAt).tz(user.timezone).format('MM/DD/YYYY hh:mm A')
          if (formattedTime === 'Invalid Date' || formattedTime === null) {
            return '-'
          } else {
            return formattedTime
          }
        } else {
          if (n.lastRanAt === 'Invalid Date' || n.lastRanAt === null) {
            return '-'
          } else {
            return n.lastRanAt
          }
        }
      }
    },
    {
      Header: 'Actions',
      disableSortBy: true,
      accessor: n => n,
      Cell: function createActionButtons ({ value }) {
        return (
          <div className='action-buttons'>
            { window.location.hash !== '#set-alerts' &&
              <>
                <div onClick={() => handleEdit(value)} className='action-button edit-button' id={'edit' + value.id}>
                  <i className='fas fa-pencil-alt'></i>
                </div>
                <Tooltip
                  content='Edit'
                  anchorId={'edit' + value.id}
                />
              </>
            }
              <div onClick={() => handlePause(value)} className='action-button alert-pause-button' id={'pause-resume' + value.id}>
                <i className={'fas ' + ((value?.isPaused) ? 'fa-play' : 'fa-pause')}></i>
              </div>
              <Tooltip
                content={value.isPaused ? 'Resume' : 'Pause'}
                anchorId={'pause-resume' + value.id}
              />
              <div onClick={() => handleDelete(value)} className='action-button delete-button' id={'delete' + value.id}>
                <i className='fas fa-trash'></i>
              </div>
              <Tooltip
                content='Delete'
                anchorId={'delete' + value.id}
              />
          </div>
        )
      }
    }
  ], [alertCheckboxes])

  useEffect(() => {
    alertsQuery.refetch()
  }, [])

  useEffect(() => {
    const alertlist = {}
    alerts.forEach(alert => {
      alertlist[alert.id] = { ...alert }
    })

    const showResume = alertCheckboxes.some(acb => alertlist[acb].isPaused)
    const showPause = alertCheckboxes.some(acb => !alertlist[acb].isPaused)

    setShowMassPause(showPause)
    setShowMassResume(showResume)
  }, [alertCheckboxes])

  if (alertsQuery.loading || alertsQuery.error) {
    return null
  }

  const { alerts } = alertsQuery.data
  if (!alerts) {
    return null
  }

  return (
    <div className='alert-list-wrap'>
      <ListView>
        <div className='alert-mass-edit-wrap'>
          <div className='checkbox-container'>
            <input type='checkbox' onClick={(e) => handleMassCheckbox(e)} id='mass-checkbox'/>
          </div>
          { alertCheckboxes.length
            ? <>
                {
                  showMassPause && <>
                  <div onClick={() => { handleMassPause() }} className='mass-action action-button' id='pause-all'><i className={'fas fa-pause'}></i></div>
                  <Tooltip content='Pause' anchorId='pause-all'/>
                </>
                }
                {
                  showMassResume && <>
                  <div onClick={() => { handleMassResume() } } className='mass-action action-button' id='play-all'><i className={'fas fa-play'}></i></div>
                  <Tooltip content='Resume' anchorId='play-all' />
                  </>
                }
                <div onClick={() => { handleMassDelete() }} className='mass-action action-button delete-button' id='delete-all'><i className='fas fa-trash'></i></div>
                <Tooltip content='Delete' anchorId='delete-all'/>
              </>
            : ''}
        </div>
        <Datatable
          columns={columns}
          data={alerts}
        />
      </ListView>
      {showModal && <AlertForm showModal={showModal} closeModal={() => setShowModal(false)} alert={alert} user={user} setRefetchFlag={setRefetchFlag}/>}
    </div>
  )
}

AlertList.propTypes = {
  user: PropTypes.object,
  memberOf: PropTypes.bool,
  alertTab: PropTypes.string,
  setRefetchFlag: PropTypes.func
}

export default AlertList
