import { isBrowser, isMobile } from 'react-device-detect'

let currentDevice = null

if (isBrowser && !isMobile) {
  currentDevice = 'browser'
} else if (!isBrowser && isMobile) {
  currentDevice = 'mobile'
}

export default currentDevice
