import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title, Button } from '@lib'
import { Form, Group, Input, Submit } from '@shared/Form'
import client from '@graphql/client'
import Swal from 'sweetalert2'

import settingsGql from '@graphql/queries/system-settings'
import updateSettings from '@graphql/mutators/update-system-settings'

const Content = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const [input, setInput] = useState({})

  useEffect(() => {
    if (loading) {
      client.query({
        query: settingsGql,
        variables: {
          fetchPolicy: 'network-only'
        }
      }).then(({ data }) => {
        if (data.systemSettings) {
          const sessionExp = data.systemSettings.find(e => e.settingName === 'SESSION_TIME_EXPIRATION')
          const sessionWarn = data.systemSettings.find(e => e.settingName === 'SESSION_TIME_EXPIRATION_WARNING')
          const mailHost = data.systemSettings.find(e => e.settingName === 'MAIL_HOST')
          const mailPort = data.systemSettings.find(e => e.settingName === 'MAIL_PORT')
          const mailUser = data.systemSettings.find(e => e.settingName === 'MAIL_USER')
          const mailPass = data.systemSettings.find(e => e.settingName === 'MAIL_PASS')
          const passExp = data.systemSettings.find(e => e.settingName === 'PASSWORD_EXPIRATION')
          const mfaSkipExp = data.systemSettings.find(e => e.settingName === 'MFA_SKIP_EXPIRATION')
          const loginAttempts = data.systemSettings.find(e => e.settingName === 'LOGIN_ATTEMPTS')
          const passLinkExp = data.systemSettings.find(e => e.settingName === 'PASSWORD_LINK_EXPIRATION')
          const actLinkExp = data.systemSettings.find(e => e.settingName === 'ACTIVATION_LINK_EXPIRATION')
          const tabServer = data.systemSettings.find(e => e.settingName === 'TABLEAU_SERVER')
          const tabTokenName = data.systemSettings.find(e => e.settingName === 'TABLEAU_TOKEN_NAME')
          const tabTokenSecret = data.systemSettings.find(e => e.settingName === 'TABLEAU_TOKEN_SECRET')
          const menuOrder = data.systemSettings.find(e => e.settingName === 'MENU_ORDER')
          const mfaEmailCodeExpiration = data.systemSettings.find(e => e.settingName === 'MFA_EMAIL_CODE_EXPIRATION')
          const mfaEmailResendDuration = data.systemSettings.find(e => e.settingName === 'MFA_EMAIL_RESEND_CODE_DURATION')
          const dataPlatformApiUrl = data.systemSettings.find(e => e.settingName === 'API_URL')

          setInput({
            sessionExp,
            sessionWarn,
            mailHost,
            mailPort,
            mailUser,
            mailPass,
            passExp,
            mfaSkipExp,
            loginAttempts,
            passLinkExp,
            actLinkExp,
            tabServer,
            tabTokenName,
            tabTokenSecret,
            menuOrder,
            mfaEmailCodeExpiration,
            mfaEmailResendDuration,
            dataPlatformApiUrl
          })

          setLoading(false)
        } else {
          // console.log('ERRRR')
        }
      })
    }
  }, [loading])
  if (loading) {
    return null
  }

  const handleShowPassword = (v) => {
    const passInput = document.querySelector('.input.' + v + ' input')
    if (passInput.type === 'password') {
      document.querySelector('.' + v + ' i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.' + v + ' i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  const handleInputChange = (name, v) => {
    setInput(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        settingValue: v,
        updated: (name === 'passExp' || v !== '')
      }
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    let updatedSettings = {}
    for (const key in input) {
      if (input[key]?.updated) {
        updatedSettings[input[key].id] = input[key].settingValue
      }
    }

    updatedSettings = JSON.stringify(updatedSettings)
    updateSettings({
      updatedSettings
    }).then(({ data, extensions }) => {
      if (data.updateSystemSettings) {
        if (updatedSettings.includes(input.menuOrder.id)) {
          localStorage.removeItem('system-menu-order-storage')
        }
        Swal.fire({
          text: 'Updated!',
          icon: 'success',
          showConfirmButton: false,
          timer: '3000'
        })
        setLoading(true)
      } else {
        console.log(extensions)
        console.log(extensions.errors)
      }
    })
  }

  return (
    <Fragment>
      <Title text='System Config' user={user}/>

      <Form className='settings-form' onSubmit={handleSave}>
        <div className='form-split-wrap'>
          <div className='form-left'>
            <div className='border-wrap'>
              <h2>Session Expiration Policy</h2>
              <Group>
                <Input
                  label='Session Length'
                  type='number'
                  value={input.sessionExp.settingValue}
                  onChange={(v) => handleInputChange('sessionExp', v)}
                />
                <span className='input-after'>Seconds</span>

                <Input
                  label='Warning Duration'
                  type='number'
                  value={input.sessionWarn.settingValue}
                  onChange={(v) => handleInputChange('sessionWarn', v)}
                />
                <span className='input-after'>Seconds</span>
              </Group>
            </div>
            <div className='border-wrap'>
              <h2>Password Expiration Policy</h2>
              <Group>
                <div>
                 <Input
                    className='input-wrap-margin'
                    label='Password Validity'
                    type='number'
                    value={input.passExp.settingValue}
                    onChange={(v) => handleInputChange('passExp', v)}
                 />
                  <Input
                    label='Login Attempts'
                    type='number'
                    value={input.loginAttempts.settingValue}
                    onChange={(v) => handleInputChange('loginAttempts', v)}
                  />
                </div>
                <span id="input-span-padding" className='input-after'>Days</span>
                <div>
                <Input
                  className='input-wrap-margin'
                  label='2FA Skip Time'
                  type='number'
                  value={input.mfaSkipExp.settingValue}
                  onChange={(v) => handleInputChange('mfaSkipExp', v)}
                />
                </div>
                <span id="input-span-padding" className='input-after input-span-padding'>Days</span>
                </Group>
            </div>
            <div className='border-wrap'>
              <h2>Email Links Policy</h2>
              <Group>
                <Input
                  label='Password Link Expiration'
                  type='number'
                  value={input.passLinkExp.settingValue}
                  onChange={(v) => handleInputChange('passLinkExp', v)}
                />
                <span className='input-after'>Hours</span>

                <Input
                  label='Activation Link Expiration'
                  type='number'
                  value={input.actLinkExp.settingValue}
                  onChange={(v) => handleInputChange('actLinkExp', v)}
                />
                <span className='input-after'>Hours</span>
              </Group>
            </div>
            <div className='border-wrap'>
              <h2>Navigations</h2>
              <Group className='full-width-input'>
                <Input
                  label='Menu Order (comma separated)'
                  type='textarea'
                  value={input.menuOrder.settingValue}
                  onChange={(v) => handleInputChange('menuOrder', v)}
                />
              </Group>
            </div>
          </div>

          <div className='form-right'>
            <div className='border-wrap'>
              <h2>SMTP Settings</h2>
              <Group>
                <Input
                  label='Host'
                  type='text'
                  value={input.mailHost.settingValue}
                  onChange={(v) => handleInputChange('mailHost', v)}
                />
                <Input
                  label='Port'
                  type='text'
                  value={input.mailPort.settingValue}
                  onChange={(v) => handleInputChange('mailPort', v)}
                />
              </Group>
              <Group>
                <Input
                  label='User'
                  type='text'
                  value={input.mailUser.settingValue}
                  onChange={(v) => handleInputChange('mailUser', v)}
                />
                <div className='form-input'>
                  <div className='input-label'>Password</div>
                  <div className='input password mailPass'>
                    <input
                      type='password'
                      autoComplete='off'
                      value={input.mailPass.settingValue}
                      onChange={(v) => handleInputChange('mailPass', v.target.value)}
                    />
                    <i onClick={() => handleShowPassword('mailPass')} className='fas fa-eye' aria-hidden='true'></i>
                  </div>
                </div>
              </Group>
            </div>
            <div className='border-wrap'>
              <h2>Tableau Server</h2>
              <Group>
                <div >
                  <Input
                  className='input-wrap-margin'
                    label='Tableau Server'
                    type='text'
                    value={input.tabServer.settingValue}
                    onChange={(v) => handleInputChange('tabServer', v)}
                  />
                </div>
              </Group>
            </div>

            <div className='border-wrap'>
              <h2>Multi-Factor Authentication</h2>
              <Group>
                <Input
                  label='MFA Email Code Expiration'
                  type='number'
                  value={input.mfaEmailCodeExpiration.settingValue}
                  onChange={(v) => handleInputChange('mfaEmailCodeExpiration', v)}
                />
                <Input
                  label='MFA Email Resend Duration'
                  type='number'
                  value={input.mfaEmailResendDuration.settingValue}
                  onChange={(v) => handleInputChange('mfaEmailResendDuration', v)}
                />
              </Group>
            </div>

            <div className='border-wrap'>
              <h2>Data Platform API</h2>
              <Group>
                <Input
                  label='Data Platform API'
                  type='text'
                  value={input.dataPlatformApiUrl.settingValue}
                  onChange={(v) => handleInputChange('dataPlatformApiUrl', v)}
                />
              </Group>
            </div>
          </div>
        </div>

        <Submit className='right'>
          <Button
            className='right-submit-button primary'
            type='submit'
            text='Save'
          />
        </Submit>
      </Form>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const SystemConfig = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default SystemConfig
