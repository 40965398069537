import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import { array } from '@utils'

import 'flatpickr/dist/flatpickr.css'
const DateTimePicker = Flatpickr.default

const Datepicker = ({ value, placeholder, settings, disabled, onChange, clear }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const ref = useRef(null)
  const handleChange = (value) => {
    let newValue = null

    if (array.isArray(value) && value.length === 1) {
      newValue = value[0]
    }

    setCurrentValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    if (clear) {
      ref.current.flatpickr.clear()
    }
  }, [clear])

  return (
    <DateTimePicker
      value={currentValue}
      options={settings}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
      ref={ref}
    />
  )
}

Datepicker.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  settings: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  clear: PropTypes.bool
}

Datepicker.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  clear: false,
  settings: {},
  onChange: () => {} // This is intentional
}

export default Datepicker
