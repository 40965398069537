import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon } from '@lib'
import { Icon as Iconify } from '@iconify/react'

const CustomLink = ({ to, icon, iconify, iconAlign, text, className, children, onClick, ...rest }) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <Link
      className={className}
      to={to}
      onClick={handleClick}
      {...rest}
    >
      {icon && iconAlign === 'left' && (
        iconify === true
          ? <Iconify
            icon={icon}
            />
          : <Icon
            className={icon}
            />
      )}
      <span>
        {children || text }
      </span>
      {icon && iconAlign === 'right' && (
        iconify === true
          ? <Iconify
            icon={icon}
            />
          : <Icon
            className={icon}
            />
      )}
    </Link>
  )
}

CustomLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  icon: PropTypes.string,
  iconify: PropTypes.bool,
  iconAlign: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
}

CustomLink.defaultProps = {
  iconify: false,
  iconAlign: 'left',
  onClick: () => {} // This is intentional
}

export default CustomLink
