import client from '../client.js'
import authGql from '@graphql/queries/auth'
import createSessionGql from '../mutations/create-session.gql'

const createSession = (input) => {
  return client.mutate({
    mutation: createSessionGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createSession) {
        const { user } = data.createSession

        store.writeQuery({
          query: authGql,
          data: {
            auth: user
          }
        })
      }
    }
  })
}

export default createSession
