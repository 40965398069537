import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import MasterReferenceForm from '@shared/MasterReferenceForm'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const handleSave = () => {
    navigate('/settings/master-references')

    Swal.fire({
      text: 'Master reference added!',
      icon: 'success',
      showConfirmButton: false,
      timer: 3000
    })
  }

  return (
    <Fragment>

      <Title text='New Master Reference' user={user}/>

      <MasterReferenceForm
        onSave={handleSave}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const NewMasterReference = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewMasterReference
