import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from '@lib'
import { Form, Input, Group, FormError } from '@shared/Form'
import client from '@graphql/client'

import clientCustomersGql from '@graphql/queries/client-customers'
import fileUploadsGql from '@graphql/queries/file-uploads'

const RlpUi = ({ setState, setTitleState, applyFilters }) => {
  const { workbook, view } = useParams()
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({})
  const [loadingRlpUi, setLoadingRlpUi] = useState(true)
  const [clientCustomers, setClientCustomers] = useState([])
  const [fileUploads, setFileUploads] = useState([])
  const claimsType = workbook.includes('Medical') ? 'Medical' : 'Pharmacy'
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  useEffect(() => {
    if (loadingRlpUi) {
      document.getElementById('tableau-viz-actions').style.visibility = 'visible'
      setTitleState(claimsType + ' Rebates')

      client.query({
        query: fileUploadsGql,
        fetchPolicy: 'network-only',
        variables: { status: 'Done' }
      }).then(({ data }) => {
        const tempFileUploads = data.fileUploads
        setFileUploads(tempFileUploads)

        client.query({
          query: clientCustomersGql,
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          const tempCust = data.clientCustomers.filter(cc => tempFileUploads.find(tfu => tfu.clientCustomerId === cc.id && tfu.template.name.includes(claimsType)))
          setClientCustomers(tempCust)
          setLoadingRlpUi(false)
        })
      })
    }
  }, [loadingRlpUi])

  const handleInputChange = (name, value) => {
    const newInput = { ...input }
    newInput[name] = value
    if (name === 'clientName') {
      delete newInput.file
    }
    setInput(newInput)
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    setLoaderState(prevState => ({ ...prevState, submit: true }))

    setInputErrors({})
    const newInputObj = {
      'File Name': fileUploads.find(fu => fu.id === input.file)?.fileName
    }
    applyFilters(newInputObj)
    setState(true)
    setTimeout(() => {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
    }, 3000)
  }

  if (loadingRlpUi) {
    return null
  }

  return (
    <Fragment>
      <div className='rlpui-wrap'>
        <Form className='rlpui-form' onSubmit={handleSave}>
          <FormError errors={inputErrors} />
          <div className='form-block'>
            <div className='form-block-header'>
              <p>Select a client from the list, then choose a file.</p>
            </div>
            <div className='form-block-body'>
              <Group>
                <Input
                  label='Client Name'
                  type='select'
                  placeholder='Select a client'
                  options={clientCustomers?.map((clientCustomer) => {
                    return {
                      text: clientCustomer.name,
                      value: clientCustomer.id
                    }
                  })}
                  value={input.clientName}
                  onChange={(v) => handleInputChange('clientName', v)}
                  errors={inputErrors.clientName}
                />

                <Input
                  label='File List'
                  type='select'
                  placeholder='Select a file'
                  options={fileUploads.filter(fu => fu.clientCustomerId === input.clientName && fu.template.name.includes(claimsType))?.map((fileUpload) => {
                    return {
                      text: fileUpload.fileName,
                      value: fileUpload.id
                    }
                  })}
                  value={input.file}
                  onChange={(v) => handleInputChange('file', v)}
                  errors={inputErrors.file}
                  disabled={!input.clientName}
                />

                <div className='not-flex'>
                  <Button
                    className='primary'
                    type='submit'
                    text='Apply'
                    disabled={!input.clientName || !input.file || loaderState.submit}
                    loading={loaderState.submit}
                  />
                </div>
              </Group>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  )
}
RlpUi.propTypes = {
  setState: PropTypes.func,
  setTitleState: PropTypes.func,
  applyFilters: PropTypes.func
}

export default RlpUi
