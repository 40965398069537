import client from '../client.js'
import destroyUserGroupGql from '../mutations/destroy-user-group.gql'

const destroyUserGroup = (input) => {
  return client.mutate({
    mutation: destroyUserGroupGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyUserGroup
