import client from '../client.js'

import triggerPasswordResetGql from '../mutations/trigger-password-reset.gql'

const triggerPasswordReset = (input) => {
  return client.mutate({
    mutation: triggerPasswordResetGql,
    variables: input,
    update: (store, { data }) => {
      // console.log('Logger::sendForgotPasswordMutator', data)
    }
  })
}

export default triggerPasswordReset
