import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Loaders } from '@lib'
import { object, array, time } from '@utils'
import { Form, Input, Submit, FormError } from '@shared/Form'
import Swal from 'sweetalert2'
import client from '@graphql/client'
import trendLogicConfigGql from '@graphql/queries/trendlogic-config'
import userDashboardExecutionsGql from '@graphql/queries/user-dashboard-executions'
import clientDashboardFiltersGql from '@graphql/queries/client-dashboard-filters'
import createUserDashboardExecution from '@graphql/mutators/create-user-dashboard-execution'

const DashboardExecutionFilters = ({ user, dashboardName }) => {
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [inputErrors, setInputErrors] = useState({})
  const [clientDashboardFilters, setClientDashboardFilters] = useState([])
  const [input, setInput] = useState({})
  const [formUpdated, setFormUpdated] = useState()
  const [minMaxDates, setMinMaxDates] = useState({
    minDate: new Date('2023-08-03T00:00:00'),
    maxDate: new Date('2023-09-11T00:00:00')
  })

  const [filterValues, setFilterValues] = useState({})

  useEffect(() => {
    if (loading) {
      // get Client_Dashboard ID to pass to filters?
      client.query({
        query: clientDashboardFiltersGql,
        fetchPolicy: 'network-only',
        variables: {
          clientDashboardName: dashboardName
        }
      }).then(({ data }) => {
        const clientDashboardFilters = data.clientDashboardFilters
        setClientDashboardFilters(clientDashboardFilters)

        client.query({
          query: trendLogicConfigGql
        }).then(({ data }) => {
          const tlConfig = JSON.parse(data.trendLogicConfig)
          setMinMaxDates({
            minDate: new Date(tlConfig.configData?.min_date + 'T00:00:00'),
            maxDate: new Date(tlConfig.configData?.max_date + 'T00:00:00')
          })
          const newReportLevelValues = tlConfig.reportLevels.map(rl => ({ text: rl.value, value: rl.value }))

          let reportLevelNames = []
          newReportLevelValues.forEach(nv => {
            reportLevelNames = reportLevelNames.concat(tlConfig.configData?.[nv.text.toLowerCase() + '_name_list'].map(nl => ({ text: nl, value: nl, belongsTo: nv.text })))
          })

          const newFilterValuesObj = {}
          clientDashboardFilters.forEach(cdf => {
            if (cdf.filterType === 'Dropdown') {
              const filterValObj = {
                filterName: cdf.filterName,
                id: cdf.id,
                parentId: cdf.parentId
              }
              if (cdf.filterName === 'Report Level') {
                filterValObj.values = newReportLevelValues
              } else if (cdf.filterName === 'Report Level Name') {
                filterValObj.values = reportLevelNames
              }
              newFilterValuesObj[cdf.id] = filterValObj
            }
          })

          setFilterValues(prevState => ({
            ...prevState,
            ...newFilterValuesObj
          }))
          setLoading(false)
        })
      })
    }
  }, [loading])

  const handleClearForm = () => {
    setInputErrors({})
    setInput({})
    setFormUpdated(new Date())
  }

  const handleInputChange = (name, v) => {
    const updateObj = { [name]: v }
    const cdfForChange = clientDashboardFilters.find(cdf => cdf.id === name)
    if (cdfForChange.filterType === 'Date') {
      if (v >= input[clientDashboardFilters.find(cdf => cdf.parentId === cdfForChange.id)?.id]) {
        updateObj[clientDashboardFilters.find(cdf => cdf.parentId === cdfForChange.id).id] = undefined
      }
    }

    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    const feErrors = {}

    clientDashboardFilters.forEach(cdf => {
      if (!input[cdf.id]) {
        feErrors[cdf.filterName] = [{
          message: `${cdf.filterName} field is required`
        }]
      }
    })

    if (!object.keys(feErrors).length) {
      const proceedWithCreate = () => {
        createUserDashboardExecution(inputToSubmit).then(({ data, extensions }) => {
          if (data.createUserDashboardExecution) {
            setInputErrors({})
            Swal.fire({
              icon: 'success',
              text: 'Successfully created',
              timer: 3000,
              showConfirmButton: false
            })
            setInput({})
            setFormUpdated(new Date())
            setLoaderState(prevState => ({ ...prevState, submit: false }))
          } else {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }

      clientDashboardFilters.forEach(cdf => {
        if (cdf.filterType === 'Date') {
          input[cdf.id] = time(input[cdf.id]).format('MM/DD/YYYY')
        }
      })

      const inputToSubmit = {
        clientDashboardName: dashboardName,
        filters: JSON.stringify(input)
      }
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      client.query({
        query: userDashboardExecutionsGql,
        fetchPolicy: 'network-only',
        variables: {
          userId: user.id,
          dashboardName
        }
      }).then(({ data }) => {
        if (data.userDashboardExecutions?.length > 4) {
          Swal.fire({
            text: 'By proceeding, you are deleting the oldest filtered set record.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Proceed'
          }).then((results) => {
            if (results.isConfirmed) {
              proceedWithCreate()
            } else {
              setLoaderState(prevState => ({ ...prevState, submit: false }))
            }
          })
        } else {
          proceedWithCreate()
        }
      })
    } else {
      setInputErrors(feErrors)
    }
  }

  if (loading) {
    return (
      <div className='dashboard-execution-filters-wrap loading'>
        <Loaders.LoaderA />
      </div>
    )
  }

  return (
    <Fragment>
      <div className='dashboard-execution-filters-wrap'>
        <Form className='dashboard-execution-form' onSubmit={handleSave} key={formUpdated}>
          <FormError errors={inputErrors} />
          {clientDashboardFilters.map((x, key) => (
            x.filterType === 'Dropdown'
              ? <Input
                  key={key}
                  label={x.filterName}
                  type='select'
                  options={x.parentId ? filterValues[x.id].values.filter(s => s.belongsTo === input[x.parentId]) : filterValues[x.id].values}
                  value={input[x.id]}
                  onChange={(v) => handleInputChange(x.id, v)}
                  disabled={x.parentId && !input[x.parentId]}
                />
              : <div key={key} className='date-field-wrap'>
                  <Input
                    label={x.filterName}
                    type='datepicker'
                    className='icon-right'
                    icon='fas fa-calendar'
                    value={input[x.id]}
                    onChange={(v) => handleInputChange(x.id, v)}
                    settings={{ minDate: (x.parentId && input[x.parentId] >= minMaxDates.minDate ? new Date(input[x.parentId].fp_incr(1)) : minMaxDates.minDate), maxDate: minMaxDates.maxDate }}
                  />
                </div>
          ))}

          <Submit>
            <Button
              className='primary'
              type='submit'
              text='Apply'
              loading={loaderState.submit}
            />
            <Button
              className='secondary'
              text='Clear'
              onClick={() => handleClearForm()}
            />
          </Submit>
        </Form>
      </div>
    </Fragment>
  )
}
DashboardExecutionFilters.propTypes = {
  user: PropTypes.object,
  dashboardName: PropTypes.string
}

export default DashboardExecutionFilters
