import client from '../client.js'
import createFileRunRequestGql from '../mutations/create-file-run-request.gql'

const createFileRunRequest = (input) => {
  return client.mutate({
    mutation: createFileRunRequestGql,
    variables: input
  })
}

export default createFileRunRequest
