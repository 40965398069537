import client from '../client.js'

import noticeGql from '../queries/notice.gql'
import updateNoticeGql from '../mutations/update-notice.gql'

const updateNotice = (input) => {
  return client.mutate({
    mutation: updateNoticeGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateNotice) {
        const variables = {
          id: input.id
        }

        try {
          // update notice cache
          store.writeQuery({
            query: noticeGql,
            variables,
            data: {
              notice: data.updateNotice
            }
          })
        } catch (error) {
          // re-query notice
          client.query({
            query: noticeGql,
            variables
          })
        }
      }
    }
  })
}

export default updateNotice
