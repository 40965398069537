import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import logoImage from '@images/logo-platform.png'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'
import { DynamicNavigation } from '@shared'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useNavigate } from 'react-router-dom'
import { axiosGet } from '@utils'
import { Can } from '@caslConfig/can.js'

const clientLogoResizedWidth = ['DisclosedRx', 'RxPreferred', 'FosterandFoster']

const MenuItems = ({ data, navigate }) => {
  return (
    <div className='menu-list-container'>
      <ul>
        {data.map((item, index) => {
          const handleClick = (e) => {
            if (item.isButton) {
              item.onClick(e)
            } else {
              navigate(item.link)
            }
          }

          if (item.name === 'Account') {
            const userBoxPopup = document.querySelector('.user-box-popup')
            if (userBoxPopup?.classList.contains('expand')) {
              item.isActive = true
            }
          } else {
            if (window.location.pathname.includes(item.name.trim().replace(/ /g, '-').toLowerCase())) {
              item.isActive = true
            }
          }

          const listItem = (
            <li key={item.id || index}>
              <div className='menu-item-container' onClick={handleClick}>
                <div className={`parent-menu-item ${item.isActive ? 'active' : ''}`}>
                  <div className='icon-container'>
                    <i className={item.icon} />
                  </div>
                  <div className='menu-value'>{item.name}</div>
                  <div>{item.element && item.element}</div>
                </div>
              </div>
            </li>
          )

          if (item.name === 'Settings') {
            return (
              <Can I='read' a='Setting' key={item.id || `setting-${index}`}>
                {listItem}
              </Can>
            )
          } else {
            return listItem
          }
        })}
      </ul>
    </div>
  )
}

const ResizedImage = ({ src, maxHeight, maxWidth }) => {
  const [visible, setVisible] = useState(false)
  const imgRef = useRef(null)

  useEffect(() => {
    const img = imgRef.current

    if (img) {
      const handleLoad = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight
        let newWidth = img.naturalWidth
        let newHeight = img.naturalHeight

        if (newHeight > maxHeight) {
          newHeight = maxHeight
          newWidth = newHeight * aspectRatio
        }

        if (newWidth > maxWidth) {
          newWidth = maxWidth
          newHeight = newWidth / aspectRatio
        }

        if (newHeight > 44) {
          document.querySelector('.img-container').classList.add('centered')
        }

        img.style.width = `${newWidth}px`
        img.style.height = `${newHeight}px`
        setVisible(true) // Un-hide the image after resizing
      }

      img.addEventListener('load', handleLoad)

      return () => {
        img.removeEventListener('load', handleLoad)
      }
    }
  }, [src, maxHeight, maxWidth])

  return (
    <img
      ref={imgRef}
      src={src}
      alt="Resized"
      style={{ display: visible ? 'block' : 'none' }}
    />
  )
}

const handleUserBoxClick = (e) => {
  e.preventDefault()
  e.stopPropagation()
  const userBoxPopup = document.querySelector('.user-box-popup')
  userBoxPopup.classList.toggle('expand')

  if (userBoxPopup.classList.contains('expand')) {
    const watchForFocus = (ee) => {
      if (!ee.target.closest('.user-box-popup')) {
        userBoxPopup.classList.remove('expand')
        document.removeEventListener('click', watchForFocus)
      }
    }
    document.addEventListener('click', watchForFocus)
  }
}

const SideMenu = ({ user }) => {
  const navigate = useNavigate()
  const menuContainerRef = useRef(null)
  const menuLogoRef = useRef(null)
  const footerItems = [
    { name: 'Account', icon: 'far fa-user-circle', onClick: handleUserBoxClick, isButton: true },
    { name: 'Help Center', icon: 'far fa-question-circle', link: '/help-center' },
    { name: 'Settings', icon: 'far fa-cog', link: '/settings' }
  ]

  const [minSearch, setMinSearch] = useState(0)
  const [showLink, setShowLink] = useState(true)
  const [logoUrl, setLogoUrl] = useState()

  const getSearchSelection = () => {
    if (localStorage.tabNavs) {
      const tabNavs = JSON.parse(localStorage.tabNavs)
      const workbookList = []
      if (tabNavs?.length > 0) {
        const navChildren = (item) => {
          item.children.map((child) =>
            navChildren(child)
          )
          item.workbooks.map((workbook) =>
            navWorkbooks(workbook)
          )
        }
        const navWorkbooks = (item) => {
          if (item.views) {
            item.views.map((view) =>
              navViews(item.project.name, item.name, view)
            )
          }
        }
        const navViews = (project, workbook, view) => {
          workbookList.push({
            contentUrl: view.contentUrl.replace('/sheets', ''),
            name: view.name,
            project,
            workbook
          })
        }
        tabNavs.map((nav) =>
          navChildren(nav)
        )
      }
      return workbookList
    }
    return []
  }

  const searchRef = React.createRef()

  const resetList = () => {
    searchRef.current.clear()
    searchRef.current.toggleMenu()
    setMinSearch(0)
    setShowLink(false)
  }

  const handleLogo = async (user) => {
    const logo = user.userClients.find(uc => uc.clientId === user.clientId && uc.userGroupForLogoId)?.userGroup?.logoPath || user.client?.logoPath
    if (user.userClients.find(uc => uc.clientId === user.clientId && uc.userGroupForLogoId)?.userGroup?.logoPath) {
      const response = await axiosGet(`s3/group-logo/${logo}`)
      setLogoUrl(response.data.url)
    } else if (logo) {
      const response = await axiosGet(`s3/client-logo/${logo}`)
      setLogoUrl(response.data.url)
    }
  }

  useEffect(() => {
    handleLogo(user)
  }, [])

  useEffect(() => {
    const footer = menuLogoRef.current
    const sidemenu = menuContainerRef.current

    if (sidemenu.scrollHeight !== Math.ceil(sidemenu.getBoundingClientRect().height)) {
      footer.classList.add('shadow')
    }

    const checkSidemenuHeight = () => {
      if (sidemenu.scrollHeight > Math.ceil(sidemenu.getBoundingClientRect().height)) {
        footer.classList.remove('shadow')
      } else {
        footer.classList.add('shadow')
      }
    }

    const handleScroll = () => {
      if (sidemenu.scrollTop === 0 && sidemenu.scrollHeight === Math.ceil(sidemenu.getBoundingClientRect().height)) {
        footer.classList.remove('shadow')
      } else {
        footer.classList.add('shadow')
      }
    }

    sidemenu.addEventListener('scroll', handleScroll)
    sidemenu.addEventListener('mousedown', checkSidemenuHeight)

    return () => {
      sidemenu.removeEventListener('scroll', handleScroll)
      sidemenu.removeEventListener('mousedown', checkSidemenuHeight)
    }
  }, [])

  return (
    <div className='menu-container'>
      <div className='sidemenu-container' ref={menuContainerRef}>
        <div className='img-container'>
          <ResizedImage src={logoUrl} maxHeight={110} maxWidth={ clientLogoResizedWidth.includes(user.client.name) ? 220 : 120}/>
        </div>
        <div className='search-container'>
          <Typeahead
            className='menu-search'
            id='global-menu-search'
            labelKey='name'
            minLength={minSearch}
            renderMenu={(results, menuProps) => {
              results = results.filter(res => res.name.length > 0)
              return (
              <Menu {...menuProps}>
                {results.map((option, position) => {
                  return (
                    <MenuItem option={option} position={position} key={position}>
                      <div>{option.name}</div>
                      <div className='tag'>{option.project}</div>
                    </MenuItem>
                  )
                }
                )}
                {showLink && (results.length >= 0) && <MenuItem className='link-all'>
                  <div onClick={resetList} className='show-list'>Or show full list of searchable items</div>
                </MenuItem>}
              </Menu>
              )
            }}
            onChange={(selected) => {
              if (selected.length > 0) {
                searchRef.current.clear()
                searchRef.current.toggleMenu()
                window.open(`/dashboard/${selected[0]?.contentUrl}`, '_blank')
              }
            }}
            onInputChange={() => {
              setShowLink(true)
            }}
            onBlur={() => {
              setMinSearch(1)
            }}
            options={getSearchSelection()}
            placeholder='Search'
            ref={searchRef}
          >
            <i className='fal fa-search' />
          </Typeahead>
        </div>
        <DynamicNavigation user={user} />
        <div className='menu-footer'>
          <MenuItems data={footerItems} navigate={navigate} />
        </div>
      </div>
      <div className='menu-xevant-logo' ref={menuLogoRef}>
        <img src={logoImage} />
      </div>
    </div>
  )
}

SideMenu.propTypes = {
  user: PropTypes.object
}

MenuItems.propTypes = {
  data: PropTypes.array,
  navigate: PropTypes.func
}

ResizedImage.propTypes = {
  src: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number
}

export default SideMenu
