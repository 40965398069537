import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Icon, Tooltip } from '@lib'
import { Link, ListView } from '@shared'
import Swal from 'sweetalert2'

import clientsGql from '@graphql/queries/clients'
import destroyClient from '@graphql/mutators/destroy-client'
import { Can } from '@caslConfig/can.js'

const Content = ({ user }) => {
  const clientsQuery = useQuery(clientsGql, {
    fetchPolicy: 'network-only',
    variables: {}
  })

  const handleDelete = (v) => {
    Swal.fire({
      text: 'Are you sure you want to delete this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        document.querySelector('.modal-backdrop').style.display = 'block'
        document.querySelector('.modal-backdrop > .modal').style.display = 'none'

        destroyClient({ id: v }).then((res) => {
          document.querySelector('.modal-backdrop').style.display = 'none'
          document.querySelector('.modal-backdrop > .modal').style.display = 'inherit'
          clientsQuery.refetch()
          if (res.extension?.errors) {
            Swal.fire({
              text: 'Error deleting client',
              icon: 'warning',
              showConfirmButton: false,
              timer: 3000
            })
          } else {
            Swal.fire({
              text: 'Client deleted',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
          }
        }).catch(err => {
          if (err) {
            Swal.fire({
              text: 'Error deleting client',
              icon: 'warning',
              showConfirmButton: false,
              timer: 3000
            })
            document.querySelector('.modal-backdrop').style.display = 'none'
            document.querySelector('.modal-backdrop > .modal').style.display = 'inherit'
            clientsQuery.refetch()
          }
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'name'
        // Filter: SelectColumnFilter,
        // filter: 'includes'
      },
      {
        Header: 'Url',
        accessor: 'clientUrl'
      },
      {
        Header: 'Status',
        accessor: 'clientStatus'
      },
      {
        id: 'actionBtnsCol',
        accessor: 'id',
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='Client'>
                <Link
                  className='action-button edit-button'
                  to={`/settings/clients/edit/${value}`}
                  icon='fas fa-pencil-alt'
                  id={`${value}-edit`}
                />
                <Tooltip
                  anchorId={`${value}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='read' a='UserGroup'>
                <Link
                  className='action-button edit-button'
                  to={`/settings/clients/${value}/user-groups`}
                  icon='fas fa-user-friends'
                  id={`${value}-groups`}
                />
                <Tooltip
                  anchorId={`${value}-groups`}
                  content='User Groups'
                />
              </Can>
              <Can I='delete' a='Client'>
                <div onClick={() => handleDelete(value)} className='action-button delete-button' id={`${value}-delete`}>
                  <Icon className='fas fa-trash'/>
                </div>
                <Tooltip
                  anchorId={`${value}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (clientsQuery.loading || clientsQuery.error) {
    return null
  }
  const { clients } = clientsQuery.data
  // console.log(clients)

  return (
    <Fragment>
      <Title text='Clients' user={user}/>

      <ListView>
        <Can I='create' a='Client'>
          <Datatable
            columns={columns}
            data={clients}
            newRowBtn={{
              icon: 'fas fa-plus',
              text: 'New Client',
              to: '/settings/clients/new'
            }}
          />
        </Can>
        <Can not I='create' a='Client'>
          <Datatable
            columns={columns}
            data={clients}
          />
        </Can>
      </ListView>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const Clients = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default Clients
