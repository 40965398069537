import client from '../client.js'

import destroyClientCustomerGql from '../mutations/destroy-client-customer.gql'
import clientCustomersGql from '../queries/client-customers.gql'

const destroyClientCustomer = (input) => {
  return client.mutate({
    mutation: destroyClientCustomerGql,
    variables: input,
    update: (store, { data }) => {
      if (data.destroyClientCustomer) {
        try {
          // get destroyed customers id
          const { id } = data.destroyClientCustomer

          // get cache value
          const clientCustomersCache = store.readQuery({
            query: clientCustomersGql
          })

          // update cache using id above ^
          store.writeQuery({
            query: clientCustomersGql,
            data: {
              clientCustomers: clientCustomersCache.clientCustomers.filter((c) => c.id !== id)
            }
          })
        } catch (error) {
          client.query({
            query: clientCustomersGql
          })
        }
      }
    }
  })
}

export default destroyClientCustomer
