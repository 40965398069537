import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Datatable, Button, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import { Buttons } from '@shared/Action'
import Modal from '@lib/Modal'
import { Can } from '@caslConfig/can.js'

import templateFieldMappingGql from '@graphql/queries/template-field-mapping'
import destroyTemplateFieldMapping from '@graphql/mutators/destroy-template-field-mapping'

const universalClientName = 'All'

const TemplateFieldMappingList = ({ links, fields, itemClass, onDelete, filters }) => {
  const [showFieldMapping, setshowFieldMapping] = useState(false)
  const [fieldMapping, setFieldMapping] = useState({
    client: '',
    template: '',
    templateField: '',
    alternateColumnName: ''
  })

  const [formOptions, setFormOptions] = useState({
    templateFieldMapping: []
  })

  // Query TemplateFieldMapping

  const templateFieldMappingQuery = useQuery(templateFieldMappingGql, {
    fetchPolicy: 'network-only',
    onCompleted: ({ templateFieldMappings }) => {
      const options = templateFieldMappings.map(({ id, alternateColumnName, client, templateField }) => (
        {
          id,
          template: templateField.template.name,
          client: client ? client.name : universalClientName,
          templateField: templateField.fieldName,
          alternateColumnName
        }
      ))
      setFormOptions((prevState) => ({
        ...prevState,
        templateFieldMapping: options
      })
      )
    }
  })

  const handleDelete = (fieldMappingID) => {
    Swal.fire({
      text: 'Are you sure you want to delete this field mapping?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        // Function destroyTemplateFieldMapping
        destroyTemplateFieldMapping(fieldMappingID).then(() => {
          onDelete(fieldMappingID)
          Swal.fire({
            text: 'Field mapping deleted!',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          templateFieldMappingQuery.refetch()
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'client'
      },
      {
        Header: 'Template',
        accessor: 'template'
      },
      {
        Header: 'Template Field',
        accessor: 'templateField'
      },
      {
        Header: 'Alternate Column Name',
        accessor: 'alternateColumnName'
      },
      {
        id: 'actionBtnsCol',
        accessor: (n) => n,
        disableSortBy: true,
        Header: '',
        Cell: function createActionButtons ({ value }) {
          if (value.client !== universalClientName) {
            return (
              <div className='action-buttons'>
                <Can I='update' a='TemplateSetting'>
                  <Link
                    className='action-button edit-button'
                    to={`${links.edit}/${value.id}`}
                    icon='fas fa-pencil-alt'
                    id={`${value.id}-edit`}
                  />
                  <Tooltip
                    anchorId={`${value.id}-edit`}
                    content='Edit'
                  />
                </Can>
                <Can I='delete' a='TemplateSetting'>
                  <div
                    onClick={() => handleDelete(value.id)}
                    className='action-button delete-button'
                    id={`${value.id}-delete`}
                  >
                    <Icon
                      className='fas fa-trash'
                    />
                  </div>
                  <Tooltip
                    anchorId={`${value.id}-delete`}
                    content='Delete'
                  />
                </Can>
              </div>
            )
          } else {
            return null
          }
        }
      }
    ],
    []
  )

  return (
    <ListView>
      <Can I='create' a='TemplateSetting'>
        <Datatable
          columns={columns}
          data={formOptions.templateFieldMapping} // TemplateFieldMapping Data
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Template Field Mapping',
            to: '/settings/template-settings/new-template-field-mapping'
          }}
        />
      </Can>
      <Can not I='create' a='TemplateSetting'>
        <Datatable
          columns={columns}
          data={formOptions.templateFieldMapping} // TemplateFieldMapping Data
        />
      </Can>

      <Modal
        show={showFieldMapping}
        onClose={() => {
          setshowFieldMapping(false)
        }}
      >
        <div className='header'>{fieldMapping.client}</div>
        <div className='content'>
          <div className='field-mapping-template'>{fieldMapping.template}</div>
          <div className='field-mapping-template-field'>{fieldMapping.templateField}</div>
          <div className='field-mapping-resolve'>{fieldMapping.alternateColumnName}</div>

          <Buttons className='field-mapping'>
            <Button
              className='small white'
              text='OK'
              onClick={() => {
                setshowFieldMapping(false)
              }}
            />
          </Buttons>
        </div>
      </Modal>
    </ListView>
  )
}

TemplateFieldMappingList.propTypes = {
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string
}

TemplateFieldMappingList.defaultProps = {
  links: {
    view: '',
    edit: ''
  },
  fields: {},
  onDelete: () => {},
  filters: {},
  value: null
}

export default TemplateFieldMappingList
