import client from '../client.js'

import createTemplateFieldMappingGql from '../mutations/create-template-field-mapping.gql'

const createTemplateFieldMapping = (input) => {
  return client.mutate({
    mutation: createTemplateFieldMappingGql,
    variables: input
  })
}

export default createTemplateFieldMapping
