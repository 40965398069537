import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Icon as Iconify } from '@iconify/react'

import Dashboard from '@wrappers/Dashboard'
import { Title, Button, Loaders } from '@lib'
import { Action, Buttons } from '@shared/Action'
import { Group, Input } from '@shared/Form'

import fileUploadResolversGql from '@graphql/queries/file-upload-resolvers'
import updateFileUploadResolver from '@graphql/mutators/update-file-upload-resolvers'

const AvailableColumnList = ({ children }) => {
  return (
    <Droppable droppableId='availableColumns'>
      {(provided) => (
        <div className='column-list' ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const AvailableColumnItem = ({ index, columnName, value, onRemove }) => {
  return (
    <Draggable draggableId={columnName} index={index}>
      {(provided, snapshot) => (
        <div className='column-item' ref={provided.innerRef} {...provided.droppableProps} {...provided.dragHandleProps}>
          <div className={classNames('column-drop-area', { 'is-resolved': value !== undefined && value !== '' }, { 'is-dragging': snapshot.draggingOver })}>
            {value && (
              <div className='column-drop-area--column-name'>
                <span>{value}</span>
                <i className='fal fa-lg fa-times remove-item' onClick={() => onRemove(columnName, value)}></i>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  )
}

const InvalidColumnList = ({ children, header }) => {
  return (
    <Droppable droppableId='invalidColumns'>
      {(provided) => (
        <div className='invalid-columns' ref={provided.innerRef} {...provided.droppableProps}>
          <div className='column-header'>{header}</div>
          <div className='column-list'>
            {children}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  )
}

const InvalidColumnItem = ({ index, columnName, value }) => {
  return (
    <Draggable draggableId={columnName} index={index}>
      {(provided) => (
        <div className='column-item' ref={provided.innerRef} {...(value === null ? provided.droppableProps : provided.draggableProps)} {...provided.dragHandleProps}>
          <div className={classNames('column-drop-area', { 'is-resolved': value === null || value === '' })}>
            { value === null
              ? (
                <div className={classNames('column-drag-item', 'column-drop-area--column-name')}></div>
                )
              : (
                <div className='column-drop-area--column-name'>
                  <Iconify icon='icon-park-outline:drag' />
                  <span>{columnName}</span>
                </div>
                )
            }
          </div>
        </div>
      )}
    </Draggable>
  )
}

const ColumnResolver = ({ user }) => {
  const navigate = useNavigate()
  const { id, directory } = useParams()
  const [state, setState] = useState({
    checkHeaders: {
      loading: true
    },
    errors: {},
    uploadedFile: {},
    availableColumns: [],
    invalidColumns: [],
    invalidColumnCount: 0,
    resolvable: false
  })
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  const activeClientUser = user.userClients.filter(x => x.clientId === user.clientId)[0]

  if (!activeClientUser?.isDatalogicUser) {
    navigate(-1, { replace: true })
  }

  useQuery(fileUploadResolversGql, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: (data) => {
      if (loading) {
        const fileUploadResolvers = JSON.parse(data?.fileUploadResolvers)
        const { id, clientId, clientCustomerId, userId, templateId, fileName, filePath, columnCount } = fileUploadResolvers.fileUpload
        const uploadedFile = {
          id,
          clientId,
          clientCustomerId,
          userId,
          templateId,
          name: fileName,
          filePath,
          columnCount
        }
        fileUploadResolvers.resolvers.forEach(r => {
          if (r.alternateColumnName && r.alternateColumnName !== '') {
            const invalidColumnValue = fileUploadResolvers.invalidColumns.find(ic => ic.name.toLowerCase() === r.alternateColumnName.toLowerCase())
            if (invalidColumnValue) {
              invalidColumnValue.value = null
              r.alternateColumnName = invalidColumnValue.name
            } else {
              r.alternateColumnName = ''
            }
          }
        })

        const isValid = isValidAvailableColumns(fileUploadResolvers.resolvers)
        setState((prevState) => ({
          ...prevState,
          availableColumns: fileUploadResolvers.resolvers,
          invalidColumns: fileUploadResolvers.invalidColumns,
          invalidColumnCount: fileUploadResolvers.resolvers?.length,
          uploadedFile,
          resolvable: isValid
        }))
        setLoading(false)
      }
    }
  })

  const isValidAvailableColumns = (availableColumns) => {
    if (availableColumns.find(ac => !ac.alternateColumnName || ac.alternateColumnName === '')) {
      return false
    } else {
      return true
    }
  }

  const handleRemoveResolverColumn = (columnName, alternateColumnName) => {
    const availableColumns = state.availableColumns
    const invalidColumns = state.invalidColumns
    availableColumns.find(ac => ac.templateField.fieldName === columnName).alternateColumnName = ''
    delete invalidColumns.find(ic => ic.name.toLowerCase() === alternateColumnName.toLowerCase()).value

    const isValid = isValidAvailableColumns(availableColumns)

    setState((prevState) => ({
      ...prevState,
      availableColumns,
      invalidColumns,
      resolvable: isValid
    })
    )
  }

  const onDragStart = (result) => {
    const elmToDrag = document.querySelector('div.column-item[data-rbd-draggable-id="' + result.draggableId + '"]')
    const parent = elmToDrag.parentNode
    const indexOfElm = Array.prototype.indexOf.call(parent.children, elmToDrag)
    for (let i = indexOfElm + 1; i < parent.children.length; i++) {
      parent.children[i].classList.add('no-transform')
    }
  }

  const onDragEnd = (result) => {
    document.querySelectorAll('.invalid-columns > .column-list > .column-item').forEach(c => {
      c.classList.remove('no-transform')
    })

    const { destination, source, draggableId } = result

    if (!destination || destination.droppableId === source.droppableId) {
      return
    }
    if (state.availableColumns.find(ac => ac.alternateColumnName?.toLowerCase() === draggableId.toLowerCase())) {
      return
    }

    const availableColumnIndex = destination.index
    const invalidColumnIndex = source.index
    const newAvailableColumns = state.availableColumns
    const newInvalidColumns = state.invalidColumns

    if (newAvailableColumns[availableColumnIndex].alternateColumnName !== '') {
      delete newInvalidColumns.find(nic => nic.name?.toLowerCase() === newAvailableColumns[availableColumnIndex].alternateColumnName?.toLowerCase())?.value
    }

    newAvailableColumns[availableColumnIndex].alternateColumnName = draggableId
    newInvalidColumns[invalidColumnIndex] = {
      name: draggableId,
      value: null
    }

    const isValid = isValidAvailableColumns(newAvailableColumns)

    setState((prevState) => ({
      ...prevState,
      availableColumns: newAvailableColumns,
      invalidColumns: newInvalidColumns,
      resolvable: isValid
    })
    )
  }

  const handleResolve = async () => {
    const isValid = isValidAvailableColumns(state.availableColumns)

    if (!isValid) {
      Swal.fire({
        text: 'Please resolve all columns',
        icon: 'warning',
        confirmButtonText: 'Okay'
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      let columnResolver = state.availableColumns.map(({ id, fileUploadId, templateFieldId, alternateColumnName }) => ({ id, fileUploadId, templateFieldId, alternateColumnName }))
      columnResolver = JSON.stringify(columnResolver)
      await updateFileUploadResolver({ updatedResolvers: columnResolver }).then(({ data, extensions }) => {
        if (data.updateFileUploadResolvers) {
          Swal.fire({
            text: 'Columns resolved',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          navigate(`/datalogic/file-uploads/${directory}`)
        } else if (extensions && extensions.errors) {
          const sqlError = extensions.errors.filter(e => e.path === 'sqlError')
          extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')
          Swal.fire({
            text: sqlError,
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
        }
        setLoaderState(prevState => ({ ...prevState, submit: false }))
      })
    }
  }

  return (
    <div className='resolver-page'>
      <div className='file-details'>

        <Group>
          <Input
            className='file-name dark-blue-bg'
            state={false}
            label='File Name'
            type='text'
            value={state.uploadedFile?.name}
            disabled={true}
          />
        </Group>

        <Group>
          <Input
            className='dark-blue-bg bold'
            state={false}
            label='Columns'
            type='text'
            value={state.invalidColumnCount}
            disabled={true}
          />
        </Group>

        <Group>
          <Input
            className='red-bg bold'
            state={false}
            label='Invalid Columns'
            type='text'
            value={state.invalidColumns.length}
            disabled={true}
          />
        </Group>
      </div>

      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className='column-resolver'>

          <div className='available-columns'>
            <div className='column-header'>Missing Required Columns</div>
            {loading && <Loaders.LoaderA />}
            <div className='column-body'>
              <div className='column-list list-label'>
                {state.availableColumns.map(column => (
                  <div key={column.id} className='column-item'>
                    <div className='column-name'>
                      {column.templateField.fieldName}
                    </div>
                  </div>
                ))}
              </div>

              <AvailableColumnList>
                {state.availableColumns.map((column, index) => (
                  <AvailableColumnItem index={index} key={column.templateField.fieldName} columnName={column.templateField.fieldName} value={column.alternateColumnName} onRemove={handleRemoveResolverColumn} />
                ))}
              </AvailableColumnList>
            </div>
          </div>

          <InvalidColumnList header='Invalid Columns'>
            {loading && <Loaders.LoaderA />}
            {state.invalidColumns.map((column, index) => (
              <InvalidColumnItem index={index} key={column.name} columnName={column.name} value={column.value} />
            ))}
          </InvalidColumnList>
        </div>

        <Action>
          <Buttons>
            <Button
              className='circle resolve-button'
              to={`/datalogic/file-uploads/${directory}`}
              text='Resolve'
              disabled={!state.resolvable}
              onClick={handleResolve}
              loading={loaderState.submit}
            />
          </Buttons>
        </Action>
      </DragDropContext>
    </div>
  )
}

const Content = ({ user }) => {
  return (
    <Fragment>
      <Title text='File Upload Resolver' user={user}/>
      <div className='file-upload-header'>
        <p className='file-upload-header-text'>Drag and drop the invalid columns to their corresponding columns to resolve file conflict.</p>
      </div>

      <ColumnResolver user={user} />
    </Fragment>
  )
}

const FileUploadResolver = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

AvailableColumnList.propTypes = {
  children: PropTypes.node
}

InvalidColumnList.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string
}

AvailableColumnItem.propTypes = {
  index: PropTypes.number,
  columnName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onRemove: PropTypes.func
}

InvalidColumnItem.propTypes = {
  index: PropTypes.number,
  columnName: PropTypes.string.isRequired,
  value: PropTypes.string,
  setColumn: PropTypes.func
}

ColumnResolver.propTypes = {
  user: PropTypes.object
}

Content.propTypes = {
  user: PropTypes.object
}

export default FileUploadResolver
