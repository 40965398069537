import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { useLocation } from 'react-router-dom'
import { Title, Link } from '@lib'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { SubscriptionList } from '@shared'

const Content = ({ user }) => {
  const tabs = {
    '#my-subscriptions': 0,
    '#subscriptions-for-others': 1,
    '#set-subscriptions': 2
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const { hash } = useLocation()
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)

  return (
    <Fragment>
      <Title text='Manage Subscriptions' user={user}/>

      <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className='tab-navs'>
          <Tab>
            <Link
              to='#my-subscriptions'
              onClick={() => { setTabIndex(identifyActiveTab('#my-subscriptions')) }}
            >
              My Subscriptions
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#subscriptions-for-others'
              onClick={() => { setTabIndex(identifyActiveTab('#subscriptions-for-others')) }}
            >
              Subscriptions Set For Others
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#set-subscriptions'
              onClick={() => { setTabIndex(identifyActiveTab('#set-subscriptions')) }}
            >
              Subscriptions Set For Me
            </Link>
          </Tab>
        </TabList>

        <TabPanel>
          <SubscriptionList user={user} />
        </TabPanel>
        <TabPanel>
          <SubscriptionList user={user} />
        </TabPanel>
        <TabPanel>
          <SubscriptionList user={user} memberOf={true} />
        </TabPanel>
      </Tabs>

    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const ManageSubscriptions = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default ManageSubscriptions
