const validateEmail = (email) => {
  const result = {
    ok: false
  }

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (regex.test(String(email).toLowerCase())) {
    result.ok = true

    const blacklistedDomains = [
      'yahoo.com',
      'gmail.com',
      'mail.com',
      'hotmail.com'
    ]

    const domain = email.substring(email.lastIndexOf('@') + 1)

    if (blacklistedDomains.includes(domain)) {
      result.ok = false
      result.message = 'Email addresses from Gmail, Hotmail, Mail and Yahoo domains are not allowed.'
    }
  } else {
    result.message = 'Invalid email supplied'
  }

  return result
}

export default validateEmail
