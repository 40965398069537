import client from '../client.js'
import updateFileUploadResolversGql from '../mutations/update-file-upload-resolvers.gql'

const updateFileUploadResolvers = (input) => {
  return client.mutate({
    mutation: updateFileUploadResolversGql,
    variables: input
  })
}

export default updateFileUploadResolvers
