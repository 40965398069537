import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Flatpickr from 'react-flatpickr'

import TextInput from './text.js'
import NumberInput from './number.js'
import PasswordInput from './password.js'
import TextareaInput from './textarea.js'
import MultiSelectInput from './multiselect.js'
import FileInput from './file.js'
import DatepickerInput from './datepicker.js'
import ToggleInput from './toggle.js'
import YesOrNoInput from './yes-or-no.js'
import DragAndDropInput from './draganddrop.js'
import StyledSelectInput from './styledSelect.js'

import { Icon } from '@lib'
import { string, array, object } from '@utils'

// eslint-disable-next-line no-import-assign
Flatpickr = Flatpickr.default

const Form = ({ className, children, onSubmit }) => {
  return (
    <form
      className={classNames('form', className)}
      onSubmit={onSubmit}
      noValidate
    >
      {children}
    </form>
  )
}

const Group = ({ className, children }) => {
  return (
    <div className={classNames('form-group', className)}>
      {children}
    </div>
  )
}

const Input = ({
  state,
  label,
  icon,
  iconRight,
  type,
  placeholder,
  searchPlaceholder,
  autoComplete,
  value,
  disabled,
  options,
  settings,
  onChange,
  errors,
  className,
  accept,
  logoPath,
  logoDirectory,
  filePlaceHolder,
  loading,
  filterCollapse,
  clear,
  hasSelectAll,
  selectedAll,
  multipleValueText,
  required,
  isScorecard,
  isHomepageActive,
  dataAttrId,
  id
}) => {
  let formInput = null

  if (!value) {
    value = ''
  }

  if (type === 'text') {
    formInput = (
      <TextInput
        state={state}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        settings={settings}
        dataAttrId={dataAttrId}
      />
    )
  } else if (type === 'password') {
    formInput = (
      <PasswordInput
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={onChange}
      />
    )
  } else if (type === 'number') {
    formInput = (
      <NumberInput
        id={id}
        state={state}
        value={value}
        placeholder={placeholder}
        settings={settings}
        disabled={disabled}
        onChange={onChange}
      />
    )
  } else if (type === 'select') {
    formInput = (
      <StyledSelectInput
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        loading={loading}
        disabled={disabled}
      />
    )
  } else if (type === 'multiselect') {
    formInput = (
      <MultiSelectInput
        value={value || []}
        options={options}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        onChange={onChange}
        loading={loading}
        disabled={disabled}
        filterCollapse={filterCollapse}
        hasSelectAll={hasSelectAll}
        selectedAll={selectedAll}
        multipleValueText={multipleValueText}
      />
    )
  } else if (type === 'textarea') {
    formInput = (
      <TextareaInput
        state={state}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
      />
    )
  } else if (type === 'file') {
    formInput = (
      <FileInput
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        accept={accept}
      />
    )
  } else if (type === 'toggle') {
    formInput = (
      <ToggleInput
        value={value}
        onChange={onChange}
        disabled={disabled}
        isScorecard={isScorecard}
        isHomepageActive={isHomepageActive}
      />
    )
  } else if (type === 'yes-no') {
    formInput = (
      <YesOrNoInput
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    )
  } else if (type === 'datepicker') {
    formInput = (
      <DatepickerInput
        value={value}
        placeholder={placeholder}
        settings={settings}
        disabled={disabled}
        onChange={onChange}
        clear={clear}
      />
    )
  } else if (type === 'datetime') {
    formInput = (
      // <div className={classNames('input', 'datetime', className)}>
        <Flatpickr
          data-enable-time
          options={settings}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      // </div>
    )
  } else if (type === 'draganddrop') {
    formInput = (
      <DragAndDropInput
        accept={accept}
        value={value}
        logoDirectory={logoDirectory}
        logoPath={logoPath}
        filePlaceHolder={filePlaceHolder}
        onChange={onChange}
        className={className}
      />
    )
  }

  return (
    <div className='form-input'>
      {label && (
        <div className={`input-label${required ? ' required' : ''}`}>
          {label}
        </div>
      )}

      <div data-attribute-id='form_error_container' className={classNames('input', type, className, { 'has-error': !!errors.length, disabled })}>
        {icon && !iconRight && (
          <Icon
            className={icon}
          />
        )}

        {formInput}

        {icon && iconRight && (
          <Icon
            className={icon}
          />
        )}
      </div>
    </div>
  )
}

const Info = ({ label, value }) => {
  return (
    <div className='form-info'>
      <div className='input-label'>
        {label}
      </div>
      <div className='form-info-value'>
        {value}
      </div>
    </div>
  )
}

const Submit = ({ className, children }) => {
  return (
    <div className={classNames('form-submit', className)}>
      {children}
    </div>
  )
}

const FormError = ({ errors }) => {
  if (!errors) {
    return null
  }

  let errorList = []

  if (array.isArray(errors)) {
    errorList = errors
  } else if (object.isObject(errors)) {
    object.keys(errors).forEach(key => {
      errorList = errorList.concat(errors[key])
    })
  }

  if (errorList.length) {
    return (
      <div data-attribute-id='form_error_container' className='form-errors'>
        {errorList.map((error, index) => (
          <div
            key={index}
            className='form-error'
          >
            {string.properCase(error.message)}
          </div>
        ))}
      </div>
    )
  }

  return null
}

const FormMessage = ({ message, className }) => {
  if (message) {
    return (
      <div className={classNames('form-message', className)}>
        {message}
      </div>
    )
  }

  return null
}

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onSubmit: PropTypes.func
}

Form.defaultProps = {
  onSubmit: () => {} // This is intentional
}

Group.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

Input.propTypes = {
  state: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  settings: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  className: PropTypes.string,
  accept: PropTypes.string,
  logoPath: PropTypes.string,
  logoDirectory: PropTypes.string,
  filePlaceHolder: PropTypes.string,
  loading: PropTypes.bool,
  clear: PropTypes.bool,
  filterCollapse: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
  selectedAll: PropTypes.bool,
  multipleValueText: PropTypes.bool,
  required: PropTypes.bool,
  isScorecard: PropTypes.bool,
  isHomepageActive: PropTypes.bool,
  dataAttrId: PropTypes.string,
  id: PropTypes.string
}

Input.defaultProps = {
  id: '',
  state: true,
  placeholder: '',
  iconRight: false,
  settings: {},
  autoComplete: 'off',
  disabled: false,
  isScorecard: false,
  isHomepageActive: false,
  filterCollapse: false,
  errors: [],
  clear: false,
  onChange: () => {}, // This is intentional
  dataAttrId: ''
}

Info.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
}

Submit.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

FormError.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
}

FormMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string
}

FormError.defaultProps = {
  errors: null
}

export {
  Form,
  Group,
  Input,
  Info,
  Submit,
  FormError,
  FormMessage
}
