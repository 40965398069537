import client from '../client.js'

import updateForgottenPasswordGql from '../mutations/update-forgotten-password.gql'

const updateForgottenPassword = (input) => {
  return client.mutate({
    mutation: updateForgottenPasswordGql,
    variables: input,
    update: (store, { data }) => {
      // console.log('Logger::updateForgottenPassword', data)
    }
  })
}

export default updateForgottenPassword
