import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Card, Button } from '@lib'
import { Form, Group, Input, FormError, Submit } from '@shared/Form'
import { array, object } from '@utils'

import client from '@graphql/client'
import masterReferencesGql from '@graphql/queries/master-references'
import masterReferenceGql from '@graphql/queries/master-reference'
import createMasterReference from '@graphql/mutators/create-master-reference'
import updateMasterReference from '@graphql/mutators/update-master-reference'

const MasterReferenceForm = ({ id, onSave }) => {
  const [input, setInput] = useState({
    referenceGroupId: null,
    value: '',
    description: ''
  })

  const [loading, setLoading] = useState(true)
  const [restricted, setRestricted] = useState({})
  const [inputErrors, setInputErrors] = useState({})
  const [disabled, setDisabled] = useState(false)

  const masterReferencesQuery = useQuery(masterReferencesGql, {
    variables: {
      getReferenceGroups: true
    }
  })

  useEffect(() => {
    if (id && loading) {
      const variables = { id }

      client.query({
        query: masterReferenceGql,
        variables
      }).then(({ data }) => {
        if (data.masterReference) {
          setInput({
            id: data.masterReference.id,
            referenceGroupId: data.masterReference.referenceGroupId,
            value: data.masterReference.value,
            description: data.masterReference.description
          })
          setLoading(false)
        } else {
          setRestricted(true)
        }
      })
    } else {
      masterReferencesQuery.refetch()
    }
  }, [loading])

  if (masterReferencesQuery.loading) {
    return null
  }

  if (id && loading) {
    if (restricted === true && !input.id) {
      // return (
      //   <Unauthorized className='board-message' />
      // )
    } else {
      return null
    }
  }

  const { masterReferences } = masterReferencesQuery.data

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    setDisabled(true)

    const feErrors = {}

    if (!input.value) {
      feErrors.value = [{
        message: 'Value field is required'
      }]
    }

    if (!object.keys(feErrors).length) {
      if (id) {
        updateMasterReference(input).then(({ data, extensions }) => {
          if (data.updateMasterReference) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        createMasterReference(input).then(({ data, extensions }) => {
          if (data.createMasterReference) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            const sqlError = extensions.errors.filter(e => e.path === 'sqlError')
            extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')

            if (sqlError[0].message === 'unique violation') {
              Swal.fire({
                text: 'Record already exists!',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setInputErrors(feErrors)
      setDisabled(false)
    }
  }

  return (
    <Card>
      <Form onSubmit={handleSave}>
        <FormError errors={inputErrors} />

        <Group>
          <Input
            label='Reference Group'
            type='select'
            placeholder='Select reference group'
            options={masterReferences.map(option => ({ value: option.id.toLowerCase(), text: option.value }))}
            value={input.referenceGroupId}
            onChange={(value) => handleInputChange('referenceGroupId', value)}
            errors={inputErrors.referenceGroupId}
          />
        </Group>

        <Group>
          <Input
            label='Value'
            type='text'
            value={input.value}
            onChange={(value) => handleInputChange('value', value)}
            errors={inputErrors.value}
          />
        </Group>

        <Group>
          <Input
            label={<>Description <span className='optional'>(Optional)</span></>}
            type='text'
            value={input.description}
            onChange={(value) => handleInputChange('description', value)}
            errors={inputErrors.description}
          />
        </Group>

        <Submit className='right'>
          <Button
            type='submit'
            disabled={disabled}
            className='form-button primary icon-left'
            icon={`${id ? '' : 'fas fa-plus'}`}
            text={`${id ? 'Update' : 'Add'} Master Reference`}
          />
        </Submit>
      </Form>
    </Card>
  )
}

MasterReferenceForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func
}

MasterReferenceForm.defaultProps = {
  id: '',
  onSave: () => {} // This is intentional
}

export default MasterReferenceForm
