import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Form, Input, Submit, FormError } from '@shared/Form'
import { Title, Button } from '@lib'
import Swal from 'sweetalert2'
import { object, axiosPost, axiosGet } from '@utils'
import { useQuery } from '@apollo/client'
import axios from 'axios'

import clientCustomersGql from '@graphql/queries/client-customers'
import createFileUpload from '@graphql/mutators/create-file-upload'
import callDlEndpoint from '@graphql/mutators/call-dl-endpoint'

import * as XLSX from 'xlsx'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { directory } = useParams()
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    fileType: directory,
    clientId: user.clientId
  })
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  const activeClientUser = user.userClients.filter(x => x.clientId === user.clientId)[0]

  if ((!activeClientUser?.isDatalogicUser || (!user?.client?.clientModules?.find(cm => cm.module.name === 'BidLogic') && directory !== 'claims')) && localStorage.getItem('siteSwitching') !== 'true') {
    navigate(-1, { replace: true })
  }

  const uploadFields = {
    claims: ['claimsType', 'clientCustomer', 'file', 'monthsOfData'],
    rates: ['file'],
    specialty: ['file'],
    network: ['file'],
    formulary: ['file']
  }

  const claimsTypeOptions = []
  if (uploadFields[directory]?.includes('claimsType')) {
    if (user?.client?.clientModules?.find(cm => cm.module.name === 'RebateLogic')) {
      claimsTypeOptions.push({ text: 'RebateLogic Pharmacy', value: 'Pharmacy' })
      claimsTypeOptions.push({ text: 'RebateLogic Medical', value: 'Medical' })
    }
    if (user?.client?.clientModules?.find(cm => cm.module.name === 'BidLogic')) {
      claimsTypeOptions.push({ text: 'BidLogic', value: 'BidLogic' })
    }

    if (claimsTypeOptions.length === 1) {
      input.claimsType = claimsTypeOptions[0].value
    }
  }

  const clientCustomersQuery = useQuery(clientCustomersGql, {
    fetchPolicy: 'network-only',
    variables: {}
  })

  function downloadTemplate () {
    const claimsTemplates = []
    if (directory === 'claims') {
      if (input.claimsType === 'BidLogic') {
        claimsTemplates.push('Claims_BidLogic')
      } else {
        claimsTemplates.push('Claims_RebateLogic_' + input.claimsType)
      }
    } else {
      claimsTemplates.push(directory)
    }

    axiosPost(`download/template/${directory}`, { name: claimsTemplates }, { responseType: 'blob' }).then((result) => {
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${directory}${directory === 'claims' ? ' ' + input.claimsType.toLowerCase() : ''} template.xlsx`
      )
      document.body.appendChild(link) // Adds the link to the html code
      link.click() // Triggers link to start downloading
      link.parentNode.removeChild(link) // Removes the link from the html code
    })
  }

  const handleInputChange = async (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleSubmit = async (v) => {
    v.preventDefault()
    const feErrors = {}
    const newInput = {}

    if (!user.client.s3BucketName) {
      feErrors.client = [{ message: 'Client not configured for upload' }]
    }

    if (uploadFields[directory].includes('claimsType')) {
      if (!input.claimsType) {
        feErrors.claimsType = [{ message: 'Claims type is required' }]
      } else {
        newInput.claimsType = input.claimsType
      }
    }

    if (uploadFields[directory].includes('clientCustomer')) {
      if (!input.clientCustomerId) {
        feErrors.clientCustomerId = [{ message: 'Client customer is required' }]
      } else {
        newInput.clientCustomerId = input.clientCustomerId
      }
    }

    if (uploadFields[directory].includes('file')) {
      if (!input.file) {
        feErrors.file = [{ message: 'File is required' }]
      } else if (input.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && input.file.type !== 'text/plain') {
        feErrors.file = [{ message: 'File must be of type .xlsx or .txt' }]
      } else {
        const buff = await input.file.arrayBuffer()
        const xlsxWb = XLSX.readFile(buff, { sheetRows: 2 })
        const data = XLSX.utils.sheet_to_json(xlsxWb.Sheets[xlsxWb.SheetNames[0] || 'Sheet1'], { header: 1 })
        if (data?.length < 2 || !data[1].some(r => r !== null)) {
          feErrors.file = [{ message: 'The file is empty. Please upload a valid file.' }]
        } else {
          newInput.fileHeaders = JSON.stringify(data[0])
          newInput.file = JSON.stringify({
            originalname: input.file.name,
            mimetype: input.file.type,
            size: input.file.size
          })
        }
      }
    }

    if (uploadFields[directory].includes('monthsOfData')) {
      if (!input.monthsOfData) {
        feErrors.monthsOfData = [{ message: 'Months of data is required' }]
      } else {
        newInput.monthsOfData = input.monthsOfData
      }
    }

    if (input.claimsType === 'BidLogic') {
      newInput.isBidlogic = true
    } else if (input.claimsType) {
      newInput.isRebatelogic = true
    }

    newInput.fileType = input.fileType

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      await createFileUpload(newInput).then(async response => {
        const data = response.data
        if (data.createFileUpload) {
          const bucket = user.client.s3BucketName.replace(/\/.*/g, '')
          const key = encodeURIComponent(data.createFileUpload.filePath.replace(/^\//, ''))

          axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
            if (e?.data?.url) {
              const uploadUrl = e.data.url
              await axios.put(uploadUrl, input.file).then(async () => {
                if (data.createFileUpload?.invalidColumns?.length > 0 || data.createFileUpload?.fileStatus?.value === 'For Resolution') {
                  navigate(`/datalogic/file-uploads/${directory}/file-resolver/${data.createFileUpload.id}`)
                } else {
                  await callDlEndpoint({ executionId: JSON.parse(data.createFileUpload.id) }).then(async endpointResult => {
                    if (endpointResult.data.callDlEndpoint) {
                      Swal.fire({
                        text: 'File uploaded',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      navigate(`/datalogic/file-uploads/${directory}`)
                    } else {
                      setLoaderState(prevState => ({ ...prevState, submit: false }))
                      setInputErrors({ file: [{ message: 'Error uploading file' }] })
                    }
                  })
                }
              }).catch(() => {
                setLoaderState(prevState => ({ ...prevState, submit: false }))
                setInputErrors({ file: [{ message: 'Error uploading file' }] })
              })
            }
          }).catch(() => {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors({ file: [{ message: 'Error uploading file' }] })
          })
        } else {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setInputErrors(response?.extensions?.errors || response?.errors)
        }
      })
    } else {
      setInputErrors(feErrors)
    }
  }

  if (clientCustomersQuery.loading || (uploadFields[directory]?.includes('clientCustomer') && clientCustomersQuery.data?.clientCustomers?.length && clientCustomersQuery.error)) {
    return null
  }

  const clientCustomers = clientCustomersQuery?.data?.clientCustomers || []

  return (
    <Fragment>
      <Title text={`New ${(directory.charAt(0).toUpperCase() + directory.slice(1))} Upload`} user={user}/>
      <div className='file-upload-header'>
        <p className='file-upload-header-text'>Supports .xlsx and .txt file extensions only. Text files cannot contain more than 3 million rows and must be pipe delimited. Dates must be in <i>mm/dd/yyyy</i> format.</p>
      </div>
      <Form className='datalogic-upload-form' onSubmit={handleSubmit}>

      <FormError errors={inputErrors} />

        <Button
          className='secondary transparent center'
          icon='fas fa-arrow-alt-to-bottom'
          onClick={downloadTemplate}
          text='Download Template'
          disabled={directory === 'claims' && !input.claimsType}
        />

        <div className='break-line'></div>

        {uploadFields[directory]?.includes('claimsType') &&
          <Input
            label='Claims Type'
            placeholder='Select claims type'
            type='select'
            value={input.claimsType || (claimsTypeOptions.length === 1 ? claimsTypeOptions[0].value : '')}
            options={claimsTypeOptions}
            onChange={(v) => handleInputChange('claimsType', v)}
            errors={inputErrors?.claimsType}
          />
        }

        {uploadFields[directory]?.includes('clientCustomer') &&
          <Input
            label='Client Customer'
            placeholder='Select Client Customer'
            type='select'
            value={input.clientCustomerId}
            options={ clientCustomers?.map((clientCustomer) => {
              return {
                text: clientCustomer.name,
                value: clientCustomer.id
              }
            }
            )}
            // disabled = {clientCustomerDisable}
            onChange={(v) => handleInputChange('clientCustomerId', v)}
            errors={inputErrors?.clientCustomerId}
          />
        }

        <Input
          label='File'
          type='draganddrop'
          value={input.file}
          onChange={(v) => handleInputChange('file', v.target.files[0])}
          accept='.xlsx, .txt'
          className='file-upload'
          errors={inputErrors?.file}
        />

        {uploadFields[directory]?.includes('monthsOfData') &&
          <Input
            label='Months of Data'
            className='months-of-data'
            type='number'
            value={input.monthsOfData}
            onChange={(v) => handleInputChange('monthsOfData', parseInt(v))}
            errors={inputErrors?.monthsOfData}
          />
        }

        <Submit className='submit-claim'>
          <Button
            type='submit'
            icon='fas fa-arrow-alt-to-top'
            loading={loaderState.submit}
          >
            Upload
          </Button>
        </Submit>
      </Form>

    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const FileLogic = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default FileLogic
