/**
 * @description Get the current month
 * @returns {string} monthName
 */
const getCurrentMonthName = () => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]

  const date = new Date()
  const monthName = monthNames[date.getMonth()]
  return monthName.toLowerCase()
}

export default getCurrentMonthName
