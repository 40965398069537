import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Card, Button } from '@lib'
import { Form, Group, Input, FormError, Submit } from '@shared/Form'
import { array, object, time } from '@utils'

import client from '@graphql/client'
import releaseNotesGql from '@graphql/queries/release-notes'
import noticeGql from '@graphql/queries/notice'
import createNotice from '@graphql/mutators/create-notice'
import updateNotice from '@graphql/mutators/update-notice'

const NoticeForm = ({ id, onSave }) => {
  const [input, setInput] = useState({
    releaseNoteId: null,
    name: '',
    headText: '',
    bodyText: '',
    startAt: '',
    endAt: '',
    isEnabled: true
  })

  const [loading, setLoading] = useState(true)
  const [restricted, setRestricted] = useState({})
  const [inputErrors, setInputErrors] = useState({})

  const releaseNotesQuery = useQuery(releaseNotesGql, { })
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (id && loading) {
      const variables = { id }

      client.query({
        query: noticeGql,
        variables
      }).then(({ data }) => {
        if (data.notice) {
          setInput({
            id: data.notice.id,
            releaseNoteId: data.notice.releaseNoteId,
            name: data.notice.name,
            headText: data.notice.headText,
            bodyText: data.notice.bodyText,
            startAt: data.notice.startAt,
            endAt: data.notice.endAt,
            isEnabled: data.notice.isEnabled
          })
          setLoading(false)
        } else {
          setRestricted(true)
        }
      })
    } else {
      releaseNotesQuery.refetch()
    }
  }, [loading])

  if (releaseNotesQuery.loading) {
    return null
  }

  if (id && loading) {
    if (restricted === true && !input.id) {
      // return (
      //   <Unauthorized className='board-message' />
      // )
    } else {
      return null
    }
  }

  const { releaseNotes } = releaseNotesQuery.data

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
  }

  const handleDateRangeChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const newTime = time(value).format('MM/DD/YYYY')

    setInput(prevState => ({
      ...prevState,
      [name]: newTime
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    setDisabled(true)

    const feErrors = {}

    if (!input.name) {
      feErrors.name = [{
        message: 'Name is required'
      }]
    }
    if (!input.headText) {
      feErrors.headText = [{
        message: 'Head text is required'
      }]
    }
    if (!input.bodyText) {
      feErrors.bodyText = [{
        message: 'Body text is required'
      }]
    }
    if (!input.startAt) {
      feErrors.startAt = [{
        message: 'Start date is required'
      }]
    }
    if (!input.endAt) {
      feErrors.endAt = [{
        message: 'End date is required'
      }]
    }
    if (Date(input.endAt) < Date(input.startAt)) {
      feErrors.endAt = [{
        message: 'Start date cannot be greater than end date'
      }]

      // Swal.fire({
      //   text: 'Start date cannot be greater than end date',
      //   icon: 'error',
      //   showConfirmButton: false,
      //   timer: 3000
      // })
    }

    if (!id && new Date(input.endAt) < new Date()) {
      feErrors.endAt = [{
        message: 'Notice dates cannot be in the past'
      }]
    }

    if (!object.keys(feErrors).length) {
      if (id) {
        updateNotice(input).then(({ data, extensions }) => {
          if (data.updateNotice) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        createNotice(input).then(({ data, extensions }) => {
          if (data.createNotice) {
            setInputErrors({})
            onSave()
          } else if (extensions && extensions.errors) {
            const sqlError = extensions.errors.filter(e => e.path === 'sqlError')
            extensions.errors = extensions.errors.filter(e => e.path !== 'sqlError')

            if (sqlError[0].message === 'unique violation') {
              Swal.fire({
                text: 'Record already exists!',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setDisabled(false)
      setInputErrors(feErrors)
    }
  }

  return (
    <Card>
      <Form onSubmit={handleSave}>
        <FormError errors={inputErrors} />

        <Group>
          <Input
            label='Notice Name'
            type='text'
            value={input.name}
            onChange={(value) => handleInputChange('name', value)}
            errors={inputErrors.name}
          />

          <Input
            label='Linked Release Note'
            type='select'
            placeholder='Select release note'
            options={releaseNotes.map(option => ({ value: option.id.toLowerCase(), text: option.version }))}
            value={input.releaseNoteId}
            onChange={(value) => handleInputChange('releaseNoteId', value)}
            errors={inputErrors.releaseNoteId}
          />
        </Group>

        <Group>
          <Input
            className='icon-right'
            label='Start Date'
            type='datepicker'
            icon='fas fa-calendar'
            placeholder='Start Date'
            settings={{ dateFormat: 'm/d/Y' }}
            value={input.startAt}
            onChange={(value) => handleDateRangeChange('startAt', value)}
          />

          <Input
            className='icon-right'
            label='End Date'
            type='datepicker'
            icon='fas fa-calendar'
            placeholder='End Date'
            settings={{ dateFormat: 'm/d/Y' }}
            value={input.endAt}
            onChange={(value) => handleDateRangeChange('endAt', value)}
          />
        </Group>

        <Group>
          <Input
            label='Head Text'
            type='text'
            value={input.headText}
            onChange={(value) => handleInputChange('headText', value)}
            errors={inputErrors.headText}
          />
        </Group>

        <Group>
          <Input
            label='Body Text'
            type='textarea'
            placeholder='Enter body text here...'
            value={input.bodyText}
            onChange={(value) => handleInputChange('bodyText', value)}
            errors={inputErrors.bodyText}
          />
        </Group>

        <Submit className='right'>
          <Button
            type='submit'
            className='form-button primary icon-left'
            icon={`${id ? '' : 'fas fa-plus'}`}
            text={`${id ? 'Update' : 'Add'} Notice`}
            disabled={disabled}
          />
        </Submit>
      </Form>
    </Card>
  )
}

NoticeForm.propTypes = {
  id: PropTypes.string,
  onSave: PropTypes.func
}

NoticeForm.defaultProps = {
  id: '',
  onSave: () => {} // This is intentional
}

export default NoticeForm
