import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Page, Button } from '@lib'
import Swal from 'sweetalert2'
import { Link } from '@shared'
import { Group, Submit, FormError } from '@shared/Form'
import { PasswordValidationChecklist } from '@shared/index.js'
import { array, object } from '@utils'

import logoImage from '@images/xevant-logo-vertical.png'
import bgImage from '@images/networkingBG.png'

import updatePassword from '@graphql/mutators/update-password'
import { isValidPassword } from '@utils/validate-password'

const ForceUpdatePassword = () => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    loading: true,
    email: '',
    password: '',
    confirmPassword: '',
    success: null,
    error: null
  })
  const [errors, setErrors] = useState({})
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  useEffect(() => {
    if (errors.password?.length === 1) {
      document.querySelector('.middle')?.classList.add('password-validation-extension')
    } else {
      document.querySelector('.middle')?.classList.remove('password-validation-extension')
    }
  }, [errors])

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const feErrors = {}

    if (!state.password) {
      feErrors.password = [{
        path: 'password',
        message: 'Password required'
      }]
    }

    if (state.password) {
      if (!isValidPassword(state.password)) {
        feErrors.password = [{
          path: 'passwordValidation',
          message: 'The password does not meet the criteria below'
        }]
      }
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      setErrors(feErrors)

      updatePassword({
        password: state.password
      }).then(({ data, extensions }) => {
        if (data.updatePassword) {
          navigate('/login')

          Swal.fire({
            text: 'Your password has been changed',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        }

        if (extensions?.errors) {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setErrors(array.groupBy(extensions.errors, 'path'))
        }
      })
    } else {
      setErrors(feErrors)
    }
  }

  const handleShowPassword = () => {
    const passInput = document.querySelector('.new-password input')
    if (passInput.type === 'password') {
      document.querySelector('.new-password i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.new-password i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  return (
    <Page
      name='login'
      className='login'
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
          <div className='right-form'>
            <div className='login-logo-center'>
              <img src={logoImage} />
            </div>

            {!state.loading && state.error && (
              <Fragment>
                <div className='page-message'>
                  {state.error}
                </div>

                <Submit className='right'>
                  <Link
                    className='button circle small icon-left'
                    to='/login'
                    icon='fad fa-long-arrow-left'
                    text='Login Page'
                  />
                </Submit>

              </Fragment>
            )}

            {state.loading && !state.error && (
              <Fragment>
                {!state.success && (
                  <>
                  <form onSubmit={handleSubmit}>
                    <h1>Update password</h1>
                    <p className='big-text margin-bottom'>Enter a new password below to gain access to your account.</p>
                    <FormError errors={errors} />
                    <Group>
                      <div className='form-input'>
                        <div className='input-label'>New Password</div>
                        <div className='input password login-password new-password'>
                          <input
                            type='password'
                            autoComplete='off'
                            value={state.password}
                            onChange={(value) => setState((prevState) => ({ ...prevState, password: value.target.value }))}
                            errors={errors.password}
                            data-attribute-id='force_change_password_new_password_field'
                          />
                          <i onClick={() => handleShowPassword()} className='fas fa-eye' aria-hidden='true'></i>
                        </div>
                      </div>
                    </Group>

                    <PasswordValidationChecklist input={state}/>

                    <Button
                      type='submit'
                      className='icon-left login-button full-width primary'
                      text='Change Password'
                      loading={loaderState.submit}
                      data-attribute-id='force_change_password_btn'
                    />
                    <div className='copyright'>
                      Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
                    </div>
                  </form>
                  </>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default ForceUpdatePassword
