import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import parser from 'html-react-parser'
import Dashboard from '@wrappers/Dashboard'
import { Link, Title, Page } from '@lib'

import logoImage from '@images/xevant-logo-horizontal.png'

import client from '@graphql/client'
import releaseNotesGql from '@graphql/queries/release-notes'
import releaseNoteGql from '@graphql/queries/release-note'

const Content = ({ user }) => {
  const [currentReleaseNote, setCurrentReleaseNote] = useState({
    name: '',
    version: '',
    bodyText: '',
    createdAt: ''
  })

  const [loading, setLoading] = useState(true)
  const { hash } = useLocation()

  let releaseNotes = null

  useEffect(() => {
    let timer

    if (hash && loading) {
      client.query({
        query: releaseNoteGql,
        variables: {
          id: hash.substring(1)
        }
      }).then(({ data }) => {
        setCurrentReleaseNote(data.releaseNote)

        timer = setTimeout(() => {
          const element = document.getElementById(`release-note${data.releaseNote.id}`)
          element.classList.add('active')
        }, 100)
      })
    } else if (loading) {
      client.query({
        query: releaseNotesGql
      }).then(({ data }) => {
        releaseNotes = data.releaseNotes

        const releaseNote = releaseNotes[0]

        if (releaseNote) {
          setTimeout(() => {
            setCurrentReleaseNote(releaseNote)
            const element = document.getElementById(`release-note${releaseNote.id}`)
            element.classList.add('active')
          }, 100)
        }
      })
    }
    setLoading(false)

    return () => {
      clearTimeout(timer)
    }
  }, [loading])

  if (!releaseNotes) {
    const releaseNotesQuery = useQuery(releaseNotesGql, {})

    if (releaseNotesQuery.loading || releaseNotesQuery.error) {
      return null
    }

    releaseNotes = releaseNotesQuery.data.releaseNotes
  }

  return (
    <Fragment>
      <Title text='Release Notes' user={user}/>
      <Page
        name='resources'
        className='resources'
      >
        <div className='content'>
          <div className='middle release-notes-mid'>
            <div className='left-info'>
              <div className='login-logo-left'>
                <img src={logoImage} />
              </div>

              <div className='left-content'>
                {releaseNotes.length > 0 &&
                  releaseNotes.map((r, key) =>
                    <div key={key}>
                      <Link
                        id={`release-note${r.id}`}
                        className='release-note--id'
                        to ={`#${r.id}`}
                        onClick={(e) => {
                          const links = document.getElementsByClassName('release-note--id')
                          for (let ctr = 0; ctr < links.length; ctr++) {
                            links[ctr].classList.remove('active')
                          }

                          e.target.classList.add('active')

                          setCurrentReleaseNote(r)
                        }}
                      >
                        {r.name}
                      </Link>
                    </div>
                  )
                }
              </div>
            </div>
            <div className='right-form'>
              <div className='doc-box'>
                <div className='release-note--title'>{currentReleaseNote.name}</div>
                <div className='release-note--date'>{currentReleaseNote.createdAt ? new Date(new Date(currentReleaseNote.createdAt).toString()).toLocaleDateString() : null}</div>
                <div className='release-note--body'>{parser(currentReleaseNote.bodyText)}</div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const Tos = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default Tos
