import client from '../client.js'
import destroyFileUploadGql from '../mutations/destroy-file-upload.gql'
import FileUploadsGql from '../queries/file-uploads.gql'
const destroyFileUpload = (input) => {
  return client.mutate({
    mutation: destroyFileUploadGql,
    variables: input,
    update: (store, { data }) => {
      // Intentional
    }
  })
}

export default destroyFileUpload
