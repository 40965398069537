import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { Datatable, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import { Can } from '@caslConfig/can.js'
import releaseNotesGql from '@graphql/queries/release-notes'
import destroyReleaseNote from '@graphql/mutators/destroy-release-note'

const ReleaseNoteList = ({ links }) => {
  // values query
  const releaseNotesQuery = useQuery(releaseNotesGql, {
    fetchPolicy: 'network-only'
  })

  const handleDelete = (releaseNoteID) => {
    Swal.fire({
      text: 'Are you sure you want to delete this notice?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        destroyReleaseNote(releaseNoteID).then(({ data, extensions }) => {
          if (data) {
            if (data.destroyReleaseNote) {
              Swal.fire({
                text: 'Release note deleted!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              })
              releaseNotesQuery.refetch()
            } else {
              const { errors } = extensions

              Swal.fire({
                text: errors[0].message,
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
              })
            }
          }
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Version',
        accessor: 'version'
      },
      {
        Header: 'Enabled',
        accessor: (r) => (r.isEnabled ? 'Yes' : 'No')
      },
      {
        Header: 'Author',
        accessor: 'user.fullName'
      },
      {
        id: 'actionBtnsCol',
        accessor: (d) => d,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='ReleaseNote'>
                <Link
                  className='action-button edit-button'
                  to={`${links.edit}/${value.id}`}
                  icon='fas fa-pencil-alt'
                />
                <Tooltip
                  anchorId={`${value.id}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='ReleaseNote'>
                <div
                  onClick={() => handleDelete(value.id)}
                  className='action-button delete-button'
                  id={`${value.id}-delete`}
                >
                  <Icon className='fas fa-trash'/>
                </div>
                <Tooltip
                  anchorId={`${value.id}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ],
    []
  )

  if (releaseNotesQuery.loading || releaseNotesQuery.error) {
    return null
  }

  const { releaseNotes } = releaseNotesQuery.data

  if (!releaseNotes) {
    return null
  }

  return (
    <ListView>
      <Can I='create' a='ReleaseNote'>
        <Datatable
          columns={columns}
          data={releaseNotes}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New Release Note',
            to: '/settings/notices-and-release-notes/new-release-note'
          }}
          sortDesc={false}
        />
      </Can>

      <Can not I='create' a='ReleaseNote'>
        <Datatable
          columns={columns}
          data={releaseNotes}
        />
      </Can>
    </ListView>
  )
}

ReleaseNoteList.propTypes = {
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.string
}

ReleaseNoteList.defaultProps = {
  links: {
    view: '',
    edit: ''
  },
  fields: {
    // client: true,
    // template: true,
    // templateField: true,
    // name: true,
    // isRequired: false,
    // action: true
  },
  onDelete: () => {}, // This is intentional
  filters: {},
  value: null
}

export default ReleaseNoteList
