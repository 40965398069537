import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import Dashboard from '@wrappers/Dashboard'
import { Card, Title, Button } from '@lib'
import Swal from 'sweetalert2'
import { Form, Group, Input, FormError, Submit, Info } from '@shared/Form'
import { PasswordValidationChecklist } from '@shared/index.js'
import { array, object } from '@utils'

import updatePasswordChange from '@graphql/mutators/update-password-change'
import { isValidPassword } from '@utils/validate-password.js'

const Content = ({ user }) => {
  const navigate = useNavigate()

  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  })
  const [loaderState, setLoaderState] = useState({
    submit: false
  })

  const handleInputChange = (name, value) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    if (name === 'oldPassword') {
      if (inputErrors.OldPassword) {
        const newInputError = { ...inputErrors }
        newInputError.OldPassword = []
        setInputErrors(newInputError)
      } else {
        const newInputError = { ...inputErrors }
        newInputError.oldPassword = []
        setInputErrors(newInputError)
      }
    }

    if (name === 'password') {
      if (inputErrors.securedPassword) {
        const newInputError = { ...inputErrors }
        newInputError.securedPassword = []
        setInputErrors(newInputError)
      } else {
        const newInputError = { ...inputErrors }
        newInputError.password = []
        setInputErrors(newInputError)
      }
    }

    const state = { ...input }
    state[name] = value
    setInput(state)
  }

  const handleSubmit = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}

    if (!input.oldPassword) {
      feErrors.oldPassword = [{ message: 'Current password required' }]
    }
    if (!input.password) {
      feErrors.password = [{ message: 'Password required' }]
    }

    if (input.password) {
      if (!isValidPassword(input.password)) {
        feErrors.securedPassword = [{ message: 'Your password needs to be made up of at least 12 characters with combined lowercase and uppercase letters and at least one number and special character' }]
      }
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      updatePasswordChange(input).then(({ data, extensions }) => {
        if (extensions && extensions.errors) {
          setLoaderState(prevState => ({ ...prevState, submit: false }))
          setInputErrors(array.groupBy(extensions.errors, 'path'))
        } else if (data.updatePasswordChange) {
          navigate('/settings', { replace: true })

          Swal.fire({
            text: 'Password changed!',
            icon: 'success',
            // buttons: false,
            showConfirmButton: false,
            timer: 3000
          })
        }
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  const handleShowPassword = () => {
    const passInput = document.querySelector('.new-password input')
    if (passInput.type === 'password') {
      document.querySelector('.new-password i').classList.add('fa-eye-slash')
      passInput.type = 'text'
    } else {
      document.querySelector('.new-password i').classList.remove('fa-eye-slash')
      passInput.type = 'password'
    }
  }

  return (
    <Fragment>
      <Title text='Change Password' />
      <Card>
        <Form className='change-password-content' onSubmit={handleSubmit}>
          <FormError errors={inputErrors} />

          <Group>
            <Info
              label='Username'
              value={user.username}
            />
          </Group>

          <Group className="half">
            <Input
              label='Current Password'
              type='password'
              onChange={(value) => handleInputChange('oldPassword', value)}
              errors={inputErrors.oldPassword}
              dataAttrId="change_password_current_password_field"
            />
          </Group>

          <Group>
            <div className='form-input'>
              <div className='input-label'>New Password</div>
              <div className='input password new-password'>
                <input
                  type='password'
                  autoComplete='off'
                  value={input.password}
                  onChange={(v) => handleInputChange('password', v.target.value)}
                  errors={inputErrors.password}
                  data-attribute-id="change_password_new_password_field"
                />
                <i onClick={() => handleShowPassword()} className='fas fa-eye' aria-hidden='true'></i>
              </div>
            </div>
          </Group>

          <PasswordValidationChecklist input={input} />

          <br/>
          <Submit className='right'>
            <Button
              type='submit'
              className='icon-left full-width password-btn primary'
              text='Change Password'
              loading={loaderState.submit}
              data-attribute-id="change_password_btn"
            />
          </Submit>
        </Form>
      </Card>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const ChangePassword = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default ChangePassword
