import React, { Fragment, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable } from '@lib'
import { ListView } from '@shared'
import { Input } from '@shared/Form'
import { time } from '@utils'

import auditLogsGql from '@graphql/queries/audit-logs'

const Content = ({ user }) => {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  })

  const auditLogsQuery = useQuery(auditLogsGql, {
    fetchPolicy: 'network-only',
    variables: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }
  })

  const columns = useMemo(() =>
    [
      {
        Header: 'User',
        accessor: 'user.fullName'
        // Filter: SelectColumnFilter,
        // filter: 'includes'
      },
      {
        Header: 'Event',
        accessor: 'event'
      },
      {
        Header: 'Table Name',
        accessor: 'tableName'
      },
      {
        Header: 'From',
        accessor: 'fromValue'
      },
      {
        Header: 'To',
        accessor: 'toValue'
      },
      {
        Header: 'Timestamp',
        accessor: n => {
          if (user.timezone) {
            const formattedTime = time.utc(n.createdAt).tz(user.timezone).format('MM/DD/YYYY hh:mm A')
            if (formattedTime === 'Invalid Date' || formattedTime === null) {
              return '-'
            } else {
              return formattedTime
            }
          } else {
            if (n.createdAt === 'Invalid Date' || n.createdAt === null) {
              return '-'
            } else {
              return n.createdAt
            }
          }
        }
      }
    ], []
  )

  const { auditLogs } = auditLogsQuery.data || []
  // console.log(auditLogs)

  const DateRangeSelection = () => {
    const handleDateRangeChange = (name, value) => {
      const newTime = value ? time(value).format('MM/DD/YYYY') : null
      setDateRange(prevState => ({
        ...prevState,
        [name]: newTime
      }))
    }

    return (
      <div className='audit-logs-date-range-selection'>
        <Input
          className='icon-right'
          // label='Start Date'
          type='datepicker'
          icon='fas fa-calendar'
          placeholder='Start Date'
          settings={{ dateFormat: 'm/d/Y' }}
          value={dateRange.startDate}
          onChange={(value) => handleDateRangeChange('startDate', value)}
        />
        <Input
          className='icon-right'
          // label='End Date'
          type='datepicker'
          icon='fas fa-calendar'
          placeholder='End Date'
          settings={{ dateFormat: 'm/d/Y' }}
          value={dateRange.endDate}
          onChange={(value) => handleDateRangeChange('endDate', value)}
        />
      </div>
    )
  }

  return (
    <Fragment>
      <Title text='Audit Logs' user={user}/>

      <ListView>
        <DateRangeSelection/>
        <Datatable
          columns={columns}
          data={auditLogs || []}
          loading={auditLogsQuery.loading}
        />
      </ListView>

    </Fragment>
  )
}

const AuditLogs = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

export default AuditLogs
