import React from 'react'
import logoImage from '@images/xevant-logo-horizontal.png'
import bgImage from '@images/networkingBG.png'

import { Page, Icon } from '@lib'

const Unauthorized = () => {
  return (
    <Page
      name='unauthorized'
      className='login' // to reuse styles from login/style.scss
      style={{ backgroundImage: 'url(' + bgImage + ')' }}
    >
      <div className='content'>
        <div className='middle'>
            <div className='left-info'>
              <div className='login-logo-left'>
                <img src={logoImage} alt="Xevant Logo" />
              </div>
              <div className='left-content unauthorized'>
                <div className='left-content-icon'>
                  <Icon className="far fa-times-octagon"/>
                </div>
              </div>
              <div className='copyright'>
                Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
              </div>
            </div>
            <div className='right-content invalid-right-content'>
              <br/>
              <h1>Unauthorized</h1>
              <p className='big-text'>Oops! It seems you don&apos;t have permission to access this link.</p>
              <br />
              <p className='big-text'>If you believe you should have access, contact your client success representative for assistance.</p>
            </div>
        </div>
      </div>
    </Page>
  )
}

export default Unauthorized
