import { ApolloClient } from '@apollo/client'
import link from './link.js'
import cache from './cache.js'

const client = new ApolloClient({
  link,
  cache
})

export default client
