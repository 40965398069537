import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import MasterReferenceList from '@shared/MasterReferenceList'

const Content = ({ user }) => {
  return (
    <Fragment>
      <Title text='Master References' user={user} />

      <MasterReferenceList
        itemClass='background-white'
        links={{
          edit: '/settings/master-reference/edit'
        }}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const MasterReferences = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default MasterReferences
