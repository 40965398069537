import client from '../client.js'
import createUserDashboardExecutionGql from '../mutations/create-user-dashboard-execution.gql'

const createUserDashboardExecution = (input) => {
  return client.mutate({
    mutation: createUserDashboardExecutionGql,
    variables: input
  })
}

export default createUserDashboardExecution
