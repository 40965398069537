import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './app.js'
import config from '@config'

import './styles/app.scss'
import 'flatpickr/dist/flatpickr.min.css'

const RootElement = document.getElementById('app')

if (RootElement) {
  const root = createRoot(RootElement)

  if (config.PENDO_API_KEY) {
    (function (apiKey) {
      (function (p, e, n, d, o) {
        // eslint-disable-next-line no-var
        var v, w, x, y, z
        o = p[d] = p[d] || {}
        o._q = o._q || []
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']

        for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
            }
          })(v[w])
        }

        y = e.createElement(n)
        y.async = !0
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')
    })(config.PENDO_API_KEY)
  }

  root.render(<App />)
} else {
  throw new Error('Root Element does not exists')
}
