import client from '../client.js'
import destroySubscriptionGql from '../mutations/destroy-subscription.gql'

const destroySubscription = (input) => {
  return client.mutate({
    mutation: destroySubscriptionGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroySubscription
