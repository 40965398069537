import client from '../client.js'
import updateAlertGql from '../mutations/update-alert.gql'

const updateAlert = (input) => {
  return client.mutate({
    mutation: updateAlertGql,
    variables: input
  })
}

export default updateAlert
