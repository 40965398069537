import React, { useMemo, useState } from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination, useGroupBy, useExpanded } from 'react-table'
import PropTypes from 'prop-types'
import { Input } from '@shared/Form'
import 'regenerator-runtime/runtime.js'

import { Buttons } from '@shared/Action'
import { Link } from '@shared'
import { Loaders } from '@lib'

// Define a default UI for filtering
function GlobalFilter ({ globalFilter, setGlobalFilter, filteredRows }) {
  const [value, setValue] = React.useState(globalFilter)
  const [searchClass, setSearchClass] = useState('')

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  const onFocus = () => {
    setSearchClass('blue-border')
  }

  const onBlur = () => {
    setSearchClass('')
  }
  return (
    <span className={`global-filter-search ${searchClass}`}>
      {/* Search:{' '} */}
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search ${filteredRows.length} ${filteredRows.length !== 1 ? 'records' : 'record'}...`}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <i className='far fa-search fa-lg'></i>
    </span>
  )
}

// This is a custom filter UI for selecting unique option from a list
export function SelectColumnFilter ({
  column: { setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // Render a multi-select box

  const selectOptions = [{ value: '', text: 'All' }, ...options.map((option, i) => ({ value: i, text: option }))]
  const [input, setInput] = useState({
    value: ''
  })
  const handleInputChange = (name, value) => {
    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
    const filteredData = selectOptions.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(value)
    })

    if (filteredData[0].text === 'All') {
      setFilter(undefined)
    } else {
      setFilter(filteredData[0].text)
    }
  }

  return (
    <Input
      type='select'
      name={id}
      placeholder={'All'}
      id={id}
      value={input.value || ''}
      onChange={(value) => handleInputChange('value', value)}
      options={selectOptions.map((option) => ({ value: option.value, text: option.text }))}
    >
    </Input>
  )
}

function Datatable ({ columns, data, newRowBtn, displayPagination, displayRecordCount, displaySearch, groupField, loading, sortDesc = true }) {
  const initState = groupField ? { groupBy: useMemo(() => [groupField]) } : {}
  if (sortDesc) {
    initState.sortBy = useMemo(() => [{ id: 'id', desc: true }])
  }
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, filteredRows, // basic table
    page, // if you don't want pagination - change page to rows
    canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, // handy pagination values
    state, preGlobalFilteredRows, setGlobalFilter // filtering // state: {}
  } = useTable(
    {
      columns,
      data,
      initialState: initState,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName]
          const rowTwoColumn = row2.values[columnName]
          if (isNaN(rowOneColumn)) {
            return rowOneColumn > rowTwoColumn ? 1 : -1
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
        }
      }
    },
    useGlobalFilter,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  )

  const fromRecord = filteredRows.length !== 0 ? state.pageIndex + 1 === 1 ? 1 : (state.pageSize * state.pageIndex) + 1 : 0
  const toRecord = page.length !== state.pageSize ? filteredRows.length : state.pageSize * (state.pageIndex + 1)

  const [input, setInput] = useState({
    value: 10
  })

  const paginationChange = (name, value) => {
    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)
    setPageSize(value)
  }

  // Render the UI for your table
  return (
    <div className='data-table'>

      <div className='data-table-top-bar'>
        <div className='data-table-left'>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter
                ? (
                    <div className='column-filter' key={column.id}>
                      {/* <label htmlFor={column.id}> { column.render('Header') }: </label> */}
                      { column.render('Filter') }
                    </div>
                  )
                : null
            )
          )}
        </div>

        <div className='data-table-right'>
          {newRowBtn && (
            <Buttons>
              <Link
                className='button primary small icon-left'
                icon={newRowBtn.icon}
                text={newRowBtn.text}
                to={newRowBtn.to}
              />
            </Buttons>
          )}

          {displaySearch && (
            <GlobalFilter
              preGlobalFilteredRows={ preGlobalFilteredRows }
              globalFilter={ state.globalFilter }
              setGlobalFilter={ setGlobalFilter }
              filteredRows={ filteredRows }
            />
          )}
        </div>
      </div>

      {loading
        ? <div className='table-loader'>
        <Loaders.LoaderA/>
      </div>
        : <table {...getTableProps()} border='1'>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} { ...headerGroup.getHeaderGroupProps() }>
              { headerGroup.headers.map((column, i) => (
                // Sorting props added at the header level, which happens to be our use case
                <th key={i} {...(column.width !== 150 && { width: column.width })} { ...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header') }
                  { !column.disableSortBy
                    ? (
                      <span>
                        {
                          column.isSorted
                            ? column.isSortedDesc
                              ? <span> <i className='fas fa-sort-down'></i></span>
                              : <span> <i className='fas fa-sort-up'></i></span>
                            : <span> <i className='fas fa-sort'></i></span>
                        }
                      </span>
                      )
                    : null}
                </th>
              )) }
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.length === 0
            ? <tr><td colSpan={headerGroups[0].headers.length}>No data available</td></tr>
            : page.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td key={i} {...cell.getCellProps()} className={`${cell.column.className ? cell.column.className : ''} ${cell.column.Header === 'Status' ? `statusCell ${cell.value?.replace(' ', '')?.toLowerCase()} ` : ''} ${cell.isAggregated || cell.isGrouped ? 'aggCell' : ''}`}>
                        {cell.isGrouped
                          ? (
                            <>
                              <span {...row.getToggleRowExpandedProps()} className='agg-toggle'>
                                <i className={`far fa-angle-${row.isExpanded ? 'down' : 'right'} fa-lg`}></i>
                              </span>
                              <div>
                                {cell.render('Cell')} ({row.subRows.length})
                              </div>
                            </>
                            )
                          : cell.isAggregated
                            ? (
                                cell.render('Aggregated')
                              )
                            : cell.isPlaceholder
                              ? null
                              : (
                                  <div>
                                    {cell.render('Cell')}
                                  </div>
                                )
                          }
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    }

      {displayRecordCount && (
        <div className="pagination">
          <div className='pagination-left'>
            <div className='page-size-filter'>
              Show {' '}
              <Input
                type='select'
                value={input.value}
                onChange={(value) => paginationChange('value', value)}
                placeholder={String(input.value)}
                className='table-pagination-select'
                options = {[{ value: 5, text: 5 }, { value: 10, text: 10 }, { value: 20, text: 20 }].map(pageSize => ({ value: pageSize.value, text: pageSize.text }))}
              >
              </Input>
              {' '} entries
            </div>
          </div>
          <div className='pagination-right'>
            <span>Showing {fromRecord} to {toRecord} of {filteredRows.length} {filteredRows.length !== 1 ? 'records' : 'record'}</span>
          </div>
        </div>
      )}

      {displayPagination && (
        <div className='pagination-bottom'>
          {filteredRows.length !== 0 && (
            <div className='pagination-buttons'>
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <i className='far fa-arrow-to-left fa-lg'></i>
              </button>
              {state.pageIndex + 1 === 1
                ? null
                : (
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {state.pageIndex}
                  </button>
                  )
              }

              <span className='pagination-page-num '>
                {state.pageIndex + 1}
                {/* Page{' '} {state.pageIndex + 1} of {pageOptions.length} */}
              </span>

              {state.pageIndex + 1 === pageOptions.length
                ? null
                : (
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {state.pageIndex + 2}
                  </button>
                  )
              }
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <i className='far fa-arrow-to-right fa-lg'></i>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Datatable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  newRowBtn: PropTypes.object,
  displayPagination: PropTypes.bool,
  displayRecordCount: PropTypes.bool,
  displaySearch: PropTypes.bool,
  groupField: PropTypes.string,
  loading: PropTypes.bool,
  sortDesc: PropTypes.bool
}

Datatable.defaultProps = {
  displayPagination: true,
  displaySearch: true,
  displayRecordCount: true,
  sortDesc: true
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  filteredRows: PropTypes.array
}

SelectColumnFilter.propTypes = {
  column: PropTypes.object,
  filterValue: PropTypes.string,
  setFilter: PropTypes.func,
  preFilteredRows: PropTypes.array,
  id: PropTypes.string
}

export default Datatable
