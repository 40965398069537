import React, { useState, useEffect } from 'react'
import { Input } from '@shared/Form'
import PropTypes from 'prop-types'
import updateUser from '@graphql/mutators/update-user'
import Swal from 'sweetalert2'

const SiteSwitcher = ({ user }) => {
  const [userClients, setUserClients] = useState([])

  useEffect(() => {
    const clients = user.userClients.map(v => ({ clientId: v?.clientId, name: v?.client?.name }))
    const clientList = clients
    setUserClients(clientList.sort((a, b) => a.name.localeCompare(b.name)))
  }, [])
  if (userClients?.length < 2) {
    return (
      <div className='client-name-container'>
        <p className='client-name'>{user.client.name}</p>
      </div>
    )
  }

  const handleSiteChange = (v) => {
    if (v !== user.clientId) {
      const input = {
        id: user.id,
        clientId: v
      }
      // COMMENTED FOR TASK EFA-267~
      const newClientUrl = user.userClients?.find(uc => uc.clientId === v)?.client.clientUrl
      localStorage.setItem('siteSwitching', true)

      updateUser(input).then(({ data }) => {
        if (data.updateUser) {
          localStorage.removeItem('tabNavs')
          localStorage.removeItem('filter-storage')
          Swal.fire({
            text: 'Switching to ' + data.updateUser.client.name,
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          }).then(() => {
            // window.location.href = '/'
            // COMMENTED FOR TASK EFA-267
            localStorage.removeItem('siteSwitching')
            if (!window.location.href.includes('localhost')) {
              window.location.href = 'https://' + newClientUrl
            } else {
              localStorage.setItem('devUrl', newClientUrl)
              window.location.href = '/'
            }
          })
        }
      })
    }
  }

  return (
    <div>
      <Input
          className='site-switcher-input'
          type='select'
          placeholder={user.client.name}
          options={userClients?.map(u => ({ text: u.name, value: u.clientId }))}
          value={user.client.id}
          onChange={(v) => handleSiteChange(v)}
      />
    </div>
  )
}

SiteSwitcher.propTypes = {
  user: PropTypes.object
}

export default SiteSwitcher
