import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import { Title, Button, Link } from '@lib'
import client from '@graphql/client'
import { Form, Input, Submit, FormError } from '@shared/Form'
import { object, array } from '@utils'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useQuery } from '@apollo/client'

import clientCustomerGql from '@graphql/queries/client-customer'
import customerReferencesGql from '@graphql/queries/customer-references'
import createClientCustomer from '@graphql/mutators/create-client-customer'
import updateClientCustomer from '@graphql/mutators/update-client-customer'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({})

  const tabs = {
    '#customer-basics': 0,
    '#key-metrics': 1,
    '#other-info': 2
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const { hash } = useLocation()
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)
  const handleTabChange = (tab) => {
    window.history.pushState(null, null, tab)
    setTabIndex(identifyActiveTab(tab))
  }

  const customerReferencesQuery = useQuery(customerReferencesGql)

  useEffect(() => {
    if (loading) {
      if (id) {
        client.query({
          query: clientCustomerGql,
          variables: {
            id
          }
        }).then(({ data }) => {
          setInput({ ...data.clientCustomer })
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
  }, [loading])
  if (loading) {
    return null
  }

  if (customerReferencesQuery.loading) {
    return null
  }

  const clientTypes = []
  const industries = []
  const pbms = []
  const formularies = []
  const customerRefs = customerReferencesQuery.data.customerReferences
  customerRefs.forEach(ref => {
    switch (ref.group.value) {
      case 'Client Type':
        clientTypes.push(ref)
        break
      case 'Client Industry':
        industries.push(ref)
        break
      case 'Client PBM':
        pbms.push(ref)
        break
      case 'Client Formulary':
        formularies.push(ref)
        break
    }
  })

  const handleInputChange = (name, v) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const updateObj = { [name]: v }
    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}
    if (!input.name) {
      feErrors.name = [{ message: 'Customer name is required' }]
    }
    if (!input.startDate) {
      feErrors.startDate = [{ message: 'Start date is required' }]
    }
    if (!input.clientHq) {
      feErrors.clientHq = [{ message: 'Client HQ is required' }]
    }
    if (!input.clientTypeId) {
      feErrors.clientTypeId = [{ message: 'Client type is required' }]
    }
    if (!input.industryTypeId) {
      feErrors.industryTypeId = [{ message: 'Industry is required' }]
    }
    if (!input.pbmId) {
      feErrors.pbmId = [{ message: 'PBM is required' }]
    }
    if (!input.totalEmployees?.toString()) {
      feErrors.totalEmployees = [{ message: 'Total employees is required' }]
    }
    if (!input.totalMembers?.toString()) {
      feErrors.totalMembers = [{ message: 'Total members is required' }]
    }
    if (!input.totalAnnualRebates?.toString()) {
      feErrors.totalAnnualRebates = [{ message: 'Total annual rebates is required' }]
    }
    if (!input.formularyId) {
      feErrors.formularyId = [{ message: 'Formulary is required' }]
    }

    input.clientId = user.clientId

    Object.keys(input).forEach(key => {
      if (typeof input[key] === 'number') {
        input[key] = input[key].toString()
      }
    })

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))
      if (id) {
        // UPDATE
        updateClientCustomer(input).then(({ data, extensions }) => {
          if (data.updateClientCustomer) {
            setInputErrors({})
            Swal.fire({
              text: 'Client customer successfully updated',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
            navigate('/settings/client-customers')
          } else {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      } else {
        // CREATE
        createClientCustomer(input).then(({ data, extensions }) => {
          if (data.createClientCustomer) {
            setInputErrors({})
            Swal.fire({
              text: 'Client customer successfully created',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
            navigate('/settings/client-customers')
          } else {
            setLoaderState(prevState => ({ ...prevState, submit: false }))
            setInputErrors(array.groupBy(extensions.errors, 'path'))
          }
        })
      }
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  return (
    <Fragment>
      <Title text={(id ? 'Edit Client Customer' : 'New Client Customer')} user={user}/>

        <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList className='tab-navs'>
            <Tab>
              <Link
                to='#customer-basics'
                onClick={() => { setTabIndex(identifyActiveTab('#customer-basics')) }}
              >
                CUSTOMER BASICS
              </Link>
            </Tab>
            <Tab>
              <Link
                to='#key-metrics'
                onClick={() => { setTabIndex(identifyActiveTab('#key-metrics')) }}
              >
                KEY METRICS
              </Link>
            </Tab>
            <Tab>
              <Link
                to='#other-info'
                onClick={() => { setTabIndex(identifyActiveTab('#other-info')) }}
              >
                OTHER INFO
              </Link>
            </Tab>
          </TabList>

          <Form className='new-client-customer-form' onSubmit={handleSave}>
            <FormError errors={inputErrors} />

            <TabPanel>
              <Input
                label='Customer Name'
                type='text'
                value={input.name}
                onChange={(v) => handleInputChange('name', v)}
                errors={inputErrors.name}
                required
              />
              <Input
                label='Start Date'
                type='datepicker'
                className='icon-right'
                icon='fas fa-calendar'
                value={input.startDate}
                onChange={(v) => handleInputChange('startDate', v)}
                errors={inputErrors.startDate}
                required
              />
              <Input
                label='Client HQ'
                type='text'
                value={input.clientHq}
                onChange={(v) => handleInputChange('clientHq', v)}
                errors={inputErrors.clientHq}
                required
              />
              <Input
                label='Client Type'
                type='select'
                placeholder='Select Client Type'
                options={clientTypes.map(opt => ({ value: opt.id, text: opt.value }))}
                value={input.clientTypeId}
                onChange={(v) => handleInputChange('clientTypeId', v)}
                errors={inputErrors.clientTypeId}
                required
              />
              <Input
                label='Industry'
                type='select'
                placeholder='Select Industry'
                options={industries.map(opt => ({ value: opt.id, text: opt.value }))}
                value={input.industryTypeId}
                onChange={(v) => handleInputChange('industryTypeId', v)}
                errors={inputErrors.industryTypeId}
                required
              />
              <Input
                label='Current PBM'
                type='select'
                placeholder='Select Client PBM'
                options={pbms.map(opt => ({ value: opt.id, text: opt.value }))}
                value={input.pbmId}
                onChange={(v) => handleInputChange('pbmId', v)}
                errors={inputErrors.pbmId}
                required
              />
              <Submit className='right'>
                <Button
                  className='secondary icon-right'
                  icon='far fa-angle-right'
                  iconAlign='right'
                  text='KEY METRICS'
                  onClick={() => handleTabChange('#key-metrics')}
                />
              </Submit>
            </TabPanel>
            <TabPanel>
              <Input
                label='Total Employees'
                type='number'
                value={input.totalEmployees?.toString()}
                onChange={(v) => handleInputChange('totalEmployees', v)}
                errors={inputErrors.totalEmployees}
                required
              />
              <Input
                label='Total Members'
                type='number'
                value={input.totalMembers?.toString()}
                onChange={(v) => handleInputChange('totalMembers', v)}
                errors={inputErrors.totalMembers}
                required
              />
              <Input
                label='Total Annual Rebates'
                type='number'
                value={input.totalAnnualRebates?.toString()}
                onChange={(v) => handleInputChange('totalAnnualRebates', v)}
                errors={inputErrors.totalAnnualRebates}
                required
              />
              <Submit className='space-between'>
                <Button
                  className='secondary icon-left'
                  icon='far fa-angle-left'
                  iconAlign='left'
                  text='CUSTOMER BASICS'
                  onClick={() => handleTabChange('#customer-basics')}
                />
                <Button
                  className='secondary icon-right'
                  icon='far fa-angle-right'
                  iconAlign='right'
                  text='OTHER INFO'
                  onClick={() => handleTabChange('#other-info')}
                />
              </Submit>
            </TabPanel>
            <TabPanel>
              <Input
                label='Formulary'
                type='select'
                placeholder='Select Formulary'
                options={formularies.map(opt => ({ value: opt.id, text: opt.value }))}
                value={input.formularyId}
                onChange={(v) => handleInputChange('formularyId', v)}
                errors={inputErrors.formularyId}
                required
              />
              <Input
                label='Salesperson'
                type='text'
                value={input.salesperson}
                onChange={(v) => handleInputChange('salesperson', v)}
                errors={inputErrors.salesperson}
              />
              <Input
                label='Other Information'
                type='textarea'
                value={input.otherInfo}
                onChange={(v) => handleInputChange('otherInfo', v)}
              />
              <Submit className='space-between'>
                <Button
                  className='secondary icon-left'
                  icon='far fa-angle-left'
                  iconAlign='left'
                  text='KEY METRICS'
                  onClick={() => handleTabChange('#key-metrics')}
                />
                <Button
                  className='primary icon-left'
                  icon={id ? 'far fa-user-edit' : 'far fa-user-plus'}
                  type='submit'
                  text={id ? 'Save Customer' : 'Add Customer'}
                  loading={loaderState.submit}
                />
              </Submit>
            </TabPanel>
          </Form>
        </Tabs>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const NewClientCustomer = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewClientCustomer
