import client from '../client.js'
import updateUserNoticeGql from '../mutations/update-user-notice.gql'

const updateUserNotice = (input) => {
  return client.mutate({
    mutation: updateUserNoticeGql,
    variables: input
  })
}

export default updateUserNotice
