import client from '../client.js'

import masterReferenceGql from '../queries/master-reference.gql'
import updateMasterReferenceGql from '../mutations/update-master-reference.gql'

const updateMasterReference = (input) => {
  return client.mutate({
    mutation: updateMasterReferenceGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateMasterReference) {
        const variables = {
          id: input.id
        }

        try {
          // update masterReference cache
          store.writeQuery({
            query: masterReferenceGql,
            variables,
            data: {
              masterReference: data.updateMasterReference
            }
          })
        } catch (error) {
          // re-query masterReference
          client.query({
            query: masterReferenceGql,
            variables
          })
        }
      }
    }
  })
}

export default updateMasterReference
