import client from '../client.js'
import createSubscriptionGql from '../mutations/create-subscription.gql'

const createSubscription = (input) => {
  return client.mutate({
    mutation: createSubscriptionGql,
    variables: input
  })
}

export default createSubscription
