import React, { useEffect, useState, useRef } from 'react'
import { Input } from '@shared/Form'
import { Button, Tooltip, Icon } from '@lib'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { Icon as Iconify } from '@iconify/react'

// dummy data used to view dropdowns and select options, will be removed when dropdowns are linked with tableau
const dummyData = [
  {
    value: 1,
    text: 'Biosmilar',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 2,
    text: 'Cardholder ID',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 3,
    text: 'Drug Class',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 4,
    text: 'Drug Group',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 5,
    text: 'Drug Label Name',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 6,
    text: 'Drug Subclass',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 7,
    text: 'Generic Indicator',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 8,
    text: 'GPI ID',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 9,
    text: 'Most Common Indication',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 10,
    text: 'NDC',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 11,
    text: 'PBM Multisource Code',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 12,
    text: 'PBM Reference',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 13,
    text: 'Pharmacy Chain Name',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 14,
    text: 'Pharmacy Name',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 15,
    text: 'Pharmacy NCPDP',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 16,
    text: 'Pharmacy NPI',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 17,
    text: 'Pharmacy Full Name',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 18,
    text: 'Rx OTC Indicator',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  },
  {
    value: 19,
    text: 'Specialty Indicator',
    data: [
      {
        value: 1,
        text: 'Option 1'
      },
      {
        value: 2,
        text: 'Option 2'
      }
    ]
  }
]

const DashboardFilter = ({ clearAllFilters }) => {
  const [input, setInput] = useState({
    filter: [],
    selectedFilters: []
  })

  const [collapsed, setCollapsed] = useState(false)
  const [filterCollapse, setFilterCollapse] = useState(false)
  const [disableApply, setDisableApply] = useState(true)
  const [disableCancel, setDisableCancel] = useState(true)
  const ref = useRef()

  useEffect(() => {
    const handleDropdownClose = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setFilterCollapse(false)
      }
    }
    document.addEventListener('mousedown', handleDropdownClose)
  }, [ref])

  useEffect(() => {
    if (input.filter?.length !== 0) {
      setDisableCancel(false)
    } else {
      setDisableCancel(true)
    }

    for (const key in input) {
      if (key !== 'filter' && key !== 'selectedFilters') {
        if (input[key].length !== 0) {
          setDisableApply(false)
        }
      } else {
        setDisableApply(true)
      }
    }
  }, [input])

  useEffect(() => {
    if (collapsed) {
      document.querySelector('.dashboard-filter-container').classList.add('dashboard-collapse-height')
      document.querySelector('.dashboard-filter-content').classList.add('dashboard-hide-content')
    } else {
      document.querySelector('.dashboard-filter-container').classList.remove('dashboard-collapse-height')
      document.querySelector('.dashboard-filter-content').classList.remove('dashboard-hide-content')
    }
  }, [collapsed])

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const handleInputChange = (name, value) => {
    const newInput = { ...input }
    newInput[name] = value
    setInput(newInput)

    if (name === 'filter') {
      const arrayFilter = []
      for (let i = 0; i < value.length; i++) {
        const data = dummyData.find((element) => element.value === value[i])
        arrayFilter.push(data)
      }

      const newInputFilter = { ...input }
      arrayFilter.sort((a, b) => {
        const filterNameA = a.text
        const filterNameB = b.text
        if (filterNameA < filterNameB) {
          return -1
        }
        if (filterNameA > filterNameB) {
          return 1
        }
        return 0
      })
      newInputFilter['selectedFilters'] = arrayFilter
      newInputFilter['filter'] = value
      setInput(newInputFilter)

      if (collapsed === false) {
        setCollapsed(true)
      }
    }
  }

  useEffect(() => {
    if (clearAllFilters === true) {
      setInput({
        filter: [],
        selectedFilters: []
      })
    }
  }, [clearAllFilters])

  const handleApply = () => {
    // function to handle apply
  }

  const handleInputClear = (name) => {
    const newInput = { ...input }
    newInput[name] = []
    setInput(newInput)
  }

  const handleClearAll = () => {
    Swal.fire({
      text: 'Do you want to clear all filters?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Clear all',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        for (const key in input) {
          if (key !== 'filter' && key !== 'selectedFilters') {
            delete input[key]
          }
        }
        const clearInput = { ...input }
        clearInput['filter'] = []
        clearInput['selectedFilters'] = []
        setInput(clearInput)
      }
    })
  }

  const handleInputDelete = (name, value) => {
    delete input[name]
    const newInput = { ...input }
    newInput['selectedFilters'] = newInput['selectedFilters'].filter((data) => data.text !== name)
    newInput['filter'] = newInput['filter'].filter((data) => data !== value)
    setInput(newInput)
  }

  return (
    <>
        <div className='dashboard-filter-container'>
          <div className='dashboard-filter-header'>
            <div className='dashboard-filter-additional-container'>
              <span className='dashboard-filter-caret' onClick={() => handleCollapse()} > {collapsed ? <Iconify icon='ph:caret-circle-up-light' /> : <Iconify icon='ph:caret-circle-down-light' /> } </span>
              <p>Additional Filters</p>
              <span id='question' className='dashboard-filter-question-icon far fa-question-circle'></span>
              <Tooltip
                content='Question Mark'
                anchorId='question'
              />
            </div>

            <div className='dashboard-filter-input-container'>
              <Input
                placeholder='Add filters'
                hasSelectAll={true}
                searchPlaceholder='Search filters'
                type='multiselect'
                filterCollapse={filterCollapse}
                value={input.filter}
                multipleValueText={true}
                onChange={(e) => handleInputChange('filter', e)}
                options={dummyData?.map(v => ({ value: v.value, text: v.text }))}
              />
              <Button onClick={() => handleApply()} disabled={disableApply} className='primary' text='APPLY'/>
              <Button onClick={() => handleClearAll()} disabled={disableCancel} className='secondary' text='CLEAR ALL'/>
            </div>
          </div>

          <div className='dashboard-filter-content'>
            <div className='dashboard-filter-grid'>

          {input.selectedFilters?.map((filter, i) => {
            return (
                <div className='dashboard-filter-grid-item' key={i}>
                  <div onClick={(v) => handleInputDelete(filter.text, filter.value)} id={`delete-input-${i}`} className='delete-icon-container'>
                    <Icon
                      className = 'remove-icon'
                      iconify={true}
                      icon='gg:remove'
                    />
                  </div>

                  <Tooltip
                    anchorId={`delete-input-${i}`}
                    content='Delete Filter'
                  />

                  <Input
                    label={`${filter?.text}`}
                    placeholder= {filter?.text.length > 10 ? `Search ${filter?.text.substring(0, 10)}...` : `Search ${filter?.text}` }
                    searchPlaceholder= {`Search ${filter?.text}`}
                    type='multiselect'
                    hasSelectAll={true}
                    value={input[filter?.text]}
                    multipleValueText={true}
                    onChange={(e) => handleInputChange(filter?.text, e)}
                    options={filter.data?.map(v => ({ value: v.value, text: v.text }))}
                  />

                  {(input[filter?.text]?.length !== 0 && input[filter?.text]?.length !== undefined) &&
                  <>
                    <div className='clear-icon-container' id={`clear-input-${i}`} onClick={(v) => handleInputClear(filter.text)}>
                      <Icon
                        className='fas fa-times'
                      />
                    </div>

                    <Tooltip
                      anchorId={`clear-input-${i}`}
                      content='Clear Filter'
                    />
                    </>
                  }
                </div>
            )
          })}

              <div className='dashboard-filter-grid-item dashboard-add-filter-container'>
                <button onClick={() => setFilterCollapse(!filterCollapse)} className='dashboard-filter-add-button'><span className='fas fa-plus'></span> Add filter</button>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

DashboardFilter.propTypes = {
  clearAllFilters: PropTypes.bool
}

export default DashboardFilter
