import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import { Title, Button } from '@lib'
import client from '@graphql/client'
import { Form, Input, Submit, FormError } from '@shared/Form'
import { object, axiosGet } from '@utils'
import Swal from 'sweetalert2'
import PropTypes from 'prop-types'
import axios from 'axios'
import config from '@config'

import userGroupGql from '@graphql/queries/user-group'
import createUserGroup from '@graphql/mutators/create-user-group'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { id, userGroupId } = useParams()
  const [loading, setLoading] = useState(true)
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    name: null
  })

  useEffect(() => {
    if (loading) {
      if (userGroupId) {
        client.query({
          query: userGroupGql,
          variables: {
            id: userGroupId,
            fetchPolicy: 'network-only'
          }
        }).then(({ data }) => {
          setInput(prevState => ({
            ...prevState,
            name: data.userGroup.name,
            logoPath: data.userGroup.logoPath,
            image: (data.userGroup.logoPath && { name: data.userGroup.logoPath })
          }))
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }
  }, [loading])

  if (loading) {
    return null
  }

  const handleInputChange = (name, v) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)

    const updateObj = { [name]: v }
    if (name === 'image') {
      updateObj.logoPath = undefined
    }

    setInput(prevState => ({
      ...prevState,
      ...updateObj
    }))
  }

  const handleSave = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    const feErrors = {}
    if (!input.name) {
      feErrors.name = [{ message: 'user group name is required' }]
    }

    const imagetype = input.image?.type
    if (imagetype && !['image/jpeg', 'image/png'].includes(imagetype)) {
      feErrors.imagetype = [{ message: 'Thumbnail must be of type .png, .jpg, .jpeg' }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      const newInput = { ...input }
      newInput.clientId = id
      newInput.name = input.name
      if (userGroupId) {
        newInput.id = userGroupId
      }
      if (input.image) {
        newInput.image = JSON.stringify({
          originalname: input.image.name,
          mimetype: input.image.type,
          size: input.image.size
        })
      }
      createUserGroup(newInput).then(async response => {
        Swal.fire({
          text: userGroupId ? 'User group updated' : 'User group created',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000
        })

        if (input.image instanceof File) {
          let bucketEnv = 'dev'
          if (config.APP_ENV === 'prod' || config.APP_ENV === 'production') {
            bucketEnv = 'prod'
          } else if (config.APP_ENV === 'staging' || config.APP_ENV === 'stage') {
            bucketEnv = 'stage'
          }
          const bucket = `xevant-${bucketEnv}-alcatraz-be-uploads-us-west-2`
          const key = encodeURIComponent('groupLogos/' + response.data.createUserGroup.logoPath?.replace(/^\//, ''))
          await axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
            const uploadUrl = e.data.url
            axios.put(uploadUrl, input.image)
            navigate(`/settings/clients/${id}/user-groups`)
          })
        } else {
          navigate(`/settings/clients/${id}/user-groups`)
        }
      }).catch(err => {
        setLoaderState(prevState => ({ ...prevState, submit: false }))
        setInputErrors(err.data.error)
      })
    } else {
      setLoaderState(prevState => ({ ...prevState, submit: false }))
      setInputErrors(feErrors)
    }
  }

  return (
    <Fragment>
      <Title text={(userGroupId ? 'Edit User Group' : 'New User Group')} user={user}/>

      <Form className='new-user-group-form' onSubmit={handleSave}>
        <FormError errors={inputErrors} />

        <Input
          label='User Group Name'
          type='text'
          value={input.name}
          onChange={(v) => handleInputChange('name', v)}
          errors={inputErrors.name}
        />

        <Input
          label='Logo'
          type='draganddrop'
          value={input.image}
          onChange={(v) => handleInputChange('image', v.target.files[0])}
          accept='.png, .jpg, .jpeg'
          logoDirectory='group-logo'
          logoPath={input.logoPath}
          className='logo-upload'
          errors={inputErrors.image}
        />

        <Submit>
          <Button
            className='primary'
            type='submit'
            text={userGroupId ? 'Edit User Group' : 'Add User Group'}
            style={{ width: '100%' }}
            loading={loaderState.submit}
          />
        </Submit>
      </Form>
    </Fragment>
  )
}
Content.propTypes = {
  user: PropTypes.object
}

const NewUserGroup = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default NewUserGroup
