import client from '../client.js'
import watchRequestStatusGql from '../mutations/watch-request-status.gql'

const watchRequestStatus = (input) => {
  return client.mutate({
    mutation: watchRequestStatusGql,
    variables: input
  })
}

export default watchRequestStatus
