import client from '../client.js'
import createAlertGql from '../mutations/create-alert.gql'

const createAlert = (input) => {
  return client.mutate({
    mutation: createAlertGql,
    variables: input
  })
}

export default createAlert
