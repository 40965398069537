import client from '@graphql/client'
import tableauNavigationsDefaultDashboardGql from '@graphql/queries/tableau-navigations-default-dashboard'

const getDefaultDashboardPath = async (user) => {
  let homePath = '/dashboard/HomeScorecard/HomeScorecard'
  let waitForQuery = false

  if (user.systemRole.name === 'System Admin') {
    homePath = '/settings'
    return homePath
  }

  if (user.clientId && user.userClients.filter(u => u.clientId === user.clientId)) {
    const activeUserClient = user.userClients.filter(u => u.clientId === user.clientId)[0]
    if (activeUserClient.navigation) {
      const userClientNav = JSON.parse(activeUserClient.navigation).find(n => n.name === 'Xevant')
      if (user.homepage) {
        const execDashboardProject = userClientNav.children?.find(c => c.name?.toLowerCase() === 'executive dashboard')

        for (const workbook of execDashboardProject?.workbooks) {
          const homepageWb = workbook.contentUrl.includes(user.homepage) ? workbook : null

          if (homepageWb) {
            homePath = `/dashboard/${homepageWb.contentUrl}/${homepageWb.defaultView.viewUrlName}`
          }
        }
      } else if (userClientNav?.children.find(c => c.name === 'Executive Dashboard')) {
        const execDashboardWb = userClientNav?.children.find(c => c.name === 'Executive Dashboard')?.workbooks.find(w => w.name.includes('Executive Dashboard'))
        homePath = `/dashboard/${execDashboardWb.contentUrl}/${execDashboardWb.defaultView.viewUrlName}`
      } else if (userClientNav?.workbooks.find(w => w.name.includes('Scorecard'))) {
        const homeScorecardWb = userClientNav.workbooks.find(w => w.name.includes('Scorecard'))
        homePath = `/dashboard/${homeScorecardWb.contentUrl}/${homeScorecardWb.defaultView.viewUrlName}`
      } else {
        const rebateChild = userClientNav?.children.find(c => c.name === 'RebateLogic')
        if (rebateChild) {
          const rebateWb = rebateChild.workbooks.find(w => w.name === 'RebateLogic Proposal') || rebateChild.workbooks[0]
          homePath = `/dashboard/${rebateWb.contentUrl}/${rebateWb.defaultView.viewUrlName}`
        } else if (userClientNav?.children.find(c => c.name === 'ScripLogic')) {
          const slWb = userClientNav?.children.find(c => c.name === 'ScripLogic')?.workbooks.find(w => w.name.includes('Scorecard'))
          homePath = `/dashboard/${slWb.contentUrl}/${slWb.defaultView.viewUrlName}`
        } else {
          // console.log('no rebatelogic?')
          waitForQuery = true
        }
      }
    } else {
      // console.log('no userclient navigation?')
      waitForQuery = true
    }
  } else {
    // console.log('no userclients?')
    waitForQuery = true
  }

  if (waitForQuery) {
    let defaultProject
    if (user.client.clientModules?.find(cm => cm.module.name === 'Executive Dashboard')) {
      defaultProject = 'Executive Dashboard'
    } else if (user.client.clientModules?.find(cm => cm.module.name === 'Home Scorecard')) {
      defaultProject = 'Home Scorecard'
    } else if (user.client.clientModules?.find(cm => cm.module.name === 'RebateLogic')) {
      defaultProject = 'RebateLogic'
    } else if (user.client.clientModules?.find(cm => cm.module.name === 'ScripLogic')) {
      defaultProject = 'ScripLogic'
    } else {
      defaultProject = user.client.clientModules?.[0].module.name
    }

    await client.query({
      query: tableauNavigationsDefaultDashboardGql,
      variables: {
        defaultProject
      }
    }).then(({ data }) => {
      const defaultDashboard = JSON.parse(data.tableauNavigationsDefaultDashboard)
      homePath = `/dashboard/${defaultDashboard.contentUrl}/${defaultDashboard.defaultView.viewUrlName}`
    })
  }

  return homePath
}

export default getDefaultDashboardPath
