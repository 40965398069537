import client from '../client.js'
import callDlEndpointGql from '../mutations/call-dl-endpoint.gql'

const callDlEndpoint = (input) => {
  return client.mutate({
    mutation: callDlEndpointGql,
    variables: input
  })
}

export default callDlEndpoint
