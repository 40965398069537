import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { Icon as Iconify } from '@iconify/react'
import client from '@graphql/client'
import { cookie } from '@utils'

import destroySession from '@graphql/mutators/destroy-session'

const UserBox = ({ user }) => {
  const userInitials = user.fullName.split(' ')[0].substring(0, 1) + user.fullName.split(' ')[1].substring(0, 1)

  const navigate = useNavigate()

  const handleLogout = () => {
    destroySession().then(({ data, extensions }) => {
      if (data.destroySession) {
        cookie.set('csrfCookie', 0, -1)
        localStorage.clear()
        navigate('/login', { replace: true })
      }
    })
  }

  return (
    <>
      <div className='user-box-contain'>
        <div className='user-box-popup'>
          <div className='user-box-circle'>
            <div>{userInitials}</div>
          </div>
          <p className='user-name'>{user.fullName}</p>
          <p className='user-email'>{user.email}</p>
          <div className='user-role'><p>{user.systemRole?.name}</p></div>
          <ul>
            <li><a href='/change-password'><Iconify width='20px' icon='teenyicons:password-solid' /><span>Change Password</span></a></li>
            <li><a onClick={handleLogout}><Iconify icon='ion:log-out-sharp' /><span>Logout</span></a></li>
          </ul>
        </div>
      </div>
    </>
  )
}

UserBox.propTypes = {
  user: PropTypes.object
}

export default UserBox
