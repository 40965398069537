import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form, Group, Input, FormError } from '@shared/Form'
import { Button } from '@lib'
import { array, triggerInputChange, validateEmail, validateNumber } from '@utils'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import * as tableau from '@utils/tableau-embed-api.js'
import Modal from '@lib/Modal'

import client from '@graphql/client'
import createAlert from '@graphql/mutators/create-alert'
import updateAlert from '@graphql/mutators/update-alert'
import alertConditionsGql from '@graphql/queries/alert-conditions'
import alertCompareGql from '@graphql/queries/alert-compare'
import alertConfigGql from '@graphql/queries/alert-config'
import trustedTicketGql from '@graphql/queries/trusted-ticket'
import tableauServerSettingGql from '@graphql/queries/tableau-server-setting'
import Swal from 'sweetalert2'
import config from '@config'

const { TableauViz, TableauEventType } = tableau
let tableauServer = null

const FormHeader = ({ formPage, triggerGetFromFilters = () => {}, fromTabValuesFlag = false, loading }) => {
  const nonAlertLogicPages = !(window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts'))
  return (
    <>
      <div className={`divider ${formPage === 2 || nonAlertLogicPages ? 'page-2' : ''}`}>
        <span>{formPage === 1 ? 'Alert Filters' : 'Alert Rules'} </span>
        <hr></hr>
      </div>
      { nonAlertLogicPages
        ? ''
        : (formPage === 1 &&
          <div className='toggle-alert-form'>
            <Input
              type='toggle'
              value={fromTabValuesFlag}
              onChange={(e) => { triggerGetFromFilters(e) }}
              disabled={loading.reportlevelname}
            />
            <span>Apply selected filters</span>
          </div>
          )
      }
    </>)
}

const FormPart1 = ({ formPage, handleInputChange, formValue, inputError, workbookFilterOptions, loading, fromTabValuesFlag, selectedAllReportLevelName, triggerGetFromFilters, reportLevelNameOptionsCache }) => {
  if (formPage === 2 || Object.keys(workbookFilterOptions).length === 0) {
    return null
  }

  const reportLevelNamePlaceholder = formValue.reportLevelName.length > 0 ? '' : loading.reportlevelname ? '' : 'Select Report Level Name'

  return (
    <div className='alert-filters-form'>
    <Form>
      <FormError errors={inputError}/>
      <FormHeader
        formPage={formPage}
        triggerGetFromFilters={triggerGetFromFilters}
        fromTabValuesFlag={fromTabValuesFlag}
        loading={loading}
      />
        <Group>
          <Input
            label='Report Level'
            type='select'
            options={workbookFilterOptions?.reportLevelOptions}
            placeholder={formValue.reportLevel ? workbookFilterOptions.reportLevelOptions.find(r => r.text === formValue.reportLevel)?.text : loading.options ? '' : 'Select Report Level'}
            value={formValue.reportLevel}
            onChange={(v) => handleInputChange('reportLevel', v)}
            errors={inputError?.reportLevel}
            loading={loading.options}
            disabled={fromTabValuesFlag && formValue.reportLevel.length}
          />
        </Group>
        <Group>
          <Input
            label='Report Level Name'
            type='multiselect'
            placeholder={reportLevelNamePlaceholder}
            options={ fromTabValuesFlag ? reportLevelNameOptionsCache : workbookFilterOptions?.reportLevelNameOptions}
            value={formValue.reportLevelName}
            onChange={(e) => handleInputChange('reportLevelName', e)}
            errors={inputError?.reportLevelName}
            selectedAll={selectedAllReportLevelName}
            hasSelectAll={true}
            loading={loading.options || loading.reportlevelname}
            disabled={!formValue.reportLevel || fromTabValuesFlag}
          />
        </Group>
        <Group>
          <Input
            label='Interval'
            type='select'
            placeholder={formValue.interval ? workbookFilterOptions.intervalOptions.find(r => r.text === formValue.interval)?.text : loading.options ? '' : 'Select Interval'}
            options={workbookFilterOptions?.intervalOptions}
            value={formValue.interval}
            onChange={(e) => handleInputChange('interval', e)}
            errors={inputError?.interval}
            loading={loading.options}
            disabled={fromTabValuesFlag && formValue.interval.length}
          />
        </Group>
        <Group>
          <Input
            label='Key Performance Indicator (KPI)'
            type='select'
            options={workbookFilterOptions.kpiOptions}
            placeholder={formValue.keyPerformanceIndicator ? workbookFilterOptions.kpiOptions.find(r => r.text === formValue.keyPerformanceIndicator)?.text : loading.options ? '' : 'Select Key Performance Indicator'}
            value={formValue?.keyPerformanceIndicator}
            onChange={(e) => handleInputChange('keyPerformanceIndicator', e)}
            errors={inputError?.keyPerformanceIndicator}
            loading={loading.options}
          />
        </Group>
    </Form>
  </div>
  )
}
const FormPart2 = ({ formPage, handleInputChange, formValue, inputError, workbookFilterOptions, usePrevValue, handleUseValueSelect, alert, setInputError }) => {
  const [customMessage, setCustomMessage] = useState(false)

  const handleFakeTextarea = (e) => {
    const v = e.target.value.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    const emailsToValidate = v.split(/,\s*/)
    let emailErrs = false
    let newV = v
    emailsToValidate.forEach(email => {
      if (email) {
        if (validateEmail(email.trim()).ok) {
          newV = newV.replace(email.trim(), '<span class="valid-email">' + email.trim() + '</span>')
        } else {
          emailErrs = true
        }
      }
    })
    document.querySelector('.member-fake-textarea').innerHTML = newV.replace(/\s(?!class)/g, '&nbsp;<wbr>')
    if (/(.com|.net|.gov)$/g.test(v) || /,\s*$/.test(v)) {
      if (emailErrs) {
        setInputError({ memberEmails: [{ message: 'Invalid email format entered.' }] })
      } else {
        setInputError({})
      }
    }
  }

  useEffect(() => {
    // add input scroll listeners
    if (formPage === 2) {
      const syncTextareas = []
      syncTextareas.push(document.querySelector('.member-fake-textarea'))
      syncTextareas.push(document.querySelector('.with-fake > textarea'))
      syncTextareas.forEach(st => st.addEventListener('scroll', () => {
        syncTextareas.forEach(t => {
          t.scrollTop = st.scrollTop
          t.scrollLeft = st.scrollLeft
        })
      }))
    }
  }, [formPage])

  useEffect(() => {
    if (formPage === 2) {
      handleFakeTextarea({ target: { value: formValue.memberEmails } })
    }
  }, [formPage])

  const formOptions = useMemo(() => {
    const options = {}
    Object.keys(workbookFilterOptions).forEach(op => {
      options[op] = workbookFilterOptions[op].map(option => { return ({ text: option.value, value: option.value }) })
    })
    return options
  }, [workbookFilterOptions])

  if (formPage === 1) {
    return null
  }

  const generatePrompt = () => {
    let names = ''
    if (formValue.reportLevelName.length > 0) {
      if (formValue.reportLevelName.length > 3) {
        names = formValue.reportLevelName.slice(0, 3).join(', ') + ', ...\'s'
      } else {
        names = formValue.reportLevelName.join(', ') + '\'s'
      }
    }

    const condition = formValue.condition ? (<span>{formValue.condition}</span>) : ''
    const value = formValue.conditionValue ? (<span>{formValue.conditionValue}</span>) : formValue.conditionValueVariance ? (<span>{formValue.conditionValueVariance} % variance</span>) : ''
    return (
        <Group>
          <div className='alert-prompt'>
            Send alert if <span>{names}</span> <span>{formValue.keyPerformanceIndicator}</span> {condition ? 'is' : ''} {condition} {value}
          </div>
        </Group>
    )
  }

  const handleRadioVariance = (e) => {
    handleInputChange('conditionValue', '')
    handleInputChange('isMeasureValue', false)
  }

  const hanldeRadioMeasureValue = (e) => {
    handleInputChange('conditionValueVariance', '')
    handleInputChange('isMeasureValue', true)
  }

  return (
    <div className='alert-rules-form'>
      <Form>
        <FormError errors={inputError} />
        <FormHeader
          formPage={formPage}
        />
        <Group>
          <Input
            type='text'
            label='Alert Name'
            value={formValue.name}
            onChange={(e) => handleInputChange('name', e)}
            errors={inputError?.name}
          />
        </Group>
        {generatePrompt()}
        <Group>
          <Input
            type='select'
            label='Condition'
            options={formOptions.conditionOptions}
            placeholder={formValue.condition ? formOptions.conditionOptions.find(r => r.text === formValue.condition).text : 'Select Condition'}
            value={formValue.condition}
            onChange={(e) => handleInputChange('condition', e)}
            errors={inputError?.condition}
          />
        </Group>
        <Group className='radio-group'>
            <div className='radio-label'>
              <input
                type='radio'
                name='isMeasureValue'
                id='isMeasureValueButton'
                checked={formValue.isMeasureValue === true}
                className={`${formValue.condition ? '' : 'disabled'} ${inputError?.isMeasureValue ? 'red-border' : ''}`}
                onChange={(e) => { hanldeRadioMeasureValue(e) }}
                disabled={!formValue.condition}
              />
              <label className={`${formValue.isMeasureValue === false || formValue.isMeasureValue === null ? 'unchecked' : ''} ${formValue.condition ? '' : 'disabled'} `} htmlFor='isMeasureValueButton'>Target Value</label>
            </div>
            <div className='radio-label'>
              <input
                type='radio'
                name='isMeasureValue'
                className={`${formValue.condition ? '' : 'disabled'} ${inputError?.isMeasureValue ? 'red-border' : ''}`}
                checked={formValue.isMeasureValue === false}
                onChange={(e) => { handleRadioVariance(e) }}
                id='isVarianceButton'
                disabled={!formValue.condition}
              />
              <label className={`${formValue.isMeasureValue === true || formValue.isMeasureValue === null ? 'unchecked' : ''} ${formValue.condition ? '' : 'disabled'} `} htmlFor='isVarianceButton'>Variance</label>
            </div>
        </Group>
        <Group>
            <Input
              type='text'
              placeholder='Enter value'
              value={formValue.conditionValue}
              className={!formValue.condition ? 'disabled' : formValue.isMeasureValue === false ? 'off' : ''}
              onChange={(v) => { handleInputChange('conditionValue', v) }}
              disabled={formValue.isMeasureValue === null || formValue.isMeasureValue === false || !formValue.condition}
              errors={inputError.conditionValue || inputError.isMeasureValue}
            />
            <Input
              type='text'
              placeholder='%'
              value={formValue.conditionValueVariance}
              className={!formValue.condition ? 'disabled' : formValue.isMeasureValue ? 'off' : ''}
              onChange={(v) => { handleInputChange('conditionValueVariance', v) }}
              disabled={formValue.isMeasureValue === null || formValue.isMeasureValue === true || !formValue.condition}
              errors={inputError.conditionValueVariance || inputError.isMeasureValue}
            />
        </Group>
        <Group>
            <Input
              type='select'
              label='Compare Against'
              value={usePrevValue}
              options={formOptions.compareValueOptions}
              onChange={(v) => { handleUseValueSelect(v) }}
              errors={formValue?.useBaseValue ? inputError?.useBaseValue : []}
            />
        </Group>
        {
          usePrevValue === 'Base Value'
            ? <Group>
                <Input
                  type='text'
                  label='Base Value'
                  value={formValue.baseValue}
                  placeholder='Enter value'
                  onChange={(v) => { handleInputChange('baseValue', v) }}
                  errors={usePrevValue === 'Previous Value' ? [] : inputError?.baseValue}
                />
              </Group>
            : ''
        }
        <hr></hr>
        <Group>
          <div className='fake-textarea-wrap form-input'>
            <div className='input-label'>.</div>
            <div className='input text'>
              <div
                className='member-fake-textarea'
                onInput={(e) => handleFakeTextarea(e)}
              >
              </div>
            </div>
          </div>
          <Input
            type='textarea'
            onChange={(e) => handleInputChange('memberEmails', e)}
            label='Add Recipients'
            placeholder='Enter email addresses separated by commas'
            className={`member-textarea with-fake ${alert ? 'edit' : ''} small`}
            value={formValue.memberEmails}
            errors={formValue?.memberEmails.length > 0 ? [] : inputError?.memberEmails}
          />
        </Group>
        <Group>
        <div className='apply-filters-toggle'>
            <Input
              type='toggle'
              onChange={(v) => setCustomMessage(v)}
              value={customMessage || formValue.message.length > 0}
            />
            <span>Add a custom message</span>
          </div>
        </Group>
        {
          (customMessage || formValue.message) &&
          <Group>
            <Input
              type='textarea'
              placeholder='Enter Message'
              value={formValue.message}
              onChange={(v) => { handleInputChange('message', v) }}
            />
          </Group>
        }
      </Form>
    </div>
  )
}
const ActionButtons = ({ formPage, setFormPage, closeModal, handleSave, alert, disabled }) => {
  const navigate = useNavigate()
  if (formPage === 1) {
    return (
      <div className='action-buttons'>
        <div className='grouped-buttons'>
          { alert
            ? ''
            : <Button
              className='tertiary go-to-list'
              text='Manage Alerts'
              onClick={() => navigate('/help-center/manage-alerts')}
              />
          }
        </div>
        <div className='grouped-buttons'>
          <Button
            className='primary'
            text='Next'
            onClick={() => setFormPage(2)}
          />
          <Button
            className='secondary'
            text='Cancel'
            onClick={() => closeModal()}
          />
        </div>
      </div>
    )
  }
  return (
    <div className='action-buttons'>
      <div className='grouped-buttons'>
        <button
          className='tertiary go-to-list'
          onClick={() => setFormPage(1)}
        >
          <div className='button-content'>
            <i className='fas fa-arrow-left'/> GO BACK
          </div>
        </button>
        { alert
          ? ''
          : <Button
            className='tertiary go-to-list'
            text='Manage Alerts'
            onClick={() => navigate('/help-center/alerts')}
            />}
      </div>
      <div className='grouped-buttons'>
        <Button
          type='submit'
          className='primary'
          text={alert ? 'Edit Alert' : 'Create Alert'}
          onClick={() => handleSave()}
          disabled={disabled}
        />
        <Button
          className='secondary'
          text='Cancel'
          onClick={() => closeModal()}
        />
      </div>
    </div>
  )
}
const AlertForm = ({ showModal, closeModal, alert, user, setRefetchFlag }) => {
  const [formPage, setFormPage] = useState(1)
  const [usePrevValue, setUsePrevValue] = useState('Previous Value')
  const [formValues, setFormValues] = useState({
    name: '',
    interval: '',
    reportLevel: '',
    reportLevelName: [],
    keyPerformanceIndicator: '',
    condition: '',
    conditionValue: null,
    conditionValueVariance: null,
    message: '',
    workbookId: '',
    viewId: '',
    isMeasureValue: null,
    baseValue: null,
    memberEmails: ''
  })
  const [inputError, setInputError] = useState({})
  const [reportLevelOptions, setReportLevelOptions] = useState([])
  const [reportLevelNameOptions, setReportLevelNameOptions] = useState([])
  const [reportLevelNameOptionsCache, setReportLevelNameOptionsCache] = useState([])
  const [intervalOptions, setIntervalOptions] = useState([])
  const [kpiOptions, setKpiOptions] = useState([])
  const [OptionsLoading, setOptionsLoading] = useState({ options: true, reportlevelname: true })
  const [fromTabValuesFlag, setFromTabValuesFlag] = useState((window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts')))
  const [selectedAllReportLevelName, setSelectedAllReportLevelName] = useState(false)
  const [fromFiltersVal, setFromFiltersVal] = useState({})
  const [reportLevelNameValuesDP, setReportLevelNameValuesDP] = useState([])
  const [kpiValuesDP, setKpiValuesDP] = useState([])
  const [intervalValuesDP, setIntervalValuesDP] = useState([])
  const vizRef = useRef(null)
  const nonAlertLogicPages = !(window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts'))
  const [disabled, setDisabled] = useState(false)

  useQuery(alertConfigGql, {
    fetchPolicy: 'network-only',
    variables: { sitename: user.client.contentUrl },
    onCompleted: (data) => {
      setReportLevelOptions(data.alertConfig.reportLevels)
      setKpiValuesDP(data.alertConfig.kpis)
      setReportLevelNameValuesDP(data.alertConfig.reportLevelNames)
      setIntervalValuesDP(data.alertConfig.intervals)
      if (alert) {
        const reportLevelNames = data.alertConfig.reportLevelNames.find(r => r.reportLevel === alert.reportLevel)
        const intervals = data.alertConfig.intervals.find(i => i.reportLevelName === alert.reportLevelName.split('|')[0])
        const kpi = data.alertConfig.kpis.find(k => k.interval === alert.interval)
        setReportLevelNameOptions(reportLevelNames.options)
        setReportLevelNameOptionsCache(reportLevelNames.options)
        setKpiOptions(kpi.options)
        setIntervalOptions(intervals.options)
      }
      setOptionsLoading({ options: false, reportlevelname: false })
    }
  })

  useEffect(() => {
    if (alert) {
      const members = alert.members.map(m => m.member)
      const a = { ...alert }
      a.memberEmails = members.join(', ')

      setFormValues({
        name: a.name,
        interval: a.interval,
        reportLevel: a.reportLevel,
        reportLevelName: a.reportLevelName.split('|').map(m => m.trim()),
        keyPerformanceIndicator: a.keyPerformanceIndicator,
        condition: a.condition,
        conditionValue: a.isMeasureValue ? a.conditionValue : null,
        conditionValueVariance: !a.isMeasureValue ? a.conditionValue : null,
        message: a.message,
        workbookId: a.workbookId,
        viewId: a.viewId,
        isMeasureValue: a.isMeasureValue,
        memberEmails: a.memberEmails
      })

      if (alert.baseValue) {
        setUsePrevValue('Base Value')
        setFormValues((formValues) => ({ baseValue: alert.baseValue, ...formValues }))
      }
      setFromTabValuesFlag(true)

      const setUpTableau = async () => {
        await getTableauServerSetting()
        await getTrustedTicket()
      }
      setUpTableau()
    } else {
      let reportLevel = ''
      let reportLevelName = []
      let interval = ''
      let keyPerformanceIndicator = ''
      let isAllSelected = false
      let reportLevelNameValues = null

      const nonAlertLogicPages = !(window.location.href.includes('/KPIAlerts') || window.location.href.includes('SummaryAlerts'))
      if (!nonAlertLogicPages) {
        const viz = document.getElementById('tableauViz')
        const filtersAsyncFn = viz.workbook.activeSheet.getFiltersAsync()

        filtersAsyncFn.then(f => {
          const keyPerformanceIndicatorValues = f.find(filter => filter.fieldName === 'Measure Name').appliedValues
          const reportLevelValues = f.find(filter => filter.fieldName === 'Report Level').appliedValues
          reportLevelNameValues = f.find(filter => filter.fieldName === 'Report Level Name')
          const intervalValues = f.find(filter => filter.fieldName === 'Interval').appliedValues

          if (keyPerformanceIndicatorValues.length === 1) {
            keyPerformanceIndicator = keyPerformanceIndicatorValues[0].value
          }

          if (reportLevelValues.length === 1) {
            reportLevel = reportLevelValues[0].value
          }

          if (reportLevelNameValues.isAllSelected) {
            setSelectedAllReportLevelName(true)
            isAllSelected = true
          } else {
            reportLevelName = reportLevelNameValues.appliedValues.map(selected => selected.value)
          }

          if (intervalValues.length === 1) {
            interval = intervalValues[0].value
          }
        }).then(() => {
          if (isAllSelected) {
            reportLevelNameValues.getDomainAsync().then((data) => {
              reportLevelName = data.values.map(v => v.value)
              const fromFiltersVal = { reportLevel, reportLevelName, interval, keyPerformanceIndicator }
              const oldState = { ...formValues }
              setFormValues({ ...oldState, ...fromFiltersVal })
              setFromFiltersVal({ ...fromFiltersVal })
              const { reportLevel: x, reportLevelName: y, interval: z, keyPerformanceIndicator: a, ...errors } = inputError
              setInputError(errors)
            })
          } else {
            const fromFiltersVal = { reportLevel, reportLevelName, interval, keyPerformanceIndicator }
            const oldState = { ...formValues }
            setFormValues({ ...oldState, ...fromFiltersVal })
            setFromFiltersVal({ ...fromFiltersVal })
            const { reportLevel: x, reportLevelName: y, interval: z, keyPerformanceIndicator: a, ...errors } = inputError
            setInputError(errors)
          }
          getFilterOptions(viz)
        })
      } else {
        setFromTabValuesFlag(false)
      }
    }
  }, [])

  const handleUseValueSelect = (v) => {
    if (v === 'Previous Value') {
      const { baseValue, ...errs } = inputError
      setInputError(errs)
    }
    setUsePrevValue(v)
  }

  const handleInputChange = (name, v) => {
    const newVal = formValues
    let hasInputError = false
    let removeConditionValueErr = false
    let removeConditionValueVarianceErr = false
    let initialConditionSet = false
    if (name === 'memberEmails') {
      triggerInputChange('member-fake-textarea', v)
    }

    if (name === 'reportLevel') {
      triggerReportLevelChange(v)
    }

    if (name === 'interval' && nonAlertLogicPages) {
      setFormValues((formValues) => ({ ...formValues, keyPerformanceIndicator: '' }))
      const kpi = kpiValuesDP.find(k => k.interval === v)
      setKpiOptions(kpi.options)
    } else {
      setFormValues((formValues) => ({ ...formValues, keyPerformanceIndicator: '' }))
    }

    if (name === 'reportLevelName' && nonAlertLogicPages) {
      setFormValues((formValues) => ({ ...formValues, interval: '' }))
      const interval = intervalValuesDP.find(i => i.reportLevelName === v[0])
      setIntervalOptions(interval.options)
    } else {
      setFormValues((formValues) => ({ ...formValues, interval: '' }))
    }

    if (name === 'condition') {
      if (formValues.isMeasureValue === null) {
        initialConditionSet = true
      }
    }

    if (name === 'conditionValueVariance' || name === 'conditionValue') {
      if (!validateNumber(v).ok) {
        hasInputError = true
      }
    }

    if (name === 'isMeasureValue' && v) {
      removeConditionValueVarianceErr = true
    }

    if (name === 'isMeasureValue' && !v) {
      removeConditionValueErr = true
    }

    const errors = { ...inputError }
    let newErrors = {}
    Object.keys(errors).forEach((k) => {
      if (k !== name) {
        newErrors[k] = errors[k]
      }
    })

    if (hasInputError) {
      errors[name] = [{
        message: 'Invalid number value'
      }]
    }

    if (removeConditionValueErr) {
      const { conditionValue, ...errs } = newErrors
      newErrors = errs
    }

    if (removeConditionValueVarianceErr) {
      const { conditionValueVariance, ...errs } = newErrors
      newErrors = errs
    }

    if (initialConditionSet) {
      newVal.isMeasureValue = true
      const { conditionValueVariance, conditionValue, isMeasureValue, ...errs } = newErrors
      newErrors = errs
    }

    newVal[name] = v
    setFormValues(newVal)
    setInputError(newErrors)
    setDisabled(false)
  }

  const handleSetExpirationDate = (e) => {
    if (e) {
      const currDate = new Date()
      currDate.setDate(currDate.getDate() + 30)
      localStorage.setItem('alert-notif-expiration-date', currDate)
    } else {
      localStorage.removeItem('alert-notif-expiration-date')
    }
  }

  const handleSave = async (e) => {
    if (e) {
      e.preventDefault()
    }

    setDisabled(true)

    // validate Alerts
    const inputToSubmit = { ...formValues }
    const feErrors = { ...inputError }

    // set baseValue to null
    if (usePrevValue === 'Previous Value') {
      inputToSubmit.baseValue = null
    }

    // set reportLevelNames to string
    inputToSubmit.reportLevelName = inputToSubmit.reportLevelName.join('|')

    // validate form values
    if (!inputToSubmit.name) {
      feErrors.name = [{
        message: 'name is required'
      }]
    }

    if (!inputToSubmit.reportLevel) {
      feErrors.reportLevel = [{
        message: 'report level is required'
      }]
    }

    if (!inputToSubmit.reportLevelName) {
      feErrors.reportLevelName = [{
        message: 'report level name is required'
      }]
    }

    if (!inputToSubmit.interval) {
      feErrors.interval = [{
        message: 'interval is required'
      }]
    }

    if (!inputToSubmit.keyPerformanceIndicator) {
      feErrors.keyPerformanceIndicator = [{
        message: 'KPI is required'
      }]
    }

    if (!inputToSubmit.condition) {
      feErrors.condition = [{
        message: 'Condition is required'
      }]
    }

    if (inputToSubmit.isMeasureValue === null) {
      feErrors.isMeasureValue = [{
        message: 'Target measure value / variance is required'
      }]
    }

    if (inputToSubmit.isMeasureValue && !inputToSubmit.conditionValue) {
      feErrors.conditionValue = [{
        message: 'Target Value is required'
      }]
    }

    if (inputToSubmit.isMeasureValue === false && !inputToSubmit.conditionValueVariance) {
      feErrors.conditionValueVariance = [{
        message: 'Variance is required'
      }]
    }

    if (inputToSubmit.conditionValue) {
      if (!validateNumber(inputToSubmit.conditionValue).ok) {
        feErrors.conditionValue = [{
          message: 'Invalid number value: Target Value'
        }]
      }
    }

    if (inputToSubmit.conditionValueVariance) {
      if (!validateNumber(inputToSubmit.conditionValueVariance).ok) {
        feErrors.conditionValueVariance = [{
          message: 'Invalid number value: Variance'
        }]
      }
    }

    if (inputToSubmit.baseValue) {
      if (!validateNumber(inputToSubmit.baseValue).ok) {
        feErrors.baseValue = [{
          message: 'Invalid number value: Base Value'
        }]
      }
    }

    if (!inputToSubmit.memberEmails.length > 0) {
      feErrors.memberEmails = [{
        message: 'recipients are required'
      }]
    } else {
      const memberEmails = inputToSubmit.memberEmails.replace(/\s+/g, '').split(',')

      memberEmails.forEach(email => {
        const emailValidation = validateEmail(email.trim())
        if (!emailValidation.ok) {
          feErrors.memberEmails = [{
            message: emailValidation.message
          }]
        }
      })
      const uniqueMembers = array.unique(memberEmails)
      if (memberEmails.length !== uniqueMembers.length) {
        feErrors.memberEmails = [{
          message: 'Duplicate emails not allowed'
        }]
      }
    }

    if (usePrevValue === 'Base Value' && !inputToSubmit.baseValue) {
      feErrors.baseValue = [{
        message: 'Base value is required'
      }]
    }

    // Convert baseValue to float
    if (usePrevValue === 'Base Value') {
      const convertBaseValue = parseFloat(inputToSubmit.baseValue)
      if (convertBaseValue) {
        inputToSubmit.baseValue = convertBaseValue
      } else {
        feErrors.baseValue = [{
          message: 'Base value required'
        }]
      }
    }

    if (alert) {
      // Update Alert
      inputToSubmit.ids = [alert.id]
      inputToSubmit.adminStop = true

      const newMembers = array.unique(inputToSubmit.memberEmails.toLowerCase().split(',').map(email => email.trim()))
      const originalMembers = array.unique(alert.members.map(m => m.member))

      const memberRemove = []
      const memberAdd = []
      newMembers.forEach(member => {
        if (!originalMembers.includes(member)) {
          memberAdd.push(member)
        }
      })
      originalMembers.forEach(member => {
        if (!newMembers.includes(member)) {
          memberRemove.push(member)
        }
      })

      inputToSubmit.memberRemove = memberRemove
      inputToSubmit.memberAdd = memberAdd
      inputToSubmit.action = 'update'
      inputToSubmit.kpi = inputToSubmit.keyPerformanceIndicator
      inputToSubmit.conditionValue = inputToSubmit.conditionValueVariance ? inputToSubmit.conditionValueVariance : inputToSubmit.conditionValue

      if (!Object.keys(feErrors).length) {
        updateAlert(inputToSubmit).then(({ data, extensions }) => {
          if (data.updateAlert) {
            Swal.fire({
              icon: 'success',
              text: 'Successfully updated',
              timer: 3000,
              showConfirmButton: false
            })
            closeModal()
            setRefetchFlag({ action: 'update', id: alert.id })
          }
        })
      } else {
        setInputError(feErrors)
      }
    } else {
    // Create Alert
    // GET WORKBOOK AND VIEW ID
      inputToSubmit.conditionValue = inputToSubmit.conditionValueVariance ? inputToSubmit.conditionValueVariance : inputToSubmit.conditionValue

      // END GET WORKBOOK AND VIEW ID STUFF

      setInputError(feErrors)

      if (!Object.keys(feErrors).length) {
        if (new Date() > localStorage.getItem('alert-notif-expiration-date') || localStorage.getItem('alert-notif-expiration-date') === null) {
          Swal.fire({
            icon: 'warning',
            showCancelButton: true,
            html: `
              <p>Alerts are triggered when the current value changes</p>
              <div class='notification-container'>
              <input type="checkbox" id="notificationCheckbox"> <span>Don't show again for 30 days </span>
              </div>
            `,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              const checkbox = document.getElementById('notificationCheckbox')
              handleSetExpirationDate(checkbox.checked)
              createAlert(inputToSubmit).then(({ data, extensions }) => {
                if (data.createAlert) {
                  Swal.fire({
                    icon: 'success',
                    text: 'Successfully created',
                    timer: 3000,
                    showConfirmButton: false
                  })
                  closeModal()
                }
              })
            }
          })
        } else {
          createAlert(inputToSubmit).then(({ data, extensions }) => {
            if (data.createAlert) {
              Swal.fire({
                icon: 'success',
                text: 'Successfully created',
                timer: 3000,
                showConfirmButton: false
              })
              closeModal()
            }
          })
        }
      } else {
        setDisabled(false)
      }
    }
  }

  const triggerReportLevelChange = (value) => {
    const newFormValues = formValues
    newFormValues.reportLevelName = []
    setOptionsLoading({ options: false, reportlevelname: true })
    if (!nonAlertLogicPages) {
      const viz = document.getElementById('tableauViz')
      const applyFilterFn = viz.workbook.activeSheet.applyFilterAsync('Report Level', [value], 'replace')
      const filtersAsyncFn = viz.workbook.activeSheet.getFiltersAsync()
      applyFilterFn.then((v) => {
        filtersAsyncFn.then((filters) => {
          filters.forEach(filter => {
            if (filter.fieldName === 'Report Level Name') {
              const filterValuesFn = filter.getDomainAsync()
              filterValuesFn.then((data) => {
                const options = data.values.map(v => ({ text: v.value, value: v.value }))
                setReportLevelNameOptions(options)
              })
            }
          })
        })
      })
    } else {
      const reportLevelNameOption = reportLevelNameValuesDP.find(r => r.reportLevel === value)
      setReportLevelNameOptions(reportLevelNameOption.options)
      setReportLevelNameOptionsCache(reportLevelNameOption.options)
    }
    setFormValues({ ...newFormValues })
    setOptionsLoading({ options: false, reportlevelname: false })
  }

  const triggerGetFromFilters = (e) => {
    if (e) {
      const oldState = formValues
      setReportLevelNameOptions(reportLevelNameOptionsCache)
      setFormValues({ ...oldState, ...fromFiltersVal })
      if (fromFiltersVal.keyPerformanceIndicator) {
        const { reportLevel, reportLevelName, interval, keyPerformanceIndicator, ...errors } = inputError
        setInputError(errors)
      } else {
        const { reportLevel, reportLevelName, interval, ...errors } = inputError
        setInputError(errors)
      }
    } else {
      const removeValues = {
        interval: '',
        reportLevel: '',
        reportLevelName: [],
        keyPerformanceIndicator: ''
      }
      setOptionsLoading({ options: true, reportlevelname: true })
      setSelectedAllReportLevelName(false)
      const oldState = { ...formValues }
      setFormValues({ ...oldState, ...removeValues })
      if (fromFiltersVal.keyPerformanceIndicator) {
        const { reportLevel, reportLevelName, interval, keyPerformanceIndicator, ...errros } = inputError
        setInputError(errros)
      } else {
        const { reportLevel, reportLevelName, interval, ...errors } = inputError
        setInputError(errors)
      }
    }
    setFromTabValuesFlag(e)
  }

  async function getTrustedTicket () {
    return await client.query({
      query: trustedTicketGql,
      fetchPolicy: 'no-cache'
    }).then(({ data }) => {
      const trustedTicket = data.trustedTicket

      const urlPath = alert.contentUrl.split('/')
      loadTableauViz(trustedTicket, urlPath[0], urlPath[2])

      return trustedTicket
    })
  }

  async function getTableauServerSetting () {
    await client.query({
      query: tableauServerSettingGql,
      fetchPolicy: 'no-cache'
    }).then(async ({ data }) => {
      try {
        tableauServer = data.tableauServerSetting?.settingValue
      } catch (err) {
        console.log('Tableau Server URL not found.', err)
      }
    })
  }

  const loadTableauViz = (ticket, workbookName, viewName) => {
    const handleFirstInteractive = () => {
      const viz = document.getElementById('tableauViz')
      getFilterOptions(viz, alert)
    }
    if (vizRef.current && ticket) {
      const viz = new TableauViz()
      viz.id = 'tableauViz'
      viz.src = `https://${tableauServer}/trusted/` + ticket + ('/t/' + user.client.contentUrl) + '/views/' + workbookName + '/' + viewName
      document.getElementById('tableauVizWrapAlertForm').appendChild(viz)
      viz.addEventListener(TableauEventType.FirstInteractive, handleFirstInteractive)
    }
  }

  const applyReportLevel = (v) => {
    const viz = document.getElementById('tableauViz')
    viz.workbook.activeSheet.applyFilterAsync('Report Level', [v], 'replace')
  }

  const getFilterOptions = (viz, alert) => {
    if (alert) {
      applyReportLevel(alert.reportLevel)
    }
    const filtersAsyncFn = viz.workbook.activeSheet.getFiltersAsync()
    let ops = []
    filtersAsyncFn.then(filters => {
      const kpi = filters.find(filter => filter.fieldName === 'Measure Name')
      const reportLevel = filters.find(filter => filter.fieldName === 'Report Level')
      const reportLevelName = filters.find(filter => filter.fieldName === 'Report Level Name')
      const interval = filters.find(filter => filter.fieldName === 'Interval')

      const kpiFn = kpi.getDomainAsync()
      const reportLevelFn = reportLevel.getDomainAsync()
      const reportLevelNameFn = reportLevelName.getDomainAsync()
      const intervalFn = interval.getDomainAsync()

      ops = [kpiFn, reportLevelFn, reportLevelNameFn, intervalFn]
    }).then(() => {
      let options = []
      Promise.all(ops)
        .then((data) => { options = data })
        .then(() => {
          const kpiOptions = options[0].values.map(op => ({ text: op.value, value: op.value }))
          const reportLevelOptions = options[1].values.map(op => ({ text: op.value, value: op.value }))
          const reportLevelNameOptions = options[2].values.map(op => ({ text: op.value, value: op.value })).filter(option => !option.value.includes('null'))
          const intervalOptions = options[3].values.map(op => ({ text: op.value, value: op.value }))
          setKpiOptions(kpiOptions)
          setReportLevelOptions(reportLevelOptions)
          setReportLevelNameOptions(reportLevelNameOptions)
          setReportLevelNameOptionsCache(reportLevelNameOptions)
          setIntervalOptions(intervalOptions)
          setOptionsLoading({ options: false, reportlevelname: false })
        })
    })
  }

  useEffect(() => {
    const setAlltoGetOptions = () => {
      if (reportLevelOptions.length !== 5) {
        const viz = document.getElementById('tableauViz')
        const options = reportLevelNameOptions.map(rlno => rlno.value)
        viz.workbook.activeSheet.applyFilterAsync('Report Level Name', [...options], 'all').then(() => {
          viz.workbook.activeSheet.getFiltersAsync().then(filters => {
            const reportLevel = filters.find(filter => filter.fieldName === 'Report Level')
            reportLevel.getDomainAsync().then(options => {
              const reportLevel = options.values.map(op => ({ text: op.value, value: op.value }))
              setReportLevelOptions(reportLevel)
            })
          })
        })
      }
      setOptionsLoading({ options: false, reportlevelname: false })
    }
    if (!fromTabValuesFlag && reportLevelNameOptions.length > 0) {
      setAlltoGetOptions()
    }
  }, [fromTabValuesFlag])

  const closeModalRevertOptions = () => {
    if (alert || nonAlertLogicPages) {
      closeModal()
    } else {
      const viz = document.getElementById('tableauViz')
      viz.workbook.activeSheet.applyFilterAsync('Report Level', [fromFiltersVal.reportLevel], 'replace')
      closeModal()
    }
  }

  const masterReferencesQueryCondition = useQuery(alertConditionsGql, {
    fetchPolicy: 'network-only'
  })

  const masterReferencesQueryCompare = useQuery(alertCompareGql, {
    fetchPolicy: 'network-only'
  })

  if (masterReferencesQueryCondition.loading || masterReferencesQueryCondition.error || masterReferencesQueryCompare.loading || masterReferencesQueryCompare.error) {
    return null
  }

  const { alertConditionOptions: conditionOptions } = masterReferencesQueryCondition.data
  const { alertCompareOptions: compareValueOptions } = masterReferencesQueryCompare.data

  if (!conditionOptions || !compareValueOptions) {
    return null
  }
  return (
    <Fragment>
      <Modal show={showModal}>
        <div className='alert-form'>
          <h2>{ alert ? 'Edit Alert' : 'Create an Alert'}</h2>
          <FormPart1
            formPage={formPage}
            handleInputChange={handleInputChange}
            formValue={formValues}
            inputError={inputError}
            workbookFilterOptions={{ kpiOptions, reportLevelOptions, reportLevelNameOptions, intervalOptions }}
            reportLevelNameOptionsCache={reportLevelNameOptionsCache}
            loading={OptionsLoading}
            fromTabValuesFlag={fromTabValuesFlag}
            selectedAllReportLevelName={selectedAllReportLevelName}
            fromFiltersVal={fromFiltersVal}
            triggerGetFromFilters={triggerGetFromFilters}
          />
          <FormPart2
            formPage={formPage}
            handleInputChange={handleInputChange}
            formValue={formValues} inputError={inputError}
            workbookFilterOptions={{ conditionOptions, compareValueOptions }}
            usePrevValue={usePrevValue}
            handleUseValueSelect={handleUseValueSelect}
            alert={alert}
            setInputError={setInputError}
          />
          <ActionButtons
            formPage={formPage}
            setFormPage={setFormPage}
            closeModal={closeModalRevertOptions}
            handleSave={handleSave}
            alert={alert}
            disabled={disabled}
          />
        </div>
      </Modal>
      <div id='tableauVizWrapAlertForm' ref={vizRef}></div>
    </Fragment>
  )
}

AlertForm.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  alert: PropTypes.object,
  user: PropTypes.object,
  setRefetchFlag: PropTypes.func
}

FormPart1.propTypes = {
  formPage: PropTypes.number,
  handleInputChange: PropTypes.func,
  formValue: PropTypes.object,
  inputError: PropTypes.object,
  workbookFilterOptions: PropTypes.object,
  loading: PropTypes.object,
  fromTabValuesFlag: PropTypes.bool,
  selectedAllReportLevelName: PropTypes.bool,
  triggerGetFromFilters: PropTypes.func,
  reportLevelNameOptionsCache: PropTypes.array
}

FormPart2.propTypes = {
  formPage: PropTypes.number,
  handleInputChange: PropTypes.func,
  formValue: PropTypes.object,
  inputError: PropTypes.object,
  workbookFilterOptions: PropTypes.object,
  usePrevValue: PropTypes.string,
  handleUseValueSelect: PropTypes.func,
  alert: PropTypes.object,
  setInputError: PropTypes.func
}

ActionButtons.propTypes = {
  formPage: PropTypes.number,
  closeModal: PropTypes.func,
  setFormPage: PropTypes.func,
  handleSave: PropTypes.func,
  alert: PropTypes.object,
  disabled: PropTypes.bool
}

FormHeader.propTypes = {
  formPage: PropTypes.number,
  triggerGetFromFilters: PropTypes.func,
  fromTabValuesFlag: PropTypes.bool,
  loading: PropTypes.object
}

export default AlertForm
