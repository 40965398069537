import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Title, Button } from '@lib'
import { object, axiosGet } from '@utils'
import { Form, Group, Input, Submit, FormError } from '@shared/Form'
import axios from 'axios'
import Dashboard from '@wrappers/Dashboard'
import Swal from 'sweetalert2'
import config from '@config'
import createResource from '@graphql/mutators/create-resource'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const [inputErrors, setInputErrors] = useState({})
  const [input, setInput] = useState({
    file: null,
    name: null,
    summary: null,
    type: null,
    image: null
  })
  const [loaderState, setLoaderState] = useState({
    submit: false
  })
  const [disabled, setDisabled] = useState(false)

  const handleInputChange = (name, v) => {
    const newInputError = { ...inputErrors }
    newInputError[name] = []
    setInputErrors(newInputError)
    setInput(prevState => ({
      ...prevState,
      [name]: v
    }))
  }

  const handleUpload = (evt) => {
    if (evt) {
      evt.preventDefault()
    }
    setDisabled(true)
    const feErrors = {}
    if (!input.name) {
      feErrors.name = [{ message: 'Resource name is required' }]
    }

    if (!input.type) {
      feErrors.type = [{ message: 'Resource type is required' }]
    }

    if (!input.file) {
      feErrors.file = [{ message: 'File is required' }]
    }

    if (!input.image) {
      feErrors.image = [{ message: 'Resource thumbnail is required' }]
    }

    const imagetype = input.image?.type
    if (imagetype && !['image/jpeg', 'image/png'].includes(imagetype)) {
      feErrors.imagetype = [{ message: 'Thumbnail must be of type .png, .jpg, .jpeg' }]
    }

    const ziptype = input.file?.type
    if (!ziptype || (ziptype !== 'application/zip' && ziptype !== 'application/x-zip-compressed')) {
      feErrors.ziptype = [{ message: 'File must be of type .zip' }]
    }

    if (!object.keys(feErrors).length) {
      setLoaderState(prevState => ({ ...prevState, submit: true }))

      const newInput = { ...input }
      newInput.file = JSON.stringify({
        originalname: input.file.name,
        mimetype: input.file.type,
        size: input.file.size
      })
      newInput.image = JSON.stringify({
        originalname: input.image.name,
        mimetype: input.image.type,
        size: input.image.size
      })
      createResource(newInput).then(async response => {
        if (response.data.createResource) {
          Swal.fire({
            text: 'Resource successfully created',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })

          if (input.file instanceof File || input.image instanceof File) {
            let bucketEnv = 'dev'
            if (config.APP_ENV === 'prod' || config.APP_ENV === 'production') {
              bucketEnv = 'prod'
            } else if (config.APP_ENV === 'staging' || config.APP_ENV === 'stage') {
              bucketEnv = 'stage'
            }
            const bucket = `xevant-${bucketEnv}-alcatraz-be-uploads-us-west-2`
            const key = encodeURIComponent('zipFiles/' + response.data.createResource.fileName.replace(/^\//, ''))
            const keyImage = encodeURIComponent('resourceUploads/images/' + response.data.createResource.image.replace(/^\//, ''))
            await axiosGet(`s3/signed-url/${bucket}/${key}`).then(async e => {
              const uploadUrl = e.data.url
              axios.put(uploadUrl, input.file)

              await axiosGet(`s3/signed-url/${bucket}/${keyImage}`).then(async e2 => {
                const uploadUrlImage = e2.data.url
                axios.put(uploadUrlImage, input.image)
                navigate('/settings/trainings-and-use-cases')
              })
            })
          } else {
            navigate('/settings/trainings-and-use-cases')
          }
        }
      })
    } else {
      setInputErrors(feErrors)
      setDisabled(false)
    }
  }

  return (
    <Fragment>

      <Title text='Upload Resource' user={user}/>

      <Form className='course-upload-form' onSubmit={handleUpload}>
      <FormError errors={inputErrors} />
        <Group>
          <Input
            label='Resource Name'
            type='text'
            value={input.name}
            onChange={(v) => handleInputChange('name', v)}
            errors={inputErrors.name}
          />
        </Group>
        <Group>
          <Input
            type='select'
            label='Resource Type'
            placeholder='Select resource type'
            options={[{ text: 'Training Course', value: 'Training' }, { text: 'Use Case', value: 'UseCase' }]}
            value={input.type}
            onChange={(v) => handleInputChange('type', v)}
            errors={inputErrors.type}
          />
        </Group>
        <Group>
          <Input
            label='Upload Resource'
            type='draganddrop'
            placeholder='upload course as .zip file'
            value={input.file}
            onChange={(v) => handleInputChange('file', v.target.files[0])}
            accept='.zip'
            logoDirectory='/resourceUploads/'
            className='resource-upload'
            errors={inputErrors.file}
          />
        </Group>
        <Group>
          <Input
            label='Upload Thumbnail Image'
            type='draganddrop'
            value={input.image}
            onChange={(v) => handleInputChange('image', v.target.files[0])}
            accept='.png, .jpg, .jpeg'
            logoDirectory='/resourceUploads/images/'
            className='thumbnail-upload'
            errors={inputErrors.image}
          />
        </Group>
        <Group>
          <Input
            label={<>Resource Summary <span className='optional'>(Optional)</span></>}
            type='textarea'
            value={input.summary}
            onChange={(v) => handleInputChange('summary', v)}
          />
        </Group>

        <Submit>
          <Button
            type='submit'
            text='Upload Resource'
            icon='fas fa-arrow-alt-to-top'
            iconAlign='left'
            className='icon-left primary full-width'
            loading={loaderState.submit}
            disabled={disabled}
          >
          </Button>
        </Submit>

      </Form>

    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const UploadCourse = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default UploadCourse
