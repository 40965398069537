import client from '../client.js'
import destroyTemplateFieldMappingGql from '../mutations/destroy-template-field-mapping.gql'

const destroyTemplateFieldMapping = (input) => {
  return client.mutate({
    mutation: destroyTemplateFieldMappingGql,
    variables: { id: input },
    update: (store, { data }) => {
    }
  })
}

export default destroyTemplateFieldMapping
