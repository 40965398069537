import client from '../client.js'
import sessionWatcherGql from '../mutations/session-watcher.gql'

const sessionWatcher = (input) => {
  return client.mutate({
    mutation: sessionWatcherGql,
    variables: input,
    update: (store, { data }) => {
      // console.log(data)
    }

  })
}

export default sessionWatcher
