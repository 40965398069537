import client from '../client.js'
import updateSubscriptionGql from '../mutations/update-subscription.gql'

const updateSubscription = (input) => {
  return client.mutate({
    mutation: updateSubscriptionGql,
    variables: input
  })
}

export default updateSubscription
