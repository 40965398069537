import client from '../client.js'

import updateClientCustomerGql from '../mutations/update-client-customer.gql'
import clientCustomersGql from '../queries/client-customers.gql'

const updateClientCustomer = (input) => {
  return client.mutate({
    mutation: updateClientCustomerGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateClientCustomer) {
        try {
          const { id } = data.updateClientCustomer
          // get cache value
          const clientCustomersCache = store.readQuery({
            query: clientCustomersGql
          })

          // update cache using id above ^
          const clientCustomers = clientCustomersCache.clientCustomers.filter((c) => c.id !== id)

          store.writeQuery({
            query: clientCustomersGql,
            data: {
              clientCustomers: [...clientCustomers, data.updateClientCustomer]
            }
          })
        } catch (error) {
          client.query({
            query: clientCustomersGql
          })
        }
      }
    }
  })
}

export default updateClientCustomer
