import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Button } from '@lib'
import client from '@graphql/client'

import fileUploadGql from '@graphql/queries/file-upload'

const Content = ({ user }) => {
  const navigate = useNavigate()
  const { id, directory } = useParams()
  const [loading, setLoading] = useState(true)
  const [fileUpload, setFileUpload] = useState({})

  const activeClientUser = user.userClients.filter(x => x.clientId === user.clientId)[0]

  if (!activeClientUser?.isDatalogicUser) {
    navigate(-1, { replace: true })
  }

  useEffect(() => {
    if (loading) {
      client.query({
        query: fileUploadGql,
        variables: {
          id
        }
      }).then(({ data }) => {
        if (data.fileUpload) {
          setFileUpload(data.fileUpload)
          setLoading(false)
        }
      })
    }
  }, [loading])

  const columns = useMemo(() =>
    [
      {
        Header: 'No. of errors',
        accessor: 'errorCount',
        disableSortBy: true
      },
      {
        Header: 'column',
        accessor: 'column',
        disableSortBy: true
      },
      {
        Header: 'value',
        accessor: 'value',
        disableSortBy: true
      },
      {
        Header: 'validation',
        accessor: 'validation',
        disableSortBy: true
      },
      {
        Header: 'message',
        accessor: 'message',
        disableSortBy: true,
        className: 'max-width'
      }
    ])

  if (loading) {
    return null
  }

  return (
    <Fragment>
      <Title text={`${fileUpload.fileName} Errors`} user={user}/>
      <div className='dl-error-summary'>
        <Button
          className='secondary icon-left error-summary-button'
          icon='far fa-angle-left'
          iconAlign='left'
          text='File Uploads'
          onClick={() => navigate(`/datalogic/file-uploads/${directory}`)}
        />
        <Datatable
          columns={columns}
          data={fileUpload.fileUploadError}
        />
      </div>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const ErrorSummary = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default ErrorSummary
