import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Loaders, Icon } from '@lib/index.js'

const Button = ({ type, text, icon, iconify, iconAlign, className, children, onClick, loading, upperCase, disabled, ...rest }) => {
  const handleClick = () => {
    if (!loading && !disabled) {
      onClick()
    }
  }

  if (loading) {
    return (
      <button
        className={classNames('button', className, { upperCase })}
        disabled={disabled}
        {...rest}
      >
        <Loaders.LoaderCircleLines />
      </button>
    )
  }

  return (
    <button
      className={classNames('button', className, { upperCase })}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {icon && iconAlign === 'left' && (
        <Icon className={icon} iconify={iconify} icon={icon} />
      )}
      {text && (<span>{text}</span>)}
      {icon && iconAlign === 'right' && (
        <Icon className={icon} iconify={iconify} icon={icon} />
      )}
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  iconify: PropTypes.bool,
  iconAlign: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  upperCase: PropTypes.bool,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  type: 'button',
  iconify: false,
  iconAlign: 'left',
  onClick: () => {}, // This is intentional
  upperCase: false,
  disabled: false
}

export default Button
