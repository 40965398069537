import client from '../client.js'
import updateMfaGql from '../mutations/update-mfa.gql'

const updateMfa = (input) => {
  return client.mutate({
    mutation: updateMfaGql,
    variables: input
  })
}

export default updateMfa
