import React, { useState } from 'react'
import PropTypes from 'prop-types'

const NumberInput = ({ id, state, value, placeholder, disabled, settings, onChange }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Shift', 'Tab', 'Enter']
  const handleKeyDown = (evt) => {
    if (allowedKeys.includes(evt.key)) {
      return
    }
    if (settings.float && evt.key === '.') {
      return
    }
    if (!/^[0-9]*$/.test(evt.key)) {
      evt.preventDefault()
    }
  }

  const handleChange = (evt) => {
    const regexStr = settings.float ? '^[0-9]*$|^[.][0-9]*$|^[0-9]*[.]$|^[0-9]*[.][0-9]*$' : '^[0-9]*$'
    const regex = new RegExp(regexStr)

    const newValue = evt.target.value

    const doubleDecimalRegex = /^\d*(\.\d{0,2})?$/
    const passDoubleDecimalRegex = settings.doubleDecimal ? doubleDecimalRegex.test(newValue) : true

    if (regex.test(newValue) && !disabled && passDoubleDecimalRegex) {
      onChange(newValue)
      setCurrentValue(newValue)
    } else if (!newValue) {
      onChange('')
      setCurrentValue('')
    }
  }

  const inputValue = state ? currentValue : value

  return (
    <input
      id={id}
      type='number'
      value={inputValue}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    />
  )
}

NumberInput.propTypes = {
  id: PropTypes.string,
  state: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  settings: PropTypes.object,
  onChange: PropTypes.func
}

NumberInput.defaultProps = {
  id: '',
  state: true,
  value: '',
  placeholder: '',
  disabled: false,
  settings: {},
  onChange: (e) => {} // This is intentional
}

export default NumberInput
