import React, { useState } from 'react'
import PropTypes from 'prop-types'

const FileInput = ({ value, placeholder, disabled, onChange, accept }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = (evt) => {
    if (evt.target.files.length === 1) {
      const file = evt.target.files[0]
      const allowedFileExtensions = ['xlsx', 'txt', 'zip', 'jpg', 'png']
      const fileExtension = file.name.split('.').pop()

      if (file.name.length <= 50 && allowedFileExtensions.includes(fileExtension)) {
        setCurrentValue(file)
      } else {
        setCurrentValue(null)
      }

      onChange(file)
    }
  }

  return (
    <div className='file-input'>
      <span className='file-name'>
        {currentValue?.name || placeholder}
      </span>
      <input
        type='file'
        // accept='.xlsx, .txt, .zip, .jpg, .png'
        accept={accept}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  )
}

FileInput.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  accept: PropTypes.string
}

FileInput.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  onChange: (e) => {}, // This is intentional
  accept: '.xlsx, .txt, .zip, .jpg, .png'
}

export default FileInput
