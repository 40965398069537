import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Dashboard from '@wrappers/Dashboard'
import { Title, Link } from '@lib'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Input } from '@shared/Form'

import resourcesGql from '@graphql/queries/resources'
import ResourceCard from '@shared/ResourceCard'

const crumbs = [
  {
    text: 'Home',
    link: '/'
  },
  {
    text: 'Resources',
    link: '/resources'
  },
  {
    text: 'Trainings & Use Cases',
    link: '/help-center/trainings-and-use-cases'
  }
]

const Content = ({ user }) => {
  const tabs = {
    '#trainingcourses': 0,
    '#usecases': 1
  }
  const identifyActiveTab = (tab) => {
    return tabs[tab] | 0
  }
  const { hash } = useLocation()
  const [tabIndex, setTabIndex] = useState(hash ? identifyActiveTab(hash) : 0)
  const [searchTerm, setSearchTerm] = useState('')

  const resourcesQuery = useQuery(resourcesGql, {})

  if (resourcesQuery.loading || resourcesQuery.error) {
    return null
  }
  const { resources } = resourcesQuery.data
  const trainings = resources.filter(r => r.type === 'Training')
  const usecases = resources.filter(r => r.type === 'UseCase')

  const functionalityTraining = trainings.filter(t => t.name === 'Functionality')[0]
  const alertlogicTraining = trainings.filter(t => t.name === 'AlertLogic')[0]

  const handleSearch = (v) => {
    setSearchTerm(v)
  }

  return (
    <Fragment>
      <Title text='Trainings & Use Cases' user={user}/>

      <Tabs className='tab-view' selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList className='tab-navs'>
          <Tab>
            <Link
              to='#trainingcourses'
              onClick={() => { setTabIndex(identifyActiveTab('#trainingcourses')) }}
            >
              Training Courses
            </Link>
          </Tab>
          <Tab>
            <Link
              to='#usecases'
              onClick={() => { setTabIndex(identifyActiveTab('#usecases')) }}
            >
              Use Cases
            </Link>
          </Tab>
        </TabList>

        <TabPanel>
          <div className='tab-panel-contain'>
            <div id='resource-blurb-container'>
              <p className='resource-blurb'>
                Welcome to Product Training! <br/>
                For an optimal training experience, we recommend that the user first complete the training for
                <Link to={`${crumbs.filter(c => c.text === 'Trainings & Use Cases')[0].link}/view/${functionalityTraining.id}`} target='_blank'> {functionalityTraining.name}</Link> and
                <Link to={`${crumbs.filter(c => c.text === 'Trainings & Use Cases')[0].link}/view/${alertlogicTraining.id}`} target='_blank'> {alertlogicTraining.name}</Link> prior to courses on different modules.
              </p>

              <Input
                className='resource-search'
                icon='far fa-search fa-lg'
                iconRight={true}
                type='text'
                placeholder='Search...'
                onChange={(v) => handleSearch(v)}
                dataAttrId="training_courses_search_field"
              />
            </div>
            <div className='resource-cards-wrapper'>
              {trainings.map((x, key) =>
                ((x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || x.summary.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) &&
                  <ResourceCard key={key} resource={x} />
                )
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className='tab-panel-contain'>
            <div id='resource-blurb-container'>
              <Input
                className='resource-search single'
                icon='far fa-search fa-lg'
                iconRight={true}
                type='text'
                placeholder='Search...'
                onChange={(v) => handleSearch(v)}
                dataAttrId="use_cases_search_field"
              />
            </div>
            <div className='resource-cards-wrapper'>
              {usecases.map((x, key) =>
                ((x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || x.summary.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) &&
                  <ResourceCard key={key} resource={x} />
                )
              )}
            </div>
          </div>
        </TabPanel>

      </Tabs>

    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const Trainings = () => {
  return (
    <Dashboard>
      <Content/>
    </Dashboard>
  )
}

export default Trainings
