const triggerChange = (className, value) => {
  const input = document.querySelector(`.${className}  > input`) || document.querySelector(`.${className} > select`) || document.querySelector(`.${className} > textarea`) || document.querySelector(`.${className}`)
  const lastValue = input.value
  input.value = value

  let event
  if (input.tagName === 'SELECT' || input.tagName === 'TEXTAREA' || input.type === 'file') {
    event = new Event('change', { bubbles: true })
  } else {
    event = new Event('input', { bubbles: true })
  }

  const tracker = input._valueTracker

  if (tracker) {
    tracker.setValue(lastValue)
  }
  return input.dispatchEvent(event)
}

export default triggerChange
