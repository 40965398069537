import React, { useState, useEffect } from 'react'
import { Button } from '@lib'
import { FormError } from '@shared/Form'
import { array, currentDevice, cookie } from '@utils'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import sendMfaEmail from '@graphql/mutators/send-mfa-email'
import createSession from '@graphql/mutators/create-session'
import AuthenticationWrapper from '@shared/AuthenticationWrapper'
import bgImage from '@images/networkingBG.png'
import logoImage from '@images/xevant-logo-horizontal.png'
import mfaCodeDurationGql from '@graphql/queries/mfa-code-duration'
import client from '@graphql/client'

const Content = ({ mfaUser }) => {
  const mfaToken = localStorage.getItem('MfaToken')
  const [mfaResetButton, setMfaResetButton] = useState(0)
  const [mfaCode, setInput] = useState('')
  const [isMfaSkipped, setChecked] = useState(false)
  const [inputErrors, setInputErrors] = useState({})
  const [userIP, setUserIP] = useState()

  useEffect(() => {
    client.query({
      query: mfaCodeDurationGql
    }).then(({ data }) => {
      if (data.mfaCodeDuration.settingValue) {
        setMfaResetButton(data.mfaCodeDuration.settingValue)
      }
    })
  }, [])

  useEffect(() => {
    if (!userIP) {
      (async () => {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        setUserIP(data.ip)
      })().catch(err => {
        console.log('err fetching ip: ', err)
      })
    }
  }, [userIP])

  useEffect(() => {
    if (!mfaResetButton) return
    const countDownTimer = setInterval(() => {
      setMfaResetButton(mfaResetButton - 1)
    }, 1000)

    return () => {
      clearInterval(countDownTimer)
    }
  }, [mfaResetButton])

  const handleVerify = (evt) => {
    if (evt) {
      evt.preventDefault()
    }

    createSession({
      ip: userIP,
      mfaToken,
      mfaCode,
      isMfaSkipped,
      device: currentDevice,
      mfaMethod: 'EMAIL'
    }).then(({ data, extensions }) => {
      if (data.createSession != null) {
        cookie.set('csrfCookie', 0, -1)
        cookie.set('sessionExpires', data.createSession.expiresAt, 14)
        cookie.set('sessionStatus', 'true', 14)
      } else {
        setInputErrors(array.groupBy(extensions.errors, 'path'))
      }
    })
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleVerify()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [mfaCode])

  const resendMfaCode = () => {
    if (mfaResetButton === 0) {
      sendMfaEmail({
        id: mfaUser.data.mfa.id,
        email: mfaUser.data.mfa.email
      }).then(({ data }) => {
        if (data) {
          setMfaResetButton(60)
          Swal.fire({
            text: 'Email Resent!',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000
          })
        }
      })
    }
  }

  return (
      <div
        className='page-mfa'
        style={{ backgroundImage: 'url(' + bgImage + ')' }}
      >
      <div className='content-code'>
        <div className='left-info'>
          <div className='login-logo-left'>
            <img src={logoImage} />
          </div>
          <div className='left-content'>
            <h2>Two-Factor Authentication (2FA)</h2>
            <p className='big-text'>
              With <span className='highlight-blue'>two-factor authentication (2FA)</span> enabled,
              you make it harder for unauthorized persons to
              access your protected information by adding an extra layer of security.
            </p>
          </div>
          <div className='copyright'>
            Copyright &copy; {new Date().getFullYear()} Xevant. All Rights Reserved.
          </div>
        </div>

        <div className='right-info'>
          <h2>Enable Two-Factor Authentication</h2>
          <p className='mfa-p'>
          We have sent the six-digit verification code to your email associated with this account.
          </p>
          <p className='mfa-p'>
          Please check your inbox and input the code below to verify your account.
          </p>
          <FormError errors={inputErrors}/>

            <div className='mfa-code-digits'>
              <input
                type='text'
                name='verificationCode'
                maxLength='6'
                pattern="\d*"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '')
                }}
                placeholder='xxxxxx'
                className='custom-mfa-input'
                value={mfaCode}
                onChange={(e) => setInput(e.target.value)}
                data-attribute-id="email_verify_code_field"
              />
            </div>

          <div className='checkbox-remember'>
            <div className='checkbox'>
              <input
                type='checkbox'
                checked={isMfaSkipped}
                onChange={() => setChecked(!isMfaSkipped)}
                data-attribute-id="email_verify_remember_checkbox_field"
              />
            </div>
            <label>Remember this code for 14 days</label>
          </div>

          <Button data-attribute-id="email_verify_btn" onClick={() => handleVerify()} text='VERIFY' className='login-button primary full-width'/>
          { mfaResetButton === 0
            ? <div className='resend-code'>Didn’t receive the code? Code expired? <span onClick={() => resendMfaCode()} className={mfaResetButton ? 'disabled' : 'clickable'}>Resend</span> </div>
            : <div className='resend-code'>Didn’t receive the code? Code expired? <span onClick={() => resendMfaCode()} className={mfaResetButton ? 'disabled' : 'clickable'}>Resend</span> in {mfaResetButton}.</div>
          }
        </div>
      </div>
    </div>
  )
}

Content.propTypes = {
  user: PropTypes.object,
  mfaUser: PropTypes.object
}

const EmailVerify = () => {
  return (
    <AuthenticationWrapper>
      <Content />
    </AuthenticationWrapper>
  )
}

export default EmailVerify
