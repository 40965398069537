import client from '../client.js'
import createResourceGql from '../mutations/create-resource.gql'

const createResource = (input) => {
  return client.mutate({
    mutation: createResourceGql,
    variables: input
  })
}

export default createResource
