import client from '../client.js'
import masterReferencesGql from '../queries/master-references.gql'
import destroyMasterReferenceGql from '../mutations/destroy-master-reference.gql'

const destroyMasterReference = (id) => {
  return client.mutate({
    mutation: destroyMasterReferenceGql,
    variables: {
      id
    },
    update: (store, { data }) => {
      const { id } = data.destroyMasterReference

      // read masterReferences existing cache
      const masterReferencesCache = store.readQuery({
        query: masterReferencesGql
      })

      // remove masterReferences from cache
      store.writeQuery({
        query: masterReferencesGql,
        data: {
          masterReferences: masterReferencesCache.masterReferences.filter(t => t.id !== id)
        }
      })
    }
  })
}

export default destroyMasterReference
