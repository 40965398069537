import React, { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import Dashboard from '@wrappers/Dashboard'
import { Title, Datatable, Icon, Tooltip } from '@lib'
import { Link, ListView } from '@shared'
import Swal from 'sweetalert2'
import client from '@graphql/client'
import { Can } from '@caslConfig/can.js'

import userGroupsGql from '@graphql/queries/user-groups'
import destroyUserGroup from '@graphql/mutators/destroy-user-group'
import clientGql from '@graphql/queries/client'

const Content = ({ user }) => {
  const [clientName, setClientName] = useState()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [userGroups, setUserGroups] = useState()

  useEffect(() => {
    if (loading) {
      client.query({
        query: clientGql,
        variables: {
          id
        }
      }).then(({ data }) => {
        setClientName(data.client.name)
      })

      client.query({
        query: userGroupsGql,
        variables: {
          clientId: id
        },
        fetchPolicy: 'network-only'
      }).then(({ data }) => {
        setUserGroups(data.userGroups)
        setLoading(false)
      })
    }
  }, [loading])

  const handleDelete = (v) => {
    Swal.fire({
      text: 'Are you sure you want to delete this user group?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        document.querySelector('.modal-backdrop').style.display = 'block'
        document.querySelector('.modal-backdrop > .modal').style.display = 'none'

        destroyUserGroup({ id: v }).then(() => {
          document.querySelector('.modal-backdrop').style.display = 'none'
          document.querySelector('.modal-backdrop > .modal').style.display = 'inherit'
          Swal.fire({
            text: 'User group deleted',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
          setLoading(true)
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'User Group',
        accessor: 'name'
      },
      {
        id: 'actionBtnsCol',
        accessor: 'id',
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              <Can I='update' a='UserGroup'>
                <Link
                  className='action-button edit-button'
                  to={`/settings/clients/${id}/user-groups/edit/${value}`}
                  icon='fas fa-pencil-alt'
                  id={`${value}-edit`}
                />
                <Tooltip
                  anchorId={`${value}-edit`}
                  content='Edit'
                />
              </Can>
              <Can I='delete' a='UserGroup'>
                <div onClick={() => handleDelete(value)} className='action-button delete-button' id={`${value}-delete`}>
                  <Icon className='fas fa-trash'/>
                </div>
                <Tooltip
                  anchorId={`${value}-delete`}
                  content='Delete'
                />
              </Can>
            </div>
          )
        }
      }
    ]
  )

  if (loading) {
    return null
  }

  return (
    <Fragment>
      <Title text={`${clientName} User Groups`} user={user}/>

      <ListView>
        <Can I='create' a='UserGroup'>
          <Datatable
            columns={columns}
            data={userGroups}
            newRowBtn={{
              icon: 'fas fa-plus',
              text: 'New User Group',
              to: `/settings/clients/${id}/user-groups/new`
            }}
          />
        </Can>
        <Can not I='create' a='UserGroup'>
          <Datatable
            columns={columns}
            data={userGroups}
          />
        </Can>
      </ListView>
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const UserGroups = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default UserGroups
