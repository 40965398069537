import client from '../client.js'
import destroyUserGql from '../mutations/destroy-user.gql'

const destroyUser = (input) => {
  return client.mutate({
    mutation: destroyUserGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyUser
