import client from '../client.js'
import destroyResourceGql from '../mutations/destroy-resource.gql'

const destroyResource = (input) => {
  return client.mutate({
    mutation: destroyResourceGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyResource
