import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const NotFound = ({ className }) => (
  <div className={classNames('server-message', className)}>
    <div className='top-bar'></div>
    <div className='message'>
      Page not found
    </div>
  </div>
)

NotFound.propTypes = {
  className: PropTypes.string
}

export default NotFound
