import client from '../client.js'
import userGql from '../queries/user.gql'
import updateUserGql from '../mutations/update-user.gql'

const updateUser = (input) => {
  // console.log('Logger::updateUser::input', input)

  return client.mutate({
    mutation: updateUserGql,
    variables: input,
    update: (store, { data }) => {
      if (data.updateUser) {
        // update to clients cache
        store.writeQuery({
          query: userGql,
          variables: {
            id: input.id
          },
          data: {
            user: data.updateUser
          }
        })
      }
    }
  })
}

export default updateUser
