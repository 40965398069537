import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '@wrappers/Dashboard'
import { Title } from '@lib'
import UserList from '@shared/UserList'

const Content = ({ user }) => {
  return (
    <Fragment>
      <Title text='Users' user={user}/>

      <UserList
        links={{
          view: '/settings/user',
          edit: '/settings/user/edit'
        }}
      />
    </Fragment>
  )
}

Content.propTypes = {
  user: PropTypes.object
}

const Users = () => {
  return (
    <Dashboard>
      <Content />
    </Dashboard>
  )
}

export default Users
