import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'

import { subject } from '@casl/ability'
import { Can } from '@caslConfig/can.js'
import { Icon as Iconify } from '@iconify/react'

import { Datatable, Icon, Tooltip } from '@lib'
import { Link } from '@shared'
import { ListView } from '@shared/ListView'

import authGql from '@graphql/queries/auth'
import usersGql from '@graphql/queries/users'
import resendUserInvite from '@graphql/mutators/resend-user-invite'
import triggerPasswordReset from '@graphql/mutators/trigger-password-reset'
import updateUser from '@graphql/mutators/update-user'
import updateResetMfa from '@graphql/mutators/update-reset-mfa'
import destroyUser from '@graphql/mutators/destroy-user'

const UserList = ({ links, setError }) => {
  // get logged in user info
  const authQuery = useQuery(authGql)
  const loggedInUser = authQuery.data.auth

  const [loadOverlay, setLoadOverlay] = useState({
    display: false,
    progress: 0
  })

  const handleSendEmail = (user) => {
    Swal.fire({
      text: `Are you sure you want to ${!user.isActivated ? 'resend activation link' : 'trigger password reset'}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${!user.isActivated ? 'Resend Activation' : 'Trigger Reset'}`,
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        if (!user.isActivated) {
          resendUserInvite({
            email: user.email
          }).then(({ data }) => {
            if (data.resendUserInvite) {
              Swal.fire({
                text: 'Account activation email sent!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              })
            }
          })
        } else {
          triggerPasswordReset({
            email: user.email
          }).then(({ data }) => {
            if (data.triggerPasswordReset) {
              Swal.fire({
                text: 'Reset password email sent!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
              })
            }
          })
        }
      }
    })
  }

  const handleUserLock = (user) => {
    Swal.fire({
      text: `Are you sure you want to ${user.isLocked ? 'unlock' : 'lock'} this account?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: (user.isLocked ? 'Unlock Account' : 'Lock Account'),
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        updateUser({
          id: user.id,
          isLocked: !user.isLocked
        }).then(({ data }) => {
          if (data.updateUser) {
            Swal.fire({
              text: `User account ${user.isLocked ? 'unlocked' : 'locked'}`,
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
      }
    })
  }

  const handleResetMfa = (user) => {
    const id = user.id
    Swal.fire({
      text: 'Do you want to reset 2FA for this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Reset',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        updateResetMfa({
          id
        }).then((data) => {
          Swal.fire({
            text: '2FA has been reset',
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          })
        })
      }
    })
  }

  const handleUserDelete = (user) => {
    Swal.fire({
      text: 'Are you sure you want to delete this account?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((value) => {
      if (value.isConfirmed) {
        setLoadOverlay(prevState => ({ ...prevState, display: true }))
        destroyUser({
          id: user.id
        }).then(({ data, extensions }) => {
          if (extensions?.errors) {
            setLoadOverlay(prevState => ({ ...prevState, display: false }))
            Swal.fire({
              text: 'Error deleting user',
              icon: 'error',
              showConfirmButton: false,
              timer: 3000
            })
          } else if (data.destroyUser) {
            setLoadOverlay(prevState => ({ ...prevState, progress: 100 }))
            Swal.fire({
              text: 'User account destroyed',
              icon: 'success',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              setLoadOverlay(prevState => ({ ...prevState, display: false }))
            })
            usersQuery.refetch()
          }
        })
      }
    })
  }

  const getLastLoginDuration = (datetime) => {
    const date = new Date(datetime)
    const now = new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)

    const diff = now - date

    const minute = 60 * 1000
    const hour = 60 * minute
    const day = 24 * hour

    if (diff < minute) {
      return 'Just now'
    } else if (diff < hour) {
      const minutes = Math.floor(diff / minute)
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`
    } else if (diff < day) {
      const hours = Math.floor(diff / hour)
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`
    } else {
      const days = Math.floor(diff / day)
      return days === 1 ? '1 day ago' : `${days} days ago`
    }
  }

  const columns = useMemo(() =>
    [
      {
        Header: 'Username',
        accessor: 'username'
        // Filter: SelectColumnFilter,
        // filter: 'includes'
      },
      {
        Header: 'Full Name',
        accessor: 'fullName'
      },
      {
        Header: 'Role',
        accessor: 'systemRole.name'
      },
      {
        Header: 'Activation Status',
        disableSortBy: true,
        accessor: u => {
          return (
            <div className='activation-status'>
              {!u.isActivated && (
                <p className='not-activated'>Not Activated</p>
              )}

              {(u.isActivated && (!u.isMfaScanned || !u.isTosAccepted)) && (
                <p className='partial'>Partial</p>
              )}

              {u.isActivated && u.isTosAccepted && (
                <p className='onboarded'>Onboarded</p>
              )}
            </div>
          )
        }
      },
      {
        Header: 'Last Login',
        accessor: 'lastLoginAt',
        Cell: function createLastLoginColumn ({ value }) {
          return (
            <div> { value !== 'Invalid Date' ? getLastLoginDuration(value) : '-' } </div>
          )
        }
      },
      {
        id: 'actionBtnsCol',
        accessor: u => u,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          const isActivated = value.isActivated

          return (
            <div className='action-buttons'>
              {loggedInUser.id !== value.id && (
                <>
                  <Can I='update' this={subject('User', { clientId: value.clientId })}>
                    <Link
                      className='action-button edit-button'
                      to={`${links.edit}/${value.id}`}
                      icon='fas fa-pencil-alt'
                      id={`${value.id}-edit`}
                    />
                    <Tooltip
                      anchorId={`${value.id}-edit`}
                      content='Edit'
                    />
                  </Can>

                  <Can I='resetMfa' this={subject('User', { clientId: value.clientId, systemRole: value.systemRole.name })}>

                    <div
                      onClick={() => handleResetMfa(value)}
                      className='action-button'
                      id={`${value.id}-reset`}
                    >
                  <Iconify width='45px' icon='tabler:2fa' />
                    </div>
                    <Tooltip
                      anchorId={`${value.id}-reset`}
                      content='Reset MFA'
                    />

                  </Can>
                  <Can I='resend' this={subject('User', { systemRole: value.systemRole.name })}>
                    <Can I='reset' this={subject('User', { systemRole: value.systemRole.name })}>
                      <div
                        onClick={() => handleSendEmail(value)}
                        className='action-button email-button'
                        id={`${value.id}-email`}
                      >
                        { !isActivated
                          ? (
                          <Icon
                            icon='icon-park-outline:send-email'
                            iconify={true}
                          />
                            )
                          : (
                          <div className='reset-password'>
                            <Icon
                              icon=':ic:outline-lock-reset'
                              iconify={true}
                            />
                          </div>
                            )
                        }
                      </div>
                      <Tooltip
                        anchorId={`${value.id}-email`}
                        content={ !isActivated ? 'Resend email activation' : 'Trigger password reset'}
                      />
                    </Can>
                  </Can>
                  <Can I='lock' this={subject('User', { systemRole: value.systemRole.name })}>
                    <div onClick={() => handleUserLock(value)} className='action-button lock-button' id={`${value.id}-lock-unlock`}>
                      {value.isLocked &&
                        <Icon className='far fa-lock-open'/>
                      }

                      {!value.isLocked &&
                        <Icon className='far fa-lock'/>
                      }
                    </div>
                    <Tooltip
                      anchorId={`${value.id}-lock-unlock`}
                      content={value.isLocked ? 'Unlock Account' : 'Lock Account'}
                    />
                  </Can>
                  <Can I='delete' this={subject('User', { clientId: value.clientId })}>
                    <div
                      onClick={() => handleUserDelete(value)}
                      className='action-button delete-button'
                      id={`${value.id}-delete`}
                    >
                      <Icon
                        className='fas fa-trash'
                      />
                    </div>
                    <Tooltip
                      anchorId={`${value.id}-delete`}
                      content='Delete'
                    />
                  </Can>
                </>
              )}
            </div>
          )
        }
      }
    ], []
  )

  const usersQuery = useQuery(usersGql, {
    // variables
  })
  // console.log(usersQuery)
  if (usersQuery.loading) {
    return null
  }
  if (usersQuery?.error?.message === 'Access Denied') {
    // console.log(usersQuery)
    setError(usersQuery.error.message)
    return null
  }
  const { users } = usersQuery.data
  const sortedUsers = [...users].sort((a, b) => {
    return b.id - a.id
  })
  // users = usersQuery.data.users

  return (
    <ListView>
      <Can I='create' a='User'>
        <Datatable
          columns={columns}
          data={sortedUsers}
          sortDesc={false}
          newRowBtn={{
            icon: 'fas fa-plus',
            text: 'New User',
            to: '/settings/user/new'
          }}
        />
      </Can>
      <Can not I='create' a='User'>
        <Datatable
          columns={columns}
          data={users}
        />
      </Can>
    </ListView>
  )
}

UserList.propTypes = {
  accountId: PropTypes.string,
  links: PropTypes.object,
  fields: PropTypes.object,
  itemClass: PropTypes.string,
  onDelete: PropTypes.func,
  filters: PropTypes.object,
  value: PropTypes.object,
  setError: PropTypes.func
}

UserList.defaultProps = {
  accountId: null,
  links: {
    view: '',
    edit: ''
  },
  fields: {
    avatar: true,
    username: true,
    name: true,
    userType: true,
    action: true
  },
  onDelete: () => {}, // This is intentional
  filters: {},
  value: null
}

export default UserList
