import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import config from '@config'
import { axiosGet } from '@utils'

const ResourceCard = ({ resource }) => {
  const handleLink = () => {
    window.open('/help-center/trainings-and-use-cases/view/' + resource.id, '_blank')
  }
  const [imgSrc, setImgSrc] = useState('')
  useEffect(() => {
    const getResourceImage = async () => {
      const response = await axiosGet(`s3/resource-image/${resource.image}`)
      setImgSrc(response.data.url)
    }
    getResourceImage()
  }, [])

  return (
    <div onClick={() => handleLink()} className='resource-card'>
      <div className='resource-image'>
        <img src={imgSrc}/>
      </div>
      <div className='resource-info'>
        <h2>{resource.name}</h2>
        <p>{resource.summary}</p>
      </div>
    </div>
  )
}
ResourceCard.propTypes = {
  resource: PropTypes.object
}

export default ResourceCard
