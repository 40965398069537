import React from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import updateMfa from '@graphql/mutators/update-mfa'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'

import AuthenticationWrapper from '@shared/AuthenticationWrapper'

dayjs.extend(utc)
dayjs.extend(relativeTime)

const Content = ({ mfaUser }) => {
  const navigate = useNavigate()
  const mfaToken = localStorage.getItem('MfaToken')

  if (mfaUser.data.mfa.isUserClientEmailEnabled) {
    try {
      const newUser = mfaUser.data.mfa
      if (newUser.isMfaScanned === false && (newUser.mfaMethod === '' || newUser.mfaMethod === null)) {
        return (
            <Navigate replace to='/authenticate/mfaselect' />
        )
      } else if (newUser.isMfaScanned && newUser.mfaMethod === 'EMAIL') {
        return (
            <Navigate replace to='/authenticate/mfacode' />
        )
      } else if (newUser.isMfaScanned && newUser.mfaMethod === 'AUTHENTICATOR') {
        return (
            <Navigate replace to='/authenticate/verify' />
        )
      }
    } catch (err) {
      // console.log(err)
    }
  } else {
    try {
      const newUser = mfaUser.data.mfa
      if (newUser.isMfaScanned === false && newUser.mfaMethod === '') {
        return (
            <Navigate replace to='/authenticate/scan' />
        )
      } else if (newUser.isMfaScanned && newUser.mfaMethod) {
        return (
            <Navigate replace to='/authenticate/verify' />
        )
      }
    } catch (err) {
      // console.log(err)
    }

    const handleMfaSelection = (mfaMethod) => {
      updateMfa({
        mfaMethod,
        mfaToken
      }).then(function ({ data }) {
        if (data.updateMfa) {
          navigate('/authenticate/scan')
        } else {
          // setInputErrors(array.groupBy(extensions.errors, 'path'))
        }
      })
    }

    handleMfaSelection('AUTHENTICATOR')
  }

  return (
    <></>
  )
}
Content.propTypes = {
  user: PropTypes.object,
  mfaUser: PropTypes.object
}

const Authenticate = () => {
  return (
    <AuthenticationWrapper>
      <Content />
    </AuthenticationWrapper>
  )
}

export default Authenticate
