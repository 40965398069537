const properCase = (str) => {
  if (typeof str !== 'string') {
    return ''
  }

  return str.charAt(0) + str.slice(1)
}

export default {
  properCase
}
