import React from 'react'
import PropTypes from 'prop-types'

const PasswordValidationChecklist = ({ input }) => {
  const validationItems = [
    {
      text: 'Must be at least 12 characters',
      pattern: /[A-Za-z\d@$!%*#?&-~.,:;^]{12,}/
    },
    {
      text: 'Must contain uppercase character(s)',
      pattern: /.*[A-Z]/
    },
    {
      text: 'Must contain lowercase character(s)',
      pattern: /.*[a-z]/
    },
    {
      text: 'Must contain at least one number',
      pattern: /.*\d/
    },
    {
      text: 'Must contain at least one special character',
      pattern: /.*[^a-zA-Z0-9\s]/
    }
  ]

  if (document.querySelector('.password-btn')) {
    if (input.password.length > 0) {
      document.querySelector('.password-btn').parentElement.classList.remove('password-margin-top')
      document.querySelector('.password-btn').parentElement.classList.add('password-no-margin-top')
    } else {
      document.querySelector('.password-btn').parentElement.classList.remove('password-no-margin-top')
      document.querySelector('.password-btn').parentElement.classList.add('password-margin-top')
    }
  }

  let validChecks = 0
  validationItems.forEach(v => {
    if (v.pattern.test(input.password)) {
      validChecks++
    }
  })

  return (
    <div data-attribute-id='password_requirement_container' className='password-validation-wrapper'>
      {input.password.length > 0 && (
        <>
        <div className='password-validator-div'>
          <div className='pass-val-bars'>
            <div className={`pass-val-bar bar-1 ${validChecks > 4 ? 'good' : validChecks > 3 ? 'avg' : 'poor'}`}></div>
            <div className={`pass-val-bar bar-2 ${validChecks > 4 ? 'good' : validChecks > 3 ? 'avg' : ''}`}></div>
            <div className={`pass-val-bar bar-3 ${validChecks > 4 && 'good'}`}></div>
          </div>
          <p className={`pass-val-text ${validChecks > 4 ? 'good' : validChecks > 3 ? 'avg' : 'poor'}`}>Strength: <span>{validChecks > 4 ? 'Good' : validChecks > 3 ? 'Average' : 'Poor'}</span></p>
        </div>

        <div data-attribute-id='password_requirement_checklist' className='validation-checklist'>
          {validationItems.map((vI, key) => (
            <div data-attribute-id='password_requirement_item' key={key} className={`validation-checklist-item ${vI.pattern.test(input.password) && 'valid'}`}>
              <i data-attribute-id='password_requirement_item_icon' className={`fas ${vI.pattern.test(input.password) ? 'fa-check-circle' : 'fa-exclamation-circle'} fa-lg`}></i>
              <p data-attribute-id='password_requirement_item_txt'>{vI.text}</p>
            </div>
          ))}
        </div>
        </>
      )}
    </div>
  )
}
PasswordValidationChecklist.propTypes = {
  input: PropTypes.object
}
PasswordValidationChecklist.defaultProps = {
  input: {
    password: ''
  }
}

export default PasswordValidationChecklist
