import client from '../client.js'
import destroyAlertGql from '../mutations/destroy-alert.gql'

const destroyAlert = (input) => {
  return client.mutate({
    mutation: destroyAlertGql,
    variables: input,
    update: (store, { data }) => {
      // This is intentional
    }
  })
}

export default destroyAlert
