import client from '../client.js'
import releaseNotesGql from '../queries/release-notes.gql'
import createReleaseNoteGql from '../mutations/create-release-note.gql'

const createReleaseNote = (input) => {
  return client.mutate({
    mutation: createReleaseNoteGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createReleaseNote) {
        try {
          // get releaseNotes cache
          const cache = store.readQuery({
            query: releaseNotesGql
          })

          // add to releaseNotes cache
          store.writeQuery({
            query: releaseNotesGql,
            data: {
              releaseNotes: [...cache.releaseNotes, data.createReleaseNote]
            }
          })
        } catch (error) {
          // re-query releaseNotes
          client.query({
            query: releaseNotesGql
          })
        }
      }
    }
  })
}

export default createReleaseNote
