import client from '../client.js'
import masterReferencesGql from '../queries/master-references.gql'
import createMasterReferenceGql from '../mutations/create-master-reference.gql'

const createMasterReference = (input) => {
  return client.mutate({
    mutation: createMasterReferenceGql,
    variables: input,
    update: (store, { data }) => {
      if (data.createMasterReference) {
        try {
          // get masterReferences cache
          const cache = store.readQuery({
            query: masterReferencesGql
          })

          // add to masterReferences cache
          store.writeQuery({
            query: masterReferencesGql,
            data: {
              masterReferences: [...cache.masterReferences, data.createMasterReference]
            }
          })
        } catch (error) {
          // re-query masterReferences
          client.query({
            query: masterReferencesGql
          })
        }
      }
    }
  })
}

export default createMasterReference
