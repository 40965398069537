import client from '../client.js'
import updateClientSummaryLogGql from '../mutations/update-finance-summary-log.gql'

const updateClientSummaryLog = (input) => {
  return client.mutate({
    mutation: updateClientSummaryLogGql,
    variables: input
  })
}

export default updateClientSummaryLog
