import React, { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import { Datatable, Tooltip } from '@lib'
import { SubscriptionForm } from '@shared'
import { time } from '@utils'
import { ListView } from '@shared/ListView'

import subscriptionsGql from '@graphql/queries/subscriptions'
import updateSubscription from '@graphql/mutators/update-subscription'
import destroySubscription from '@graphql/mutators/destroy-subscription'

const SubscriptionList = ({ user, memberOf }) => {
  const [state, setState] = useState({
    selArr: []
  })
  const [showModal, setShowModal] = useState(false)
  const [subscriptionToEdit, setSubscriptionToEdit] = useState({})

  const subscriptionsQuery = useQuery(subscriptionsGql, {
    variables: {
      fetchPolicy: 'network-only',
      subscriptionTab: window.location.hash === '' ? '#my-subscriptions' : window.location.hash,
      ...(memberOf ? { memberEmail: user.email } : { ownerId: user.id })
    }
  })

  useEffect(() => {
    subscriptionsQuery.refetch()
  }, [])

  const handlePause = (v, adminStop) => {
    const updateInput = {
      ids: [v.id],
      isPaused: (adminStop ? !v.isPaused : !v.members.find(m => m.member === user.email).isPaused),
      adminStop
    }
    updateSubscription(updateInput).then(({ data }) => {
      if (data.updateSubscription) {
        Swal.fire({
          text: updateInput.isPaused ? 'Subscription paused' : 'Subscription resumed',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        })
        subscriptionsQuery.refetch()
      }
    })
  }

  const handleDelete = (v) => {
    const destroySubscribe = (adminDelete) => {
      const delInput = {
        ids: [v.id],
        adminDelete
      }
      destroySubscription(delInput).then(({ data }) => {
        if (data.destroySubscription) {
          Swal.fire({
            text: 'Subscription deleted',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          })
          subscriptionsQuery.refetch()
        }
      })
    }

    if (user.username === v.user.username) {
      if (window.location.hash === '#my-subscriptions') {
        Swal.fire({
          text: 'Are you sure you want to unsubscribe from this subscription?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Unsubscribe',
          customClass: {
            actions: 'subscription-swal-actions'
          }
        }).then((results) => {
          if (results.isConfirmed) {
            destroySubscribe(true)
          }
        })
      } else {
        Swal.fire({
          text: 'You are the owner of this subscription. Would you like to unsubscribe, or delete for all members?',
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Delete For All',
          confirmButtonText: 'Unsubscribe Me',
          customClass: {
            actions: 'subscription-swal-actions'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // unsubscribe
            destroySubscribe()
          } else if (result.isDenied) {
            // delete for all
            destroySubscribe(true)
          }
        })
      }
    } else {
      Swal.fire({
        text: 'Are you sure you want to unsubscribe from this subscription?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Unsubscribe'
      }).then((result) => {
        if (result.isConfirmed) {
          // unsubscribe
          destroySubscribe()
        }
      })
    }
  }

  const checkChange = (v) => {
    if (v.target.value !== '') {
      if (v.target.checked) {
        setState(prevState => ({
          ...prevState,
          selArr: [...prevState.selArr, v.target.value]
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          selArr: prevState.selArr.filter(e => e !== v.target.value)
        }))
      }
    }

    let isChecked = false
    let isCheckedLength = 0
    const checkboxes = document.querySelectorAll('.checkbox-col > input')

    checkboxes.forEach(c => {
      if (c.checked) {
        isChecked = true
        isCheckedLength += 1
        document.querySelectorAll('.mass-action').forEach(el => { el.style.visibility = 'visible' })
      }
    })

    if (isCheckedLength === checkboxes.length) {
      document.querySelector('.subscription-mass-edit-wrap > input').checked = true
    } else {
      document.querySelector('.subscription-mass-edit-wrap > input').checked = false

      if (!isChecked) {
        document.querySelectorAll('.mass-action').forEach(el => { el.style.visibility = 'hidden' })
        document.querySelector('.subscription-mass-edit-wrap > input').checked = false
      }
    }

    const checkedBoxes = [...checkboxes].filter(c => c.checked)
    const isPauseArray = []
    checkedBoxes.forEach(cb => {
      const rowBtn = document.getElementById(cb.value + '-pause-resume').querySelector('i')
      isPauseArray.push(rowBtn.classList.contains('fa-pause'))
    })
    if (!isPauseArray.includes(false)) {
      document.getElementById('play-all').style.visibility = 'hidden'
    } else if (!isPauseArray.includes(true)) {
      document.getElementById('pause-all').style.visibility = 'hidden'
    }
  }

  const handleMassPause = (setPaused) => {
    const updateArr = [...state.selArr]
    if (updateArr.length > 0) {
      Swal.fire({
        text: 'Do you want to ' + (setPaused ? 'Pause ' : 'Resume ') + updateArr.length + ' subscriptions?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: (setPaused ? 'Pause' : 'Resume')
      }).then((result) => {
        if (result.isConfirmed) {
          const updateInput = {
            ids: updateArr,
            isPaused: setPaused,
            adminStop: window.location.hash !== '#set-subscriptions'
          }
          updateSubscription(updateInput).then(({ data }) => {
            if (data.updateSubscription) {
              Swal.fire({
                text: updateInput.isPaused ? 'Subscription paused' : 'Subscription resumed',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
              })
              subscriptionsQuery.refetch()
            }
          })
        }
      })
    } else {
      setState(prevState => ({
        ...prevState,
        selArr: []
      }))
    }
  }

  const handleMassDelete = () => {
    const delArr = []
    const massDestroySubscribe = (adminDelete) => {
      const delInput = {
        ids: delArr,
        adminDelete
      }
      destroySubscription(delInput).then(({ data }) => {
        if (data.destroySubscription) {
          Swal.fire({
            text: 'Subscriptions deleted',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          })
          document.querySelectorAll('.data-table tbody input[type="checkbox"]').forEach(c => {
            c.checked = null
          })
          subscriptionsQuery.refetch()
          setState(prevState => ({
            ...prevState,
            selArr: []
          }))
        }
      })
    }

    let allOwned = true
    state.selArr.forEach(sc => {
      if (document.querySelector('.data-table tbody input[type="checkbox"][value="' + sc + '"]')) {
        delArr.push(sc)
        const selSub = subscriptions.find(s => s.id === sc)
        if (selSub.user.username !== user.username) {
          allOwned = false
        }
      }
    })
    if (delArr.length > 0) {
      if (window.location.hash === '#my-subscriptions') {
        Swal.fire({
          text: 'Are you sure you want to unsubscribe from this subscription?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Unsubscribe',
          customClass: {
            actions: 'subscription-swal-actions'
          }
        }).then((results) => {
          if (results.isConfirmed) {
            massDestroySubscribe(true)
          }
        })
      } else {
        Swal.fire({
          text: (allOwned ? 'You are the owner of the selected subscriptions. Would you like to unsubscribe, or delete for all members?' : 'Unsubscribe from ' + delArr.length + ' subscriptions'),
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: allOwned,
          denyButtonText: 'Delete for All',
          confirmButtonText: 'Unsubscribe Me',
          customClass: {
            actions: 'subscription-swal-actions'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // unsubscribe
            massDestroySubscribe()
          } else if (result.isDenied) {
            // delete
            massDestroySubscribe(true)
          }
        })
      }
    } else {
      setState(prevState => ({
        ...prevState,
        selArr: []
      }))
    }
  }

  const checkSelAll = (v) => {
    const checkboxes = document.querySelectorAll('.data-table tbody input[type="checkbox"]')
    if (v.target.checked) {
      const newCheckArr = []
      checkboxes.forEach(c => {
        c.checked = 'checked'
        newCheckArr.push(c.value)
      })
      setState(prevState => ({
        ...prevState,
        selArr: [...newCheckArr]
      }))

      document.querySelectorAll('.mass-action').forEach(el => { el.style.visibility = 'visible' })
    } else {
      checkboxes.forEach(c => {
        c.checked = null
      })
      setState(prevState => ({
        ...prevState,
        selArr: []
      }))

      document.querySelectorAll('.mass-action').forEach(el => { el.style.visibility = 'hidden' })
    }

    const checkedBoxes = [...checkboxes].filter(c => c.checked)
    const isPauseArray = []
    checkedBoxes.forEach(cb => {
      const rowBtn = document.getElementById(cb.value + '-pause-resume').querySelector('i')
      isPauseArray.push(rowBtn.classList.contains('fa-pause'))
    })
    if (!isPauseArray.includes(false)) {
      document.getElementById('play-all').style.visibility = 'hidden'
    } else if (!isPauseArray.includes(true)) {
      document.getElementById('pause-all').style.visibility = 'hidden'
    }
  }

  const showForm = (s) => {
    setSubscriptionToEdit(s)
    setShowModal(true)
  }

  const closeForm = () => {
    setShowModal(false)
    setSubscriptionToEdit({})
    subscriptionsQuery.refetch()
  }

  const handlePreview = (v) => {
    let tabNav
    if (JSON.parse(localStorage.getItem('tabNavs'))?.length > 0) {
      tabNav = JSON.parse(localStorage.getItem('tabNavs'))[0]
    } else {
      tabNav = JSON.parse(user.userClients.find(uc => uc.clientId === user.client.id).navigation)
    }

    const searchWbs = (item) => {
      if (item.workbooks.find(w => w.id === v.workbookId)) {
        const foundWb = item.workbooks.find(w => w.id === v.workbookId)
        if (foundWb.views.find(view => view.id === v.viewId)) {
          const foundView = foundWb.views.find(view => view.id === v.viewId)
          let previewLink
          // if custom view and user owns, set previewLink to custom view
          if (v.customViewUrl) {
            const viewToLoad = v.customViewUrl.split('/views/')[1]
            previewLink = `/dashboard/${viewToLoad.split('/')[0]}/${viewToLoad.split('/')[1]}?loadView=${encodeURIComponent(viewToLoad)}`
          } else {
            previewLink = `/dashboard/${foundWb.contentUrl}/${foundView.viewUrlName}?loadView=og&${v.filterString ? v.filterString : '?filters'}`
          }

          window.open(previewLink, '_blank')
        } else {
          Swal.fire({
            text: 'View Not Found',
            icon: 'error',
            timer: 3000,
            showConfirmButton: false
          })
        }
      } else {
        item.children.forEach(ic => {
          searchWbs(ic)
        })
      }
    }

    if (tabNav && localStorage.getItem('tabNavs').search('"id":"' + v.workbookId + '"') > 0) {
      searchWbs(tabNav)
    } else {
      Swal.fire({
        text: 'Workbook Not Found',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      })
    }
  }

  const columns = useMemo(() =>
    [
      {
        id: 'checkboxCol',
        accessor: n => n,
        disableSortBy: true,
        Header: '',
        Cell: function createCheckboxCol ({ value }) {
          return (
            <div className='checkbox-col'>
              <input
                onChange={(v) => checkChange(v)}
                type='checkbox'
                value={value?.id}
              />
            </div>
          )
        }
      },
      // uncomment next line to add column filter on owner level
      // ...(memberOf ? [{ Header: 'Owner', accessor: 'user.username', Filter: SelectColumnFilter, filter: 'includes' }] : []),
      {
        Header: 'Subject',
        accessor: 'subject'
      },
      {
        Header: 'Schedule',
        accessor: n => {
          if (n.frequency === 'dataRefresh') {
            return 'On Data Refresh'
          } else if (n.schedule.includes('||')) {
            const schedParts = n.schedule.split('||')
            const schedFrame = schedParts[0].toLowerCase()
            const schedDay = schedParts[1]
            const schedWeekday = schedParts[2].toLowerCase()
            const schedTime = schedParts[3]

            if (schedFrame === 'daily') {
              return schedTime + ' ' + schedFrame
            } else if (schedFrame === 'weekly') {
              return schedTime + ' every ' + schedWeekday
            } else if (schedFrame === 'monthly') {
              if (schedDay === 'firstDay') {
                return schedTime + ' first day of each month'
              } else if (schedDay === 'lastDay') {
                return schedTime + ' last day of each month'
              } else if (schedDay.includes('specific')) {
                return schedTime + ' ' + schedDay.replace('specific ', '') + ' ' + schedWeekday + ' of each month'
              }
            }
            return n.schedule
          }
        }
      },
      {
        Header: 'Last Ran At',
        accessor: n => {
          if (user.timezone) {
            const formattedTime = time.utc(n.lastRanAt).tz(user.timezone).format('MM/DD/YYYY hh:mm A')
            if (formattedTime === 'Invalid Date' || formattedTime === null) {
              return '-'
            } else {
              return formattedTime
            }
          } else {
            if (n.lastRanAt === 'Invalid Date' || n.lastRanAt === null) {
              return '-'
            } else {
              return n.lastRanAt
            }
          }
        }
      },
      {
        id: 'actionBtnsCol',
        accessor: n => n,
        disableSortBy: true,
        Header: 'Actions',
        Cell: function createActionButtons ({ value }) {
          return (
            <div className='action-buttons'>
              {!value.customViewUrl || value.createdBy === user.id
                ? <>
                <div
                  className='action-button'
                  id={`${value.id}-preview`}
                  onClick={() => handlePreview(value)}
                >
                  <i className='fas fa-eye'></i>
                </div>
                <Tooltip
                  anchorId={`${value.id}-preview`}
                  content='Preview'
                />
              </>
                : <div className='blank-space'></div>
              }

              {!memberOf &&
              <>
                <div onClick={() => showForm(value)} className='action-button edit-button' id={`${value.id}-edit`}>
                  <i className='fas fa-pencil-alt'></i>
                </div>
                <Tooltip
                  content='Edit'
                  anchorId={`${value.id}-edit`}
                />
                <div onClick={() => handlePause(value, true)} className='action-button subscription-pause-button' id={`${value.id}-pause-resume`}>
                  <i className={'fas ' + ((value?.isPaused) ? 'fa-play' : 'fa-pause')}></i>
                </div>
                <Tooltip
                  content={ value.isPaused ? 'Resume' : 'Pause' }
                  anchorId={`${value.id}-pause-resume`}
                />
              </>
              }
              {window.location.hash === '#member-subscriptions' &&
                <div>
                  { value?.members.find(m => m.member === user.username) && <div onClick={() => handlePause(value)} className={'action-button subscription-pause-button ' + (value?.members.find(m => m.member === user.email)?.isPaused ? 'play' : '')}><i className={'fas ' + (value?.members.find(m => m.member === user.email)?.isPaused ? 'fa-play' : 'fa-pause')}></i></div> }
                </div>
              }
              {
              window.location.hash === '#set-subscriptions' &&
              <>
                <div onClick={() => handlePause(value)} className='action-button subscription-pause-button' id={`${value.id}-pause-resume`}>
                  <i className={'fas ' + (value.members.find(x => x.member === user.username).isPaused ? 'fa-play' : 'fa-pause')}></i>
                </div>
                <Tooltip
                  content={ value.members.find(x => x.member === user.username).isPaused ? 'Resume' : 'Pause' }
                  anchorId={`${value.id}-pause-resume`}
                />
              </>
              }
              <div onClick={() => handleDelete(value)} className='action-button delete-button' id={`${value.id}-delete`}>
                <i className='fas fa-trash'></i>
              </div>
              <Tooltip
                content='Delete'
                anchorId={`${value.id}-delete`}
              />
            </div>
          )
        }
      }
    ], []
  )

  if (subscriptionsQuery.loading || subscriptionsQuery.error) {
    return null
  }
  const { subscriptions } = subscriptionsQuery.data
  if (!subscriptions) {
    return null
  }

  return (
    <div className='subscription-list-wrap'>
    <ListView>
      <div className='subscription-mass-edit-wrap'>
        <div className='checkbox'>
          <input type='checkbox' onChange={(v) => checkSelAll(v)} />
        </div>
        <div onClick={() => handleMassPause(true)} className='mass-action action-button' id='pause-all'><i className={'fas fa-pause'}></i></div>
        <Tooltip content='Pause' anchorId='pause-all'/>

        <div onClick={() => handleMassPause(false)} className='mass-action action-button' id='play-all'><i className={'fas fa-play'}></i></div>
        <Tooltip content='Resume' anchorId='play-all'/>

        <div onClick={() => handleMassDelete()} className='mass-action action-button delete-button' id='delete-all'><i className='fas fa-trash'></i></div>
        <Tooltip content='Delete' anchorId='delete-all'/>
      </div>
      <Datatable
        columns={columns}
        data={subscriptions}
      />
    </ListView>
    {showModal && <SubscriptionForm showModal={showModal} subscription={subscriptionToEdit} closeModal={closeForm}/> }
    </div>
  )
}
SubscriptionList.propTypes = {
  user: PropTypes.object,
  memberOf: PropTypes.bool,
  subscriptionTab: PropTypes.string
}
SubscriptionList.defaultProps = {
  memberOf: false
}

export default SubscriptionList
