import client from '../client.js'
import systemSettingsGql from '@graphql/queries/system-settings'
import updateSystemSettingGql from '../mutations/update-system-settings.gql'

const updateSystemSetting = (input) => {
  return client.mutate({
    mutation: updateSystemSettingGql,
    variables: input
    // update: (store, { data }) => {
    //   store.writeQuery({
    //     query: systemSettingsGql,
    //     data: {
    //       systemSettings: data.updateSystemSettings
    //     }
    //   })
    // }
  })
}

export default updateSystemSetting
